import React, { useEffect, useState } from "react";

import {
    Autocomplete,
    Chip,
    // Paper,
    TextField
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    // container: {
    //     padding: `0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    //     borderRadius: 0
    // },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        '&:hover': {
            filter: 'brightness(120%)',
        },
    },
    chip: {
        margin: theme.spacing(1),
    }
}));

export function TagsFilter({ onFiltered }) {
    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const controller = new AbortController();

        async function fetchData() {
            await loadTags();
        }
        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadTags = async () => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value) => {
        setSelecteds(value);
        onFiltered(value);
    }

    return (
        // <Paper className={classes.container}>
        <Autocomplete
            multiple
            size="small"
            options={tags}
            value={selecteds}
            onChange={(e, v, r) => onChange(v)}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        color="secondary"
                        sx={{
                            '& .MuiChip-deleteIcon': {
                                color: option.color,
                                transition: '1.5s'
                            },
                            '& .MuiChip-deleteIcon:hover': {
                                color: 'red',
                            },
                        }}
                        style={{
                            backgroundColor: option.color + "20",
                            borderColor: option.color,
                            color: option.color,
                        }}
                        variant="outlined"
                        label={option.name}
                        className={classes.chip}
                        {...getTagProps({ index })}
                        size="small"
                    />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder={i18n.t("translation.tags.placeholder.filterByName")} />
            )}
        />
        // </Paper>
    )
}
