import { Button } from '@mui/material';
import { i18n } from '../../translate/i18n';
import DownloadCSV from '../DownloadCSV';

export default function DownloadExampleFile(props) {
    // TODO: Componetize
    const goToExampleFile = () => {
        const content = [{
            name: i18n.t("translation.variables.table.name"),
            // countryCode: i18n.t("translation.variables.table.countryCode"),
            // areaCode: i18n.t("translation.variables.table.areaCode"),
            phoneNumber: i18n.t("translation.variables.table.phone")
        }, {
            name: "Bits",
            // countryCode: 55,
            // areaCode: 61,
            phoneNumber: "+55 (61) 4042-8274"
        }
        ]
        DownloadCSV(
            i18n.t("translation.campaigns.buttons.example.fileName") + "_-_" + i18n.t("translation.validation.locale") + '.csv',
            content,
            'text/csv; charset=utf-8'
        );
    }

    return (
        <Button
            variant={props?.variant || "contained"}
            color="primary"
            onClick={goToExampleFile}
            {...props}
        >
            {i18n.t("translation.campaigns.buttons.example.title")}
        </Button>
    );
}
