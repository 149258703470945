import { Colorize } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    gridClasses,
    styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid } from "@mui/x-data-grid";

import { Formik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import ColorPicker from "../ColorPicker";

// Hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePlan from "../../hooks/usePlan";
import useTenant from "../../hooks/useTenant";

// Components
import DownloadExampleFile from "../DownloadExampleFile";
import FormContainer from "../FormComponents/FormContainer";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import TextFieldInput from "../FormComponents/TextFieldInput";

// Style for Dialog Actions
const StyledDialogActions = styled(DialogActions)({
    justifyContent: "space-between", // Aligns items to the edges
});

// Style for DropZone
// Start:
const baseStyle = {
    alignItems: "center",
    backgroundColor: "#fafafa",
    borderColor: "#131B43", // theme.palette.primary.main,
    borderRadius: 15,
    borderStyle: "dashed",
    borderWidth: 1,
    color: "#131B43", // theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: "8px",
    outline: "none",
    padding: "20px",
    transition: "border .24s ease-in-out",
    minWidth: 365,
    width: "100%",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};
// End.

const useStyles = makeStyles((theme) => ({
    actionButtonsBox: {
        display: "flex",
        gap: theme.spacing(1),
    },
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.23)",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    btnWrapper: {
        position: "relative",
    },
    colorAdorment: {
        width: 20,
        height: 20,
        borderRadius: 4,
    },
    dataGridContainer: {
        minHeight: 100,
        width: "100%",
    },
    dialogAction: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        gap: theme.spacing(2),
        flexDirection: "row",
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

const ContactGroupSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(250, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    description: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(500, `${i18n.t("translation.validation.too_long")}`),
    color: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(9, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

export function ContactGroupsModal({ open, onClose }) {
    const classes = useStyles();

    // Context
    const { getPlanValue } = usePlan();
    const { tenantId } = useTenant();

    // State
    const [contacts, setContacts] = useState([]);
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
    const initialState = {
        name: "",
        description: "",
        color: "",
        contacts: [],
    };
    const [rows, setRows] = useState([]);
    const [csvFile, setCsvFile] = useState([]);

    // API
    const descriptionRef = useRef();
    const axiosPrivate = useAxiosPrivate();
    const { isDragActive, isDragAccept, isDragReject } = useDropzone();
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const columns = useMemo(
        () => [
            {
                field: "id",
                headerName: i18n.t("translation.variables.table.id"),
                flex: 0.07,
                minWidth: 30,
                maxWidth: 70,
                headerAlign: "center",
                align: "center",
            },
            {
                field: "name",
                headerName: i18n.t("translation.variables.table.name"),
                flex: 0.07,
                minWidth: 30,
                align: "left",
                description: i18n.t(
                    "translation.contactGroupModal.table.descriptions.name"
                ),
            },
            {
                field: "number",
                headerName: i18n.t("translation.variables.table.phone"),
                flex: 0.07,
                minWidth: 30,
                headerAlign: "center",
                align: "center",
                description: i18n.t(
                    "translation.bot.table.descriptions.protocol"
                ),
                renderCell: (params) => `+${params.row.number}`,
            },
        ],
        []
    );

    useEffect(() => {
        if (!contacts) return;

        let dataGridRows = contacts.map((contact, i) => ({
            id: i + 1,
            name: contact[0],
            number: +cleanNumber(contact[1]),
        }));

        setRows(dataGridRows);
    }, [contacts]);

    function cleanNumber(number) {
        return number
            .replaceAll("-", "")
            .replaceAll(" ", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("+", "")
            .replaceAll(".", "")
            .replaceAll("_", "");
    }

    function handleClose() {
        setCsvFile([]);
        setContacts([]);
        onClose();
    }

    async function handleSave(values) {
        try {
            await axiosPrivate.post(`/${tenantId}/contactGroups`, {
                ...values,
                contacts: rows,
            });
            toast.success(
                i18n.t("translation.contactGroupsModal.toasts.success")
            );
            handleClose();
        } catch {
            toast.error(i18n.t("translation.contactGroupsModal.toasts.error"));
        }
    }

    const checkFile = (files, rejectedFiles) => {
        if (files[0].e) {
        }
        if (rejectedFiles.length > 0) {
            toastError(i18n.t("translation.validation.medias.fileTooBig"));
        } else {
            var reader = new FileReader();
            let contactList = [];
            try {
                reader.onload = function (e) {
                    let contactListCsv = reader.result.split("\n");
                    contactListCsv.shift();

                    // Only load till the contact limit on Tenant's current plan\
                    const limit = getPlanValue("campaignMaxContacts");

                    // Start processing contacts
                    contactListCsv.slice(0, limit).forEach((contactRow) => {
                        // For each row, clean up
                        if (contactRow && contactRow !== "") {
                            contactRow.replace(/\r?\n|\r/g, "");
                            let currentRow = contactRow.split(";");
                            contactList.push(currentRow);
                        }
                    });

                    // Filter contact list
                    contactList = contactList
                        .filter((x) => x[1] !== "")
                        .filter((x) => x[1]);
                    setContacts(contactList);

                    // If contact list is greater than the limit, alert user
                    if (contactListCsv.length > limit) {
                        toast.error(
                            i18n.t(
                                "translation.campaigns.toasts.error.maxContactsReached",
                                {
                                    maxContacts: getPlanValue(
                                        "campaignMaxContacts"
                                    ),
                                }
                            )
                        );
                    }
                };

                reader.readAsText(files[0]);
                setCsvFile(files);
            } catch (err) {
                toastError(
                    i18n.t("translation.validation.medias.notCSV") + "\n" + err
                );
            }
        }
    };

    return (
        open && (
            <div className={classes.root}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    scroll="paper"
                >
                    <DialogTitle>
                        {i18n.t("translation.contactGroupsModal.title.create")}
                    </DialogTitle>
                    <Formik
                        initialValues={initialState}
                        enableReinitialize={true}
                        validationSchema={ContactGroupSchema}
                        onSubmit={(values, actions, e) => {
                            setTimeout(() => {
                                handleSave(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({
                            errors,
                            isSubmitting,
                            setFieldValue,
                            touched,
                            values,
                        }) => (
                            <FormContainer>
                                <DialogContent dividers>
                                    <MultiFieldLine>
                                        <TextFieldInput
                                            name="name"
                                            label={i18n.t(
                                                "translation.whatsappModal.form.name"
                                            )}
                                            size="small"
                                            autoFocus
                                            required
                                            disabled={isSubmitting}
                                            error={
                                                touched.name &&
                                                Boolean(errors.name)
                                            }
                                            helperText={
                                                touched.name && errors.name
                                            }
                                        />
                                        <TextFieldInput
                                            name="color"
                                            label={i18n.t(
                                                "translation.queueModal.form.color"
                                            )}
                                            size="small"
                                            onFocus={() => {
                                                setColorPickerModalOpen(true);
                                                descriptionRef.current.focus();
                                            }}
                                            required
                                            disabled={isSubmitting}
                                            error={
                                                touched.color &&
                                                Boolean(errors.color)
                                            }
                                            helperText={
                                                touched.color && errors.color
                                            }
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    values.color,
                                                            }}
                                                            className={
                                                                classes.colorAdorment
                                                            }
                                                        ></div>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <IconButton
                                                        size="small"
                                                        color="default"
                                                        onClick={() =>
                                                            setColorPickerModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <Colorize />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        <ColorPicker
                                            open={colorPickerModalOpen}
                                            handleClose={() =>
                                                setColorPickerModalOpen(false)
                                            }
                                            onChange={(color) =>
                                                setFieldValue("color", color)
                                            }
                                        />
                                    </MultiFieldLine>

                                    <TextFieldInput
                                        name="description"
                                        label={i18n.t(
                                            "translation.contactGroupModal.form.description"
                                        )}
                                        size="small"
                                        required
                                        disabled={isSubmitting}
                                        error={
                                            touched.description &&
                                            Boolean(errors.description)
                                        }
                                        helperText={
                                            touched.description &&
                                            errors.description
                                        }
                                        inputRef={descriptionRef}
                                        multiline
                                        minRows={3}
                                    />

                                    <Dropzone
                                        onDrop={checkFile}
                                        accept={{
                                            "text/plain": [".csv"],
                                            "text/csv": [".csv"],
                                            "text/x-csv": [".csv"],
                                            "application/csv": [".csv"],
                                            "text/comma-separated-values": [
                                                ".csv",
                                            ],
                                            "text/x-comma-separated-values": [
                                                ".csv",
                                            ],
                                            "text/tab-separated-values": [
                                                ".csv",
                                            ],
                                            "application/vnd.ms-excel": [
                                                ".csv",
                                            ],
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ style })}>
                                                <input {...getInputProps()} />
                                                <p>
                                                    {csvFile.length !== 0
                                                        ? csvFile[0].name
                                                        : i18n.t(
                                                            "translation.campaigns.form.addContacts"
                                                        )}
                                                </p>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div
                                        className={classes.dividerSilent}
                                    ></div>

                                    <div>
                                        <Accordion
                                            expanded={contacts.length > 0}
                                            className={classes.accordion}
                                        >
                                            <AccordionSummary>
                                                {i18n.t(
                                                    "translation.contactGroupsModal.contacts",
                                                    { count: contacts.length }
                                                )}
                                            </AccordionSummary>

                                            <AccordionDetails
                                                className={classes.flexWrap}
                                            >
                                                <Box
                                                    className={
                                                        classes.dataGridContainer
                                                    }
                                                >
                                                    <DataGrid
                                                        checkboxSelection
                                                        disableRowSelectionOnClick
                                                        rows={rows}
                                                        columns={columns}
                                                        autoHeight={true}
                                                        getRowHeight={() =>
                                                            "auto"
                                                        }
                                                        pageSize={100}
                                                        getRowId={(row) =>
                                                            row.id
                                                        }
                                                        sx={{
                                                            [`& .${gridClasses.cell}`]:
                                                            {
                                                                py: 1,
                                                            },
                                                        }}
                                                        rowsPerPageOptions={[
                                                            20, 50, 100,
                                                        ]}
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </DialogContent>
                                <StyledDialogActions>
                                    <DownloadExampleFile variant="outlined" />
                                    <Box className={classes.actionButtonsBox}>
                                        <Button
                                            onClick={handleClose}
                                            className={classes.buttonColorError}
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t(
                                                "translation.validation.buttons.cancel"
                                            )}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t(
                                                "translation.validation.buttons.okAdd"
                                            )}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={
                                                        classes.buttonProgress
                                                    }
                                                />
                                            )}
                                        </Button>
                                    </Box>
                                </StyledDialogActions>
                            </FormContainer>
                        )}
                    </Formik>
                </Dialog>
            </div>
        )
    );
}

export default ContactGroupsModal;
