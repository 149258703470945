import { createContext, useMemo } from "react";
import openSocket from "socket.io-client";

// Special Hooks
import { useSocketSetup } from "../../hooks/useSocket/Events";

// Services
import { Outlet } from "react-router-dom";
import { getBackendUrl } from "../../services/config";
import socketOptions from "../../services/socket";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const socket = useMemo(() => {
        const options = socketOptions();
        const manager = openSocket(getBackendUrl(), options);
        return manager
    }, []);

    // start token
    useSocketSetup(socket)

    return (
        <SocketContext.Provider value={{ socket }}>
            {/* {children} */}
            <Outlet />
        </SocketContext.Provider>
    )
}

export default SocketContext;
