import React, { useEffect, useMemo, useReducer, useState } from "react";

import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Tooltip
} from "@mui/material";

import { DataGrid, gridClasses } from '@mui/x-data-grid';

import {
    Block,
    CheckCircleOutlined,
    // DeleteOutline,
    EditOutlined,
    Search,
    ShoppingCartCheckoutOutlined
} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';

import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TenantsModal from "../../components/TenantsModal";

import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

// import { Can } from "../../components/Can";
import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_TENANTS") {
        const tenants = action.payload;
        const newTenants = [];

        tenants.forEach((tenant) => {
            const tenantIndex = state.findIndex((t) => t.id === tenant.id);
            if (tenantIndex !== -1) {
                state[tenantIndex] = tenant;
            } else {
                newTenants.push(tenant);
            }
        });

        return [...state, ...newTenants];
    }

    if (action.type === "UPDATE_TENANTS") {
        const tenant = action.payload;
        const tenantIndex = state.findIndex((t) => t.id === tenant.id);

        if (tenantIndex !== -1) {
            state[tenantIndex] = tenant;
            return [...state];
        } else {
            return [tenant, ...state];
        }
    }

    if (action.type === "DELETE_TENANTS") {
        const tenantId = action.payload;

        const tenantIndex = state.findIndex((t) => t.id === tenantId);
        if (tenantIndex !== -1) {
            state.splice(tenantIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    formControl: {
        marginRight: theme.spacing(2),
        "& input": {
            height: "1.2195em"
        }
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    quickAnswer: {
        // width: 300,
    },
    searchMobile: {
        width: "100%",
        padding: theme.spacing(0, 1, 2),
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        }
    },
    status: {
        display: "flex",
        justifyContent: "center"
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit"
    }
}));

const Tenants = () => {
    const classes = useStyles();

    const [, setLoading] = useState(false);

    const [tenants, dispatch] = useReducer(reducer, []);

    // const [deletingTenants, setDeletingTenants] = useState(null);
    const [disablingTenants, setDisablingTenants] = useState(null);
    const [confirmAction, setConfirmAction] = useState("")
    const [selectedTenants, setSelectedTenants] = useState(null);

    const [tenantsModalOpen, setTenantsModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const { user } = useAuth();
    const { tenant, tenantId, userTenant } = useTenant();

    const [showAll, setShowAll] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [, setHasMore] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const [rows, setRows] = useState([{
        id: '',
        name: '',
        slug: '',
        email: '',
        documentNumber: '',
        addressStreet: '',
        addressZip: '',
        addressCity: '',
        addressCountry: '',
        planId: '',
        plan: {},
        settings: {},
        dueDate: '',
        schedules: '',
        status: '',
        checkoutUrl: '',
        createdAt: '',
        updatedAt: '',
    }]);

    useEffect(() => {
        if (!tenants) return;

        let dataGridRows = tenants.map(tenant => ({
            id: tenant?.id,
            name: tenant?.name,
            slug: tenant?.slug,
            email: tenant?.email,
            documentNumber: tenant?.documentNumber,
            addressStreet: tenant?.addressStreet,
            addressZip: tenant?.addressZip,
            addressCity: tenant?.addressCity,
            addressCountry: tenant?.addressCountry,
            planId: tenant?.planId,
            plan: tenant?.plan,
            settings: tenant?.settings,
            dueDate: tenant?.dueDate,
            schedules: tenant?.schedules,
            status: tenant?.status,
            checkoutUrl: tenant?.extraInfo?.stripe?.checkout?.url,
            createdAt: tenant?.createdAt,
            updatedAt: tenant?.updatedAt,
        }));

        setRows(dataGridRows)

    }, [tenants]);

    const columns = useMemo(() => [
        {
            field: "name",
            headerName: i18n.t("translation.variables.table.name"),
            flex: 0.6,
            minWidth: 160,
            description: i18n.t("translation.tenants.table.descriptions.name")
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.tenants.table.descriptions.visibility.description"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.status
                    ? (<div className={classes.status}>
                        <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.tenants.table.descriptions.status.active")}
                    </div>)
                    : (<div className={classes.status}>
                        <Block fontSize="small" className={classes.marginRight} color="error" /> {i18n.t("translation.tenants.table.descriptions.status.inactive")}
                    </div>)
            )
        },
        {
            field: "plan",
            headerName: i18n.t("translation.variables.table.plan"),
            flex: 0.4,
            minWidth: 120,
            description: i18n.t("translation.tenants.table.descriptions.plan"),
            renderCell: params => (
                params.row.plan?.name
                    ? (<Tooltip arrow title={params.row.plan?.description}>
                        <span>
                            {params.row.plan?.name}
                        </span>
                    </Tooltip>)
                    : ""
            )
        },
        {
            field: "createdAt",
            headerName: i18n.t("translation.variables.table.createdAt"),
            flex: 0.2,
            minWidth: 60,
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                new Date(params.row.createdAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short' })
            )
        },
        {
            field: "dueDate",
            headerName: i18n.t("translation.variables.table.dueDate"),
            flex: 0.2,
            minWidth: 60,
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.dueDate
                    ? new Date(params.row.dueDate).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short' })
                    : "-"
            )
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.2,
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                // params.row.owner?.id === user.id || showAll ? (
                <>
                    {params.row.checkoutUrl && (
                        <IconButton
                            size="small"
                            className={classes.actionButtons}
                            color="primary"
                            onClick={() => {
                                window.open(params.row.checkoutUrl, "_blank");
                            }}
                        >
                            <ShoppingCartCheckoutOutlined />
                        </IconButton>
                    )}
                    <IconButton
                        size="small"
                        className={classes.actionButtons}
                        color="primary"
                        onClick={() => handleEditTenant(params.row)}
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        // TODO: add disable pxermission
                        size="small"
                        className={params.row.status
                            ? [classes.actionButtons, classes.buttonColorError].join(' ')
                            : classes.actionButtons
                        }
                        color={params.row.status ? "error" : "primary"}
                        onClick={() => {
                            setConfirmAction(params.row.status ? "disable" : "enable");
                            setConfirmModalOpen(true);
                            setDisablingTenants(params.row);
                        }}
                    >
                        {params.row.status
                            ? <Block />
                            : <CheckCircleOutlined />
                        }
                    </IconButton>

                    {/* <Can
                        role={userTenant?.role}
                        perform="tenant-modal:delete:tenant"
                        yes={() => (
                            <>
                                <IconButton
                                    size="small"
                                    className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                    onClick={(e) => {
                                        // setConfirmModalOpen(true);
                                        handleDeleteTenants(params.row.id)
                                        setDeletingTenants(params.row);
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </>
                        )}
                    /> */}
                </>
                // ) : ''
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [tenants]);

    useEffect(() => {
        if (searchParam.length > 3) {
            dispatch({ type: "RESET" });
            setPageNumber(1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        // const { role } = userTenant;
        const roles = ["super", "admin", "supervisor"];
        const adminUser = roles.indexOf(userTenant?.role) !== -1;
        setShowAll(adminUser)
    }, [user, userTenant])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        if (searchParam.length < 4 && tenants > 0) {
            return () => {
                setLoading(false);
                isMounted = false;
                controller.abort();
            };
        }

        const fetchTenants = async () => {
            try {
                const { data } = await axiosPrivate.get(`/tenants/list/`, {
                    params: { searchParam, pageNumber, showAll },
                });
                if (isMounted) {
                    dispatch({ type: "LOAD_TENANTS", payload: data.tenants });
                    setHasMore(data.hasMore);
                    setLoading(false);
                };
            } catch (err) {
                isMounted && toastError(err, "", "Error while trying to pull tenants on tenant manager page.");
                setLoading(false);
            }
        };
        fetchTenants();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, showAll, tenantId]);

    // Handle Tenant Changes
    const handleTenantChange = (data) => {
        if (data.action === "update" || data.action === "create" || data.action === "disable") {
            dispatch({ type: "UPDATE_TENANTS", payload: data.tenant });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_TENANTS", payload: data.tenantId });
        }
    };
    useSocketListener(`tenant`, (data) => handleTenantChange(data))

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    // TODO: change to toggle?
    const handleOpenTenantsModal = () => {
        setSelectedTenants(null);
        setTenantsModalOpen(true);
    };

    const handleCloseTenantsModal = () => {
        setSelectedTenants(null);
        setTenantsModalOpen(false);
    };

    const handleEditTenant = (tenant) => {
        setSelectedTenants(tenant);
        setTenantsModalOpen(true);
    };

    const handleToggleTenants = async (tenantId) => {
        if (!tenantId) return;
        try {
            if (confirmAction === "disable") {
                await axiosPrivate.put(`/tenants/${tenantId}/disable`)
                toast.success(i18n.t("translation.tenants.toasts.disable"));
            } else {
                await axiosPrivate.put(`/tenants/${tenantId}`, { planId: disablingTenants.planId, status: true });
                toast.success(i18n.t("translation.tenants.toasts.enable"));
            }
        } catch (err) {
            toastError(err);
        }
        setDisablingTenants(null);
        setSearchParam("");
        setPageNumber(1);
    };

    // const handleDeleteTenants = async (tenantId) => {
    //     if (!tenantId) return;
    //     try {
    //         await axiosPrivate.delete(`/tenants/${tenantId}`);
    //         toast.success(i18n.t("translation.tenants.toasts.deleted"));
    //     } catch (err) {
    //         toastError(err);
    //     }
    //     setDisablingTenants(null);
    //     setSearchParam("");
    //     setPageNumber(1);
    // };

    // const loadMore = () => {
    //     setPageNumber((prevState) => prevState + 1);
    // };

    // const handleScroll = (e) => {
    //     if (!hasMore || loading) return;
    //     const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    //     if (scrollHeight - (scrollTop + 100) < clientHeight) {
    //         loadMore();
    //     }
    // };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    disablingTenants
                        ? confirmAction === "disable"
                            ? `${i18n.t("translation.tenants.confirmationModal.title", { name: disablingTenants.name })}`
                            : `${i18n.t("translation.tenants.confirmationModal.titleAlternate", { name: disablingTenants.name })}`
                        : ""
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                isError={confirmAction === "disable"}
                onConfirm={() => handleToggleTenants(disablingTenants.id)}
            >
                {disablingTenants && (
                    confirmAction === "disable" ? (
                        <>{i18n.t("translation.tenants.confirmationModal.message")}</>
                    ) : (
                        <>{i18n.t("translation.tenants.confirmationModal.messageAlternate")}</>
                    )
                )}
            </ConfirmationModal>
            <TenantsModal
                open={tenantsModalOpen}
                onClose={handleCloseTenantsModal}
                selectedTenant={selectedTenants}
            ></TenantsModal>
            <MainHeader>
                <Title>
                    {i18n.t("translation.tenants.title")}
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            size="small"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => (window.open(`/${tenant.slug}/plans`, "_self"))}
                            className={classes.formControl}
                        >
                            {i18n.t("translation.tenants.buttons.plans")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenTenantsModal}
                        >
                            {i18n.t("translation.tenants.buttons.add")}
                        </Button>
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenTenantsModal}
                        >
                            {i18n.t("translation.tenants.buttons.add")}
                        </Button>
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            // onScroll={handleScroll}
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{
                            width: "100%"
                        }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}

                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer >
    );
};

export default Tenants;
