import { Field } from "formik";
import { useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { Lock, LockOpen } from "@mui/icons-material";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import PlanModal from "../PlansModal";
// import BotModal from "../BotModal";

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: theme.spacing(-0.5),
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    new: {
        borderTop: "1px solid #dadde9"
    },
    status: {
        display: "flex",
    },
}))

const PlanSelect = ({ fieldId, user }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [planModalOpen, setPlanModalOpen] = useState(false);

    const fetchPlans = async (isMounted) => {
        try {
            const { data } = await axiosPrivate.get(`/plans/list`);
            let filtered = data.filter(planFilter);

            if (isMounted) {
                setPlans(filtered);
                setLoading(false);
            }
        } catch (err) {
            toastError(err, "", "Error while trying to get bot list info on botItems component.");
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        fetchPlans(isMounted);

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const planFilter = (plan) => {
        if (!plan.status) return

        // TODO: check if user from controller domain
        if (!plan.public && user.role !== "super") return

        return plan
    }

    // TODO: create plans on the fly
    const handleOpenPlanModal = () => {
        setPlanModalOpen(true)
    }

    const handleClosePlanModal = () => {
        setPlanModalOpen(false)

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchPlans(isMounted)

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    return (<>
        <PlanModal
            open={planModalOpen}
            onClose={handleClosePlanModal}
        />
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel id="plan-selection-label">{i18n.t("translation.planModal.form.option.plan")}</InputLabel>
            <Field
                as={Select}
                label={i18n.t("translation.planModal.form.option.plan")}
                labelId="plan-selection-label"
                id="plan-selection"
                name={fieldId}
                fullWidth
            >
                <MenuItem value={""}>{i18n.t("translation.botModal.form.option.select")}</MenuItem>
                {!loading && plans.map((plan) => (
                    <MenuItem key={plan.id} value={plan.id}>
                        <div className={classes.connectInfoItem}>
                            <Tooltip arrow title={plan.description}>
                                <>
                                    <span className={classes.status}>
                                        {plan.public
                                            ? <LockOpen fontSize="small" className={classes.marginRight} />
                                            : <Lock fontSize="small" className={classes.marginRight} color="error" />
                                        }
                                        {plan.name}
                                    </span>
                                    <span>
                                        {plan.currency} {plan.price}/{i18n.t(`translation.planModal.form.option.recurrence.${plan.recurrence}`)}
                                    </span>
                                </>
                            </Tooltip>
                        </div>
                    </MenuItem>
                ))}
                <MenuItem onClick={handleOpenPlanModal} className={classes.new}>{i18n.t("translation.planModal.form.option.add")}</MenuItem>
            </Field>
        </FormControl>
    </>)
}

export default PlanSelect;
