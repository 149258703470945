import React, { useEffect, useState } from "react";

import {
    Avatar,
    Button,
    Divider,
    Drawer,
    IconButton,
    InputLabel,
    Paper,
    Tooltip,
    Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
    Call,
    Close,
    Mail,
    ManageAccountsRounded
} from "@mui/icons-material";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import handleContactLink from "../handleContactLink";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
// import ModalImageCors from "../ModalImageCors";
import avatar from "../../services/avatar";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    contactAvatar: {
        margin: theme.spacing(2),
        maxWidth: `calc(100% - ${theme.spacing(1)})`,
        width: "14vw",
        height: "14vw",
    },
    contactDetails: {
        height: "100%",
        marginTop: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
        borderBottomRightRadius: theme.spacing(1.5),
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: 4,
        },
    },
    content: {
        display: "flex",
        backgroundColor: "#f0f2f5",
        flexDirection: "column",
        padding: "8px 0px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    dialog: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        // overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        // borderTop: "1px solid rgba(0, 0, 0, 0.05)",
        // borderRight: "1px solid rgba(0, 0, 0, 0.05)",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        // overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    groupContact: {
        display: "flex",
        alignItems: "center",
        minWidth: '100%',
        justifyContent: "space-between",
    },
    groupContactAdmin: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(3)
    },
    groupContactAvatar: {},
    groupContactInfo: {
        display: "flex",
        alignItems: "center"
    },
    groupContactName: {
        paddingLeft: theme.spacing(2)
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        backgroundColor: "#f0f2f5",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    paper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const ContactDrawer = ({ open, handleDrawerClose, chat, contact, contacts, loading }) => {
    const classes = useStyles();
    const [isGroup, setIsGroup] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const getContact = () => {
        if (!contacts) {
            return contact;
        };
        let data = contacts.find(contact => !contact.owner);
        return data
    }

    useEffect(() => {
        if (contact?.isGroup || contacts?.length > 2) {
            setIsGroup(true);
        } else {
            setIsGroup(false)
        }
    }, [contact, contacts])

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            PaperProps={{ style: { position: "absolute" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
                container: document.getElementById("drawer-container"),
                style: { position: "absolute" },
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.header}>
                <IconButton onClick={handleDrawerClose} size="large">
                    <Close />
                </IconButton>
                <Typography style={{ justifySelf: "center" }}>
                    {isGroup
                        ? i18n.t("translation.contactDrawer.header.group")
                        : i18n.t("translation.contactDrawer.header.single")}
                </Typography>
            </div>
            {loading ? (
                <ContactDrawerSkeleton classes={classes} />
            ) : (
                <div className={classes.content}>
                    <Paper square variant="outlined" className={classes.contactHeader}>

                        {/* TODO: fix modal issues when getting images from other places */}
                        {/* <ModalImageCors imageUrl={contact?.profilePicUrl || avatar(getContact()?.email)} /> */}
                        <Avatar
                            alt={isGroup
                                ? chat?.title
                                : getContact()?.name}
                            src={isGroup
                                ? avatar(chat?.title, true)
                                : getContact()?.profilePicUrl || avatar(getContact()?.email)}
                            className={classes.contactAvatar}
                            variant="rounded"
                        ></Avatar>


                        <Typography>
                            {isGroup ? chat?.title : getContact()?.name}
                        </Typography>

                        {!isGroup && getContact()?.number && (
                            <Tooltip arrow title={i18n.t("translation.contact.tooltips.callContact")}>
                                <Button onClick={() => handleContactLink("phone", getContact()?.number)} startIcon={<Call fontSize="small" />}>
                                    {contact.formattedNumber}
                                </Button>
                            </Tooltip>
                        )}
                        {!isGroup && getContact()?.email && (
                            <Tooltip arrow title={i18n.t("translation.contact.tooltips.mailContact")}>
                                <Button onClick={() => handleContactLink("mail", getContact()?.email)} startIcon={<Mail fontSize="small" />}>
                                    {getContact()?.email}
                                </Button>
                            </Tooltip>
                        )}
                        {!isGroup && contact && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setModalOpen(true)}
                            >
                                {i18n.t("translation.contactDrawer.buttons.edit")}
                            </Button>
                        )}
                    </Paper>
                    {!isGroup && contact && (
                        <ContactModal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            contactId={getContact()?.id}
                        ></ContactModal>
                    )}
                    {contact?.extraInfo?.length > 0 && (
                        <Paper square variant="outlined" className={classes.contactDetails}>
                            <Typography variant="subtitle1">
                                {i18n.t("translation.contactDrawer.extraInfo")}
                            </Typography>
                            {contact?.extraInfo?.map(info => (
                                <Paper
                                    key={info.id}
                                    square
                                    variant="outlined"
                                    className={classes.contactExtraInfo}
                                >
                                    <InputLabel>{info.name}</InputLabel>
                                    <Typography component="div" noWrap style={{ paddingTop: 2 }}>
                                        <MarkdownWrapper>{info.value}</MarkdownWrapper>
                                    </Typography>
                                </Paper>
                            ))}
                        </Paper>
                    )}
                    {contacts?.length > 2 && (
                        <Paper square variant="outlined" className={classes.contactDetails}>
                            <Typography variant="subtitle1">
                                {i18n.t("translation.contactDrawer.group.members", { total: contacts?.length })}
                            </Typography>
                            <Divider className={classes.divider} />
                            {contacts.map(contact => (
                                <Typography key={contact.id} component="div" noWrap className={classes.groupContact} style={{ paddingTop: 2 }}>
                                    <div className={classes.groupContactInfo}>
                                        <Avatar src={contact?.profilePicUrl || avatar(contact.email)} alt={contact.name} variant="rounded" />
                                        <Typography className={classes.groupContactName}>{contact.name}</Typography>
                                        {contact.owner && (
                                            <Tooltip arrow title={i18n.t("translation.contactDrawer.group.admin")}>
                                                <ManageAccountsRounded fontSize="small" className={classes.groupContactAdmin} color="primary" />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <Tooltip arrow title={i18n.t("translation.contact.tooltips.mailContact")}>
                                        <IconButton onClick={() => handleContactLink("mail", contact.email)}>
                                            <Mail fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            ))}
                        </Paper>
                    )}
                </div>
            )
            }
        </Drawer >
    );
};

export default ContactDrawer;
