import React, { useEffect, useReducer, useState } from "react";

import {
    ContentCopyOutlined,
    DeleteOutline,
    EditOutlined
} from "@mui/icons-material";
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MarkdownWrapper from "../../components/MarkdownWrapper";
import QueueModal from "../../components/QueueModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TutorialModalButton from "../../components/TutorialModalButton";

import { toast } from "react-toastify";
import { Can } from "../../components/Can";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import ColorItem from "../../components/ColorItem";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    greetingMessage: {
        // width: "100%",
    },
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    actionsCell: {
        minWidth: 140
    },
    preventLineBreak: {
        width: "15%",
        minWidth: 240
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_QUEUES") {
        const queues = action.payload;
        const newQueues = [];

        queues.forEach((queue) => {
            const queueIndex = state.findIndex((q) => q.id === queue.id);
            if (queueIndex !== -1) {
                state[queueIndex] = queue;
            } else {
                newQueues.push(queue);
            }
        });

        return [...state, ...newQueues];
    }

    if (action.type === "UPDATE_QUEUES") {
        const queue = action.payload;
        const queueIndex = state.findIndex((u) => u.id === queue.id);

        if (queueIndex !== -1) {
            state[queueIndex] = queue;
            return [...state];
        } else {
            return [queue, ...state];
        }
    }

    if (action.type === "DELETE_QUEUE") {
        const queueId = action.payload;
        const queueIndex = state.findIndex((q) => q.id === queueId);
        if (queueIndex !== -1) {
            state.splice(queueIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Queues = () => {
    const classes = useStyles();
    const { tenantId, userTenant } = useTenant();

    const [queues, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);

    const [duplicate, setDuplicate] = useState(false);
    const [queueModalOpen, setQueueModalOpen] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        if (!tenantId) return;
        (async () => {
            setLoading(true);
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/queue`);
                dispatch({ type: "LOAD_QUEUES", payload: data });

                setLoading(false);
            } catch (err) {
                toastError(err);
                setLoading(false);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    // Handle Queue Changes
    const handleQueueChange = (data) => {
        if (data.action === "create" || data.action === "update") {
            dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
        }
    };
    useSocketListener(`queue`, (data) => handleQueueChange(data))

    const handleOpenQueueModal = () => {
        setQueueModalOpen(true);
        setSelectedQueue(null);
        setDuplicate(false);
    };

    const handleCloseQueueModal = () => {
        setQueueModalOpen(false);
        setSelectedQueue(null);
    };

    const handleEditQueue = (queue) => {
        setSelectedQueue(queue);
        setQueueModalOpen(true);
        setDuplicate(false);
    };

    const handleDuplicateQueue = (queue) => {
        setSelectedQueue(queue);
        setQueueModalOpen(true);
        setDuplicate(true);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedQueue(null);
    };

    const handleDeleteQueue = async (queueId) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/queue/${queueId}`);
            toast.success(i18n.t("translation.queueModal.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setSelectedQueue(null);
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedQueue &&
                    `${i18n.t("translation.queues.confirmationModal.title")} ${selectedQueue.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteQueue(selectedQueue.id)}
            >
                {i18n.t("translation.queues.confirmationModal.message")}
            </ConfirmationModal>
            <QueueModal
                open={queueModalOpen}
                onClose={handleCloseQueueModal}
                queueId={selectedQueue?.id}
                duplicate={duplicate}
            />
            <MainHeader>
                <Title>
                    {i18n.t("translation.queues.title")}
                    <TutorialModalButton content="queues" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenQueueModal}
                    >
                        {i18n.t("translation.queues.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.id")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.color")}
                            </TableCell>
                            <TableCell align="left">
                                {i18n.t("translation.variables.table.greeting")}
                            </TableCell>
                            <TableCell align="center" className={classes.preventLineBreak}>
                                {i18n.t("translation.variables.table.checkWorkHours")}
                            </TableCell>
                            <TableCell align="center" className={classes.actionsCell}>
                                {i18n.t("translation.variables.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {queues.map((queue) => (
                                <TableRow key={queue.id}>
                                    <TableCell align="center">{queue.id}</TableCell>
                                    <TableCell align="center">{queue.name}</TableCell>
                                    <TableCell align="center">
                                        <ColorItem color={queue.color} />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            className={classes.greetingMessage}
                                            variant="body2"
                                        // noWrap
                                        >
                                            <MarkdownWrapper>
                                                {queue.greetingMessage}
                                            </MarkdownWrapper>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {queue.checkWorkHours === true ?
                                            i18n.t("translation.validation.options.enabled") : i18n.t("translation.validation.options.disabled")}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            className={classes.actionButtons}
                                            onClick={() => handleEditQueue(queue)}
                                        >
                                            <EditOutlined />
                                        </IconButton>

                                        <Can
                                            role={userTenant?.role}
                                            perform="queues:duplicate"
                                            yes={() => (
                                                <>
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        className={classes.actionButtons}
                                                        onClick={() => handleDuplicateQueue(queue)}
                                                    >
                                                        <ContentCopyOutlined />
                                                    </IconButton>
                                                </>
                                            )}
                                        />

                                        <Can
                                            role={userTenant?.role}
                                            perform="queues:delete"
                                            yes={() => (
                                                <>
                                                    <IconButton
                                                        size="small"
                                                        className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                                        onClick={() => {
                                                            setSelectedQueue(queue);
                                                            setConfirmModalOpen(true);
                                                        }}
                                                    >
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Queues;
