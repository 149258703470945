import React, { useEffect, useReducer, useState } from "react";

import {
    Block,
    BlockOutlined,
    CheckCircleOutlined,
    DeleteOutline,
    EditOutlined,
    Search
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TutorialModalButton from "../../components/TutorialModalButton";
import UserModal from "../../components/UserModal";

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import avatar from "../../services/avatar";
import { isControllerUrl } from "../../services/config";
import { i18n } from "../../translate/i18n";

import appVars from "../../../package.json";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePlan from "../../hooks/usePlan";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_USERS") {
        const users = action.payload;
        const newUsers = [];

        users.forEach((user) => {
            const userIndex = state.findIndex((u) => u.id === user.id);
            if (userIndex !== -1) {
                state[userIndex] = user;
            } else {
                newUsers.push(user);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_USERS") {
        const user = action.payload;
        const userIndex = state.findIndex((u) => u.id === user.id);

        if (userIndex !== -1) {
            state[userIndex] = user;
            return [...state];
        } else {
            return [user, ...state];
        }
    }

    if (action.type === "DELETE_USER") {
        const userId = action.payload;

        const userIndex = state.findIndex((u) => u.id === userId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    contactName: {
        paddingLeft: theme.spacing(2)
    },
    disabled: {
        display: "flex",
        justifyContent: "center"
    },
    enabled: {
        display: "flex",
        justifyContent: "center"
    },
    formControl: {
        marginRight: "16px",
        "& input": {
            height: "1.2195em"
        }
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    minWidthColumns: {
        display: "flex",
        alignItems: "center",
        minWidth: '100%',
    },
    searchMobile: {
        width: "100%",
        padding: "0 8px 16px",
    },
}));

const Users = () => {
    const classes = useStyles();

    // Context
    const { tenantId, userData, userTenant } = useTenant();
    const { getPlanValue } = usePlan();

    // State
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserTenant, setSelectedUserTenant] = useState(null);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [users, dispatch] = useReducer(reducer, []);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                if (tenantId) {
                    try {
                        const { data } = await axiosPrivate.get(`/users/${tenantId}`, {
                            params: { searchParam, pageNumber },
                        });
                        dispatch({ type: "LOAD_USERS", payload: data.users });
                        setHasMore(data.hasMore);
                        setLoading(false);
                    } catch (err) {
                        toastError(err);
                    }
                };
            }
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, tenantId]);

    // Handle user updates
    const handleUserUpdate = (data) => {
        // Check if updates or adds to list
        if (data.action === "update" || data.action === "create") {
            dispatch({ type: "UPDATE_USERS", payload: data.user })
        }

        // Check if removes from list
        if (data.action === "disable") {
            dispatch({ type: "DELETE_USER", payload: +data.user.id });
        }
        if (data.action === "delete") {
            dispatch({ type: "DELETE_USER", payload: +data.userId });
        }
    }
    useSocketListener(`users`, (data) => handleUserUpdate(data))

    const handleOpenUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(true);
    };

    const handleCloseUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setUserModalOpen(true);
    };

    const handleUserTenantStatus = async (user) => {
        try {
            const userTenantData = {
                userTenant: {
                    status: !userData(user)?.status,
                    tenantId,
                }
            }
            // user.userTenant[0].status = !user.userTenant[0].status;

            await axiosPrivate.put(`/user/${user.id}`, userTenantData);
            toast.success(i18n.t("translation.userModal.success"));
        } catch (err) {
            toastError(err);
        }
        resetUser()
    };

    const handleDeleteUser = async (userId) => {
        try {
            const userData = {
                global: false,
                tenantId
            }
            await axiosPrivate.put(`/user/disable/${userId}`, userData);
            toast.success(i18n.t("translation.users.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        resetUser()
    };

    const resetUser = () => {
        setSelectedUserTenant(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    // TODO: get settings from plan
    const maxUsers = getPlanValue("users");
    const usersTotal = users.filter(user => {
        const userInfo = userData(user);
        if (
            userInfo?.status === false ||
            (
                userInfo?.role !== "finance" &&
                userInfo?.role !== "dashboard"
            ) ||
            (
                userInfo?.role === 'super' &&
                user?.email?.split('@')[1] === appVars.controllerDomain &&
                !isControllerUrl
            )
        ) {
            return false;
        } else {
            return true;
        }
    }).length;

    const canCreateUsers = (usersTotal, maxUsers) => {
        if (usersTotal < maxUsers) {
            return true;
        }
    };

    const enableCreateButton = () => {
        if (canCreateUsers(usersTotal, maxUsers)) {
            handleOpenUserModal();
        } else {
            toast.error(i18n.t("translation.backendErrors.ERR_NO_PERMISSION_CREATE_USER_LIMIT"));
        }
    }

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedUserTenant &&
                    `${i18n.t(`translation.users.confirmationModal.${userData(selectedUserTenant)?.status
                        ? userData(selectedUserTenant)?.hasTickets
                            ? "disable"
                            : "delete"
                        : "enable"
                        }.title`)} ${selectedUserTenant.name}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                isError={userData(selectedUserTenant)?.status}
                onConfirm={() => {
                    userData(selectedUserTenant)?.status
                        ? userData(selectedUserTenant)?.hasTickets
                            ? handleUserTenantStatus(selectedUserTenant)
                            : handleDeleteUser(selectedUserTenant.id)
                        : handleUserTenantStatus(selectedUserTenant)
                }}
            >
                {i18n.t(`translation.users.confirmationModal.${userData(selectedUserTenant)?.status === true
                    ? userData(selectedUserTenant)?.hasTickets === true
                        ? "disable"
                        : "delete"
                    : "enable"
                    }.message`)}
            </ConfirmationModal>
            <UserModal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="form-dialog-title"
                userId={selectedUser && selectedUser.id}
            />
            <MainHeader>
                <Title>
                    {i18n.t("translation.users.title")}
                    <TutorialModalButton content="users" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            onChange={handleSearch}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={enableCreateButton}
                        >
                            {i18n.t("translation.validation.buttons.okAdd")}
                        </Button>
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={enableCreateButton}
                        >
                            {i18n.t("translation.validation.buttons.okAdd")}
                        </Button>
                    </Box>

                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t("translation.variables.table.name")}</TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.email")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.profile")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.status.title")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.connection")}
                            </TableCell>
                            <TableCell align="center" className={classes.actionsCell}>
                                {i18n.t("translation.variables.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {users.map((u) => (
                                <TableRow key={u.id}>
                                    <TableCell align="center" className={classes.minWidthColumns}>
                                        <Avatar src={avatar(u.email)} alt={u.name} variant="rounded" />
                                        <Typography className={classes.contactName} variant="inherit">{u.name}</Typography>
                                    </TableCell>
                                    <TableCell align="center">{u.email}</TableCell>
                                    <TableCell align="center">
                                        {i18n.t(`userModal.form.profile.role.${isControllerUrl && userData(u)?.role === 'super' ? 'support' : userData(u)?.role}`)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {userData(u)?.status === true ? (
                                            <div className={classes.enabled}>
                                                <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.active")}
                                            </div>
                                        ) : (
                                            <div className={classes.disabled}>
                                                <Block color="error" fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.inactive")}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">{
                                        (userData(u)?.whatsapp !== null
                                            ? userData(u)?.whatsapp?.name
                                            : i18n.t("translation.userModal.form.connection.none")
                                        )
                                    }</TableCell>
                                    <TableCell align="center">


                                        <Can
                                            role={userTenant?.role}
                                            perform={userData(u)?.role === 'super'
                                                ? "user-modal:edit:super"
                                                : (userData(u)?.role === 'admin'
                                                    ? "user-modal:edit:admin"
                                                    : "user-modal:edit:user")
                                            }
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    className={classes.actionButtons}
                                                    onClick={() => handleEditUser(u)}
                                                >
                                                    <EditOutlined />
                                                </IconButton>
                                            )}
                                        />
                                        <Can
                                            role={userTenant?.role}
                                            perform={userData(u)?.role === 'super'
                                                ? "user-modal:delete:super"
                                                : (userData(u)?.role === 'admin'
                                                    ? "user-modal:delete:admin"
                                                    : "user-modal:delete:user")
                                            }
                                            yes={() => (
                                                <IconButton
                                                    // TODO: add disable pxermission
                                                    size="small"
                                                    className={userData(u)?.status
                                                        ? [classes.actionButtons, classes.buttonColorError].join(' ')
                                                        : classes.actionButtons
                                                    }
                                                    color={userData(u)?.status ? "error" : "primary"}
                                                    onClick={() => {
                                                        setConfirmModalOpen(true);
                                                        setSelectedUserTenant(u);
                                                    }}
                                                >
                                                    {userData(u)?.status
                                                        ? userData(u)?.hasTickets
                                                            ? <BlockOutlined />
                                                            : <DeleteOutline />
                                                        : <CheckCircleOutlined />
                                                    }
                                                </IconButton>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Users;
