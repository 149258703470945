import { useState, useEffect } from "react";

import useAxiosPrivate from "../useAxiosPrivate";
import toastError from "../../errors/toastError";
import useTenant from "../useTenant";

const usePlan = () => {
    const [plan, setPlan] = useState([]);
    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchPlan = async () => {
            try {
                const { data } = await axiosPrivate.get(`/tenants/${tenantId}`, { signal });
                isMounted && setPlan(data?.plan);
            } catch (err) {
                toastError(err, "", "Error ocurred while trying to fetch plan data at usePlan.");
            }
        };
        if (tenantId) fetchPlan();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const getPlanValue = (key) => {
        const value = plan?.planPieces?.find(p => p.fieldName === key)?.PlanComposition?.quantity;
        return value || 0;
    };

    return { plan, getPlanValue };
};

export default usePlan;
