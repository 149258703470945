import React from 'react';

import { MenuItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import StatusTooltip from '../StatusTooltip';

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    }
}))

const ConnectionItems = (whatsApps) => {
    const classes = useStyles();

    return (
        whatsApps.map((whatsApp) => (
            <MenuItem key={whatsApp.id} value={whatsApp.id} className={classes.connectInfoItem}>
                {whatsApp.name}
                <div className={classes.connectionInfo}>
                    <div className={classes.connectionInfoSpan}>
                        {i18n.t("translation.userModal.form.connection.status")}
                    </div>
                    <StatusTooltip whatsApp={whatsApp} />
                </div>
            </MenuItem>
        ))
    );
};

export default ConnectionItems;