// Based on:
// https://blog.logrocket.com/push-notifications-react-firebase/
// https://stackoverflow.com/questions/62225339/how-to-use-process-env-in-a-react-service-worker
// https://medium.com/@bozazec/cra-workbox-firebase-cloud-messaging-all-together-solved-1643e75ee3c0

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import logger from "../logger";
import firebaseConfig from "./config";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp)
// export const messaging = (async () => {
//     try {
//         const isSupportedBrowser = await isSupported();
//         if (isSupportedBrowser) {
//             return getMessaging(firebaseApp)
//         } else {
//             console.error("Notifications are not supported on this system.")
//         }
//     } catch (err) {
//         console.error("Error assessing notifications support:", err);
//         return null;
//     }
// })

// Check for Firebase service worker
// Based on: https://medium.com/@sumanthegde123/web-push-notifications-with-react-and-firebase-with-safari-error-handling-d2979d10c9ac
// export const getOrRegisterServiceWorker = () => {
//     if ("serviceWorker" in navigator &&
//         typeof navigator.serviceWorker !== "undefined"
//     ) {
//         return navigator.serviceWorker
//             .getRegistration("/firebase-push-notification-scope")
//             .then((serviceWorker) => {
//                 if (serviceWorker) return serviceWorker;
//                 return navigator.serviceWorker.register(
//                     "/firebase-messaging-sw.js", {
//                     scope: "/firebase-push-notification-scope"
//                 });
//             });
//     }
//     throw new Error("The browser doesn`t support service worker.");
// };

export const getFirebaseToken = async (setFirebaseToken) => {
    try {
        // const messagingResolve = await messaging();
        if (messaging) {
            // const serviceWorkerRegistration = await getOrRegisterServiceWorker();
            const registration = await navigator.serviceWorker.ready;
            if (registration) {
                try {
                    if (await checkNotificationPermission() === "granted") {
                        const currentToken = await getToken(messaging, {
                            serviceWorkerRegistration: registration,
                            vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY
                        });

                        if (currentToken) {
                            logger({ type: "info", title: "Firebase (Current Token)", content: currentToken })
                            setFirebaseToken(currentToken);
                        } else {
                            logger({ type: "info", title: "Firebase", content: "No current registration notification token available, request permission to generate one." })
                        }
                    }
                } catch (err) {
                    console.error("An error occurred while retrieving notification token.", err);
                }
            } else {
                console.error("Failed to retrieve service worker registration for notifications.")
            }
        }
    } catch (err) {
        console.error("Failed to retrieve service worker registration for notifications.", err)
    }
}

export const checkNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    return permission
}

export const onMessageListener = async () => {
    onMessage(messaging, (payload) => {
        logger({ type: "info", title: "Firebase", content: payload })
    })
}

export default getFirebaseToken
