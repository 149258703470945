import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

import { i18n } from "../../translate/i18n";
import TicketsQueueSelect from "../TicketsQueueSelect";


const useStyles = makeStyles(theme => ({
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}));


function AdvancedFilter({ open, selectedQueueIds, setSelectedQueueIds, userTenant, setVisible }) {
    const classes = useStyles();
    return (
        <Dialog open={open}>
            <DialogTitle id="form-dialog-title">
                {i18n.t("translation.dashboard.advancedFilterModal.title")}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {i18n.t("translation.dashboard.advancedFilterModal.content")}
                </DialogContentText>
                <br />
                <TicketsQueueSelect
                    selectedQueueIds={selectedQueueIds}
                    userQueues={userTenant?.queues}
                    onChange={values => setSelectedQueueIds(values)}
                    sx={{ width: "100%" }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.buttonColorError}
                    color="error"
                    onClick={() => setVisible(false)}
                    variant="outlined"
                >
                    {i18n.t("translation.validation.buttons.cancel")}
                </Button>
                <Button
                    className={classes.btnWrapper}
                    color="primary"
                    onClick={() => { setVisible(false) }}
                    type="submit"
                    variant="contained"
                >
                    {i18n.t("translation.validation.buttons.filter")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default AdvancedFilter;
