import logger from "../../services/logger";

export const NotificationFavicon = ({ content, state }) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) return;

    // Select the icon to use
    let icon = "/favicon.png";
    switch (content) {
        case "messages":
            icon = '/favicon-notification-green.png';
            break;

        case "error":
            icon = '/favicon-notification-red.png';
            break;

        case "warning":
            icon = '/favicon-notification-yellow.png';
            break;

        default:
            logger({ type: "error_info", title: "Notification Favicon", content: `No icon set for content state: ${content}` })
    }

    // Check for notifications
    link.href = state
        ? icon
        : '/favicon.png'

    return
}

export default NotificationFavicon
