import React, { useEffect, useState } from "react";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
// import useTickets from "../../../hooks/useTickets";

import Chart from 'react-apexcharts';
import { i18n } from "../../../translate/i18n";

import { useTheme } from "@mui/material";
import colorTheme from "../../../services/colorTheme";

import Title from "../../../components/LayoutComponents/CardTitle";
import toastError from "../../../errors/toastError";
import useTenant from "../../../hooks/useTenant";

const ChartTicketsYear = () => {
    const theme = useTheme();
    // const date = useRef(new Date().toISOString());
    // const { tickets } = useTickets({ date: date.current });
    const { tenantId } = useTenant();
    const [chartTickets, setChartTickets] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const useData = [];
    const result = {};

    useEffect(() => {
        const controller = new AbortController();
        let isMounted = true;
        if (isMounted === true) {
            const fetchData = async () => {
                if (tenantId) {
                    try {
                        const { data } = await axiosPrivate.get(`/${tenantId}/dashboard/ticketsYear`);
                        setChartTickets(data);
                    }
                    catch (err) {
                        toastError(err, "", "Error ocurred while trying to fetch dashboard's tickets per month chart.");
                    }
                }
            }
            fetchData();
        }
        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetChartData = () => {
        // const palette = colorTheme(theme.palette.primary.main, chartTickets?.length)
        // chartTickets.map((item, index) => {
        chartTickets.map(item => {
            if (item.userTenantId in result) {
                return result[item.userTenantId].data[parseInt(item.month) - 1] += item.ticketCount;
            } else {
                result[item.userTenantId] = {
                    name: item["userTenant.user.name"],
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // fillColor: palette[index]
                };
                return result[item.userTenantId].data[parseInt(item.month) - 1] = item.ticketCount;
            }
        })

        Object.keys(result).forEach(userTenantId => {
            if (result[userTenantId].name === null) {
                useData.push({
                    name: i18n.t("translation.dashboard.charts.perUser.defaultLabel"),
                    data: result[userTenantId].data
                });
            } else {
                useData.push({
                    name: result[userTenantId].name,
                    data: result[userTenantId].data
                });
            }
        });
        return useData
    }

    const options = {
        xaxis: {
            categories: [
                i18n.t("translation.variables.calendar.months.january.short"),
                i18n.t("translation.variables.calendar.months.february.short"),
                i18n.t("translation.variables.calendar.months.march.short"),
                i18n.t("translation.variables.calendar.months.april.short"),
                i18n.t("translation.variables.calendar.months.may.short"),
                i18n.t("translation.variables.calendar.months.june.short"),
                i18n.t("translation.variables.calendar.months.july.short"),
                i18n.t("translation.variables.calendar.months.august.short"),
                i18n.t("translation.variables.calendar.months.september.short"),
                i18n.t("translation.variables.calendar.months.october.short"),
                i18n.t("translation.variables.calendar.months.november.short"),
                i18n.t("translation.variables.calendar.months.december.short"),
            ]
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                columnWidth: '60%'
            }
        },
        colors: colorTheme(theme.palette.primary.main, (chartTickets?.length - 1))
    }

    return (
        chartTickets && (
            <>

                <Title>
                    {i18n.t("translation.dashboard.charts.perUser.title")}
                </Title>
                <Chart
                    options={options}
                    height={300}
                    series={GetChartData()}
                    type="bar"
                />
            </>
        )
    );
};

export default ChartTicketsYear;
