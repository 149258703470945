import React, { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import toastError from '../../errors/toastError';
import useQueues from '../../hooks/useQueues';
import useTenant from '../../hooks/useTenant';

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    }
}))

const QueueItems = () => {
    const classes = useStyles();
    const { tenantId } = useTenant();
    const { findAll: findAllQueues } = useQueues();

    const [loading, setLoading] = useState(false);
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchQueues = async () => {
            if (!tenantId) return;
            try {
                const list = await findAllQueues();
                if (isMounted) {
                    setQueues(list);
                    setLoading(false);
                };
            } catch (err) {
                toastError(err, "", "Error while trying to get queue list info on queueItems component.");
            }
        };
        fetchQueues();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !loading && (
        queues.map((queue) => (
            <MenuItem key={queue.id} value={queue.id} className={classes.connectInfoItem}>
                {queue.name}
            </MenuItem>
        ))
    );
};

export default QueueItems;
