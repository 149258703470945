import {
	Link,
	Typography
} from '@mui/material';

import React from 'react';

export default function GoBackButton({label,url}) {
	return (
        <>
            <Typography variant="body2" color="textSecondary" align="center">
                <Link color="inherit" traget="_blank" href={url}>
                    {label}
                </Link>
            </Typography>
        </>
	);
};