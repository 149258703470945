import React from 'react';

import {
    Grid,
    Paper,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    dashCard: {
        width: '100%',
        maxWidth: "calc(100% / 3)",
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
        }
    },
    dashCardInnerLeft: {
        [theme.breakpoints.down('md')]: {
            maxWidth: "65%",
            width: "100%",
            float: "left",
            flex: 1,
        }
    },
    dashCardInnerRight: {
        [theme.breakpoints.down('md')]: {
            maxWidth: "35%",
            width: "100%",
            float: "right",
            textAlign: "right",
            flex: 1,
            marginTop: "-5px"
        }
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        height: 120,
        width: "100%",
        border: '1px solid rgba(0, 0, 0, 0.05)',
        [theme.breakpoints.down('md')]: {
            height: 64,
            display: "inline-block",
            verticalAlign: "middle"
        }
    },
}))

export const DashCard = (props) => {
    const classes = useStyles();

    return (
        <Grid item className={classes.dashCard}>
            <Paper className={classes.customFixedHeightPaper}>
                <Typography
                    component="h3"
                    variant="h6"
                    color="primary"
                    paragraph
                    className={classes.dashCardInnerLeft}
                >
                    {props?.title}
                </Typography>
                <Grid item className={classes.dashCardInnerRight}>
                    <Typography component="h1" variant="h4">
                        {props?.children}
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default DashCard;