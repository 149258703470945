import React, { useEffect, useState } from 'react';

import { MenuItem, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import toastError from '../../errors/toastError';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useTenant from '../../hooks/useTenant';
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    }
}))

const BotItems = (current) => {
    const classes = useStyles();
    const { tenantId } = useTenant();
    const [loading, setLoading] = useState(false);
    const [bots, setBots] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchBots = async () => {
            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`${tenantId}/bots`);
                const filtered = data.filter((bot) => bot.id !== current);
                isMounted && setBots(filtered);
                isMounted && setLoading(false);
            } catch (err) {
                toastError(err, "", "Error while trying to get bot list info on botItems component.");
            }
        };
        fetchBots();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    return !loading && bots.map((bot) => (
        <MenuItem key={bot.id} value={bot.id} className={classes.connectInfoItem}>
            <Tooltip arrow title={bot.description}>
                <span>
                    {bot.title} ({i18n.t(`translation.bots.actions.${bot.action}`)})
                </span>
            </Tooltip>
        </MenuItem>
    ));
};

export default BotItems;
