import React, { useCallback, useEffect, useMemo, useState } from "react";
import Routes from "./routes";

import CssBaseline from "@mui/material/CssBaseline";
import {
    StyledEngineProvider,
    ThemeProvider
} from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/index.css";
import handleTheme from "./assets/styles/theme";

import { ptBR } from "@mui/material/locale";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { getFirebaseToken, onMessageListener } from "./services/firebase/firebaseInit";

const App = () => {
    const [locale, setLocale] = useState();
    const [i18nlocale] = useLocalStorage("i18nextLng", "pt-BR", true);

    // Call firebase notifications
    const [, setFirebaseToken] = useLocalStorage("firebaseToken", null, true);

    // Call firebase settings
    const getNotificationPermission = useCallback(async () => {
        await getFirebaseToken(setFirebaseToken);
        onMessageListener();
    }, [setFirebaseToken]);

    // Call external theme
    const theme = useMemo(() => handleTheme(locale), [locale])

    useEffect(() => {
        if (i18nlocale) {
            const browserLocale = i18nlocale.substring(0, 5);
            if (browserLocale === "pt-BR" || browserLocale === "pt") {
                setLocale(ptBR);
            }
        }

        // Get registration for Firebase notifications
        getNotificationPermission()
    }, [i18nlocale, getNotificationPermission]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <Routes />
                </ CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
