import React, { useState } from "react";

import { Button } from "@mui/material";

import TicketsModal from "../TicketsModal";

const TicketListCount = ({ queues, status, ticketCount, userTenant }) => {
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(ticketCount);

    return (
        <>
            <TicketsModal
                open={open}
                handleClose={() => setOpen(false)}
                status={status}
                userTenant={userTenant}
                queues={queues}
                showAll={true}
                setCount={setCount}
            />
            <Button
                variant="outlined"
                color="primary"
                onClick={() => count > 0 ? setOpen(true) : null}
            >
                {count}
            </Button>
        </>
    );
}

export default TicketListCount;