import React, { useEffect, useRef, useState } from "react";

import { Field, FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";

import appVars from "../../../package.json";
import toastError from "../../errors/toastError";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import MultiFieldLine from "../FormComponents/MultiFieldLine";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    btnWrapper: {
        position: "relative",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    multFieldLineLeft: {
        display: "flex",
        justifyContent: "flex-start",
        // "& > *:not(:last-child)": {
        "& > *:last-child": {
            // marginRight: theme.spacing(1),
            marginRight: 0,
        },
        width: "100%",
    },
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    number: Yup.string()
        .min(8, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`),
    email: Yup.string()
        .email(`${i18n.t("translation.validation.invalid_email")}`),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    const initialState = {
        name: "",
        number: "",
        email: "",
        sendFarewellMessage: true,
        blockCampaigns: false
    };

    const [contact, setContact] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchContact = async () => {
            if (initialValues) {
                setContact(prevState => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!contactId || !tenantId) return;

            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/contacts/${contactId}`);
                if (isMounted.current) {
                    setContact(data);
                }
            } catch (err) {
                toastError(err, "", "While trying to fetch contacts at Contact Modal");
            }
        };
        fetchContact();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId, open, initialValues, tenantId]);

    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const handleSaveContact = async values => {
        if (tenantId) {
            try {
                if (contactId) {
                    await axiosPrivate.put(`/${tenantId}/contacts/${contactId}`, values);
                } else {
                    const { data } = await axiosPrivate.post(`/${tenantId}/contacts`, values);
                    if (onSave) {
                        onSave(data);
                    }
                }
                handleClose();
                toast.success(i18n.t("translation.contactModal.success"));
            } catch (err) {
                toastError(err, "", "While trying to save contact at Contact Modal");
            }
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {contactId
                        ? `${i18n.t("translation.contactModal.title.edit")}`
                        : `${i18n.t("translation.contactModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={contact}
                    enableReinitialize={true}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveContact(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <Typography variant="subtitle1" gutterBottom>
                                    {i18n.t("translation.contactModal.form.mainInfo")}
                                </Typography>
                                <MultiFieldLine>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.contactModal.form.name")}
                                        name="name"
                                        autoFocus
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.contactModal.form.number")}
                                        name="number"
                                        error={touched.number && Boolean(errors.number)}
                                        helperText={touched.number && errors.number}
                                        placeholder={appVars.examples.phone}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </MultiFieldLine>
                                <div>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.contactModal.form.email")}
                                        name="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        placeholder={i18n.t("translation.contactModal.form.emailPlaceholder")}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                    />
                                </div>

                                <MultiFieldLine left={true}>
                                    <Tooltip title={i18n.t("translation.userModal.form.sendFarewellMessage.note")} placement="top">
                                        <FormControl component="fieldset">
                                            <FormGroup >
                                                <Field
                                                    as={FormControlLabel}
                                                    name="sendFarewellMessage"
                                                    control={<Checkbox size="small" checked={values.sendFarewellMessage} />}
                                                    label={i18n.t("translation.userModal.form.sendFarewellMessage.title")}
                                                    labelPlacement="end"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Tooltip>
                                </MultiFieldLine>

                                <MultiFieldLine left={true}>
                                    <Tooltip title={i18n.t("translation.userModal.form.blockCampaigns.note")} placement="top">
                                        <FormControl component="fieldset">
                                            <FormGroup >
                                                <Field
                                                    as={FormControlLabel}
                                                    name="blockCampaigns"
                                                    control={<Checkbox size="small" checked={values.blockCampaigns} />}
                                                    label={i18n.t("translation.userModal.form.blockCampaigns.title")}
                                                    labelPlacement="end"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Tooltip>
                                </MultiFieldLine>

                                <Typography
                                    style={{ marginBottom: 8, marginTop: 12 }}
                                    variant="subtitle1"
                                >
                                    {i18n.t("translation.contactModal.form.extraInfo")}
                                </Typography>

                                <FieldArray name="extraInfo">
                                    {({ push, remove }) => (
                                        <>
                                            {values.extraInfo &&
                                                values.extraInfo.length > 0 &&
                                                values.extraInfo.map((info, index) => (
                                                    <div
                                                        className={classes.extraAttr}
                                                        key={`${index}-info`}
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.contactModal.form.extraName")}
                                                            name={`extraInfo[${index}].name`}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.textField}
                                                            fullWidth
                                                        />
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.contactModal.form.extraValue")}
                                                            name={`extraInfo[${index}].value`}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.textField}
                                                            fullWidth
                                                        />
                                                        <IconButton
                                                            size="small"
                                                            className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                                            onClick={() => remove(index)}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                            <div className={classes.extraAttr}>
                                                <Button
                                                    style={{ flex: 1, marginTop: 8 }}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => push({ name: "", value: "" })}
                                                >
                                                    {`+ ${i18n.t("translation.contactModal.buttons.addExtraInfo")}`}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("translation.validation.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {contactId
                                        ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                        : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ContactModal;
