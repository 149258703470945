import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    AddTwoTone,
    DeleteOutline,
    EditOutlined,
    Search,
    VisibilityOutlined
} from "@mui/icons-material";
import {
    Box,
    Button,
    Chip,
    IconButton,
    InputAdornment,
    Paper,
    TextField
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TutorialModalButton from "../../components/TutorialModalButton";
import WikiModal from "../../components/WikiModal";

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_WIKI") {
        const wikis = action.payload;
        const newWiki = [];

        wikis.forEach((wiki) => {
            const wikiIndex = state.findIndex((w) => w.id === wiki?.id);
            if (wikiIndex !== -1) {
                state[wikiIndex] = wiki;
            } else {
                newWiki.push(wiki);
            }
        });

        return [...state, ...newWiki];
    }

    if (action.type === "UPDATE_WIKI") {
        const wiki = action.payload;
        const wikiIndex = state.findIndex((w) => w.id === wiki?.id);

        if (wikiIndex !== -1) {
            state[wikiIndex] = wiki;
            return [...state];
        } else {
            return [wiki, ...state];
        }
    }

    if (action.type === "DELETE_WIKI") {
        const wikiId = action.payload;

        const wikiIndex = state.findIndex((w) => w?.id === wikiId);
        if (wikiIndex !== -1) {
            state.splice(wikiIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        '&:hover': {
            filter: 'brightness(120%)',
        },
    },
    chip: {
        margin: 2,
    },
    formControl: {
        marginRight: theme.spacing(2),
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    tag: {
        padding: 3,
        paddingLeft: 10,
        paddingRight: 10,
        color: "#FFF", // Use theme color, check other tags in the system.
        fontWeight: "bold",
        borderRadius: theme.spacing(3),
        display: "inline-block",
    },
    search: {
        color: "gray"
    },
    searchMobile: {
        width: "100%",
        padding: theme.spacing(0, 1, 2, 1),
    },
}));

const Wiki = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [wiki, dispatch] = useReducer(reducer, []);
    const [selectedwiki, setSelectedwiki] = useState(null);
    const [wikiModalOpen, setwikiModalOpen] = useState(false);
    const [deletingwiki, setDeletingwiki] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    //const { tenant, userTenant } = useTenant();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const { tenant, tenantId } = useTenant();

    const [rows, setRows] = useState([{
        id: '',
        title: '',
        protocol: '',
        tag: [],
    }]);


    useEffect(() => {
        if (!wiki) return;

        let dataGridRows = wiki.map(article => ({
            id: article?.id,
            title: article?.title,
            protocol: article?.protocol,
            tag: article?.tag,
        }));

        setRows(dataGridRows)

    }, [wiki]);

    const columns = useMemo(() => [
        {
            field: "protocol",
            headerName: i18n.t("translation.variables.table.protocol"),
            flex: 0.05,
            minWidth: 30,
            align: 'center',
            description: i18n.t("translation.wiki.table.descriptions.protocol")
        },
        {
            field: "title",
            headerName: i18n.t("translation.variables.table.wiki"),
            flex: 0.6,
            minWidth: 260,
            description: i18n.t("translation.wiki.table.descriptions.article")
        },
        {
            field: "tag",
            headerName: i18n.t("translation.variables.table.tag"),
            flex: 0.15,
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    {/* <div
                        className={classes.tag}
                        style={{ backgroundColor: params.row.tag?.color }}
                    >
                        {params.row.tag?.name}
                    </div> */}
                    <Chip
                        color="secondary"
                        sx={{
                            '& .MuiChip-deleteIcon': {
                                color: params.row.tag?.color,
                                transition: '0.5s'
                            },
                            '& .MuiChip-deleteIcon:hover': {
                                color: 'red',
                            },
                        }}
                        style={{
                            backgroundColor: params.row.tag?.color + "20",
                            borderColor: params.row.tag?.color,
                            color: params.row.tag?.color,
                        }}
                        variant="outlined"
                        label={params.row.tag?.name}
                        className={classes.chip}
                        // {...getTagProps({ index })}
                        size="small"
                    />
                </>
            )
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.2,
            minWidth: 220,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    <IconButton
                        size="small"
                        color="primary"
                        className={classes.actionButtons}
                        onClick={() => navigate(`/${tenant.slug}/wiki/${params.row.protocol}`)}
                    >
                        <VisibilityOutlined />
                    </IconButton>
                    <IconButton
                        size="small"
                        className={classes.actionButtons}
                        color="primary"
                        onClick={() => handleEditwiki(params.row)}
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        size="small"
                        className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                        onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingwiki(params.row);
                        }}
                    >
                        <DeleteOutline />
                    </IconButton>
                </>
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [wiki]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            if (tenantId) {
                setLoading(true);
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/wiki/`, {
                        params: { searchParam, pageNumber },
                    }, { signal: controller.signal });
                    dispatch({ type: "LOAD_WIKI", payload: data.wikis });
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                }
            }
        };

        fetchData();

        return () => {
            setLoading(false)
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, tenantId]);

    // Handle Wiki Changes
    const handleWikiChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            dispatch({ type: "UPDATE_WIKI", payload: data.wiki });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_WIKI", payload: +data.wikiId });
        }
    };
    useSocketListener(`wiki`, (data) => handleWikiChange(data))

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenwikiModal = () => {
        setSelectedwiki(null);
        setwikiModalOpen(true);
    };

    const handleClosewikiModal = () => {
        setSelectedwiki(null);
        setwikiModalOpen(false);
    };

    const handleEditwiki = (wiki) => {
        setSelectedwiki(wiki);
        setwikiModalOpen(true);
    };

    const handleDeletewiki = async (wikiProtocol) => {
        try {
            await axiosPrivate.delete(`/${tenantId}/wiki/${wikiProtocol}`);
            toast.success(i18n.t("translation.wiki.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingwiki(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingwiki &&
                    `${i18n.t("translation.wiki.confirmationModal.title")} ${deletingwiki.title
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeletewiki(deletingwiki.protocol)}
            >
                {i18n.t("translation.wiki.confirmationModal.message")}
            </ConfirmationModal>
            <WikiModal
                open={wikiModalOpen}
                onClose={handleClosewikiModal}
                aria-labelledby="form-dialog-title"
                wikiId={selectedwiki && selectedwiki.id}
                wikiProtocol={selectedwiki && selectedwiki.protocol}
                tag={selectedwiki && selectedwiki.tagId | 1}
            ></WikiModal>
            <MainHeader>
                <Title>
                    {i18n.t("translation.wiki.title")}
                    <TutorialModalButton content="wiki" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            size="small"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search
                                            className={classes.search}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            onClick={handleOpenwikiModal}
                        >
                            {i18n.t("translation.wiki.buttons.add")}
                        </Button>
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size='small'
                            onClick={handleOpenwikiModal}
                        >
                            <AddTwoTone />
                            {i18n.t("translation.wiki.buttons.add")}
                        </Button>
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search className={classes.search} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >

                {rows && <DataGrid
                    className={classes.gridPaper}
                    rows={rows}
                    columns={columns}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    pageSize={20}
                    getRowId={row => row.id}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                />}

                {/* <Table size="small">
                    <TableHead>
                        <TableRow>
                        <TableCell align="center">
                            {i18n.t("translation.variables.table.tag")}
                        </TableCell>
                        <TableCell align="left">
                            {i18n.t("translation.variables.table.wiki")}
                        </TableCell>
                        <TableCell align="center" className={classes.actionsCell}>
                            {i18n.t("translation.variables.table.actions")}
                        </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                        {wiki.map((wiki) => (
                            <TableRow key={wiki.id}>
                            <TableCell align="center">  { wiki.tagId === 0 ?   <div
                                className={classes.tag}
                                style={{ backgroundColor: "black" }}
                                >  Padrão</div> :
                                <div
                                className={classes.tag}
                                style={{ backgroundColor: wiki.tag?.color }}
                            >  {wiki.tag?.name}</div>


                            }</TableCell>
                            <TableCell align="left">
                                <Typography
                                className={classes.quickAnswer}
                                variant="body2"
                                // noWrap
                                >
                                {wiki.title}
                                </Typography>
                            </TableCell>
                            <TableCell align="center" className={classes.actionsCell}>
                            <IconButton
                                size="small"
                                color="primary"
                                className={classes.actionButtons}
                                onClick={() =>  navigate(`/${tenant.slug}/wiki/${wiki.protocol}`)}
                                >
                                <VisibilityOutlined />
                                </IconButton>
                                <IconButton
                                size="small"
                                color="primary"
                                className={classes.actionButtons}
                                onClick={() => handleEditwiki(wiki)}
                                >
                                <EditOutlined />
                                </IconButton>

                                <IconButton
                                size="small"
                                className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                onClick={(e) => {
                                    setConfirmModalOpen(true);
                                    setDeletingwiki(wiki);
                                }}
                                >
                                <DeleteOutline />
                                </IconButton>
                            </TableCell>
                            </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={3} />}
                        </>
                    </TableBody>
                </Table> */}
            </Paper>
        </MainContainer>
    );
};

export default Wiki;
