import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { TwitterPicker } from "react-color";

import colors from "./colors";

const ColorPicker = ({ onChange, currentColor, handleClose, open }) => {
    const [selectedColor, setSelectedColor] = useState(currentColor);

    const handleChange = (color) => {
        setSelectedColor(color.hex);
        handleClose();
    };

    return open && (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth="xs"
        >
            <TwitterPicker
                width="348px"
                color={selectedColor}
                colors={colors}
                onChange={handleChange}
                onChangeComplete={(color) => onChange(color.hex)}
            />
        </Dialog>
    );
};

export default ColorPicker;
