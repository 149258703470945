import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";


import makeStyles from '@mui/styles/makeStyles';
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import { useNavigate } from "react-router-dom";
import { i18n } from "../../translate/i18n";

// import { Can } from "../../components/Can";
import { toast } from "react-toastify";
import Divider from "../../components/Divider";
import MultiFieldLine from "../../components/FormComponents/MultiFieldLine";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePlan from "../../hooks/usePlan";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    chatPaper: {
        display: "flex",
        height: "100%",
    },
    // container: {
    //     maxHeight: 'calc(86.5vh)',
    //     padding: theme.spacing(2, 3),
    // },
    content: {
        padding: theme.spacing(0, 3, 2),
    },
    cta: {
        margin: theme.spacing(1, 0),
    },
    formActions: {
        flex: "0 0 auto",
        display: "flex",
        padding: theme.spacing(1),
        alignItems: "center",
        justifyContent: "flex-end",
    },
    formControl: {
        // marginRight: theme.spacing(2),
        "& input": {
            height: "1.2195em"
        }
    },
    label: {
        padding: "5px 0",
    },
    mainContainer: {
        display: "flex",
        flexDirection: "column"
    },
    mainPaper: {
        flex: 1,
        flexWrap: "wrap",
        height: `calc(100vh - ${theme.spacing(16.5)})`,
        overflowY: "scroll",
        overflowX: "hidden",
        padding: theme.spacing(2, 3),
        ...theme.scrollbarStyles,
    },
    option: {
        display: "inline-flex",
        width: "100%",
        margin: `${theme.spacing(2)} 0`,
    },
    p: {
        whiteSpace: "pre-line",
    },
    removeBottomMargin: {
        marginBottom: 0,
    },
    textCampaignsContainer: {
        width: "100%",
    },
    textField: {
        margin: `0 0 0 auto`,
        maxWidth: theme.spacing(14),
        height: theme.spacing(56),
    },
    title: {
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    titleH3: {
        fontSize: "1rem",
        fontWeight: "500",
        width: "100%",
    },
    wrapperExample: {},
    wrapperInfo: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: '0',
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            flexWrap: "wrap",
        },
    },
}));

const TenantSchema = Yup.object().shape({
    //TODO: Add one for CPF or CNPJ when country is Brazil
    name: Yup.string()
        .min(4, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    email: Yup.string()
        .email(`${i18n.t("translation.validation.invalid_email")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    slug: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .matches(/^[a-zA-Z0-9-]+$/, `${i18n.t("translation.validation.specialChars")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

const Account = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const { tenant, tenantId } = useTenant();
    const { plan } = usePlan();

    // TODO: Change user type to match new API
    const initialState = {
        name: '',
        email: '',
        documentNumber: '',
        addressStreet: '',
        addressZip: '',
        addressCity: '',
        addressCountry: '',
    };

    const [account, setAccount] = useState(initialState);
    const [checkExtraFields, setCheckExtraFields] = useState(false);
    const [hasDocNumber, setHasDocNumber] = useState(false);

    useEffect(() => {
        if (!tenantId) return;
        let isMounted = true;
        const controller = new AbortController();

        const fetchTenants = async () => {
            try {
                const { data } = await axiosPrivate.get(`/tenants/${tenantId}`);
                if (isMounted) {
                    setAccount(data);
                    setCheckExtraFields(data?.documentNumber ? true : false);
                    setHasDocNumber(data?.documentNumber ? true : false);
                };
            } catch (err) {
                isMounted && toastError(err, "", "Error while trying to pull tenants on account page.");
            }
        };
        fetchTenants();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    // TODO: change to toggle?
    // const handleOpenTenantsModal = () => {
    //     setTenantsModalOpen(true);
    // };

    const handleSaveTenant = async values => {
        try {
            // Remove empty properties from an object
            const data = Object.fromEntries(
                Object.entries(values).filter(([_, value]) => value !== "" && value !== undefined)
            );
            setHasDocNumber(data?.documentNumber ? true : false);

            await axiosPrivate.put(`/tenants/${tenant.id}`, data);
            toast.success(i18n.t("translation.tenantModal.success"));
        } catch (err) {
            toastError(err, "", "Error while trying to save tenant information.");
        }
    };

    return (
        <MainContainer className={classes.container}>
            <MainHeader>
                <Title>
                    {i18n.t("translation.account.title")}
                </Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        // TODO: add open invoices notification/alert
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/${tenant.slug}/invoices`)}
                        className={classes.formControl}
                    >
                        {i18n.t("translation.account.buttons.invoices")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Grid container>
                <Grid item xs={12} md={6} className={classes.wrapperInfo}>
                    <Paper className={classes.mainPaper} variant="outlined">
                        <div>
                            <Typography component="h2" variant="h6">
                                {i18n.t("translation.account.content.title")}
                            </Typography>
                            <Typography variant="body1">
                                {i18n.t("translation.account.content.description")}
                            </Typography>
                            <Divider />

                            <Typography variant="h6">
                                {i18n.t("translation.account.details.title")}
                            </Typography>
                            <Typography variant="body1">
                                {i18n.t("translation.account.details.description")}
                            </Typography>
                            <Divider silent={true} />

                            <Formik
                                initialValues={account}
                                enableReinitialize={true}
                                validationSchema={TenantSchema}
                                onSubmit={(values, actions) => {
                                    setTimeout(() => {
                                        const { checkExtraFields, ...filteredValues } = values;
                                        handleSaveTenant(filteredValues);
                                        actions.setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({ values, touched, errors, isSubmitting }) => (
                                    <Form>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.tenantModal.form.name")}
                                            autoFocus
                                            name="name"
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.userModal.form.email")}
                                            name="email"
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />

                                        <div>
                                            {/* <Accordion defaultExpanded className={classes.accordion}> */}
                                            <Accordion className={classes.accordion} expanded={checkExtraFields}>
                                                <AccordionSummary>
                                                    <Tooltip title={i18n.t("translation.tenantModal.form.extraFields.note")} placement="top">
                                                        <FormControl component="fieldset">
                                                            <FormGroup>
                                                                <Field
                                                                    as={FormControlLabel}
                                                                    name="checkExtraFields"
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            checked={checkExtraFields}
                                                                            onClick={() => setCheckExtraFields(check => !check)}
                                                                        />
                                                                    }
                                                                    label={i18n.t("translation.tenantModal.form.extraFields.title")}
                                                                    labelPlacement="end"
                                                                    className={classes.removeBottomMargin}
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Tooltip>
                                                </AccordionSummary>

                                                <AccordionDetails className={classes.flexWrap}>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.tenantModal.form.documentNumber")}
                                                        fullWidth
                                                        name="documentNumber"
                                                        error={touched.documentNumber && Boolean(errors.documentNumber)}
                                                        helperText={touched.documentNumber && errors.documentNumber}
                                                        variant="outlined"
                                                        size="small"
                                                        disabled={hasDocNumber}
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.tenantModal.form.addressStreet")}
                                                        name="addressStreet"
                                                        multiline
                                                        minRows={3}
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                    />

                                                    <MultiFieldLine>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.tenantModal.form.addressCity")}
                                                            name="addressCity"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                        />

                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.tenantModal.form.addressZip")}
                                                            name="addressZip"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </MultiFieldLine>

                                                    <MultiFieldLine>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.tenantModal.form.addressState")}
                                                            name="addressState"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                        />

                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.tenantModal.form.addressCountry")}
                                                            name="addressCountry"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </MultiFieldLine>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>

                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.cta}
                                        >
                                            {i18n.t("translation.validation.buttons.okEdit")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} className={classes.wrapperExample}>
                    <Paper className={classes.mainPaper} variant="outlined">
                        <div>
                            <Typography component="h2" variant="h6">
                                {i18n.t("translation.account.subscription.title")}
                            </Typography>
                            <Typography variant="body1">
                                {i18n.t("translation.account.subscription.description")}
                            </Typography>
                            <Divider />

                            <Typography variant="h6">
                                {i18n.t("translation.account.plan.title") + ": " + plan.name}
                            </Typography>
                            <Typography variant="body1">
                                {plan.description}
                            </Typography>
                            <ul>
                                {plan.planPieces?.map((piece) => (
                                    <li key={piece?.name}>
                                        {`${i18n.t(`translation.planModal.form.option.planPiece.name.${piece?.fieldName}`)}: ${piece?.PlanComposition?.quantity}`}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </MainContainer >
    );
};

export default Account;
