import React from "react";

import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

import makeStyles from '@mui/styles/makeStyles';

import appVars from "../../../package.json";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MarkdownWrapper from "../../components/MarkdownWrapper";

import Divider from "../../components/Divider/index.js";
import useSettings from "../../hooks/useSettings";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n.js";

const useStyles = makeStyles(theme => ({
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    chatPaper: {
        display: "flex",
        height: "100%",
    },
    container: {
        maxHeight: 'calc(86.5vh)',
        padding: "16px 24px",
    },
    content: {
        padding: "0 24px 16px",
    },
    formActions: {
        flex: "0 0 auto",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    label: {
        padding: "5px 0",
    },
    mainContainer: {
        display: "flex",
        flexDirection: "column"
    },
    mainPaper: {
        overflowX: "hidden",
        ...theme.scrollbarStyles,
    },
    option: {
        display: "inline-flex",
        width: "100%",
        margin: `${theme.spacing(2)} 0`,
    },
    p: {
        whiteSpace: "pre-line",
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            // maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    textCampaignsContainer: {
        width: "100%",
    },
    textField: {
        margin: `0 0 0 auto`,
        maxWidth: "109px",
        height: "450px",
    },
    title: {
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    titleH3: {
        fontSize: "1rem",
        fontWeight: "500",
        width: "100%",
    },
    wrapperExample: {},
    wrapperInfo: {
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: '0'
        }
    },
    wrapperMain: {
        flex: 1,
        height: "calc(100vh - 48px)",
    },
}));

const Api = () => {
    const classes = useStyles();
    const { getSettingValue } = useSettings();
    const { tenant } = useTenant();

    const goToSupport = () => {
        window.open(`${appVars.support.link}?referral=${tenant.slug}`, "_blank");
    }

    return (
        <MainContainer className={classes.container}>
            <MainHeader>
                <Title>{i18n.t("translation.api.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled="false"
                        onClick={goToSupport}
                    >
                        {i18n.t("translation.validation.buttons.support")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Grid container spacing={0} className={classes.mainContainer}>
                <Grid item xs={12} md={7} className={classes.wrapperInfo}>
                    <Paper className={classes.mainPaper} variant="outlined">
                        <div className={classes.root}>
                            <div className={classes.container}>
                                <div className={classes.title}>
                                    <h2 className={classes.titleH2}>{i18n.t("translation.apiModal.title")}</h2>
                                </div>
                                <Typography variant="body1">
                                    {i18n.t("translation.apiModal.content")}
                                </Typography>
                                <Divider />

                                <Typography variant="h6">{i18n.t("translation.apiModal.ui.token.title")}</Typography>
                                <Typography variant="body1">{i18n.t("translation.apiModal.ui.token.description")}</Typography>
                                <Divider silent={true} />

                                <Typography variant="body1">{i18n.t("translation.apiModal.ui.token.url", { url: "" })}</Typography>

                                {/* TODO: update token through this page */}
                                <div className={classes.option}>
                                    <TextField
                                        id="api-url-setting"
                                        readOnly
                                        label={i18n.t("translation.apiModal.ui.url.input")}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        value={appVars.apiURL || ""}
                                    />
                                </div>
                                <div className={classes.option}>
                                    <TextField
                                        id="api-token-setting"
                                        readOnly
                                        label={i18n.t("translation.apiModal.ui.token.input")}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        value={getSettingValue("userApiToken") || ""}
                                    />
                                </div>
                                <Divider />
                                <Typography variant="body1">
                                    <span className={classes.titleH3}>{i18n.t("translation.apiModal.ui.information.title")}</span><br />
                                    <span className={classes.p}>
                                        <MarkdownWrapper>{i18n.t("translation.apiModal.ui.information.description")}</MarkdownWrapper>
                                    </span>
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={5} className={classes.wrapperExample}>
                    <Paper className={classes.mainPaper} variant="outlined" >
                        <div className={classes.root}>
                            <div className={classes.container}>
                                <div className={classes.title}>
                                    <h2 className={classes.titleH2}>{i18n.t("translation.apiModal.example.title")}</h2>
                                </div>
                                <Typography variant="body1">
                                    {i18n.t("translation.apiModal.example.content")}
                                </Typography>
                                <Divider />
                                <Typography variant="body1">
                                    <span className={classes.titleH3}>{i18n.t("translation.apiModal.ui.messages")}</span><br />
                                    <span className={classes.p}>
                                        Authorization: Bearer {getSettingValue("userApiToken")}<br />
                                        Content-Type: application/json
                                    </span>
                                </Typography>
                                <div className={classes.option}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        label="Payload"
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        value={
                                            "{\n" +
                                            "    \"number\": \"556140428274\",\n" +
                                            "    \"body\": \"" + i18n.t("translation.apiModal.ui.examples.messages") + "\",\n" +
                                            "    \"email\": \"" + i18n.t("translation.apiModal.ui.examples.email") + "\",\n" +
                                            "    \"mediaUrl\": \"" + i18n.t("translation.apiModal.ui.examples.mediaUrl") + "\",\n" +
                                            "    \"name\": \"" + i18n.t("translation.apiModal.ui.examples.name") + "\",\n" +
                                            "    \"openTicket\": \"" + i18n.t("translation.apiModal.ui.examples.openTicket") + "\",\n" +
                                            "    \"queue\": \"" + 5 + "\",\n" +
                                            "    \"bot\": \"" + 1 + "\",\n" +
                                            "    \"whatsappId\": \"" + 1 + "\"\n" +
                                            "}"}
                                        className={classes.settingTextField}
                                        readOnly
                                    />
                                </div>

                                <Typography variant="body1">
                                    <span className={classes.titleH3}>{i18n.t("translation.apiModal.ui.media")}</span><br />
                                    <span className={classes.p}>
                                        Authorization: Bearer {getSettingValue("userApiToken")}<br />
                                        Content-Type: multipart/form-data
                                    </span>
                                </Typography>
                                <div className={classes.option}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        label={i18n.t("translation.apiModal.ui.media")}
                                        fullWidth
                                        multiline
                                        minRows={9}
                                        value={
                                            "{\n" +
                                            "    \"number\": \"556140428274\",\n" +
                                            "    \"medias\": {" + i18n.t("translation.apiModal.ui.examples.media") + "},\n" +
                                            "    \"body\": \"" + i18n.t("translation.apiModal.ui.examples.messages") + "\",\n" +
                                            "    \"email\": \"" + i18n.t("translation.apiModal.ui.examples.email") + "\",\n" +
                                            "    \"name\": \"" + i18n.t("translation.apiModal.ui.examples.name") + "\",\n" +
                                            "    \"openTicket\": \"" + i18n.t("translation.apiModal.ui.examples.openTicket") + "\",\n" +
                                            "    \"queue\": \"" + 5 + "\",\n" +
                                            "    \"bot\": \"" + 1 + "\",\n" +
                                            "    \"whatsappId\": \"" + 1 + "\"\n" +
                                            "}"}
                                        className={classes.settingTextField}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default Api;
