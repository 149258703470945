import React, { useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import {
    IconButton,
    Tooltip,
} from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import GPTModal from "../GPTModal";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    button: {
        float: "right",
        marginBottom: theme.spacing(-4),
        position: "relative",
        zIndex: "1",

    }
}));

const GPTModalButton = ({ disabled, field, setFieldValue, prompt }) => {
    const classes = useStyles();
    const [gptModalOpen, setGptModalOpen] = useState(false);

    const checkClick = () => {
        if (!field) {
            toastError(i18n.t("translation.gptModal.form.fieldError"))
        } else {
            setGptModalOpen(true)
        }
    }

    return (
        <>
            <GPTModal
                open={gptModalOpen}
                onClose={() => setGptModalOpen(false)}
                field={field}
                setFieldValue={setFieldValue}
                prompt={prompt}
            />
            <Tooltip
                title={i18n.t("translation.gptModal.tooltip")}
                placement="bottom"
                className={classes.tooltip}
                arrow
            >
                <IconButton
                    onClick={() => checkClick()}
                    disabled={disabled}
                    variant="outlined"
                    size="small"
                    className={classes.button}
                >
                    <AutoAwesome />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default GPTModalButton;
