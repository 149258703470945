import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    colorError: {
        color: 'white',
        backgroundColor: theme.palette.error.main
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}))

const ConfirmationModal = ({ isError, title, children, open, onClose, onConfirm }) => {
    const classes = useStyles();
    if (isError === undefined) isError = true;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog" className={isError && classes.colorError}>{title}</DialogTitle>
            <DialogContent dividers>
                <Typography>{children}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => onClose(false)}
                    className={classes.buttonColorError}
                >
                    {i18n.t("translation.validation.buttons.cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onClose(false);
                        onConfirm();
                    }}
                    color={isError ? "error" : "primary"}
                    className={isError && classes.colorError}
                >
                    {i18n.t("translation.validation.buttons.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
