import { Field, Form, Formik } from "formik";
import React, { useEffect, /*useMemo,*/ useState, } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
    AttachFile,
    Cancel,
    CheckCircleOutlined,
    DeleteOutline,
    ErrorOutline,
    QueueOutlined,
    ScheduleOutlined,
    SellOutlined,
} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";

import ConnectionItems from "../../../components/ConnectionItems";
import ContactGroupsModal from "../../../components/ContactGroupModal";
import GPTModalButton from "../../../components/GPTModalButton";
import InputVariables from "../../../components/InputVariables";
import Title from "../../../components/LayoutComponents/Title";
import MainContainer from "../../../components/MainContainer";
import MainHeader from "../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../components/MainHeaderButtonsWrapper";
import TutorialModalButton from "../../../components/TutorialModalButton";

import toastError from "../../../errors/toastError";
import { i18n } from "../../../translate/i18n";

import MultiFieldLine from "../../../components/FormComponents/MultiFieldLine";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import usePlan from "../../../hooks/usePlan";
import useTenant from "../../../hooks/useTenant";
import useWhatsApps from "../../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    modalWrap: {
        display: "flex",
        flexWrap: "wrap",
    },
    modal: {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 auto",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    uploadInput: {
        display: "none",
    },
    newMessageBox: {
        marginTop: theme.spacing(1),
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "left",
        border: "1px solid",
        borderRadius: 15,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    status: {
        display: "flex",
        justifyContent: "center",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    preventLineBreak: {
        width: "15%",
        minWidth: 240,
    },
}));

// const baseStyle = {
//     alignItems: "center",
//     backgroundColor: "#fafafa",
//     borderColor: "#131B43", // theme.palette.primary.main,
//     borderRadius: 15,
//     borderStyle: "dashed",
//     borderWidth: 1,
//     color: "#131B43", // theme.palette.primary.main,
//     cursor: "pointer",
//     display: "flex",
//     flex: 1,
//     flexDirection: "column",
//     marginBottom: "8px",
//     outline: "none",
//     padding: "20px",
//     transition: "border .24s ease-in-out",
//     minWidth: 365,
//     width: "100%",
// };

// const activeStyle = {
//     borderColor: "#2196f3",
// };

// const acceptStyle = {
//     borderColor: "#00e676",
// };

// const rejectStyle = {
//     borderColor: "#ff1744",
// };

const CampaignSchema = Yup.object().shape({
    message: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(1200, `${i18n.t("translation.validation.too_long")}`),
});

const filter = createFilterOptions({
    trim: true,
});

const Campaign = () => {
    const classes = useStyles();
    const { userTenant, tenantId, userTenantId, tenant } = useTenant();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [options, setOptions] = useState([]);

    const initialState = {
        wpId: "",
        name:
            i18n.t("translation.variables.campaign.name") +
            "_" +
            new Date()
                .toISOString()
                .substring(0, 19)
                .replaceAll("T", ".")
                .replaceAll("-", "")
                .replaceAll(":", ""),
        message: `${i18n.t(
            "translation.variables.campaign.content"
        )} {{firstName}}, `,
        hasMedia: false,
        sendAt: null,
        status: "pending",
        senderGroupId: null,
        userTenantId: userTenant?.id,
    };

    const { whatsApps } = useWhatsApps();
    const { getPlanValue, plan } = usePlan();

    const [inputs, setInputs] = useState(initialState);
    const [campaignModalOpen, setCampaignModalOpen] = useState(false);
    const [campaignId, setCampaignId] = useState();
    const [medias, setMedias] = useState([]);

    const fetchData = async () => {
        const { data } = await axiosPrivate.get(`/${tenantId}/senderGroups`);
        setOptions(data.senderGroups);
    };

    useEffect(() => {
        if (tenantId) fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    useEffect(() => {
        let setHasMedia;
        medias.length > 0 ? (setHasMedia = true) : (setHasMedia = false);
        if (inputs.hasMedia !== setHasMedia) {
            setInputs((inputs) => ({ ...inputs, hasMedia: setHasMedia }));
        }
    }, [inputs.hasMedia, medias]);

    const [speaches, setSpeaches] = useState({});
    const [speachCount, setSpeachCount] = useState(0);

    const [, /*csvFile*/ setCsvFile] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [field, setField] = useState();

    const [usage, setUsage] = useState({
        limit: 1,
        usage: 0,
        message: i18n.t("translation.campaigns.buttons.credits.loading"),
    });
    const [usageInfo, setUsageInfo] = useState({
        color: "primary",
        message: usage.message,
        credits: 0,
        tooltip: i18n.t("translation.campaigns.tooltips.trial"),
    });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchSession = async () => {
            try {
                const { data } = await axiosPrivate.get(
                    `/${tenantId}/campaign/limit`
                );
                isMounted &&
                    setUsage((info) => ({
                        ...info,
                        usage: data[0].count,
                        limit: getPlanValue("campaignLimit"),
                    }));
            } catch (err) {
                toastError(err);
            }
        };
        if (tenantId) fetchSession();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, plan]);

    useEffect(() => {
        let limit = Number(usage.limit);
        let use = Number(usage.usage);
        let credits = limit - use;
        let percent = credits / limit;

        setUsageInfo((prev) => ({ ...prev }));

        let message = i18n.t("translation.campaigns.buttons.credits.left", {
            credits: credits.toLocaleString("pt-BR"),
            limit: limit.toLocaleString("pt-BR"),
        });
        let buy = i18n.t("translation.campaigns.buttons.credits.buy", {
            credits: credits.toLocaleString("pt-BR"),
            limit: limit.toLocaleString("pt-BR"),
        });
        let negative = i18n.t("translation.campaigns.buttons.credits.negative");

        if (credits <= 0) {
            setUsageInfo((info) => ({
                ...info,
                color: "secondary",
                message: negative,
                credits: credits,
                tooltip: i18n.t(
                    "translation.campaigns.tooltips.buyCreditsNow",
                    {
                        credits: credits.toLocaleString("pt-BR"),
                        limit: limit.toLocaleString("pt-BR"),
                    }
                ),
            }));
        } else if (percent <= 0.15 || credits < 1) {
            setUsageInfo((info) => ({
                ...info,
                color: "secondary",
                message: buy,
                credits: credits,
                tooltip: i18n.t("translation.campaigns.tooltips.buyCredits"),
            }));
        } else {
            setUsageInfo((info) => ({
                ...info,
                color: "primary",
                message: message,
                credits: credits,
                tooltip: i18n.t("translation.campaigns.tooltips.creditsInfo"),
            }));
        }
    }, [usage]);



    useEffect(() => {
        setSpeachCount(1);
    }, [contacts]);

    const checkContent = (inputs) => {
        if (!inputs.wpId) {
            toast.error(i18n.t("translation.campaigns.toasts.error.noWpId"));
            return;
        }
        if (!inputs.senderGroupId) {
            toast.error(
                i18n.t("translation.campaigns.toasts.error.noContacts")
            );
            return;
        }
        if (contacts.length > usage.limit - usage.usage) {
            toast.error(i18n.t("translation.campaigns.toasts.error.noCredits"));
            return;
        }
        handleSaveInstantCampaign(inputs);
    };

    const handleSaveCampaign = (values) => {
        setInputs((inputs) => ({
            ...inputs,
            message: values.message,
            userTenantId,
        }));
        checkContent(inputs);
    };

    const handleSaveInstantCampaign = async (inputs) => {
        try {
            let instantCampaignId;
            let values;

            if (medias.length > 0) {
                values = new FormData();
                values.append("name", inputs.name);
                values.append("message", inputs.message);
                values.append("hasMedia", inputs.hasMedia);
                values.append("userTenantId", inputs.userTenantId);
                values.append("sendAt", inputs.sendAt);
                values.append("senderGroupId", inputs.senderGroupId);
                values.append("wpId", inputs.wpId);
                values.append("status", "pending");
                medias.forEach((media) => {
                    values.append("file", media);
                });
            } else {
                values = inputs;
            }

            if (tenantId) {
                if (campaignId) {
                    await axiosPrivate.put(
                        `/${tenantId}/campaign/${campaignId}`,
                        values ? values : inputs
                    );
                } else {
                    const { data: responseData } = await axiosPrivate.post(
                        `/${tenantId}/campaign`,
                        values ? values : inputs
                    );
                    setCampaignId(responseData.id);
                    instantCampaignId = responseData.id;
                }
            }

            toast.success(i18n.t("translation.campaigns.toasts.saved"));
            medias
                ? handleSendMedia(instantCampaignId)
                : handleSendMessage(instantCampaignId);
            navigate(`/${tenant.slug}/reports/campaigns`);
        } catch (err) {
            toast.error(
                i18n.t("translation.campaigns.toasts.error.saveCampaign") + err
            );
        }
        handleCloseCampaignModal();
    };

    const handleDeleteFromCampaign = (index) => {
        contacts.splice(index, 1);
        setContacts(contacts);
        setSpeaches((state) => state + 1);
    };

    // TODO: componentize
    const handleInputMedias = (e) => {
        if (!e.target.files) return;
        const selectedMedias = Array.from(e.target.files);
        let renamedMedias = [];
        for (let media of selectedMedias) {
            const file = new File([media], `cpg_${media.name}`, {
                type: media.type,
            });
            renamedMedias.push(file);
        }
        setMedias(renamedMedias);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleOpenCampaignModal = () => {
        setCampaignModalOpen(true);
    };

    const handleCloseCampaignModal = () => {
        setCampaignModalOpen(false);
    };

    const handleFocus = (e) => {
        setField(e.target);
    };

    //Componentize
    const InputFile = () => {
        if (medias?.length > 0)
            return (
                <>
                    <div className={classes.newMessageBox}>
                        <Button
                            startIcon={<Cancel color="error" />}
                            component="span"
                            onClick={(e) => setMedias([])}
                            size="small"
                        >
                            <Typography>
                                {medias[0]?.name}
                                {medias.length > 1
                                    ? i18n.t(
                                        "translation.validation.medias.hasOthers",
                                        { medias: medias.length }
                                    )
                                    : ""}
                            </Typography>
                        </Button>
                    </div>
                </>
            );
        else
            return (
                <>
                    <div className={classes.newMessageBox}>
                        <input
                            multiple
                            type="file"
                            id="upload-button2"
                            className={classes.uploadInput}
                            onChange={handleInputMedias}
                        />
                        <label htmlFor="upload-button2">
                            <Button
                                startIcon={<AttachFile />}
                                component="span"
                                size="small"
                            >
                                <Typography>
                                    {i18n.t(
                                        "translation.validation.medias.none"
                                    )}
                                </Typography>
                            </Button>
                        </label>
                    </div>
                </>
            );
    };

    const cleanNumber = (number) => {
        return number
            .replaceAll("-", "")
            .replaceAll(" ", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("+", "")
            .replaceAll(".", "")
            .replaceAll("_", "");
    };

    const handleSendMedia = (instantCampaignId) => {
        handleSendMessage(medias, instantCampaignId);
    };

    const handleSendMessage = async (medias, instantCampaignId) => {
        try {
            setSpeachCount(0);
            for (let i = 0; i < contacts.length; i++) {
                let msgtemp = inputs.message;
                for (let [key, value] of Object.entries(speaches)) {
                    if (value[speachCount] !== "") {
                        msgtemp = msgtemp.replace(
                            "$" + key,
                            value[speachCount]
                        );
                    }
                }
                if (typeof speaches["SPEACH1"] !== "undefined") {
                    if (speachCount === speaches["SPEACH1"].length - 1) {
                        setSpeachCount(0);
                    } else {
                        setSpeachCount((state) => state + 1);
                    }
                }
            }
            setContacts(contacts);
            setSpeaches((state) => state + 1);
        } catch (err) {
            toastError(err);
        }
        setContacts(contacts);
        setSpeaches((state) => state + 1);
        // toast.success(i18n.t("translation.campaigns.toasts.success"));

        setInputs(initialState);
        setCsvFile([]);
        setMedias([]);
        setCampaignId();
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);
        return filtered;
    };

    const renderOption = (props, option) => {
        if (option.name) {
            return (
                <li {...props} style={{ gap: "5px" }}>
                    <SellOutlined sx={{ color: `${option.tagColor}` }} />
                    <span>{option.name}</span>
                </li>
            );
        } else {
            return (
                <li {...props}>
                    {i18n.t("translation.newTicketModal.add")} {option.name}
                </li>
            );
        }
    };

    const renderOptionLabel = (option) => {
        if (option.name) {
            return `${option.name}`;
        } else {
            return `${option.name}`;
        }
    };

    const [openModal, setOpenModal] = useState(false);

    function handleOpenModal() {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSelectOption = (e, newValue) => {
        setInputs({
            ...inputs,
            senderGroupId: newValue?.id,
        });
    };

    return (
        <MainContainer>
            <ContactGroupsModal open={openModal} onClosed={handleCloseModal} />
            <div className={classes.modalWrap}>
                <Dialog
                    open={campaignModalOpen}
                    onClose={handleCloseCampaignModal}
                    scroll="paper"
                >
                    <DialogTitle>
                        {i18n.t("translation.campaigns.form.title")}
                    </DialogTitle>
                    <Formik
                        initialValues={inputs}
                        enableReinitialize={true}
                        validationSchema={CampaignSchema}
                        onSubmit={(values, actions, e) => {
                            setTimeout(() => {
                                handleSaveCampaign(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({
                            touched,
                            errors,
                            isSubmitting,
                            values,
                            getFieldProps,
                            setFieldValue,
                        }) => (
                            <Form>
                                <DialogContent dividers>
                                    <MultiFieldLine>
                                        <Field
                                            as={TextField}
                                            label={i18n.t(
                                                "translation.campaigns.form.name"
                                            )}
                                            id="name"
                                            name="name"
                                            onFocus={handleFocus}
                                            error={
                                                touched.name &&
                                                Boolean(errors.name)
                                            }
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            variant="outlined"
                                            size="small"
                                            onBlur={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                        <Field
                                            as={TextField}
                                            label={i18n.t(
                                                "translation.scheduledMessagesModal.form.date"
                                            )}
                                            type="datetime-local"
                                            ampm="false"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 600 }} //5 minutes
                                            name="sendAt"
                                            id="sendAt"
                                            error={
                                                touched.sendAt &&
                                                Boolean(errors.sendAt)
                                            }
                                            helperText={
                                                touched.sendAt && errors.sendAt
                                            }
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            fullWidth
                                            className={classes.textField}
                                        />
                                    </MultiFieldLine>
                                    <Autocomplete
                                        options={options}
                                        clearOnBlur
                                        autoHighlight
                                        freeSolo
                                        clearOnEscape
                                        getOptionLabel={renderOptionLabel}
                                        renderOption={renderOption}
                                        filterOptions={createAddContactOption}
                                        onChange={(e, newValue) =>
                                            handleSelectOption(e, newValue)
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={i18n.t(
                                                    "translation.campaigns.senderGroups"
                                                )}
                                                variant="outlined"
                                                autoFocus
                                                name="senderGroup"
                                                id="senderGroup"
                                                className={classes.textField}
                                                size="small"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                            <Tooltip
                                                                title={i18n.t(
                                                                    "translation.senderGroups.buttons.add"
                                                                )}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={
                                                                        handleOpenModal
                                                                    }
                                                                >
                                                                    <QueueOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        className={classes.maxWidth}
                                        fullWidth
                                    >
                                        <InputLabel>
                                            {i18n.t(
                                                "translation.campaigns.form.id"
                                            )}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            label={i18n.t(
                                                "translation.campaigns.form.id"
                                            )}
                                            name="wpId"
                                            variant="outlined"
                                            size="small"
                                            className={classes.textField}
                                            fullWidth
                                            placeholder={i18n.t(
                                                "translation.campaigns.form.id"
                                            )}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            {ConnectionItems(whatsApps)}
                                        </Field>
                                    </FormControl>

                                    <GPTModalButton
                                        disabled={isSubmitting}
                                        field={
                                            field || getFieldProps("message")
                                        }
                                        setFieldValue={setFieldValue}
                                        prompt={i18n.t(
                                            "translation.gptModal.prompts.campaignMessage"
                                        )}
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t(
                                            "translation.campaigns.form.message"
                                        )}
                                        id="message"
                                        name="message"
                                        autoFocus
                                        onFocus={handleFocus}
                                        multiline
                                        minRows={3}
                                        fullWidth
                                        error={
                                            touched.message &&
                                            Boolean(errors.message)
                                        }
                                        helperText={
                                            touched.message && errors.message
                                        }
                                        variant="outlined"
                                        size="small"
                                        onBlur={(e) => {
                                            handleChange(e);
                                        }}
                                    />
                                    <InputVariables
                                        group="campaigns"
                                        field={field}
                                        setFieldValue={setFieldValue}
                                    />
                                    <InputFile />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={classes.btnWrapper}
                                        disabled={isSubmitting}
                                    >
                                        {i18n.t(
                                            "translation.validation.buttons.send"
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Dialog>
            </div>

            <MainHeader>
                <Title>
                    {i18n.t("translation.campaigns.title")}
                    <TutorialModalButton content="campaigns" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Tooltip arrow title={usageInfo.tooltip}>
                        <Button variant="outlined" color={usageInfo.color}>
                            {usageInfo.message}
                        </Button>
                    </Tooltip>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleOpenCampaignModal()}
                    >
                        {inputs.id ?? medias > 0 ?? contacts.length > 0
                            ? i18n.t("translation.campaigns.buttons.edit")
                            : i18n.t("translation.campaigns.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.number")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t(
                                    "translation.variables.table.status.title"
                                )}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.dateTime")}
                            </TableCell>
                            <TableCell
                                align="center"
                                className={classes.actionsCell}
                            >
                                {i18n.t("translation.variables.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map((contact, i) => (
                                <TableRow key={i}>
                                    <TableCell align="center">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell align="center">
                                        {contact[0]}
                                    </TableCell>
                                    <TableCell align="center">
                                        +{cleanNumber(contact[1])}
                                    </TableCell>
                                    <TableCell align="center">
                                        {contact[4] === "Success" ? (
                                            <div className={classes.status}>
                                                <CheckCircleOutlined
                                                    fontSize="small"
                                                    className={
                                                        classes.marginRight
                                                    }
                                                />{" "}
                                                {i18n.t(
                                                    "translation.variables.table.status.sent"
                                                )}
                                            </div>
                                        ) : contact[4] === "Error" ? (
                                            <div className={classes.status}>
                                                <ErrorOutline
                                                    fontSize="small"
                                                    className={
                                                        classes.marginRight
                                                    }
                                                    color="error"
                                                />{" "}
                                                {i18n.t(
                                                    "translation.variables.table.status.error"
                                                )}
                                            </div>
                                        ) : (
                                            <div className={classes.status}>
                                                <ScheduleOutlined
                                                    fontSize="small"
                                                    className={
                                                        classes.marginRight
                                                    }
                                                />{" "}
                                                {i18n.t(
                                                    "translation.variables.table.status.pending"
                                                )}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {contact[3]}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            className={[
                                                classes.actionButtons,
                                                classes.buttonColorError,
                                            ].join(" ")}
                                            onClick={() => {
                                                handleDeleteFromCampaign(i);
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Campaign;
