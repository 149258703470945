import React, { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import toastError from '../../errors/toastError';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useTenant from '../../hooks/useTenant';

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    }
}))

const UserTenantItems = () => {
    const classes = useStyles();
    const { tenantId } = useTenant();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchUsers = async () => {
            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/users/${tenantId}`);
                let blockRoles = ["finance", "dashboard"];
                let availableUsers = data.users.filter(user => (
                    user.status === true &&
                    user.userTenant[0].status === true &&
                    blockRoles.indexOf(user.userTenant[0].role) === -1
                ));
                if (isMounted) {
                    setUsers(availableUsers);
                    setLoading(false);
                }
            } catch (err) {
                toastError(err, "", "Error while trying to set users in BotOptions component.");
            }
        };
        fetchUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    return !loading && (
        users.map((user) => (
            <MenuItem key={user.id} value={user.id} className={classes.connectInfoItem}>
                {user.name}
            </MenuItem>
        ))
    );
};

export default UserTenantItems;
