import en from "./en/en-US.json";
import es from "./es/es.json";
import pt from "./pt/pt-BR.json";

export const resources = {
	"en": en,
	"es": es,
	"pt": pt,
};

export default resources;
