import { Field } from "formik";
import { useEffect, useState } from "react";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import ContactGroupsModal from "../ContactGroupModal";

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
    new: {
        borderTop: "1px solid #dadde9"
    }
}))

const ContactGroupSelect = ({ current, fieldId, onChange }) => {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();

    // Context
    const { tenantId } = useTenant();

    // State
    const [loading, setLoading] = useState(false);
    const [contactGroups, setContactGroups] = useState([]);
    const [contactGroupsModalOpen, setContactGroupsModalOpen] = useState(false);

    const fetchContactGroups = async (isMounted) => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`${tenantId}/contactGroups`);
            const filtered = data?.contactGroups?.filter((contactGroup) => contactGroup.id !== current);
            if (isMounted && filtered) {
                setContactGroups(filtered);
                setLoading(false);
            }
        } catch (err) {
            toastError(err, "", "Error while trying to get bot list info on botItems component.");
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchContactGroups(isMounted);

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const handleOpenContactGroupsModal = () => {
        setContactGroupsModalOpen(true)
    }

    const handleCloseContactGroupsModal = () => {
        setContactGroupsModalOpen(false)

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchContactGroups(isMounted)

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    return (<>
        <ContactGroupsModal
            open={contactGroupsModalOpen}
            onClose={handleCloseContactGroupsModal}
        />
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel>
                {i18n.t("translation.contactGroupsModal.form.option.contactGroup")}</InputLabel>
            <Field
                as={Select}
                label={i18n.t("translation.contactGroupsModal.form.option.contactGroup")}
                name={fieldId}
                id={fieldId}
                fullWidth
                onChange={onChange}
            >
                <MenuItem
                    key="no-key-contact-group"
                    value={""}
                >
                    {i18n.t("translation.botModal.form.option.select")}
                </MenuItem>
                {!loading && contactGroups?.length > 0 && contactGroups?.map((contactGroup) => (
                    <MenuItem
                        key={contactGroup.id}
                        value={contactGroup.id}
                        className={classes.connectInfoItem}
                    >
                        <Tooltip arrow title={contactGroup.description}>
                            <>
                                <span>
                                    {contactGroup.name}
                                </span>
                                <span>
                                    {/* TODO: add a counter so users know how many contacts are in each list*/}
                                </span>
                            </>
                        </Tooltip>
                    </MenuItem>
                )
                )}
                <MenuItem
                    key="new-key-contact-group"
                    onClick={handleOpenContactGroupsModal}
                    className={classes.new}>{i18n.t("translation.contactGroupsModal.form.option.add")}
                </MenuItem>
            </Field>
        </FormControl>
    </>)
}

export default ContactGroupSelect;
