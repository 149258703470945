import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../useAxiosPrivate";

import useTenant from "../useTenant";

const useTickets = (props) => {
    const {
        date,
        fixUser,
        isGroup,
        pageNumber,
        queueIds,
        searchParam,
        showAll,
        limitNoQueue,
        status,
        tags,
        withUnreadMessages,
        userTenantId
    } = props;
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);
    const { tenantId, userTenant } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        // if (!queueIds || !userTenant) return;
        if (!userTenant) return;
        const controller = new AbortController();

        setLoading(true);
        const fetchTickets = async () => {
            if (!tenantId) return;

            try {
                const { data } = await axiosPrivate.get(
                    `/${tenantId}/tickets`,
                    { params: props },
                    { signal: controller.signal }
                )

                setTickets(data.tickets)
                setHasMore(data.hasMore)
                setCount(data.count)
                setLoading(false)

            } catch (err) {
                setLoading(false)
                toastError(err, "", "Couldn't fetch tickets from backend at Use Tickets")
            }
        }

        // const closeTicket = async (ticket) => {
        //     await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, {
        //         status: "closed",
        //         userTenantId: ticket.userTenantId || null,
        //     })
        // }
        fetchTickets()

        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        date,
        fixUser,
        isGroup,
        limitNoQueue,
        pageNumber,
        queueIds,
        searchParam,
        showAll,
        status,
        tags,
        tenantId,
        userTenantId,
        withUnreadMessages
    ])

    return { tickets, loading, hasMore, count };
};

export default useTickets;
