import React from "react";

import { Button, Paper, TextField, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

import makeStyles from '@mui/styles/makeStyles';

import { Can } from "../../components/Can";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";


import appVars from "../../../package.json";
import Divider from "../../components/Divider/index.js";
import toastError from "../../errors/toastError";
import usePlan from "../../hooks/usePlan";
import useTenant from "../../hooks/useTenant";
import { getBackendUrl } from "../../services/config";
import { i18n } from "../../translate/i18n.js";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    container: {
        padding: "16px 24px",
    },
    mainPaper: {
        margin: "0 auto",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minWidth: "600px",
        },
    },
    title: {
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    titleH3: {
        fontSize: "1rem",
        fontWeight: "500",
        width: "100%",
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textCampaignsContainer: {
        width: "100%",
    },
    content: {
        padding: "0 24px 16px",
    },
    formActions: {
        flex: "0 0 auto",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    option: {
        display: "inline-flex",
        width: "100%",
        margin: "8px 0",
    },
    settingLabel: {
        padding: "5px 0",
    },
    settingOption: {
        marginLeft: "auto",
    },
    settingTextField: {
        margin: "0 0 0 auto",
        maxWidth: "109px",
    },
    settingTextFieldReadOnly: {
        margin: "0 0 0 auto",
    },
}));

const Super = () => {
    const { tenant, userTenant } = useTenant();
    const { getPlanValue } = usePlan();

    const classes = useStyles();

    // const axiosPrivate = useAxiosPrivate();

    const handleChangeSetting = async e => {
        // const selectedValue = e.target.value;
        // const settingKey = e.target.name;

        // try {
        // 	await axiosPrivate.put(`/${tenantId}/settings/${settingKey}`, {
        // 		value: selectedValue,
        // 	});
        // 	toast.success(i18n.t("translation.settings.toasts.success"));
        // } catch (err) {
        // 	toastError(err);
        // }
        toastError(i18n.t("translation.super.toasts.noPermissionUpdate"), true);
    };

    const goToSupport = () => {
        window.open(`${appVars.support.link}/?referral=${tenant.slug}`, "_blank");
    }

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.super.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled="false"
                        onClick={goToSupport}
                    >
                        {i18n.t("translation.settings.support")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.title}>
                            <h2 className={classes.titleH2}>{i18n.t("translation.super.settings.title")}</h2>
                        </div>
                        <Can
                            role={userTenant?.role}
                            perform="settings-super-items:edit"
                            yes={() => (
                                <>
                                    <Divider />
                                    <Typography variant="body1">
                                        {i18n.t("translation.super.settings.description")}
                                    </Typography>
                                    <Divider />
                                    <Typography variant="body1">
                                        <span className={classes.titleH3}>{i18n.t("translation.super.settings.server")}</span>
                                    </Typography>
                                    <div className={classes.option}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            label="Node JS Environment"
                                            fullWidth
                                            value={process.env.NODE_ENV}
                                            className={classes.settingTextFieldReadOnly}
                                            readOnly
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            label="Backend URL"
                                            fullWidth
                                            value={getBackendUrl()}
                                            className={classes.settingTextFieldReadOnly}
                                            readOnly
                                        />
                                    </div>
                                    <Divider />
                                    <Typography variant="body1">
                                        <span className={classes.titleH3}>{i18n.t("translation.super.settings.limits")}</span>
                                    </Typography>
                                    <div className={classes.option}>
                                        <Typography variant="body1" >
                                            <span className={classes.settingLabel}>{i18n.t("translation.settings.settings.limits.maxUsers")}</span>
                                        </Typography>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            id="maxUsers-setting"
                                            name="maxUsers"
                                            value={getPlanValue("users")}
                                            className={classes.settingTextField}
                                            onChange={handleChangeSetting}
                                        />
                                    </div>

                                    <div className={classes.option}>
                                        <Typography variant="body1">
                                            <span className={classes.settingLabel}>{i18n.t("translation.settings.settings.limits.maxConnections")}</span>
                                        </Typography>

                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            id="maxConnections-setting"
                                            name="maxConnections"
                                            value={getPlanValue("connectionsWhatsapp")}
                                            className={classes.settingTextField}
                                            onChange={handleChangeSetting}
                                        />
                                    </div>

                                    <div className={classes.option}>
                                        <Typography variant="body1">
                                            <span className={classes.settingLabel}>{i18n.t("translation.settings.settings.limits.maxGpt")}</span>
                                        </Typography>

                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            id="gptLimit-setting"
                                            name="gptLimit"
                                            value={getPlanValue("gptLimit")}
                                            className={classes.settingTextField}
                                            onChange={handleChangeSetting}
                                        />
                                    </div>

                                    <Divider />

                                    <div className={classes.option}>
                                        <Typography variant="body1">
                                            <span className={classes.settingLabel}>{i18n.t("translation.settings.settings.limits.campaigns.successfull")}</span>
                                        </Typography>

                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            id="campaignLimit-setting"
                                            name="campaignLimit"
                                            value={getPlanValue("campaignLimit")}
                                            className={classes.settingTextField}
                                            onChange={handleChangeSetting}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="body1">
                                            <span className={classes.settingLabel}>{i18n.t("translation.settings.settings.limits.campaigns.maxContacts")}</span>
                                        </Typography>

                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            id="campaignMaxContacts-setting"
                                            name="campaignMaxContacts"
                                            value={getPlanValue("campaignMaxContacts")}
                                            className={classes.settingTextField}
                                            onChange={handleChangeSetting}
                                        />
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </div>
            </Paper>

        </MainContainer>

    );
};

export default Super;
