import React, { useEffect, useRef, useState } from "react";

import Chart from 'react-apexcharts';
import Title from "../../../components/LayoutComponents/CardTitle";
import { i18n } from "../../../translate/i18n";

import { useTheme } from "@mui/material";
import toastError from "../../../errors/toastError";

import useTickets from "../../../hooks/useTickets";

const ChartTicketsQueues = ({ data }) => {
    const theme = useTheme();
    const date = useRef(new Date().toISOString());
    const { tickets, loading } = useTickets({ date: date.current });
    const [chartTickets, setChartTickets] = useState([]);

    const labels = [];
    const useData = [];
    const color = [];

    useEffect(() => {
        if (loading) return;

        const controller = new AbortController();
        const fetchData = () => {
            try {
                setChartTickets(data?.ticketCountQueues?.rows);
            } catch (err) {
                toastError(err, "", "Error ocurred while trying to fetch dashboard's tickets queues donut chart.");
            }
        }
        fetchData();
        return () => {
            controller.abort();
        };
    }, [tickets, loading, data]);

    const GetLabelChart = () => {
        chartTickets?.forEach(function (item) {
            if (item?.queue?.name !== undefined && item?.queue?.name?.indexOf) {
                labels.push(item?.queue?.name?.toString());
            }
            else {
                labels.push(i18n.t("translation.dashboard.charts.perQueue.defaultQueueLabel"));
            }
        });
        return labels
    }

    const GetColorChart = () => {
        chartTickets?.forEach(function (item) {
            if (item?.queue?.color !== undefined && item?.queue?.color?.indexOf) {
                color.push(item?.queue?.color);
            } else {
                color.push(theme.palette.primary.main);
            }
        });
        return color
    }

    const GetChartData = () => {
        chartTickets?.forEach(function (item) {
            useData.push(parseInt(item?.ticketCount));
        });
        return useData
    }

    const chartOptions = {
        labels: GetLabelChart(),
        colors: GetColorChart()
    }

    return (
        chartTickets && (
            <>
                <Title>
                    {i18n.t("translation.dashboard.charts.perQueue.title")}
                </Title>
                <Chart
                    options={chartOptions}
                    height={300}
                    series={GetChartData()}
                    type="donut"
                />
            </>
        )
    );
};

export default ChartTicketsQueues;
