import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { parseISO, format, isSameDay } from "date-fns";

import {
    Avatar,
    Badge,
    Divider,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {
    GroupRounded,
    PersonRounded,
    SupervisedUserCircleOutlined
} from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import avatar from "../../services/avatar";
import useTenant from "../../hooks/useTenant";

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: props => props.type === "group"
            ? theme.palette.badgesGroup.main
            : theme.palette.badgesSingle.main,
        color: props => props.type === "group"
            ? theme.palette.badgesGroup.main
            : theme.palette.badgesSingle.main,
        width: 22,
        height: 22,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        position: "absolute",
        top: 10,
        left: 18,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 2.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""'
        }
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1
        },
        "100%": {
            transform: "scale(1.6)",
            opacity: 0
        }
    }
}))(Badge);

const useStyles = makeStyles(theme => ({
    chatType: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },
    chatTypeColorGroup: {
        backgroundColor: theme.palette.badgesGroup.main
    },
    chatTypeColorSingle: {
        backgroundColor: theme.palette.badgesSingle.main
    },
    contactLastMessage: {
        paddingRight: 20,
    },
    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    icon: {
        color: "#fff",
        width: 18,
        height: 18,
    },
    infoTags: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "auto",
        fontSize: "0.75em",
    },
    infoTag: {
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        padding: "2px 6px 1px",
        marginLeft: 5,
        borderRadius: "10vh",
    },
    infoTagSecondary: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: "1px 6px 0px",
        marginLeft: 5,
        // transform: "scale(1) translate(0px, 1px)",
        borderRadius: theme.spacing(2),
    },
    infoTagIcon: {
        '& svg': {
            height: "0.65em",
            maxWidth: "0.75em",
            marginLeft: 5,
            color: theme.palette.primary.main,
            transform: "scale(1.3) translate(0px, 2px)",
        },
    },
    lastMessageTime: {
        justifySelf: "flex-end",
    },
    ticket: {
        position: "relative",
    },
    ticketListDivider: {
        marginLeft: 0,
    }
}));

const ChatListItem = ({ chat }) => {
    const classes = useStyles();
    const { uuid } = useParams();

    const { user } = useAuth();
    const { tenantId, tenant } = useTenant();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const getContact = () => {
        let data = chat?.users?.find(u => u?.user?.id !== chat?.ownerId);
        return data?.user
    }

    const handleSelectChat = (chat) => {
        markMessagesAsRead(chat);
        navigate(`/${tenant.slug}/chats/${chat.uuid}`);
    }

    const markMessagesAsRead = async (chat) => {
        if (tenantId) {
            if (unreadMessages(chat) > 0) {
                try {
                    await axiosPrivate.post(`/${tenantId}/chats/${chat.id}/read`, { userId: user?.id });
                } catch (err) {
                    console.error("Error reading messages: ", err)
                }
            }
        }
    }

    const unreadMessages = (chat) => {
        const currentUser = chat?.users.find(u => u?.user?.id === user.id);
        return currentUser?.unreadMessages;
    }

    return (
        <>
            <ListItemButton
                dense
                onClick={e => {
                    handleSelectChat(chat);
                }}
                className={classes.ticket}
                selected={uuid && uuid === chat?.uuid}
            >
                <Tooltip
                    arrow
                    placement="right"
                    title={
                        chat.isGroup
                            ? i18n.t("translation.chat.tooltips.group")
                            : i18n.t("translation.chat.tooltips.single")
                    }
                >
                    <span
                        className={[classes.chatType, chat.isGroup
                            ? classes.chatTypeColorGroup
                            : classes.chatTypeColorSingle].join(" ")}
                    ></span>
                </Tooltip>
                <ListItemAvatar>
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={
                            chat.isGroup
                                ? i18n.t("translation.chat.tooltips.group")
                                : i18n.t("translation.chat.tooltips.single")
                        }
                    >

                        <StyledBadge
                            className={classes.badge}
                            type={chat.isGroup ? "group" : "single"}
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            badgeContent={
                                chat.isGroup
                                    ? <GroupRounded className={classes.icon} />
                                    : <PersonRounded className={classes.icon} />
                            }
                        >
                            <Avatar
                                src={chat?.isGroup
                                    ? avatar(chat?.title, true)
                                    : avatar(getContact()?.email)}
                                alt={chat?.isGroup
                                    ? chat?.title
                                    : getContact()?.name}
                                variant="rounded"
                            />
                        </StyledBadge>
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className={classes.contactNameWrapper}>
                            <Typography
                                noWrap
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                {chat?.title}
                            </Typography>

                            {chat?.lastMessage && (
                                <Typography
                                    className={classes.lastMessageTime}
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {isSameDay(parseISO(chat.updatedAt), new Date()) ? (
                                        <>{format(parseISO(chat.updatedAt), "HH:mm")}</>
                                    ) : (
                                        <>{format(parseISO(chat.updatedAt), "dd/MM/yyyy")}</>
                                    )}
                                </Typography>
                            )}
                        </span>
                    }
                    secondary={
                        <span className={classes.contactNameWrapper}>
                            <Typography
                                className={classes.contactLastMessage}
                                noWrap
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            >
                                {chat.lastMessage ? (
                                    <MarkdownWrapper>{chat.lastMessage}</MarkdownWrapper>
                                ) : (
                                    <br />
                                )}
                            </Typography>

                            <div className={classes.infoTags}>
                                {chat.isGroup && (
                                    <div className={classes.infoTagIcon} title={i18n.t("translation.tickets.tabs.isGroup.badge")}><SupervisedUserCircleOutlined /></div>
                                )}
                                {unreadMessages(chat) !== 0 && chat.uuid !== uuid && (
                                    <div className={classes.infoTagSecondary} title={i18n.t("translation.ticketsList.unreadMessages")}>{unreadMessages(chat)}</div>
                                )}
                            </div>
                        </span>
                    }
                />
            </ListItemButton>
            <Divider
                variant="inset"
                component="li"
                className={classes.ticketListDivider}
            />
        </>
    )
}

export default ChatListItem;