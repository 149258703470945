import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import { DeleteOutline } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Divider from "../Divider";
import MultiFieldLine from "../FormComponents/MultiFieldLine";

const useStyles = makeStyles(theme => ({
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: theme.spacing(-1.5),
        marginLeft: theme.spacing(-1.5),
    },
    btnWrapper: {
        position: "100%",
    },
    colorGreen: {
        color: theme.palette.secondary.main
    },
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: theme.spacing(-0.5),
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    removeBottomMargin: {
        marginBottom: 0,
    }
}));

const PlanSchema = Yup.object().shape({
    name: Yup.string()
        .min(4, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`)
});

const PlanModal = ({ open, onClose, initialValues }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    // TODO: Change user type to match new API
    const initialState = {
        id: '',
        uuid: '',
        name: '',
        description: '',
        price: '',
        currency: 'BRL',
        recurrence: 'monthly',
        status: true,
        public: true,
        planPieces: {},
        // planPrices: {}
    };

    const [plan, setPlan] = useState(initialValues || initialState);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        const fetchPlan = async () => {
            let planData
            // Check if a plan is selected
            if (!initialValues) {
                let { id, ...newPlan } = initialState;
                planData = newPlan
                setPlan(newPlan)
            } else {
                // If a plan is selected, go on, otherwise, end
                try {
                    let { planPieces, ...data } = initialValues;
                    let planComposition = handlePlanPieces(planPieces);

                    planData = { ...data, planComposition }
                } catch (err) {
                    toastError(err, "", "Error while trying to set plan data.");
                }
            };
            if (isMounted) {
                setPlan(planData);
                setLoading(false)
            }
        };

        fetchPlan();

        return () => {
            isMounted = false
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, open]);

    const handleClose = () => {
        setPlan(initialState);
        onClose();
    };

    const handleSavePlan = async values => {
        try {
            // Remove empty properties from an object
            const data = Object.fromEntries(
                Object.entries(values).filter(([_, value]) => value !== "" && value !== undefined)
            );

            if (plan?.id) {
                await axiosPrivate.put(`/plans/${initialValues.uuid}`, data);
            } else {
                await axiosPrivate.post("/plans", data);
            }
            toast.success(i18n.t("translation.planModal.success"));
        } catch (err) {
            toastError(err, "", "Error while trying to save tenant information.");
        }
        handleClose();
    };

    const handlePlanPieces = (planPieces) => {
        const data = planPieces.map((item) => ({
            planPieceId: item.id,
            quantity: item.PlanComposition.quantity
        }))
        return data
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                // fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {plan
                        ? `${i18n.t("translation.planModal.title.edit")}`
                        : `${i18n.t("translation.planModal.title.add")}`}
                </DialogTitle>
                {!loading && plan && (<>
                    <Formik
                        initialValues={plan}
                        enableReinitialize={true}
                        validationSchema={PlanSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSavePlan(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, touched, errors, isSubmitting }) => (
                            <Form>
                                <DialogContent dividers>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.planModal.form.name")}
                                        autoFocus
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <MultiFieldLine>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            size="small"
                                            fullWidth
                                        >
                                            <InputLabel id="status-label">
                                                {i18n.t("translation.userModal.form.connection.status")}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t("translation.userModal.form.connection.status")}
                                                name="status"
                                                labelId="status-label"
                                                id="status"
                                                required
                                            >
                                                <MenuItem value={true}>
                                                    {i18n.t("translation.validation.state.active")}
                                                </MenuItem>
                                                <MenuItem value={false}>
                                                    {i18n.t("translation.validation.state.inactive")}
                                                </MenuItem>
                                            </Field>
                                        </FormControl>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            size="small"
                                            fullWidth
                                        >
                                            <InputLabel id="public-label">
                                                {i18n.t("translation.planModal.form.visibility")}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t("translation.planModal.form.visibility")}
                                                name="public"
                                                labelId="public-label"
                                                id="public"
                                                required
                                            >
                                                <MenuItem value={true}>
                                                    {i18n.t("translation.quickAnswers.table.descriptions.visibility.public")}
                                                </MenuItem>
                                                <MenuItem value={false}>
                                                    {i18n.t("translation.quickAnswers.table.descriptions.visibility.private")}
                                                </MenuItem>
                                            </Field>
                                        </FormControl>
                                    </MultiFieldLine>

                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.planModal.form.description")}
                                        name="description"
                                        multiline
                                        minRows={3}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />

                                    <MultiFieldLine>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.planModal.form.currency")}
                                            name="currency"
                                            // error={touched.currency && Boolean(errors.currency)}
                                            // helperText={touched.currency && errors.currency}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.planModal.form.price")}
                                            name="price"
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            // inputProps={{ min: 0.00, max: 9999.99 }}
                                            step="0.01"
                                            // style={{ width: 50 }}
                                            // className={classes.labelPaddingInput}
                                            fullWidth
                                        />
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            size="small"
                                            fullWidth
                                        >
                                            <InputLabel id="recurrence-input-label">
                                                {i18n.t("translation.planModal.form.recurrence")}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t("translation.planModal.form.recurrence")}
                                                name="recurrence"
                                                labelId="recurrence-input-label"
                                                id="recurrence"
                                                required
                                            >
                                                <MenuItem value="monthly">
                                                    {i18n.t("translation.planModal.form.option.recurrence.monthly")}
                                                </MenuItem>
                                                <MenuItem value="annually">
                                                    {i18n.t("translation.planModal.form.option.recurrence.annually")}
                                                </MenuItem>
                                                {user?.role === "super" && (
                                                    <MenuItem value="trial">
                                                        {i18n.t("translation.planModal.form.option.recurrence.trial")}
                                                    </MenuItem>
                                                )}
                                            </Field>
                                        </FormControl>
                                    </MultiFieldLine>
                                    <Typography
                                        style={{ marginBottom: 8, marginTop: 12 }}
                                        variant="subtitle1"
                                    >
                                        {i18n.t("translation.planModal.form.planComposition")}
                                    </Typography>

                                    <FieldArray name="planComposition">
                                        {({ push, remove }) => (
                                            <>
                                                {values.planComposition &&
                                                    values.planComposition.length > 0 &&
                                                    values.planComposition.map((planPiece, index) => {
                                                        // Plan options
                                                        const pieces = [
                                                            { id: 1, label: i18n.t("translation.planModal.form.option.planPiece.name.users") },
                                                            { id: 2, label: i18n.t("translation.planModal.form.option.planPiece.name.connectionsWhatsapp") },
                                                            { id: 3, label: i18n.t("translation.planModal.form.option.planPiece.name.campaignLimit") },
                                                            { id: 4, label: i18n.t("translation.planModal.form.option.planPiece.name.campaignMaxContacts") },
                                                            { id: 5, label: i18n.t("translation.planModal.form.option.planPiece.name.gptLimit") },
                                                        ]

                                                        // // Get the list of used planPieceIds
                                                        // const usedPlanPieceIds = values.planComposition.map(item => item.planPieceId);

                                                        // // Filter the available options based on usedPlanPieceIds
                                                        // const availableOptions = pieces.filter(piece => !usedPlanPieceIds.includes(piece.id));

                                                        return (
                                                            <MultiFieldLine
                                                                key={`${index}-planPiece`}
                                                            >
                                                                <FormControl
                                                                    variant="outlined"
                                                                    className={classes.formControl}
                                                                    size="small"
                                                                    fullWidth
                                                                >
                                                                    <InputLabel id={`planComposition[${index}].planPieceId-label`}>
                                                                        {i18n.t("translation.planModal.form.planPieces.name")}
                                                                    </InputLabel>
                                                                    <Field
                                                                        as={Select}
                                                                        label={i18n.t("translation.planModal.form.planPieces.name")}
                                                                        name={`planComposition[${index}].planPieceId`}
                                                                        labelId={`planComposition[${index}].planPieceId-label`}
                                                                        id={`planComposition[${index}].planPieceId`}
                                                                        required
                                                                    >
                                                                        {pieces.map(option => (
                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </FormControl>

                                                                <Field
                                                                    as={TextField}
                                                                    label={i18n.t("translation.planModal.form.planPieces.quantity")}
                                                                    name={`planComposition[${index}].quantity`}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    type="number"
                                                                    inputProps={{ min: 1, max: 99999 }}
                                                                    className={classes.textField}
                                                                // fullWidth
                                                                />
                                                                <div>
                                                                    <IconButton
                                                                        size="small"
                                                                        className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <DeleteOutline />
                                                                    </IconButton>
                                                                </div>
                                                            </MultiFieldLine>
                                                        )
                                                    })}
                                                <div className={classes.extraAttr}>
                                                    <Button
                                                        // style={{ flex: 1, marginTop: 8 }}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => push({ name: "", value: "" })}
                                                    >
                                                        {`+ ${i18n.t("translation.contactModal.buttons.addExtraInfo")}`}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>

                                    {/* <Typography
                                        style={{ marginBottom: 8, marginTop: 12 }}
                                        variant="subtitle1"
                                    >
                                        {i18n.t("translation.planModal.form.planPrices")}
                                    </Typography>
                                    <FieldArray name="planPrices">
                                        {({ push, remove }) => (
                                            <>
                                                {values.planPrices &&
                                                    values.planPrices.length > 0 &&
                                                    values.planPrices.map((planPrice, index) => {
                                                        return (
                                                            <MultiFieldLine
                                                                key={`${index}-planPrice`}
                                                            >
                                                                <MultiFieldLine>
                                                                    <Field
                                                                        as={TextField}
                                                                        label={i18n.t("translation.planModal.form.currency")}
                                                                        name={`planPrice[${index}].currency`}
                                                                        variant="outlined"
                                                                        size="small"
                                                                    // fullWidth
                                                                    />
                                                                    <Field
                                                                        as={TextField}
                                                                        label={i18n.t("translation.planModal.form.price")}
                                                                        name={`planPrice[${index}].price`}
                                                                        type="number"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        step="0.01"
                                                                    // fullWidth
                                                                    />
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        className={classes.formControl}
                                                                        size="small"
                                                                    // fullWidth
                                                                    >
                                                                        <InputLabel id={`planPrice[${index}].recurrence-input-label`}>
                                                                            {i18n.t("translation.planModal.form.recurrence")}
                                                                        </InputLabel>
                                                                        <Field
                                                                            as={Select}
                                                                            label={i18n.t("translation.planModal.form.recurrence")}
                                                                            id={`planPrice[${index}].recurrence`}
                                                                            name={`planPrice[${index}].recurrence`}
                                                                            labelId={`planPrice[${index}].recurrence-input-label`}
                                                                            required
                                                                        >
                                                                            <MenuItem value="monthly">
                                                                                {i18n.t("translation.planModal.form.option.recurrence.monthly")}
                                                                            </MenuItem>
                                                                            <MenuItem value="annually">
                                                                                {i18n.t("translation.planModal.form.option.recurrence.annually")}
                                                                            </MenuItem>
                                                                            {user?.role === "super" && (
                                                                                <MenuItem value="trial">
                                                                                    {i18n.t("translation.planModal.form.option.recurrence.trial")}
                                                                                </MenuItem>
                                                                            )}
                                                                        </Field>
                                                                    </FormControl>
                                                                    <ToggleButton
                                                                        value="check"
                                                                        size="small"
                                                                        name={`planPrice[${index}].public`}
                                                                        selected={planPrice[index]?.public || true}
                                                                        variant="outlined"
                                                                        sx={{ borderRadius: "20px" }}
                                                                    >
                                                                        {planPrice[index]?.public
                                                                            ? <Lock />
                                                                            : <LockOpenOutlined />
                                                                        }
                                                                    </ToggleButton>
                                                                    <ToggleButton
                                                                        value="check"
                                                                        size="small"
                                                                        name={`planPrice[${index}].status`}
                                                                        selected={planPrice[index]?.public || true}
                                                                        variant="outlined"
                                                                        sx={{ borderRadius: "20px" }}
                                                                    >
                                                                        {planPrice[index]?.public
                                                                            ? <Check />
                                                                            : <Disable />
                                                                        }
                                                                    </ToggleButton>
                                                                </MultiFieldLine>
                                                                <div>
                                                                    <IconButton
                                                                        size="small"
                                                                        className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <DeleteOutline />
                                                                    </IconButton>
                                                                </div>
                                                            </MultiFieldLine>
                                                        )
                                                    })}
                                                <div className={classes.extraAttr}>
                                                    <Button
                                                        // style={{ flex: 1, marginTop: 8 }}
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => push({ name: "", value: "" })}
                                                    >
                                                        {`+ ${i18n.t("translation.contactModal.buttons.addExtraInfo")}`}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray> */}
                                    <Divider silent={true} />

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        className={classes.buttonColorError}
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t("translation.validation.buttons.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {plan
                                            ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                            : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </>)}
            </Dialog>
        </div>
    );
};

export default PlanModal;
