import {
    AddOutlined,
    // AddCircleOutlineOutlined,
    FilterList
} from "@mui/icons-material";
import { Badge, FormControlLabel, IconButton, InputBase, Paper, Switch, Tab, Tabs, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TutorialModalButton from "../../components/TutorialModalButton";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import useSettings from "../../hooks/useSettings";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import Title from "../LayoutComponents/Title";
import NewTicketModal from "../NewTicketModal";
import SearchParamModal from "../SearchParamModal";
import TabPanel from "../TabPanel";
import TicketsList from "../TicketsList";
import TicketsQueueSelect from "../TicketsQueueSelect";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },

    badge: {
        right: "-10px",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    hide: {
        display: "none !important",
    },
    multiItemLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1, 1, 0),
        gap: theme.spacing(1)
    },
    searchIcon: {
        color: "grey",
        alignSelf: "center",
    },
    searchInput: {
        flex: 1,
        borderRadius: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    searchInputWrapper: {
        flex: 1,
        // background: "#fff",
        display: "flex",
        border: "1px solid rgba(0,0,0,0.25)",
        borderRadius: theme.spacing(2),
        pdding: theme.spacing(0, 1),
    },
    showAll: {
        margin: "unset"
    },
    tab: {
        minWidth: 120,
        width: 120,
    },
    tabsHeader: {
        flex: "none",
        backgroundColor: "#f0f2f5",
    },
    ticketsList: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 0,
    },
    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        // alignItems: "center",
        background: "#fafafa",
        padding: theme.spacing(1, 1, 2),
        gap: theme.spacing(1),
    },
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
    },
}));

const TicketsManager = () => {
    const classes = useStyles();
    const { settings, getSettingValue } = useSettings();
    const searchInputRef = useRef();

    const initialState = {
        parameter: "",
        filter: "all",
        status: "all",
        dateRange: {
            dateStart: "",
            dateEnd: ""
        }
    }
    const [searchParam, setSearchParam] = useState(initialState);

    const [tab, /*setTab*/] = useState("open");
    const [tabOpen, setTabOpen] = useState("open");

    const { whatsApps } = useContext(WhatsAppsContext);
    const [showGroups, setShowGroups] = useState(false);

    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);

    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [groupsCount, setGroupsCount] = useState(0);

    const [selectedTags, setSelectedTags] = useState([]);

    const { tenant, userTenant, userTenantAdminRole } = useTenant();
    const [selectedQueueIds, setSelectedQueueIds] = useState();
    const [searchParamModalOpen, setSearchParamModalOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!userTenant) return;
        if (userTenantAdminRole) {
            setShowAllTickets(true);
        }
        setSelectedQueueIds(userTenant?.queues?.map((q) => q.id));
    }, [userTenant, userTenantAdminRole]);

    let searchTimeout;
    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();

        clearTimeout(searchTimeout);

        if (searchedTerm === "") {
            setSearchParam((prevState) => ({
                ...prevState,
                parameter: searchedTerm,
            }));
            return;
        }

        searchTimeout = setTimeout(() => {
            setSearchParam((prevState) => ({
                ...prevState,
                parameter: searchedTerm,
            }));
        }, 500);
    };

    const handleSelectedTags = (selectedTags) => {
        const tags = selectedTags.map(t => t.id);
        setSelectedTags(tags);
    }

    // const handleChangeTab = (e, newValue) => {
    //     setTab(newValue);
    // };
    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
        }
    }
    const handleChangeTabOpen = (e, newValue) => {
        setTabOpen(newValue);
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 };
        }
    };

    useEffect(() => {
        if (!whatsApps) return

        // const enabledGroups = whatsApps.find(w => w?.checkGroups === true && w?.status === "CONNECTED")
        const enabledGroups = whatsApps.find(w => w?.checkGroups === true)
        if (enabledGroups) setShowGroups(true)
    }, [whatsApps])

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <SearchParamModal
                open={searchParamModalOpen}
                defaultSearchParams={initialState}
                searchParam={searchParam}
                searchParamState={setSearchParam}
                tags={selectedTags}
                handleSelectedTags={handleSelectedTags}
                handleClose={() => {
                    setSearchParamModalOpen(false)
                }}
            />
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
                <div className={classes.multiItemLine}>
                    <Title>
                        {i18n.t("translation.tickets.title")}
                        <TutorialModalButton content="tickets" />
                    </Title>
                    <IconButton
                        // variant="contained" // Won't work on iconbuttons.
                        className={classes.actionButtons}
                        color="primary"
                        onClick={() => setNewTicketModalOpen(true)}
                        size="small"
                    >
                        <AddOutlined />
                    </IconButton>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setNewTicketModalOpen(true)}
                    >
                        {i18n.t("translation.validation.buttons.newTicket")}
                    </Button> */}
                </div>
                <div className={classes.multiItemLine}>
                    <div className={classes.searchInputWrapper}>
                        <Tooltip title={i18n.t("translation.tickets.search.tooltip")}>
                            <IconButton
                                className={classes.searchIcon}
                                color="primary"
                                onClick={() => setSearchParamModalOpen(true)}
                            >
                                <FilterList />
                            </IconButton>
                        </Tooltip>
                        <InputBase
                            className={classes.searchInput}
                            inputRef={searchInputRef}
                            placeholder={i18n.t("translation.tickets.search.placeholder")}
                            // type="search"
                            onChange={handleSearch}
                        />
                    </div>
                    <TicketsQueueSelect
                        selectedQueueIds={selectedQueueIds}
                        userQueues={userTenant?.queues}
                        onChange={(values) => setSelectedQueueIds(values)}
                    />
                    <Can
                        role={userTenant?.role}
                        perform="ticket-manager:show:all"
                        yes={() => (
                            <FormControlLabel
                                label={i18n.t("translation.validation.buttons.showAll")}
                                labelPlacement="start"
                                className={classes.showAll}
                                control={
                                    <Switch
                                        size="small"
                                        checked={showAllTickets}
                                        onChange={() =>
                                            setShowAllTickets((prevState) => !prevState)
                                        }
                                        name="showAllTickets"
                                        color="primary"
                                    />
                                }
                            />
                        )}
                    />
                </div>
            </Paper>

            <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
                {JSON.stringify(initialState) === JSON.stringify(searchParam)
                    ? (<>
                        <Tabs
                            value={tabOpen}
                            onChange={handleChangeTabOpen}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab
                                label={
                                    <Badge
                                        className={classes.badge}
                                        badgeContent={openCount}
                                        color="primary"
                                        overlap="rectangular"
                                    >
                                        {i18n.t("translation.ticketsList.assignedHeader")}
                                    </Badge>
                                }
                                value={"open"}
                            />
                            {settings && settings.length > 0 &&
                                getSettingValue("submitTicketForReview") === "enabled"
                                ? <Tab
                                    value={"waiting"}
                                    // icon={<PauseCircleOutline />}
                                    label={i18n.t("translation.tickets.tabs.waiting.title")}
                                    classes={{ root: classes.tab }}
                                />
                                : null
                            }
                            {showGroups
                                ? <Tab
                                    label={
                                        <Badge
                                            className={classes.badge}
                                            badgeContent={groupsCount}
                                            color="secondary"
                                            overlap="rectangular"
                                        >
                                            {i18n.t("translation.ticketsList.groupsHeader")}
                                        </Badge>
                                    }
                                    value={"groups"}
                                />
                                : ''
                            }
                            <Tab
                                label={
                                    <Badge
                                        className={classes.badge}
                                        badgeContent={pendingCount}
                                        color="secondary"
                                        overlap="rectangular"
                                    >
                                        {i18n.t("translation.ticketsList.pendingHeader")}
                                    </Badge>
                                }
                                value={"pending"}
                            />
                        </Tabs>
                        <Paper className={classes.ticketsList}>
                            <TicketsList
                                status="open"
                                isGroup={false}
                                showAll={showAllTickets}
                                selectedQueueIds={selectedQueueIds}
                                updateCount={(val) => setOpenCount(val)}
                                style={applyPanelStyle("open")}
                            />
                            {settings && settings.length > 0 &&
                                getSettingValue("submitTicketForReview") === "enabled"
                                ? <TicketsList
                                    status="waiting"
                                    showAll={showAllTickets}
                                    selectedQueueIds={selectedQueueIds}
                                />
                                : ''
                            }
                            {showGroups
                                ? <TicketsList
                                    status="open"
                                    isGroup={true}
                                    showAll={showAllTickets}
                                    selectedQueueIds={selectedQueueIds}
                                    updateCount={(val) => setGroupsCount(val)}
                                    style={applyPanelStyle("groups")}
                                />
                                : ''
                            }
                            <TicketsList
                                status="pending"
                                selectedQueueIds={selectedQueueIds}
                                updateCount={(val) => setPendingCount(val)}
                                style={applyPanelStyle("pending")}
                            />
                        </Paper>
                    </>)
                    : (
                        <TicketsList
                            searchParam={searchParam}
                            tags={selectedTags}
                            showAll={showAllTickets} // Used to be "true"
                            selectedQueueIds={selectedQueueIds}
                        />
                    )}
            </TabPanel>
        </Paper>
    );
};

export default TicketsManager;
