import React, { useEffect, useState } from "react";

import {
    Chip,
    TextField
} from '@mui/material';
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';

import appVars from "../../../package.json";
import toastError from "../../errors/toastError";
import { isControllerUrl } from "../../services/config";
import { i18n } from "../../translate/i18n";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(1),
    }
}));

const filterOptions = createFilterOptions({
    trim: true,
});

export function UsersFilter({ onFiltered, initialUsers, searchParams }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(initialUsers);
    const [selected, setSelected] = useState(initialUsers);

    const { user } = useAuth();
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        async function fetchData() {
            await fetchUsers();
        }
        fetchData();// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelected([]);
        if (
            Array.isArray(initialUsers) &&
            Array.isArray(users) &&
            users.length > 0
        ) {
            onChange(initialUsers);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialUsers, users]);

    const fetchUsers = async () => {
        let isMounted = true;
        setLoading(true);

        if (tenantId) {
            try {
                const { data } = await axiosPrivate.get(`/users/${tenantId}`, {
                    params: { searchParams },
                });

                let availableUsers = data.users.filter(userFilter);

                if (isMounted) {
                    setUsers(availableUsers);
                    setLoading(false);
                };
            } catch (err) {
                if (isMounted) {
                    setLoading(false);
                };
                toastError(err, "", "Couldn't fetch users from backend at User's Filter");
            }
        };
    };

    const userFilter = (testUser) => {
        let blockRoles = []; // i.e.: ["tenant"]
        let otherDomains = true;

        // Check if user ID is the same as current
        if (testUser.id === user.id) return

        // check if user is part of the role list, remove it if so
        if (blockRoles.indexOf(testUser.role) !== -1) return

        // Check if user from the same domain as the controller
        if (otherDomains === false && !isControllerUrl()) {
            // If it is a controller isntallatin, then move forward anyway, else block it
            if (testUser.email.split('@')[1] !== appVars.controllerDomain) return
        }

        return testUser
    }

    const onChange = async (value) => {
        setSelected(value);
        onFiltered(value);
    };

    return (
        <Autocomplete
            getOptionLabel={option => option.name}
            onChange={(e, newValue) => onChange(newValue)}
            options={users}
            filterOptions={filterOptions}
            noOptionsText={i18n.t("translation.transferTicketModal.noOptions")}
            loading={loading}
            fullWidth
            multiple

            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={i18n.t("translation.transferTicketModal.fieldLabel")}
                    variant="outlined"
                />
            )}

            label="user"
            size="small"
            value={selected}
            // isOptionEqualToValue={() => selected === initialUsers} // TODO: refine function to deal with Autocomplete invalid error onClose
            renderTags={(value, getUserProps) =>
                value.map((option, index) => (
                    <Chip
                        color="primary"
                        sx={{
                            '& .MuiChip-deleteIcon': {
                                color: option.color,
                                transition: '1.5s'
                            },
                            '& .MuiChip-deleteIcon:hover': {
                                color: 'red',
                            },
                        }}
                        variant="outlined"
                        label={option.name}
                        className={classes.chip}
                        {...getUserProps({ index })}
                        size="small"
                    />
                ))
            }
        />
    );
}

export default UsersFilter;
