import React from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import { i18n } from "../../translate/i18n";
import TicketsList from "../TicketsList";

const TicketModal = ({ open, handleClose, userTenant, queues, showAll, status, setCount }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                {status === 'pending'
                    ? i18n.t("translation.ticketsModal.title")
                    : status === 'waiting'
                        ? i18n.t("translation.ticketsModal.waiting", { username: userTenant?.user.name })
                        : i18n.t("translation.ticketsModal.open", { username: userTenant?.user.name })
                }
            </DialogTitle>
            <DialogContent dividers>
                <TicketsList
                    status={status}
                    showAll={showAll}
                    selectedQueueIds={queues}
                    updateCount={(val) => setCount(val)}
                    userTenantId={userTenant?.id}
                    fixUser={true}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                >
                    {i18n.t("translation.variables.buttons.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TicketModal;