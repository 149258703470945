import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from './services/serviceWorker/serviceWorkerRegistration';

import App from "./App";

ReactDOM.render(
    <App />,
    document.getElementById("root")
);

// Service Workers: App install
serviceWorkerRegistration.register();

