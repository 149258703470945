import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
    Block,
    CheckCircleOutlined
} from "@mui/icons-material";
import {
    Avatar,
    Button,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DataGrid, gridClasses } from '@mui/x-data-grid';

import Title from "../../../../components/LayoutComponents/Title";
import MainContainer from "../../../../components/MainContainer";
import MainHeader from "../../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import TicketsListCount from "../../../../components/TicketsListCount";
import TicketsQueueSelect from '../../../../components/TicketsQueueSelect';

import toastError from '../../../../errors/toastError';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useSocketListener } from '../../../../hooks/useSocket/Events';
import useTenant from '../../../../hooks/useTenant';
import avatar from '../../../../services/avatar';
import { isControllerUrl } from '../../../../services/config';
import { i18n } from "../../../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        }
    },
    contactName: {
        paddingLeft: theme.spacing(2)
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    disabled: {
        display: "flex",
        justifyContent: "center"
    },
    enabled: {
        display: "flex",
        justifyContent: "center"
    },
    mainPaper: {
        margin: "0 auto",
        overflowY: "scroll",
        width: "100%",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    wrap: {
        whiteSpace: "normal !important",
        wordWrap: "break-word !important"
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_USERS") {
        const users = action.payload;
        const newUsers = [];

        users.forEach((user) => {
            const userIndex = state.findIndex((u) => u.id === user.id);
            if (userIndex !== -1) {
                state[userIndex] = user;
            } else {
                newUsers.push(user);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_USERS") {
        const user = action.payload;
        const userIndex = state.findIndex((u) => u.id === user.id);

        if (userIndex !== -1) {
            state[userIndex] = user;
            return [...state];
        } else {
            return [user, ...state];
        }
    }

    if (action.type === "DELETE_USER") {
        const userId = action.payload;

        const userIndex = state.findIndex((u) => u.id === userId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Teams = () => {
    const classes = useStyles();

    const [users, dispatch] = useReducer(reducer, []);
    const { tenant, tenantId, userTenant } = useTenant();

    const [ticketsCount, setTicketsCount] = useState();

    const [, setLoading] = useState(false);
    const [searchParam,] = useState("");

    const [selectedQueueIds, setSelectedQueueIds] = useState([]);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [rows, setRows] = useState([{
        id: '',
        user: '',
        email: '',
        role: '',
        status: false,
        nps: ''
    }]);

    useEffect(() => {
        if (!userTenant?.queues) return;
        setSelectedQueueIds(userTenant?.queues?.map(q => q.id));
    }, [userTenant])

    useEffect(() => {
        if (!users) return;

        let dataGridRows = users.map(u => ({
            id: u?.id,
            user: u?.name,
            email: u?.email,
            role: i18n.t(`userModal.form.profile.role.${isControllerUrl && u?.userTenant[0].role === 'super' ? 'support' : u?.userTenant[0].role}`),
            status: u?.userTenant[0].status,
            nps: i18n.t("translation.variables.soon"),
        }));
        setRows(dataGridRows)

    }, [users]);

    useEffect(() => {
        dispatch({ type: "RESET" });
    }, [searchParam]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setLoading(true);
        const fetchUsers = async () => {
            try {
                const { data } = await axiosPrivate.get(`/users/${tenantId}`, {
                    params: { searchParam },
                }, { signal });
                dispatch({ type: "LOAD_USERS", payload: data.users });
            } catch (err) {
                toastError(err);
            }
        };
        if (tenantId) fetchUsers();

        return () => {
            controller.abort();
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, tenantId]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchTickets = async () => {
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/tickets/count`, {}, { signal });
                setTicketsCount(data);
            } catch (err) {
                toastError(err);
            }
        };
        if (tenantId) fetchTickets();

        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, tenantId])

    // Handle User updates
    const handleUserUpdate = (data) => {
        // Check if updates or adds to list
        if (data.action === "update" || data.action === "create") {
            dispatch({ type: "UPDATE_USERS", payload: data.user })
        }

        // Check if removes from list
        if (data.action === "delete") {
            dispatch({ type: "DELETE_USER", payload: +data.userId });
        }
    }
    useSocketListener(`users`, (data) => handleUserUpdate(data))


    const populateTickets = (userId, status) => {
        if (!ticketsCount) return;
        const user = ticketsCount.find(user => user?.id === userId);
        const ticketCount = user?.tickets.find(t => t?.status === status);

        return ticketCount?.count || 0;
    }

    const columns = useMemo(() => [
        {
            field: "user",
            headerName: i18n.t("translation.variables.table.user"),
            flex: 0.6,
            minWidth: 260,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            renderCell: params => (
                <>
                    <Avatar src={avatar(params.row.email)} alt={params.row.user} variant="rounded" />
                    <Typography className={classes.contactName} variant="inherit">{params.row.user}</Typography>
                </>
            )
        },
        {
            field: "role",
            headerName: i18n.t("translation.variables.table.profile"),
            flex: 0.4,
            minWidth: 120,
            description: i18n.t("translation.dashboard.teams.table.descriptions.profile"),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.4,
            minWidth: 120,
            description: i18n.t("translation.dashboard.teams.table.descriptions.status"),
            type: "string",
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    {params.row.status === true ? (
                        <div className={classes.enabled}>
                            <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.active")}
                        </div>
                    ) : (
                        <div className={classes.disabled}>
                            <Block color="error" fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.inactive")}
                        </div>
                    )}
                </>
            )
        },
        {
            field: "open",
            headerName: i18n.t("translation.variables.table.tickets.open"),
            flex: 0.3,
            minWidth: 80,
            type: 'number',
            description: i18n.t("translation.dashboard.teams.table.descriptions.tickets.open"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <TicketsListCount
                    queues={selectedQueueIds}
                    status="open"
                    showAll={true}
                    ticketCount={populateTickets(params.row.id, "open")}
                    user={params.row}
                />
            )
        },
        {
            field: "waiting",
            headerName: i18n.t("translation.variables.table.tickets.waiting"),
            flex: 0.3,
            minWidth: 80,
            type: 'number',
            description: i18n.t("translation.dashboard.teams.table.descriptions.tickets.waiting"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <TicketsListCount
                    queues={selectedQueueIds}
                    showAll={true}
                    status="waiting"
                    ticketCount={populateTickets(params.row.id, "waiting")}
                    user={params.row}
                />
            )
        },
        {
            field: "nps",
            headerName: i18n.t("translation.variables.table.nps"),
            flex: 0.3,
            minWidth: 120,
            type: "string",
            description: i18n.t("translation.dashboard.teams.table.descriptions.nps"),
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //   field: "actions",
        //   headerName: i18n.t("translation.variables.table.actions"),
        //   flex: 0.4,
        //   minWidth: 120,
        //   width: "auto",
        //   type: "string",
        //   sortable: false,
        //   headerAlign: 'center',
        //   align: 'center'
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [users, ticketsCount]);

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.reports.report.teams.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TicketsQueueSelect
                        selectedQueueIds={selectedQueueIds}
                        userQueues={userTenant?.queues}
                        onChange={values => setSelectedQueueIds(values)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/${tenant.slug}/reports`)}
                    // disabled
                    >
                        {i18n.t("translation.reports.buttons.selector")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{
                            // minHeight: 400,
                            // height: "25vh",
                            width: "100%"
                        }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}

                        </Grid>
                    </div>
                </div>
            </Paper>

        </MainContainer >
    )
}

export default Teams;
