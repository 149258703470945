import React from 'react';

import {
    Link,
    Typography
} from '@mui/material';

import appVars from "../../../package.json";

export default function Copyright() {

    const getSlugUrl = () => {
        if (window.location.hostname === 'localhost') {
            return "localhost";
        } else {
            let slug = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
            return slug;
            ;
        }
    }
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright "}
            <Link color="inherit" traget="_blank" href={"https://" + appVars.referralDomain + "?referral=" + getSlugUrl()}>
                {appVars.appName},
            </Link>{" "}
            {new Date().getFullYear() + "."}
        </Typography>
    );
};
