import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";

import ChatActionButtons from "../ChatActionButtons";
import ChatInfo from "../ChatInfo";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/chatInput";
import MessagesList from "../MessagesList/chat";
import NewChatModal from "../NewChatModal";
import TicketHeader from "../TicketHeader";

import { toast } from "react-toastify";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
        position: "relative",
        overflow: "hidden",
    },

    ticketInfo: {
        maxWidth: "50%",
        flexBasis: "50%",
        [theme.breakpoints.down('md')]: {
            maxWidth: "unset",
            flexBasis: "unset",
        },
    },
    ticketActionButtons: {
        maxWidth: "50%",
        flexBasis: "50%",
        display: "flex",
        [theme.breakpoints.down('md')]: {
            display: "inline",
            float: "right",
            position: "absolute",
            right: "0",
            marginTop: "5px",
        },
    },
    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginRight: -drawerWidth,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Chat = () => {
    const { uuid } = useParams();
    const classes = useStyles();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [newChatModalOpen, setNewChatModalOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const [chat, setChat] = useState({});

    const { user } = useAuth();
    const { tenant, tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        // const signal = controller.signal;

        if (!uuid) return;
        setLoading(true);

        const fetchChat = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/chats/${uuid}`);
                    setChat(data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err, "", "Error ocurred while trying to fetch chats at the Chat component.");
                    navigate(`/${tenant?.slug}/chats`);
                }
            }
        };
        fetchChat();

        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, uuid, tenantId]);

    // Handlers
    // Handle Chat Changes
    const handleChatUserChange = (data) => {
        if (data.action === "create" || data.action === "update") {
            setChat(data.chat);
        }

        if (data.action === "delete") {
            toast.success(i18n.t("translation.ticketOptionsMenu.confirmationModal.success"));
            navigate(`/${tenant?.slug}/chats`);
        }
    };
    useSocketListener(`channel:internal:chat:${chat?.id}`, (data) => handleChatUserChange(data))


    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleCloseOrOpenChat = (chat) => {
        setNewChatModalOpen(false);
        if (chat !== undefined && chat.uuid !== undefined) {
            navigate(`/${tenant.slug}/chats/${chat.uuid}`);
        }
    }

    const showChat = () => {
        return (
            <>
                <TicketHeader
                    loading={loading}
                    destination={`/${tenant?.slug}/chats`}
                >
                    <div className={classes.ticketInfo}>
                        <ChatInfo
                            chat={chat}
                            onClick={handleDrawerOpen}
                        />
                    </div>
                    {chat?.ownerId === user.id && (
                        <div className={classes.ticketActionButtons}>
                            <ChatActionButtons
                                chat={chat}
                                openChatModal={setNewChatModalOpen}
                            />
                        </div>
                    )}
                </TicketHeader>
                <ReplyMessageProvider>
                    <MessagesList
                        chat={chat}
                        isGroup={chat?.isGroup}
                    ></MessagesList>
                    <MessageInput
                        chatId={chat.id}
                    />
                </ReplyMessageProvider>
            </>
        )
    }

    return (
        <>
            <NewChatModal
                chat={chat}
                modalOpen={newChatModalOpen}
                onClose={(chat) => handleCloseOrOpenChat(chat)}
                type="edit"
            />
            <div className={classes.root} id="drawer-container">
                <Paper
                    variant="outlined"
                    elevation={0}
                    className={clsx(classes.mainWrapper, {
                        [classes.mainWrapperShift]: drawerOpen,
                    })}
                >
                    {chat && showChat()}
                </Paper>
                <ContactDrawer
                    open={drawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    chat={chat}
                    contacts={chat?.users?.map((user) => ({
                        id: user.user.id,
                        name: user.user.name,
                        email: user.user.email,
                        role: user.user.role,
                        owner: user.user.id === chat.ownerId
                    }))}
                    loading={loading}
                />
            </div>
        </>
    );
};

export default Chat;
