const logger = ({ type, title, content }) => {
    let color;
    let contentTitle;

    switch (type) {
        case "info":
            color = '\x1b[36m';
            contentTitle = "Info"
            break;

        case "warn":
            color = '\x1b[33m';
            contentTitle = "Warn"
            break;

        case "error_info":
            color = '\x1b[31m';
            contentTitle = "Error"
            break;

        default:
            color = ''
            break;
    }

    return type === "error"
        ? console.error(content)
        : console.log(`${color}${title || contentTitle}\x1b[0m:`, content)
}

export default logger;
