const handleContactLink = (action, contact) => {
  let linkAction;
  if (action === "phone") {
    let phone = contact.toString();
    let checkBR = phone.substring(0, 2);
    let checkNO = Number(phone.substring(4, 5));
    if (checkBR === '55' && checkNO >= 7) {
      let mobile = phone.substring(4);
      if (mobile.length < 9) {
        phone = phone.substring(0, 4) + 9 + mobile;
      }
    }
    linkAction = 'tel:';
    contact = phone;
  } else if (action === "mail") {
    linkAction = "mailto:";
  }
  window.open(linkAction + contact);
}

export default handleContactLink;