import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { axiosPrivate } from "../../services/axios";

import { i18n } from "../../translate/i18n";

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import logger from "../../services/logger";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [authPersist, setAuthPersist] = useLocalStorage("authPersist", false, false);

    // Deal with tokens
    // const [, setSocketToken] = useLocalStorage("token", false, true);
    const [token, setToken] = useState(null);

    // Firebase Token
    // TODO: https://github.com/firebase/quickstart-js/blob/master/messaging/main.ts
    const [firebaseToken] = useLocalStorage("firebaseToken", null, true);
    const [notificationToken, setNotificationToken] = useLocalStorage("firebaseTokenRegistered", null, false);

    // Navigation data
    const loggedInPage = "/";
    const navigate = useNavigate();
    const location = useLocation();

    // Handle client origin
    const fromLocation = useCallback(() => {
        const hasLocation = location.state?.from?.pathname !== "/tenants" ? location.state?.from?.pathname : null;
        const defaultLocation = loggedInPage;
        const finalLocation = hasLocation || defaultLocation;

        return finalLocation === "/unauthorized"
            ? defaultLocation
            : finalLocation;
    }, [location])

    // Handle User login
    const handleLogin = useCallback(async (userData) => {
        try {
            const { data } = await axiosPrivate.post("/auth/login", userData);
            axiosPrivate.defaults.headers.Authorization = `Bearer ${data.token}`;

            // Set token information on Cookies and Storage
            setToken(data.token);
            // setSocketToken(data.token)
            localStorage.setItem("token", data.token)

            // Set user data and log success
            setUser(data.user);
            toast.success(i18n.t("translation.auth.toasts.success"));

            // Go to the given page
            navigate(
                fromLocation(data.user),
                { replace: true }
            );
        } catch (err) {
            toastError(err, "", "Error during login attempts on AuthContext.");
        }
    }, [fromLocation, navigate])


    // Handle User logout
    const handleLogout = useCallback(async () => {
        try {
            await axiosPrivate.delete("/auth/logout");
            axiosPrivate.defaults.headers.Authorization = undefined;

            // Clear token info
            setToken(null);
            // setSocketToken(null)
            localStorage.removeItem("token");

            // Clear user
            setUser({});
            toast.success(i18n.t("translation.auth.toasts.logout"));

            // Go to logged out page
            navigate("/login");
        } catch (err) {
            toastError(err);
        }
    }, [navigate]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        // Register user with the given firebase device registration.
        if (notificationToken === true) {
            logger({ type: "info", title: "Firebase", content: "Notification Token already registered." })
        } else if (!firebaseToken || firebaseToken === null || firebaseToken.length <= 4) {
            logger({ type: "error_info", title: "Firebase", content: "Notification Token not yet retrieved." })
        } else if (!user || JSON.stringify(user) === '{}') {
            logger({ type: "error_info", title: "Firebase", content: "User not logged in or is null." })
        } else {
            // Set notifications with Firebase
            user?.userTenant?.map(async (u) => {
                let notificationData = {
                    tenantId: u.tenantId,
                    key: firebaseToken,
                    status: true,
                    userTenantId: u.id
                }

                try {
                    // Check if has the auth token
                    if (axiosPrivate.defaults.headers.Authorization?.length <= 8) return;

                    // If has the auth token, send the notification token to the server
                    const { status } = await axiosPrivate.post(`/${u.tenantId}/FCMtoken`, notificationData, { signal: controller.signal })
                    status === 200 && setNotificationToken(true)
                } catch (err) {
                    isMounted && console.error("Error:", err)
                }
            });
        }

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, notificationToken])

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                handleLogin,
                handleLogout,
                authPersist,
                setAuthPersist,
                token,
                setToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
