import clsx from "clsx";
import React, { useContext, useEffect, useRef, useState } from "react";

import data from '@emoji-mart/data';
import emojiPt from '@emoji-mart/data/i18n/pt.json';
import Picker from '@emoji-mart/react';

import MicRecorder from "mic-recorder-to-mp3";

import {
    AttachFile,
    Cancel,
    CheckCircleOutline,
    Clear,
    HighlightOff,
    ImageOutlined,
    Lock,
    LockOpen,
    Mic,
    Mood,
    MoreVert,
    Send,
    StickyNote2Outlined
} from "@mui/icons-material";
import {
    Avatar,
    CircularProgress,
    ClickAwayListener,
    FormControlLabel,
    Grid,
    Hidden,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
import RecordingTimer from "./RecordingTimer";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import useSettings from "../../hooks/useSettings";
import useTenant from "../../hooks/useTenant";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        background: "#f0f2f5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // borderTop: "1px solid rgba(0, 0, 0, 0.05)",
        [theme.breakpoints.down("sm")]: {
            // position: "fixed",
            bottom: 0,
            width: "100%",
        },
    },
    mainWrapperInternal: {
        background: theme.palette.comment.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // borderTop: "1px solid rgba(0, 0, 0, 0.05)",
        [theme.breakpoints.down('md')]: {
            // position: "fixed",
            bottom: 0,
            width: "100%",
        },
    },

    dropInfo: {
        background: "#f0f2f5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: 15,
        left: 0,
        right: 0,
    },

    dropInfoOut: {
        display: "none",
    },

    gridFiles: {
        maxHeight: "300px",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    marginRight: {
        marginRight: theme.spacing(1),
    },
    newMessageBox: {
        background: "#f0f2f5",
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },
    newMessageBoxInternal: {
        background: theme.palette.comment.main,
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "center",
    },
    messageInputWrapper: {
        padding: 5,
        marginRight: 7,
        background: "#fff",
        display: "flex",
        borderRadius: 20,
        flex: 1,
        position: "relative",
    },

    messageInput: {
        paddingLeft: 10,
        flex: 1,
        border: "none",
    },

    sendMessageIcons: {
        color: "#54656f",
    },

    uploadInput: {
        display: "none",
    },

    viewMediaInputWrapper: {
        maxHeight: "300px",
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#f0f2f5",
        // borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    },

    emojiBox: {
        position: "absolute",
        bottom: 63,
        width: 40,
        borderTop: "1px solid #e8e8e8",
        zIndex: 500,
    },

    circleLoading: {
        color: theme.palette.secondary.main,
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },

    audioLoading: {
        color: theme.palette.secondary.main,
        opacity: "70%",
    },

    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },

    cancelAudioIcon: {
        color: "red",
    },

    sendAudioIcon: {
        color: theme.palette.primary.main,
    },

    replyginMsgWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(1),
        paddingLeft: 73,
        paddingRight: 7,
    },

    replyginMsgContainer: {
        flex: 1,
        marginRight: 5,
        overflowY: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderRadius: theme.spacing(1),
        display: "flex",
        position: "relative",
    },

    replyginMsgBody: {
        padding: 10,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },

    replyginContactMsgSideColor: {
        flex: "none",
        width: theme.spacing(0.5),
        backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
        flex: "none",
        width: theme.spacing(0.5),
        backgroundColor: "#6bcbef",
    },

    signMessage: {
        margin: 7,
        color: "gray"
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 600,
    },
    // TODO: componetize
    messageQuickAnswersWrapper: {
        margin: 0,
        position: "absolute",
        bottom: "50px",
        background: "#ffffff",
        padding: "2px",
        // border: "1px solid #CCC",
        left: 0,
        width: "100%",
        borderRadius: theme.spacing(2),
        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.07)",
        "& li": {
            listStyle: "none",
            "& a": {
                display: "block",
                padding: theme.spacing(1),
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxHeight: "32px",
                "&:hover": {
                    background: "#F1F1F1",
                    cursor: "pointer",
                },
            },
        },
        zIndex: 500
    },
    status: {
        display: "flex",
        alignItems: "center"
    },
}));

const MessageInput = ({ ticketStatus, ticketId, ticketType }) => {
    const classes = useStyles();
    const [medias, setMedias] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [showEmoji, setShowEmoji] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recording, setRecording] = useState(false);
    const [quickAnswers, setQuickAnswer] = useState([]);
    const [typeBar, setTypeBar] = useState(false);
    const inputRef = useRef();
    const [onDragEnter, setOnDragEnter] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);

    const { user } = useAuth();
    const { settings } = useSettings();
    const { tenantId, userTenant } = useTenant();

    const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
    const [comment, setComment] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const isMobile = window.innerWidth <= 760;

    useEffect(() => {
        if (!replyingMessage) return;
        inputRef.current.focus();
    }, [replyingMessage]);

    useEffect(() => {
        if (!replyingMessage || !ticketId) return;
        inputRef.current.focus();
        return () => {
            setInputMessage("");
            setShowEmoji(false);
            setMedias([]);
            setReplyingMessage(null);
        };
    }, [ticketId, replyingMessage, setReplyingMessage]);

    useEffect(() => {
        if (!onDragEnter) return;
        setTimeout(() => {
            setOnDragEnter(false);
        }, 3000);
    }, [onDragEnter]);

    const getSettingValue = key => {
        const value = settings?.find(s => s.key === key)?.value;
        return value;
    };

    const handleChangeInput = (e) => {
        setInputMessage(e.target.value);
        handleLoadQuickAnswer(e.target.value);
    };

    const handleUploadQuickMessageMedia = async (blob, message) => {
        setLoading(true);
        try {
            const extension = blob.type.split("/")[1];
            const formData = new FormData();
            const filename = `${new Date().getTime()}.${extension}`;
            formData.append("medias", blob, filename);
            formData.append("body", message);
            formData.append("fromMe", true);

            if (tenantId) {
                try {
                    await axiosPrivate.post(`/${tenantId}/messages/${ticketId}`, formData);
                } catch (err) {
                    toastError(err);
                }
            }

        } catch (err) {
            toastError(err);
            setLoading(false);
        }
        setLoading(false);
    };

    const handleQuickAnswersClick = (value) => {
        if (value.hasMedia === true) {
            value.QuickAnswerMedias.forEach(async (v) => {
                if (v.mediaUrl) {
                    try {
                        const { data } = await axiosPrivate.get(v.mediaUrl, {
                            responseType: "blob",
                        });

                        handleUploadQuickMessageMedia(data, v.body);
                        return;
                        //  handleChangeMedias(response)
                    } catch (err) {
                        toastError(err);
                    }
                }

            });
        }
        setInputMessage(value.message);
        setTypeBar(false);
    };

    const handleAddEmoji = (e) => {
        let emoji = e.native;
        setInputMessage((prevState) => prevState + emoji);
    };

    const handleChangeMedias = (e) => {
        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    const handleInputPaste = (e) => {
        if (e.clipboardData.files[0]) {
            const selectedMedias = Array.from(e.clipboardData.files);
            setMedias(selectedMedias);
        }
    };

    const handleInputDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0]) {
            const selectedMedias = Array.from(e.dataTransfer.files);
            setMedias(selectedMedias);
        }
    };

    const handleUploadMedia = async (e) => {
        setLoading(true);
        if (e) { e.preventDefault(); }

        const formData = new FormData();
        formData.append("fromMe", true);
        medias.forEach((media) => {
            formData.append("medias", media);
            formData.append("body", media.name);
        });

        if (tenantId) {
            try {
                await axiosPrivate.post(`/${tenantId}/messages/${ticketId}`, formData);
            } catch (err) {
                toastError(err);
            }
        }

        setLoading(false);
        setMedias([]);
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === "") return;
        setLoading(true);

        let message = {
            read: 1,
            fromMe: true,
            mediaUrl: "",
            body: signMessage
                ? `*${user?.name}:*\n${inputMessage.trim()}`
                : inputMessage.trim(),
            quotedMsg: replyingMessage,
            isComment: comment
        };
        if (ticketStatus === "waiting") {
            message = {
                read: 1,
                fromMe: true,
                mediaUrl: "",
                body: signMessage ? signMessage : i18n.t("translation.messages.waitingForReview"),
                quotedMsg: replyingMessage,
                ticketId: ticketId,
            }
        }

        if (tenantId) {
            try {
                await axiosPrivate.post(`/${tenantId}/messages/${ticketId}`, message);
            } catch (err) {
                toastError(err);
            }
        }

        setInputMessage("");
        setShowEmoji(false);
        setLoading(false);
        setReplyingMessage(null);
    };

    const handleStartRecording = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await Mp3Recorder.start();
            setRecording(true);
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleLoadQuickAnswer = async (value) => {
        if (value && value.indexOf("/") === 0) {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/quickAnswers/`, {
                        params: { searchParam: inputMessage.substring(1) },
                    });
                    setQuickAnswer(data.quickAnswers);
                    if (data.quickAnswers.length > 0) {
                        setTypeBar(true);
                    } else {
                        setTypeBar(false);
                    }
                } catch (err) {
                    setTypeBar(false);
                }
            }
        } else {
            setTypeBar(false);
        }
    };

    const handleUploadAudio = async () => {
        setLoading(true);
        if (tenantId) {
            try {
                const [, blob] = await Mp3Recorder.stop().getMp3();
                if (blob.size < 10000) {
                    setLoading(false);
                    setRecording(false);
                    return;
                }

                const formData = new FormData();
                const filename = `audio-record-site-${new Date().getTime()}.mp3`;
                formData.append("medias", blob, filename);
                formData.append("body", filename);
                formData.append("fromMe", true);

                await axiosPrivate.post(`/${tenantId}/messages/${ticketId}`, formData);
            } catch (err) {
                toastError(err);
            }
        }

        setRecording(false);
        setLoading(false);
    };

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3();
            setRecording(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event) => {
        setAnchorEl(null);
    };

    const renderSignMessage = () => {
        const signMessageComponent = (
            <FormControlLabel
                className={classes.signMessage}
                label={i18n.t("translation.messagesInput.signMessage")}
                labelPlacement="start"
                control={
                    <Switch
                        size="small"
                        checked={signMessage}
                        onChange={(e) => {
                            setSignMessage(e.target.checked);
                        }}
                        name="showAllTickets"
                        color="primary"
                    />
                }
            />
        )

        let showComponent = false;
        if (((userTenant?.role === "supervisor" || userTenant?.role === "user") &&
            settings && settings.length > 0 && getSettingValue("signMessage") === "enabled") ||
            (userTenant?.role === "super" || userTenant?.role === "admin")) {
            showComponent = true;
        }

        return showComponent ? signMessageComponent : '';
    }

    const renderReplyingMessage = (message) => {
        return (
            <div className={classes.replyginMsgWrapper}>
                <div className={classes.replyginMsgContainer}>
                    <span
                        className={clsx(classes.replyginContactMsgSideColor, {
                            [classes.replyginSelfMsgSideColor]: !message.fromMe,
                        })}
                    ></span>
                    <div className={classes.replyginMsgBody}>
                        {!message.fromMe && (
                            <span className={classes.messageContactName}>
                                {message.contact?.name}
                            </span>
                        )}
                        <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    </div>
                </div>
                <IconButton
                    aria-label="showRecorder"
                    component="span"
                    disabled={loading || ticketStatus !== "open"}
                    onClick={() => setReplyingMessage(null)}
                    size="large">
                    <Clear className={classes.sendMessageIcons} />
                </IconButton>
            </div>
        );
    };

    if (medias.length > 0)
        return (
            <Paper
                elevation={0}
                square
                className={classes.viewMediaInputWrapper}
                onDragEnter={() => setOnDragEnter(true)}
                onDrop={(e) => handleInputDrop(e)}
            >
                <IconButton
                    aria-label="cancel-upload"
                    component="span"
                    onClick={(e) => setMedias([])}
                    size="large">
                    <Cancel className={classes.sendMessageIcons} />
                </IconButton>

                {loading ? (
                    <div>
                        <CircularProgress className={classes.circleLoading} />
                    </div>
                ) : (
                    <Grid item className={classes.gridFiles}>
                        <Typography variant="h6" component="div">
                            {i18n.t("translation.uploads.titles.titleFileList")} ({medias.length})
                        </Typography>
                        <List>
                            {medias.map((value, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemAvatar>
                                            <Avatar alt={value.name} src={URL.createObjectURL(value)} variant="rounded" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${value.name}`}
                                            secondary={`${parseInt(value.size / 1024)} kB`}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <InputBase
                            style={{ width: "0", height: "0" }}
                            inputRef={function (input) {
                                if (input != null) {
                                    input.focus();
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleUploadMedia();
                                }
                            }}
                            defaultValue={medias[0].name}
                        />
                    </Grid>
                )}
                <IconButton
                    aria-label="send-upload"
                    component="span"
                    onClick={handleUploadMedia}
                    disabled={loading}
                    size="large">
                    <Send className={classes.sendMessageIcons} />
                </IconButton>
            </Paper>
        );
    else {
        return (
            <Paper
                square
                elevation={0}
                className={comment === true ? classes.mainWrapperInternal : classes.mainWrapper}
                onDragEnter={() => setOnDragEnter(true)}
                onDrop={(e) => handleInputDrop(e)}
            >
                <div className={onDragEnter ? classes.dropInfo : classes.dropInfoOut}>
                    {i18n.t("translation.uploads.titles.titleUploadMsgDragDrop")}
                </div>
                {replyingMessage && renderReplyingMessage(replyingMessage)}
                <div className={comment === true ? classes.newMessageBoxInternal : classes.newMessageBox}>
                    <Hidden only={ticketStatus === "waiting" ? [] : ["sm", "xs"]}>
                        <Tooltip arrow title={i18n.t("translation.ticket.ticketComment.tooltip")}>
                            <IconButton
                                component="span"
                                disabled={loading || recording || ticketStatus !== "open"}
                                onClick={(e) => setComment((prevState) => !prevState)}
                                size="large">
                                <StickyNote2Outlined className={classes.sendMessageIcons} />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            aria-label="emojiPicker"
                            component="span"
                            disabled={loading || recording || ticketStatus !== "open"}
                            onClick={(e) => setShowEmoji((prevState) => !prevState)}
                            size="large">
                            <Mood className={classes.sendMessageIcons} />
                        </IconButton>
                        {showEmoji ? (
                            <div className={classes.emojiBox}>
                                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                                    <>
                                        <Picker
                                            data={data}
                                            i18n={emojiPt} //Fix multiple languages
                                            previewPosition="none"
                                            perLine={8}
                                            icons="outline"
                                            maxFrequentRows={2}
                                            emojiVersion={14}
                                            skinTonePosition="none"
                                            theme="light"
                                            onEmojiSelect={handleAddEmoji}
                                        />
                                    </>
                                </ClickAwayListener>
                            </div>
                        ) : null}

                        <input
                            multiple
                            type="file"
                            id="upload-button"
                            disabled={loading || recording || ticketStatus !== "open" || comment}
                            className={classes.uploadInput}
                            onChange={handleChangeMedias}
                        />
                        <label htmlFor="upload-button">
                            <IconButton
                                aria-label="upload"
                                component="span"
                                disabled={loading || recording || ticketStatus !== "open" || comment}
                                onMouseOver={() => setOnDragEnter(true)}
                                size="large">
                                <AttachFile className={classes.sendMessageIcons} />
                            </IconButton>
                        </label>
                        {renderSignMessage()}
                    </Hidden>
                    <Hidden only={ticketStatus === "waiting" ? [] : ["md", "lg", "xl"]}>
                        <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleOpenMenuClick}
                            size="large">
                            <MoreVert></MoreVert>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            keepMounted
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuItemClick}
                        >
                            <MenuItem onClick={handleMenuItemClick}>
                                <IconButton
                                    aria-label="emojiPicker"
                                    component="span"
                                    disabled={loading || recording || ticketStatus !== "open"}
                                    onClick={(e) => setShowEmoji((prevState) => !prevState)}
                                    size="large">
                                    <Mood className={classes.sendMessageIcons} />
                                </IconButton>
                            </MenuItem>
                            <MenuItem onClick={handleMenuItemClick}>
                                <input
                                    multiple
                                    type="file"
                                    id="upload-button"
                                    disabled={loading || recording || ticketStatus !== "open"}
                                    className={classes.uploadInput}
                                    onChange={handleChangeMedias}
                                />
                                <label htmlFor="upload-button">
                                    <IconButton
                                        aria-label="upload"
                                        component="span"
                                        disabled={loading || recording || ticketStatus !== "open"}
                                        size="large">
                                        <AttachFile className={classes.sendMessageIcons} />
                                    </IconButton>
                                </label>
                            </MenuItem>
                            <MenuItem onClick={handleMenuItemClick}>
                                {renderSignMessage()}
                            </MenuItem>
                        </Menu>
                    </Hidden>
                    <div className={classes.messageInputWrapper}>
                        <InputBase
                            inputRef={(input) => {
                                input && input.focus();
                                input && (inputRef.current = input);
                            }}
                            className={classes.messageInput}
                            placeholder={
                                ticketStatus === "open"
                                    ? isMobile
                                        ? i18n.t("translation.messagesInput.mobile.placeholderOpen")
                                        : i18n.t("translation.messagesInput.placeholderOpen")
                                    : isMobile
                                        ? i18n.t("translation.messagesInput.mobile.placeholderClosed")
                                        : i18n.t("translation.messagesInput.placeholderClosed")
                            }
                            multiline
                            maxRows={5}
                            value={inputMessage}
                            onChange={handleChangeInput}
                            disabled={recording || loading || ticketStatus !== "open"}
                            onPaste={(e) => {
                                ticketStatus === "open" && handleInputPaste(e);
                            }}
                            onKeyPress={(e) => {
                                if (loading || e.shiftKey) return;
                                else if (e.key === "Enter") {
                                    handleSendMessage();
                                }
                            }}
                        />
                        {typeBar ? (
                            <ul className={classes.messageQuickAnswersWrapper}>
                                {quickAnswers.map((value, index) => {
                                    return (
                                        <li
                                            className={classes.messageQuickAnswersWrapperItem}
                                            key={index}
                                        >
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a onClick={() => handleQuickAnswersClick(value)}>
                                                {<div className={classes.status}>
                                                    {value.visibility
                                                        ? <LockOpen fontSize="small" className={classes.marginRight} />
                                                        : <Lock fontSize="small" className={classes.marginRight} color="error" />}
                                                    {value.hasMedia === true
                                                        ? <ImageOutlined fontSize="small" className={classes.marginRight} />
                                                        : ""
                                                    }
                                                    {value.shortcut} - {value.message}
                                                </div>}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    {inputMessage ? (
                        <IconButton
                            aria-label="sendMessage"
                            component="span"
                            onClick={handleSendMessage}
                            disabled={loading}
                            size="large">
                            <Send className={classes.sendMessageIcons} />
                        </IconButton>
                    ) : recording ? (
                        <div className={classes.recorderWrapper}>
                            <IconButton
                                aria-label="cancelRecording"
                                component="span"
                                fontSize="large"
                                disabled={loading || comment}
                                onClick={handleCancelAudio}
                                size="large">
                                <HighlightOff className={classes.cancelAudioIcon} />
                            </IconButton>
                            {loading ? (
                                <div>
                                    <CircularProgress className={classes.audioLoading} />
                                </div>
                            ) : (
                                <RecordingTimer />
                            )}

                            <IconButton
                                aria-label="sendRecordedAudio"
                                component="span"
                                onClick={handleUploadAudio}
                                disabled={loading || comment}
                                size="large">
                                <CheckCircleOutline className={classes.sendAudioIcon} />
                            </IconButton>
                        </div>
                    ) : (
                        <IconButton
                            aria-label="showRecorder"
                            component="span"
                            disabled={loading || ticketStatus !== "open" || comment}
                            onClick={handleStartRecording}
                            size="large">
                            <Mic className={classes.sendMessageIcons} />
                        </IconButton>
                    )}
                </div>
            </Paper>
        );
    };
};

export default MessageInput;
