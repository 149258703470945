import React from "react";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BotOptions from "../BotOptions";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    description: {
        paddingBottom: theme.spacing(2)
    },
    btnWrapper: {
        position: "100%",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
    }
}));

const BotModal = ({ open, onClose, selectedBot }) => {
    const classes = useStyles();
    const handleClose = () => onClose();

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {selectedBot
                        ? `${i18n.t("translation.botModal.title.edit")}`
                        : `${i18n.t("translation.botModal.title.add")}`}
                </DialogTitle>

                <DialogContent dividers>
                    <Typography className={classes.description}>
                        {i18n.t("translation.botModal.form.description")}
                    </Typography>
                    <BotOptions firstBot={selectedBot} selectedBot={selectedBot} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default BotModal;
