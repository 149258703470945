import * as Yup from "yup"
import { i18n } from "../../translate/i18n"

const loginFormSchema = Yup.object().shape({
    email: Yup.string()
        .min(6, i18n.t("translation.validation.too_short"))
        .max(80, i18n.t("translation.validation.too_long"))
        .required(i18n.t("translation.validation.required"))
        .email(i18n.t("translation.validation.invalid_email")),
    password: Yup.string()
        .required(i18n.t("translation.validation.required")),
})

export { loginFormSchema }
