import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PersistLogin from "../components/PersistLogin";

// Context
import { AuthProvider } from "../context/Auth/AuthContext";
import { SocketProvider } from "../context/Socket/SocketContext";
import { TenantProvider } from "../context/Tenant/TenantContext";
import { UserProvider } from "../context/User/UserContext";

// Layout
import CheckoutLayout from "../layout/CheckoutLayout";
import LoggedOutLayout from "../layout/LoggedOutLayout";

// Pages
import Account from "../pages/Account";
import Api from "../pages/Api";
import App from "../pages/App/";
import Bots from "../pages/Bots/";
import Campaigns from "../pages/Campaign";
import Campaign from "../pages/Campaign/Single";
import Chat from "../pages/Chat";
import Checkout from "../pages/Checkout";
import Connections from "../pages/Connections/";
import ContactGroups from "../pages/ContactGroups";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Reports from "../pages/Dashboard/Reports/";
import ReportTeams from "../pages/Dashboard/Reports/Teams";
import Invoices from "../pages/Invoices";
import Login from "../pages/Login/";
import NotFound from "../pages/NotFound/";
import Plans from "../pages/Plans/";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import ScheduledMessages from "../pages/ScheduledMessages";
import SelectTenants from "../pages/SelectTenants";
import Settings from "../pages/Settings/";
import Subscriptions from "../pages/Subscriptions";
import Super from "../pages/Super/";
import Tags from "../pages/Tags/";
import Tenants from "../pages/Tenants";
import Test from "../pages/Test";
import Tickets from "../pages/Tickets/";
import Unauthorized from "../pages/Unauthorized";
import Users from "../pages/Users";
import Widget from "../pages/Widget/";
import Wikis from "../pages/Wiki";
import Wiki from "../pages/Wiki/Single";
import PrivateRoute from "./PrivateRoute";

const appRoutes = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    {/* Pulblic routes */}
                    <Route path="/" element={<LoggedOutLayout />}>
                        <Route path="app" element={<App />} />
                        <Route path="login" element={<Login />} />


                        {/* Fallback for not found pages */}
                        <Route path="404" element={<NotFound />} />
                    </Route>

                    {/* Checkout layout */}
                    <Route element={<CheckoutLayout />}>
                        <Route path="checkout" element={<Checkout />} />
                    </Route>

                    {/* Private Routes */}
                    <Route element={<PersistLogin />}>
                        <Route element={<SocketProvider />}>
                            <Route element={<UserProvider />}>
                                <Route path="/" element={<LoggedOutLayout />}>
                                    <Route index element={<SelectTenants />} />
                                </Route>

                                {/* Provide tenant identification to go through */}
                                <Route path=":tenantSlug" element={<TenantProvider />}>

                                    {/* Private routes available to all users */}
                                    <Route element={<PrivateRoute allowedRoles={["user", "supervisor", "finance", "dashboard", "admin", "super"]} />} >
                                        <Route path="test" element={<Test />} />
                                        <Route path="chats" element={<Chat />}>
                                            <Route index element={<Chat />} />
                                            <Route path=":uuid" element={<Chat />} />
                                        </Route>
                                        <Route path="contactGroups" element={<ContactGroups />} />
                                        <Route path="contacts" element={<Contacts />} />
                                        <Route path="quickAnswers" element={<QuickAnswers />} />
                                        <Route path="tickets" element={<Tickets />}>
                                            <Route index element={<Tickets />} />
                                            <Route path=":uuid" element={<Tickets />} />
                                        </Route>
                                        <Route path="wiki">
                                            <Route index element={<Wikis />} />
                                            <Route path=":protocol" element={<Wiki />} />
                                        </Route>

                                        {/* Fallback for unauthorized attempts */}
                                        <Route path="unauthorized" element={<Unauthorized />} />
                                    </Route>

                                    {/* Availale only to information users*/}
                                    <Route element={<PrivateRoute allowedRoles={["dashboard", "finance", "supervisor", "admin", "super"]} />} >
                                        <Route index element={<Dashboard />} />
                                        <Route path="reports">
                                            <Route index element={<Reports />} />
                                            <Route path="teams" element={<ReportTeams />} />
                                        </Route>
                                    </Route>

                                    {/* Availale only to campaign aligned users*/}
                                    <Route element={<PrivateRoute allowedRoles={["dashboard", "supervisor", "admin", "super"]} />} >
                                        <Route path="campaign">
                                            <Route index element={<Campaigns />} />
                                            <Route path=":campaignId" element={<Campaign />} />
                                        </Route>
                                    </Route>

                                    {/* Availale only to finance users*/}
                                    <Route element={<PrivateRoute allowedRoles={["finance", "admin", "super"]} />} >
                                        <Route path="account" element={<Account />} />
                                        <Route path="invoices" element={<Invoices />} />
                                        <Route path="subscriptions" element={<Subscriptions />} />
                                    </Route>

                                    {/* Availale only to supervisors users*/}
                                    <Route element={<PrivateRoute allowedRoles={["supervisor", "admin", "super"]} />} >
                                        <Route path="scheduledMessages" element={<ScheduledMessages />} />
                                        <Route path="tags" element={<Tags />} />
                                        <Route path="users" element={<Users />} />
                                    </Route>

                                    {/* Availale only to admin users*/}
                                    <Route element={<PrivateRoute allowedRoles={["admin", "super"]} />} >
                                        <Route path="api" element={<Api />} />
                                        <Route path="bots" element={<Bots />} />
                                        <Route path="connections" element={<Connections />} />
                                        <Route path="queues" element={<Queues />} />
                                        <Route path="settings" element={<Settings />} />
                                        <Route path="widget" element={<Widget />} />
                                    </Route>

                                    {/* Availale only to super users*/}
                                    <Route element={<PrivateRoute allowedRoles={["super"]} />} >
                                        <Route path="super" element={<Super />} />
                                        <Route path="tenants" element={<Tenants />} />
                                        <Route path="plans" element={<Plans />} />
                                    </Route>

                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    {/* TODO: Catch all for missing pages */}
                    <Route path="*" element={<Navigate to="/" />} />

                </Routes>

                <ToastContainer
                    autoClose={2000}
                    position="bottom-right"
                    newestOnTop
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" // TODO: change to dark with dark theme.
                />
            </AuthProvider>
        </BrowserRouter >
    );
};

export default appRoutes;
