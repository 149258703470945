import { Checkbox, FormControlLabel } from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
import { Field, useField } from "formik";

// const useStyles = makeStyles((theme) => ({
//     field: {
//         marginTop: theme.spacing(0),
//         marginBottom: theme.spacing(1),
//     },
// }));

export default function CheckboxInput({ label, className, ...props }) {
    // const classes = useStyles();
    const [field] = useField(props);
    return (
        <FormControlLabel
            label={label}
            className={className}
            control={
                <Field
                    as={Checkbox}
                    id={props.name}
                    size="small"
                    checked={field.value}
                    // className={classes.field}
                    {...props}
                />
            }
        />
    );
}
