// import md5 from 'md5';

// TODO: add other services support such as Google and Facebook:
// https://gist.github.com/jcsrb/1081548
// https://unavatar.io/
// https://github.com/stewartlord/identicon.js
// https://dicebear.com/how-to-use/http-api

// export const gravatar = email => {
//     if (!email) return;

//     const trimedEmail = email.trim().toLowerCase();
//     const hash = email ? md5(trimedEmail) : "";
//     const defaultAvatar = encodeURIComponent('mp'); // Either a URL or an option as shown at https://br.gravatar.com/site/implement/images/
//     const avatar = `https://www.gravatar.com/avatar/${hash}?d=${defaultAvatar}`;

//     return avatar;
// } 

const avatar = (email, fallback) => {
    if (!email) return;

    const trimedEmail = email.trim().toLowerCase().replace(/ /g, '');
    const urlEncodeEmail = encodeURIComponent(trimedEmail)
    let fallBackService = false;

    if (fallback) {
        let siteUrl = trimedEmail.split('@').pop();
        const colors = "0B1543,8BD070,F2691C,F38112,1F256E";
        const services = [
            { service: "BoringAvatars", api: `https://source.boringavatars.com/bauhaus/250/${urlEncodeEmail}?square=true&colors=${colors}` },
            { service: "DiceBear", api: `https://api.dicebear.com/5.x/icons/svg?seed=${urlEncodeEmail}&backgroundType=solid&backgroundColor=${colors}` },
            { service: "Google", api: `https://s2.googleusercontent.com/s2/favicons?domain=${siteUrl}&sz=48` },
            { service: "IconHorse", api: `https://icon.horse/icon/${siteUrl}&sz=48` },
            // { service: "FaviconGraber", api: `http://favicongrabber.com/api/grab/${siteUrl}` },
        ];

        if (typeof fallback === 'boolean' && fallback) {
            fallBackService = services[0].api;
        } else if (typeof fallback === 'string') {
            fallBackService = services.find((s) => s.service === fallback)?.api ?? null;
        }
    }

    // const grabIcon = async (iconUrl) => {
    //     await fetch(iconUrl)
    //         .then(response => response.json())
    //         .then(({ icons }) => {
    //             const lastIcon = icons[icons.length - 1];
    //             const lastSrc = lastIcon.src;
    //             fallBackService = lastSrc
    //         });
    // }
    // if (fallback === "FaviconGraber") grabIcon(fallBackService);

    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    const checkEmail = regexExp.test(trimedEmail);

    const unavatar = `https://unavatar.io/${trimedEmail}?fallback=${fallBackService}`;

    return checkEmail ? unavatar : fallBackService;
}

export default avatar;