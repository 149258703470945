import React, { useEffect, useState } from "react";

import Chart from 'react-apexcharts';
import Title from "../../../components/LayoutComponents/CardTitle";
import { i18n } from "../../../translate/i18n";

import { useTheme } from "@mui/material";
import toastError from "../../../errors/toastError";
import colorTheme from "../../../services/colorTheme";

const ChartTicketsWhatsapps = ({ data }) => {
    const theme = useTheme();
    const [chartTickets, setChartTickets] = useState([]);

    const labels = [];
    const useData = [];
    const color = [];

    useEffect(() => {
        const controller = new AbortController();
        const fetchData = () => {
            try {
                setChartTickets(data?.ticketCountWhatsapp?.rows);
            } catch (err) {
                toastError(err, "", "Error ocurred while trying to fetch dashboard's tickets connections donut chart.");
            }
        }
        fetchData();
        return () => {
            controller.abort();
        };
    }, [data]);

    const GetLabelChart = () => {
        chartTickets?.forEach(function (item) {
            if (item?.whatsapp?.name !== undefined && item?.whatsapp?.name?.indexOf) {
                labels.push(item?.whatsapp?.name?.toString());
            } else {
                labels.push(i18n.t("translation.dashboard.charts.perConnection.defaultConnectionLabel"));
            }
        });
        return labels
    }

    const GetColorChart = () => {
        const palette = colorTheme(theme.palette.primary.main, chartTickets?.length);
        chartTickets?.forEach(function (item, index) {
            color.push(palette[index]);
        });
        return color
    }

    const GetChartData = () => {
        chartTickets?.forEach(function (item) {
            useData.push(parseInt(item?.ticketCount));
        });

        return useData
    }

    const chartOptions = {
        labels: GetLabelChart(),
        colors: GetColorChart()
    }

    return (
        chartTickets && (
            <>
                <Title>
                    {i18n.t("translation.dashboard.charts.perConnection.title")}
                </Title>
                <Chart
                    options={chartOptions}
                    height={300}
                    series={GetChartData()}
                    type="donut"
                />
            </>
        )
    );
};

export default ChartTicketsWhatsapps;
