import { Field, Formik } from "formik";
import React from "react";

import { i18n } from "../../translate/i18n";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

import DateFieldInput from "../FormComponents/DateFieldInput/DateField";
import FormContainer from "../FormComponents/FormContainer";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import TextFieldInput from "../FormComponents/TextFieldInput";
import { TagsFilter } from "../TagsFilter";

// Style for Dialog Actions
const StyledDialogActions = styled(DialogActions)({
    justifyContent: "space-between", // Aligns items to the edges
});

const useStyles = makeStyles(theme => ({
    actionButtonsBox: {
        display: "flex",
        gap: theme.spacing(1),
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    dialogAction: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        gap: theme.spacing(2),
        flexDirection: "row",
    },
}));

export default function SearchParamModal({ open, defaultSearchParams, handleClose, searchParam, searchParamState, searchInputRef, handleSelectedTags }) {
    const classes = useStyles();

    // const now = new Date();

    const initialState = {
        searchTerms: searchParam?.parameter || "",
        searchBy: searchParam?.filter || "all",
        dateStart: searchParam?.dateRange?.dateStart,
        // ? searchParam.dateRange.dateStart
        // : `${now.getFullYear()}-${("0" + (now.getMonth() + 1)).slice(-2)}-${("0" + (now.getDate() - 15)).slice(-2)}`,
        dateEnd: searchParam?.dateRange?.dateEnd,
        // ? searchParam.dateRange.dateEnd
        // : `${now.getFullYear()}-${("0" + (now.getMonth() + 1)).slice(-2)}-${("0" + now.getDate()).slice(-2)}`,
        status: searchParam?.status || "all"
    }

    const handleClearSearch = () => {
        searchParamState(defaultSearchParams);
        handleClose();
    }

    const handleStatusChange = (values) => {
        searchParamState((prevState) => ({
            ...prevState,
            parameter: values.searchTerms,
            filter: values.searchBy,
            status: values.status,
            dateRange: {
                dateStart: values.dateStart,
                dateEnd: values.dateEnd
            }
        }));
        handleClose();
    };

    return open && (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                {i18n.t("translation.searchParamsModal.title")}
            </DialogTitle>

            <Formik
                initialValues={initialState}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleStatusChange(values);
                        actions.setSubmitting(false)
                    }, 400)
                }}
            >
                {({ isSubmitting, loading }) => (
                    <FormContainer>
                        <DialogContent dividers>
                            <TextFieldInput
                                name="searchTerms"
                                label={
                                    i18n.t('translation.searchParamsModal.inputs.searchTerms')
                                }
                                size="small"
                                disabled={isSubmitting}
                                inputRef={searchInputRef}
                            />

                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                            >
                                <InputLabel>
                                    {i18n.t("translation.searchParamsModal.inputs.searchBy.title")}
                                </InputLabel>
                                <Field
                                    as={Select}
                                    label={i18n.t("translation.searchParamsModal.inputs.searchBy.title")}
                                    id="searchBy"
                                    name="searchBy"
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    fullWidth
                                    placeholder={i18n.t("translation.searchParamsModal.inputs.searchBy.title")}
                                >
                                    <MenuItem value="all">
                                        {i18n.t("translation.searchParamsModal.inputs.searchBy.options.all")}
                                    </MenuItem>
                                    <MenuItem value="contactName">
                                        {i18n.t("translation.searchParamsModal.inputs.searchBy.options.contactName")}
                                    </MenuItem>
                                    <MenuItem value="contactNumber">
                                        {i18n.t("translation.searchParamsModal.inputs.searchBy.options.contactNumber")}
                                    </MenuItem>
                                    <MenuItem value="userName">
                                        {i18n.t("translation.searchParamsModal.inputs.searchBy.options.UserName")}
                                    </MenuItem>
                                    <MenuItem value="ticketNumber">
                                        {i18n.t("translation.searchParamsModal.inputs.searchBy.options.TicketNumber")}
                                    </MenuItem>
                                    <MenuItem value="message">
                                        {i18n.t("translation.searchParamsModal.inputs.searchBy.options.Message")}
                                    </MenuItem>
                                </Field>
                            </FormControl>

                            <MultiFieldLine>
                                <DateFieldInput
                                    name="dateStart"
                                    label={i18n.t("translation.dashboard.dateFilterModal.dateRange.start")}
                                    size="small"
                                    // autoFocus
                                    disabled={isSubmitting}
                                    InputLabelProps={{ shrink: true }}

                                />
                                <DateFieldInput
                                    name="dateEnd"
                                    label={i18n.t("translation.dashboard.dateFilterModal.dateRange.end")}
                                    size="small"
                                    // autoFocus
                                    disabled={isSubmitting}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </MultiFieldLine>

                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                            >
                                <InputLabel>
                                    {i18n.t("translation.searchParamsModal.inputs.status.title")}
                                </InputLabel>
                                <Field
                                    as={Select}
                                    label={i18n.t("translation.searchParamsModal.inputs.status.title")}
                                    id="status"
                                    name="status"
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    fullWidth
                                    placeholder={i18n.t("translation.searchParamsModal.inputs.status.title")}
                                >
                                    <MenuItem value="all">
                                        {i18n.t("translation.searchParamsModal.inputs.status.options.all")}
                                    </MenuItem>
                                    <MenuItem value="open">
                                        {i18n.t("translation.ticketsList.assignedHeader")}
                                    </MenuItem>
                                    <MenuItem value="pending">
                                        {i18n.t("translation.ticketsList.pendingHeader")}
                                    </MenuItem>
                                    <MenuItem value="waiting">
                                        {i18n.t("translation.tickets.tabs.waiting.title")}
                                    </MenuItem>
                                    <MenuItem value="closed">
                                        {i18n.t("translation.tickets.tabs.closed.title")}
                                    </MenuItem>
                                </Field>
                            </FormControl>

                            <TagsFilter
                                onFiltered={handleSelectedTags}
                            />
                        </DialogContent>

                        <StyledDialogActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={handleClearSearch}
                            >
                                {i18n.t(
                                    "translation.validation.buttons.clear"
                                )}
                            </Button>
                            <Box className={classes.actionButtonsBox}>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t(
                                        "translation.validation.buttons.cancel"
                                    )}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {i18n.t(
                                        "translation.validation.buttons.search"
                                    )}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </StyledDialogActions>
                    </FormContainer>
                )}
            </Formik>
        </Dialog>
    );
}
