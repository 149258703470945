import React, { useCallback, useEffect, useReducer, useState } from "react";

import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {
    DeleteOutline,
    EditOutlined,
    SearchOutlined,
} from "@mui/icons-material";

import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import findDefaultSystemTagName from "../../components/DefaultSystemTagName";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";

const reducer = (state, action) => {
    if (action.type === "LOAD_TAGS") {
        const tags = action.payload;
        const newTags = [];

        tags.forEach((tag) => {
            const tagIndex = state.findIndex((s) => s.id === tag.id);
            if (tagIndex !== -1) {
                state[tagIndex] = tag;
            } else {
                newTags.push(tag);
            }
        });

        return [...state, ...newTags];
    }

    if (action.type === "UPDATE_TAGS") {
        const tag = action.payload;
        const tagIndex = state.findIndex((s) => s.id === tag.id);

        if (tagIndex !== -1) {
            state[tagIndex] = tag;
            return [...state];
        } else {
            return [tag, ...state];
        }
    }

    if (action.type === "DELETE_TAG") {
        const tagId = action.payload;

        const tagIndex = state.findIndex((s) => s.id === tagId);
        if (tagIndex !== -1) {
            state.splice(tagIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tagColor: {
        width: 60,
        height: 20,
        alignSelf: "center",
        borderRadius: 4
    },
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionCell: {
        alignItems: "center",
        width: "15%",
        maxWidth: 160,
        minWidth: 140
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },

}));

const Tags = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [deletingTag, setDeletingTag] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [tags, dispatch] = useReducer(reducer, []);
    const [tagModalOpen, setTagModalOpen] = useState(false);

    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    const fetchTags = useCallback(async () => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/tags/`, {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_TAGS", payload: data.tags });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, tenantId]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchTags();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, fetchTags]);

    // Handle Tag Changes
    const handleTagChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            dispatch({ type: "UPDATE_TAGS", payload: data.tag });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_TAG", payload: +data.tagId });
        }
    };
    useSocketListener(`tag`, (data) => handleTagChange(data))

    const handleOpenTagModal = () => {
        setSelectedTag(null);
        setTagModalOpen(true);
    };

    const handleCloseTagModal = () => {
        setSelectedTag(null);
        setTagModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditTag = (tag) => {
        setSelectedTag(tag);
        setTagModalOpen(true);
    };

    const handleDeleteTag = async (tagId) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/tags/${tagId}`);
            toast.success(i18n.t("translation.tags.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingTag(null);
        setSearchParam("");
        setPageNumber(1);

        dispatch({ type: "RESET" });
        setPageNumber(1);
        await fetchTags();
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingTag &&
                    `${i18n.t("translation.tags.confirmationModal.deleteTitle")}`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteTag(deletingTag.id)}
            >
                {i18n.t("translation.tags.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <TagModal
                open={tagModalOpen}
                onClose={handleCloseTagModal}
                reload={fetchTags}
                aria-labelledby="form-dialog-title"
                tagId={selectedTag && selectedTag.id}
            />
            <MainHeader>
                <Title>{i18n.t("translation.tags.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("translation.variables.placeholders.search")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenTagModal}
                    >
                        {i18n.t("translation.tags.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.name")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.color")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.systemTag")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.tickets.tags")}
                            </TableCell>
                            <TableCell align="center" className={classes.tableActions}>
                                {i18n.t("translation.variables.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {tags.map((tag) => (
                                <TableRow key={tag.id}>
                                    <TableCell align="center">
                                        {
                                            tag.system
                                                ? findDefaultSystemTagName(tag)
                                                : tag.name
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className={classes.customTableCell}>
                                            <span className={classes.tagColor}
                                                style={{
                                                    backgroundColor: tag.color,
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {tag.system ? i18n.t("translation.variables.boolean.yes") : i18n.t("translation.variables.boolean.no")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {tag.count > 0 ? tag.count : i18n.t("translation.variables.table.noTickets")}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            className={classes.actionButtons}
                                            onClick={() => handleEditTag(tag)}
                                        >
                                            <EditOutlined />
                                        </IconButton>

                                        {!tag.system && <IconButton
                                            size="small"
                                            className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingTag(tag);
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Tags;
