// Reference
// https://developer.wordpress.org/rest-api/reference/posts/

const appVars = require("../../package.json");

const baseurl = appVars.changelog.wordpress.url;
const changelogEndpoint = `/wp-json/wp/v2/posts?categories=${appVars.changelog.wordpress.category}&per_page=1`;
// const postsEndpoint = '/wp-json/wp/v2/posts';
// const categoriesEndpoint = '/wp-json/wp/v2/categories';

const getWordpressPosts = () => new Promise((resolve, reject) => {
    const endpoint = baseurl + changelogEndpoint;
    fetch(endpoint)
        .then(response => response.json()
            .then(json => ({
                totalPages: response.headers.get("x-wp-totalpages"),
                totalPosts: response.headers.get("x-wp-total"),
                allHeaders: response.headers,
                json
            })))
        .then(result => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });


});

export default getWordpressPosts;
