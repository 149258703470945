import React, { useEffect, useMemo, useReducer, useState } from "react";

import {
    Button,
    Chip,
    Grid,
    IconButton,
    Paper,
    Tooltip
} from "@mui/material";

import { DataGrid, gridClasses } from "@mui/x-data-grid";

import {
    Block,
    CheckCircleOutlined,
    EditOutlined,
    MonetizationOnOutlined,
    OpenInNewRounded,
    PrintOutlined
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import { useNavigate } from "react-router-dom";
import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSocket from "../../hooks/useSocket";
import useTenant from "../../hooks/useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_INVOICES") {
        const invoices = action.payload;
        const newInvoices = [];

        invoices.forEach((invoice) => {
            const invoiceIndex = state.findIndex((i) => i.id === invoice.id);
            if (invoiceIndex !== -1) {
                state[invoiceIndex] = invoice;
            } else {
                newInvoices.push(invoice);
            }
        });

        return [...state, ...newInvoices];
    }

    if (action.type === "UPDATE_INVOICES") {
        const invoice = action.payload;
        const invoiceIndex = state.findIndex((i) => i.id === invoice.id);

        if (invoiceIndex !== -1) {
            state[invoiceIndex] = invoice;
            return [...state];
        } else {
            return [invoice, ...state];
        }
    }

    if (action.type === "DELETE_INVOICES") {
        const invoiceId = action.payload;

        const invoiceIndex = state.findIndex((i) => i.id === invoiceId);
        if (invoiceIndex !== -1) {
            state.splice(invoiceIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140,
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    formControl: {
        marginRight: theme.spacing(2),
        "& input": {
            height: "1.2195em",
        },
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    root: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexWrap: "wrap",
        },
    },
    status: {
        display: "flex",
        justifyContent: "center",
    },
}));

const Invoices = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [, setLoading] = useState(false);
    const { tenant, tenantId } = useTenant();
    const [invoices, dispatch] = useReducer(reducer, []);
    const { socket } = useSocket();

    const [showAll] = useState(true);
    const [pageNumber,] = useState(1);
    const [, setHasMore] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const [rows, setRows] = useState([{
        id: "",
        planName: "",
        planDescription: "",
        subscriptionId: "",
        status: "",
        currency: "",
        subtotal: "",
        total: "",
        provider: "",
        dueDateAt: "",
        createdAt: "",
        updatedAt: "",
        extraInfo: {},
    }]);

    useEffect(() => {
        if (!invoices) return;
        let dataGridRows = invoices.map((invoice) => ({
            id: invoice?.id,
            planName: invoice?.subscription?.plan?.name,
            planDescription: invoice?.subscription?.plan?.description,
            subscriptionId: invoice?.subscriptionId,
            status: invoice?.status,
            currency: invoice?.currency,
            subtotal: invoice?.subtotal,
            total: invoice?.total,
            provider: invoice?.provider,
            dueDateAt: invoice?.dueDateAt,
            createdAt: invoice?.createdAt,
            updatedAt: invoice?.updatedAt,
            extraInfo: invoice?.extraInfo,

        }));

        setRows(dataGridRows);
    }, [invoices]);

    const columns = useMemo(
        () => [
            {
                field: "id",
                headerName: i18n.t("translation.variables.table.id"),
                flex: 0.3,
                minWidth: 80,
                description: i18n.t("translation.invoices.table.descriptions.id"),
                renderCell: (params) => (params.row?.extraInfo[params.row?.provider]?.invoice?.id || "-"),
            },
            {
                field: "plan",
                headerName: i18n.t("translation.variables.table.plan"),
                flex: 0.3,
                minWidth: 80,
                description: i18n.t("translation.invoices.table.descriptions.plan"),
                renderCell: (params) => (
                    <Tooltip arrow title={params.row?.planDescription}>
                        <div>
                            {params.row?.planName}
                        </div>
                    </Tooltip>
                ),
            },
            {
                field: "status",
                headerName: i18n.t("translation.variables.table.status.title"),
                flex: 0.1,
                minWidth: 40,
                description: i18n.t("translation.invoices.table.descriptions.status"),
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    <div className={classes.status}>
                        {params.row.status === "draft" && (
                            <Chip
                                size="small"
                                icon={<EditOutlined />}
                                label={i18n.t("translation.invoices.table.status.draft")}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "open" && (
                            <Chip
                                size="small"
                                icon={<MonetizationOnOutlined />}
                                label={i18n.t("translation.invoices.table.status.open")}
                                color={params.row.dueDateAt >= new Date() ? "primary" : "error"}
                                variant="outlined"

                            />
                        )}
                        {params.row.status === "paid" && (
                            <Chip
                                size="small"
                                icon={<CheckCircleOutlined />}
                                label={i18n.t("translation.invoices.table.status.paid")}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "void" && (
                            <Chip
                                size="small"
                                icon={<Block />}
                                label={i18n.t("translation.invoices.table.status.void")}
                                color="error"
                                variant="outlined"
                            />
                        )}
                        {params.row.status === "uncollectible" && (
                            <Chip
                                size="small"
                                icon={<Block />}
                                label={i18n.t("translation.invoices.table.status.uncollectible")}
                                color="error"
                                variant="outlined"
                            />
                        )}
                    </div>
                )
            },
            {
                field: "provider",
                headerName: i18n.t("translation.variables.table.provider"),
                flex: 0.2,
                minWidth: 40,
                headerAlign: "center",
                align: "center",
                description: i18n.t("translation.invoices.table.descriptions.provider"),
            },
            {
                field: "subtotal",
                headerName: i18n.t("translation.variables.table.subtotal"),
                flex: 0.1,
                minWidth: 40,
                description: i18n.t("translation.invoices.table.descriptions.subtotal"),
                renderCell: (params) => new Intl.NumberFormat(
                    i18n.t("translation.validation.locale"),
                    { style: "currency", currency: params.row.currency }
                ).format(params.row.subtotal)
            },
            {
                field: "total",
                headerName: i18n.t("translation.variables.table.total"),
                flex: 0.1,
                minWidth: 40,
                description: i18n.t("translation.invoices.table.descriptions.total"),
                renderCell: (params) => new Intl.NumberFormat(
                    i18n.t("translation.validation.locale"),
                    { style: "currency", currency: params.row.currency }
                ).format(params.row.total)
            },
            {
                field: "dueDateAt",
                headerName: i18n.t("translation.variables.table.dueDate"),
                flex: 0.2,
                minWidth: 40,
                type: "date",
                headerAlign: "center",
                align: "center",
                renderCell: (params) =>
                    params.row.dueDateAt
                        ? new Date(params.row.dueDateAt).toLocaleString(
                            i18n.t("translation.validation.locale"),
                            { dateStyle: "short" }
                        )
                        : "-",
            },
            {
                field: "updatedAt",
                headerName: i18n.t("translation.variables.table.updatedDate"),
                flex: 0.2,
                minWidth: 40,
                type: "date",
                headerAlign: "center",
                align: "center",
                renderCell: (params) =>
                    new Date(params.row.createdAt).toLocaleString(
                        i18n.t("translation.validation.locale"),
                        { dateStyle: "short" }
                    ),
            },
            {
                field: "actions",
                headerName: i18n.t("translation.variables.table.actions"),
                flex: 0.2,
                minWidth: 40,
                sortable: false,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    // params.row.owner?.id === user.id || showAll ? (
                    <>
                        <IconButton
                            size="small"
                            className={classes.actionButtons}
                            color="primary"
                            onClick={() => window.open(
                                `${params.row?.extraInfo[params.row?.provider]?.invoice?.url}`,
                                "_blank"
                            )}
                            disabled={!params.row?.extraInfo[params.row?.provider]?.invoice?.url}
                        >
                            <OpenInNewRounded />
                        </IconButton>
                        <IconButton
                            size="small"
                            className={classes.actionButtons}
                            color="primary"
                            onClick={() => window.open(
                                `${params.row?.extraInfo[params.row?.provider]?.invoice?.pdf}`,
                                "_blank"
                            )}
                            disabled={!params.row?.extraInfo[params.row?.provider]?.invoice?.pdf}
                        >
                            <PrintOutlined />
                        </IconButton>
                    </>
                )
            }
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [invoices]
    );

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchInvoices = async () => {
            try {
                const params = { pageNumber, showAll };
                const { data } = await axiosPrivate.get(`/${tenantId}/invoices`, { params });
                if (isMounted) {
                    dispatch({ type: "LOAD_INVOICES", payload: data.invoices });
                    setHasMore(data.hasMore);
                    setLoading(false);
                }
            } catch (err) {
                isMounted &&
                    toastError(err, "", "Error while trying to pull invoices on page.");
                setLoading(false);
            }
        };
        fetchInvoices();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, showAll, tenantId]);

    useEffect(() => {
        socket.on(`invoice-update`, (data) => {
            if (
                data.action === "update" ||
                data.action === "create" ||
                data.action === "disable"
            ) {
                dispatch({ type: "UPDATE_INVOICES", payload: data.Invoice });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_INVOICES",
                    payload: data.invoiceId,
                });
            }
        });

        return () => {
            const keys = [
                `invoice-update`
            ]

            //Disconnect from all IO
            for (const key of keys) {
                socket.off(key)
            }
        };
    }, [socket, tenantId]);

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.invoices.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        // TODO: add open invoices notification/alert
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/${tenant.slug}/subscriptions`)}
                        className={classes.formControl}
                    >
                        {i18n.t("translation.account.buttons.subscriptions")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid
                            item
                            sx={{ width: "100%" }}
                        >
                            {rows && (
                                <DataGrid
                                    className={classes.gridPaper}
                                    rows={rows}
                                    columns={columns}
                                    autoHeight={true}
                                    getRowHeight={() => "auto"}
                                    pageSize={20}
                                    // onPageChange={() => logger({type: "info", content: "TODO: get extra info from server"})}
                                    getRowId={(row) => row.id}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                />
                            )}
                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default Invoices;
