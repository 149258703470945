import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    multiFieldLine: {
        display: "flex",
        justifyContent: "space-between",
        columnGap: theme.spacing(1),
        width: "100%",
    },
    multiFieldLineLeft: {
        display: "flex",
        justifyContent: "flex-start",
        columnGap: theme.spacing(1),
        width: "100%",
    }
}));

export default function MultiFieldLine({ children, left, props }) {
    const classes = useStyles();

    return (
        <div
            className={left
                ? classes.multiFieldLineLeft
                : classes.multiFieldLine
            }
            {...props}
        >
            {children}
        </div>
    );
}
