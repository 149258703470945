import React, { useEffect, useState } from "react";

import {
    Avatar,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// import { Field } from "formik";
import { useNavigate } from "react-router-dom";
import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import avatar from "../../services/avatar";
import logger from "../../services/logger";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    container: {
        height: "calc(100vh)",
        padding: theme.spacing(3),
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        zIndex: 1
    },
    changelog: {
        maxWidth: 440,
        padding: theme.spacing(4),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    img: {
        margin: theme.spacing(4, 1),
        padding: theme.spacing(0, 0, 4),
        width: 195,
        borderBottom: "1px solid #cecece",
    },
    paper: {
        padding: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 25,
        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
        maxWidth: 440,
        width: "100%"
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    tenantSelector: {
        maxHeight: theme.spacing(22),
        marginTop: theme.spacing(4),
        width: "70%",
        overflowY: "auto",
        ...theme.scrollbarStyles,
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
}));

const SelectTenants = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [tenantList, setTenantList] = useState([])

    useEffect(() => {
        if (JSON.stringify(user) === '{}') {
            navigate("/login", { replace: true })
            toastError(i18n.t("translation.tenant.toasts.noUser"), "", "No user identified on tenants page.")
        }

        // Check if there are any valid tenants available
        const validTenants = user?.userTenant?.filter((tenant) => tenant.status === true);
        validTenants && setTenantList(validTenants)

        // If only one tenant, log in
        if (validTenants?.length === 1) {
            logger({ type: "info", title: "Auth Manager", content: `Only one tenant available for this user, logging in.` })
            navigate(`/${validTenants[0].tenant.slug}${loggedInPage(user.userTenant[0].role)}`);
        }
    }, [user, navigate])

    const loggedInPage = (role) => {
        switch (role) {
            case "super":
                return "/super";

            case "admin":
            case "dashboard":
            case "finance":
                return "/";

            default:
                return "/tickets";
        }
    };

    return tenantList.length > 0
        ? <>
            <Typography component="h1" variant="h5">
                {i18n.t("translation.tenant.title")}
            </Typography>
            {tenantList && (
                <div className={classes.tenantSelector}>
                    {tenantList.map((userTenant) => (
                        <MenuItem
                            onClick={() => navigate(`/${userTenant.tenant.slug}${loggedInPage(userTenant.role)}`)}
                            key={userTenant.tenantId}
                            sx={{ minWidth: "160px" }}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    src={avatar(userTenant.tenant.email, "IconHorse")}
                                    alt={userTenant.tenant.name}
                                    variant="rounded"
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography noWrap>
                                        {userTenant.tenant.name}
                                    </Typography>
                                }
                                secondary={i18n.t(`translation.userModal.form.profile.role.${userTenant.role}`)}
                            />
                        </MenuItem>
                    ))}
                </div>
            )}
        </>
        : <Typography component="h1" variant="h5">
            {i18n.t("translation.tenant.noTenants")}
        </Typography>
};

export default SelectTenants;
