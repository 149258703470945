import { useEffect } from "react";
import { axiosPrivate } from "../../services/axios";

import logger from "../../services/logger";
import useAuth from "../useAuth";
import useRefreshToken from "../useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { token } = useAuth();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            }, (err) => Promise.reject(err)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (err) => {
                const prevRequest = err?.config;
                if (err?.response?.status === 401) {
                    logger({ type: "error_info", title: "Axios", content: err.message })
                }
                if (err?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(err);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };

    }, [token, refresh]);

    return axiosPrivate;
}

export default useAxiosPrivate;
