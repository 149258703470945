const MaskData = (type, data) => {
    let content

    if (type === "phone") {
        // Check if phone is still using national format
        if (data?.charAt(0) === "(") {
            const regex = /([0-9]{4,5})/i;
            const maskLength = data?.match(regex)[0]?.length;

            // Return masked contetn
            content = data?.replace(regex, '*'.repeat(maskLength))
        } else if (data?.charAt(0) === "+") {
            // Split by spaces or dashes
            const blocks = data.split(/[ -]/);
            const blocksLength = blocks.length;

            // Check what kind of phone format is being passed
            const type = blocksLength === 2
                ? blocks[1].length > 6
                    ? "Type 5"
                    : "Type 1"
                : blocksLength === 3
                    ? "Type 2"
                    : blocksLength === 4
                        ? "Type 3"
                        : "Type 4"

            // Match any kind of phone number in the world
            const regex = {
                "Type 1": /^(\+\d{1,3})[- ](\d{3,6})$/,
                "Type 2": /^(\+\d{1,3})[- ](\d{1,5})[- ](\d{2,6})$/,
                "Type 3": /^(\+\d{1,3})[- ](\S{1,6})[- ](\d{2,5})[- ](\d{2,5})$/,
                "Type 4": /^(\+\d{1,4})[- ](\S{1,5})[- ](\S{2,3})[- ](\S{2})[- ](\d{2,3})$/,
                "Type 5": /^(\+\d{1,3})[- ](\d{7,120})$/,
            }

            // Check what matchers are going to be replaced
            const modifier = {
                "Type 1": [2],
                "Type 2": [2],
                "Type 3": [3],
                "Type 4": [3, 4],
                "Type 5": [2],
            }

            // Match what is going to be replaced
            const matcher = data?.match(regex[type])

            let mask = matcher[modifier[type][0]]
            let masked

            // Replace according to the type of phone
            switch (type) {
                case 'Type 1':
                    masked = data?.replace(mask.slice(0, mask.length - 2), '*'.repeat(mask.length - 2))
                    break;

                case 'Type 4':
                    masked = data?.replace(matcher[3], '*'.repeat(matcher[3].length))?.replace(matcher[4], '*'.repeat(matcher[4].length))
                    break;

                case 'Type 5':
                    let maskLength = Math.floor(mask.length / 2)
                    masked = data.replace(mask.slice(0, maskLength), '*'.repeat(maskLength))
                    break;

                default:
                    masked = data?.replace(mask, '*'.repeat(mask.length))
            }

            // Return masked content
            content = masked;
        } else {
            // if first char is not legacy nor international code, return the current data
            content = data
        }
    }

    if (type === "email") {
        const regex = /.*(?=@)/;
        const maskLength = data?.match(regex)[0]?.length - 4;
        content = data?.replace(data?.substring(2, maskLength + 2), '*'.repeat(maskLength));
    }

    return content
};

export default MaskData;
