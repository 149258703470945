import { axiosPrivate } from "../../services/axios";
import logger from "../../services/logger";
import useAuth from "../useAuth";
// import { useLocalStorage } from "../useLocalStorage";

const useRefreshToken = () => {
    const { setToken, setUser } = useAuth();
    // const [, setSocketToken] = useLocalStorage("token", null, true)

    const refresh = async () => {
        try {
            const { data } = await axiosPrivate.post("/auth/refresh_token");

            // Set token data
            // setSocketToken(data.token)
            localStorage.setItem("token", data.token)
            setToken(data.token);

            // Set user data
            setUser(data.user);

            return data.token
        } catch (err) {
            logger({ type: "error_info", title: "Token", content: err.message })

            // Set variables
            const url = err?.config?.url;
            const status = err?.response?.status;

            // Check if it's unrecoverable, then remove auth.
            if (url === "/auth/refresh_token" && status === 401) {
                setToken(null)
                setUser({})
            }
        }
    }

    return refresh;
}

export default useRefreshToken;
