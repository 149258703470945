import React from "react";

import {
    ArrowBackIos,
} from "@mui/icons-material";
import { Button, Card } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { useNavigate } from "react-router-dom";
import useTenant from "../../hooks/useTenant";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles((theme) => ({
    backArrow: {
        [theme.breakpoints.down('md')]: {
            padding: "6px 0px 6px 20px",
            minWidth: "unset",
            float: "left",
        },
    },
    ticketHeader: {
        display: "flex",
        backgroundColor: "#f0f2f5",
        flex: "none",
        borderBottom: "1px solid rgba(0, 0, 0, 0.02)",
        [theme.breakpoints.down('md')]: {
            // flexWrap: "wrap",
        },
    },
}));

const TicketHeader = ({ loading, children, destination }) => {
    const classes = useStyles();
    const { tenant } = useTenant();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(destination || `/${tenant.slug}/tickets`);
    };

    return (
        <>
            {loading ? (
                <TicketHeaderSkeleton />
            ) : (
                <Card square className={classes.ticketHeader}>
                    <Button color="primary" onClick={handleBack} className={classes.backArrow}>
                        <ArrowBackIos />
                    </Button>
                    {children}
                </Card>
            )}
        </>
    );
};

export default TicketHeader;
