import toastError from "../../../errors/toastError";

const SocketEmiter = (socket, event, data) => {
    // const { socket } = useSocket();
    // const sendMessage = useCallback((socket, event, data) => {

    // Check socket
    if (!socket) {
        return toastError("ERR_SOCKET_NOT_AVAILABLE", "", "While trying to emit event to Socket.IO server.");
    }

    // Check event data
    if (!data || !event) {
        return toastError("ERR_SOCKET_DATA_UNUSABLE", "", "While trying to emit event data to Socket.IO server.");
    }

    // Emit event to backend
    socket.emit(String(event), data)
    // }, [])

    // // Run action
    // return sendMessage(socket, event, data);
}

export default SocketEmiter
