// Helper function to transform color to RGB
function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}

// Helper function to transform color to HEX
function rgbToHex(r, g, b) {
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

const colorPalette = (color, variations) => {
    if (!color) return;
    if (!variations) variations = 1;

    // Create color theme
    let theme = [color]

    // Convrets main color to RGB to easily calculate variations
    const colorRgb = hexToRgb(color);
    const stepFactor = { r: 0.0055, g: 0.019, b: 0.725 };
    const stepSize = Math.floor(255 / variations);

    // Generate the variations
    for (let i = 1; i <= variations; i++) {
        const colorVariant = rgbToHex(
            Math.max(Math.min(colorRgb.r + i * Math.floor(stepSize * stepFactor.r), 255), 0),
            Math.max(Math.min(colorRgb.g + i * Math.floor(stepSize * stepFactor.g), 255), 0),
            Math.max(Math.min(colorRgb.b + i * Math.floor(stepSize * stepFactor.b), 255), 0),
        );
        theme.push(colorVariant);
    }

    return theme
}

export default colorPalette;
