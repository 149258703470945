import { useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import makeStyles from '@mui/styles/makeStyles';

import { format, parseISO, startOfHour } from "date-fns";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";

import { i18n } from "../../../translate/i18n";

import Title from "../../../components/LayoutComponents/CardTitle";
import useTickets from "../../../hooks/useTickets";

const useStyles = makeStyles(theme => ({
    labelLeft: {
        textAnchor: "middle",
        fill: theme.palette.text.primary,
        maxWidth: "15px"
    }
}))

const Chart = () => {
    const theme = useTheme();
    const classes = useStyles()

    const date = useRef(new Date().toISOString());
    const { tickets, loading, count } = useTickets({ date: date.current, showAll: true });

    const [chartData, setChartData] = useState([
        { time: "00:00", amount: 0 },
        { time: "01:00", amount: 0 },
        { time: "02:00", amount: 0 },
        { time: "03:00", amount: 0 },
        { time: "04:00", amount: 0 },
        { time: "05:00", amount: 0 },
        { time: "06:00", amount: 0 },
        { time: "07:00", amount: 0 },
        { time: "08:00", amount: 0 },
        { time: "09:00", amount: 0 },
        { time: "10:00", amount: 0 },
        { time: "11:00", amount: 0 },
        { time: "12:00", amount: 0 },
        { time: "13:00", amount: 0 },
        { time: "14:00", amount: 0 },
        { time: "15:00", amount: 0 },
        { time: "16:00", amount: 0 },
        { time: "17:00", amount: 0 },
        { time: "18:00", amount: 0 },
        { time: "19:00", amount: 0 },
        { time: "20:00", amount: 0 },
        { time: "21:00", amount: 0 },
        { time: "22:00", amount: 0 },
        { time: "23:00", amount: 0 }
    ]);

    useEffect(() => {
        if (loading || tickets.length === 0) return;

        setChartData(prevState => {
            let aux = [...prevState];

            aux.forEach(a => {
                tickets.forEach(ticket => {
                    format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
                        a.amount++;
                });
            });

            return aux;
        });
    }, [tickets, loading]);

    return (
        <>
            <Title>
                {`${i18n.t("translation.dashboard.charts.perDay.title")}${count}`}
            </Title>
            <ResponsiveContainer>
                <BarChart
                    data={chartData}
                    barSize={40}
                    width={730}
                    height={250}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
                    <YAxis
                        type="number"
                        allowDecimals={false}
                        stroke={theme.palette.text.secondary}
                    >
                        <Label
                            angle={270}
                            position="left"
                            className={classes.labelLeft}
                        >
                            {i18n.t("translation.dashboard.charts.perDay.tickets")}
                        </Label>
                    </YAxis>
                    <Bar dataKey="amount" fill={theme.palette.primary.main} />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default Chart;
