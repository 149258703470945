import { Field, Form, Formik } from "formik";
import React, { useState } from "react";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import usePlan from "../../hooks/usePlan";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	btnWrapper: {
		position: "100%",
	},

	buttonProgress: {
		color: theme.palette.secondary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
	formControl: {
		// margin: theme.spacing(1),
		minWidth: 120,
	},
	colorGreen: {
		color: theme.palette.secondary.main
	},
}));

const GPTModal = (props) => {
	const classes = useStyles();
	const { tenantId } = useTenant();
	const { getPlanValue } = usePlan();
	const [loading, setLoading] = useState(false);

	const initialState = {
		prompt: props.prompt,
	};

	const axiosPrivate = useAxiosPrivate();

	const handleSaveMessage = async values => {
		const GPTData = { ...values };
		let limit = getPlanValue("gptLimit");

		if (tenantId) {
			const { data } = await axiosPrivate.get(`/${tenantId}/gpt/limit`);
			let usedCredits = data[0].count;

			if (limit > usedCredits) {
				if (props.field) {
					try {
						toast.success(i18n.t("translation.gptModal.success"));
						const { data } = await axiosPrivate.post(`/${tenantId}/gpt`, GPTData);
						if (props.field) {
							let content = data.response;
							if (props.setFieldValue) {
								props.setFieldValue(props.field.name, props.field.value += " " + content);
							} else {
								props.field.value += " " + content;
							}
						}
					} catch (err) {
						toastError(err);
					}
				} else {
					toastError(i18n.t("translation.gptModal.form.fieldError"));
				}
			} else {
				toastError(i18n.t("translation.gptModal.form.limitError", { limit: limit }));
			};
		}

		handleClose();
	};

	const handleClose = () => {
		props.onClose();
		setLoading(false)
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{i18n.t("translation.gptModal.title")}
				</DialogTitle>
				<Formik
					initialValues={initialState}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setLoading(true)
						setTimeout(() => {
							handleSaveMessage(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("translation.gptModal.form.prompt")}
									autoFocus
									name="prompt"
									variant="outlined"
									size="small"
									multiline
									minRows={5}
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									className={classes.buttonColorError}
									disabled={isSubmitting || loading}
									variant="outlined"
								>
									{i18n.t("translation.validation.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting || loading}
									variant="contained"
									className={classes.btnWrapper}
								>
									{i18n.t("translation.validation.buttons.generate")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default GPTModal;
