import { toast } from "react-toastify";
import logger from "../services/logger";
import { i18n } from "../translate/i18n";

const toastError = (err, info, context) => {
    let errorMsg = err.response?.data?.error?.message || err.response?.data?.message || err.response?.data?.error || err?.message || err;
    let errorVars;

    // If the error message is derived from canceling, stop
    if (errorMsg === "canceled") return;

    // If the message error is related to network issues, set this
    if (err?.code === "ERR_NETWORK") {
        errorMsg = "ERR_NO_NETWORK"
    }

    // If the error message is a String, check for vars
    if (typeof errorMsg === "string" && errorMsg?.split("|vars:")?.length > 1) {
        errorVars = JSON.parse(errorMsg.split("|vars:")[1]);
        errorMsg = errorMsg.split("|vars:")[0];
    }

    // Set the type of toast, error or info
    const msgType = (message) => {
        if (!info) {
            toast.error(message)
        } else {
            toast.info(message)
        }
    }

    // If errorMsg exists, deal it
    if (errorMsg) {
        if (i18n.exists(`backendErrors.${errorMsg}`)) {
            msgType(i18n.t(`backendErrors.${errorMsg}`, errorVars), {
                toastId: errorMsg,
            });
        } else {
            msgType(errorMsg, {
                toastId: errorMsg,
            });
        }
    } else if (!err?.response) {
        if (context) {
            logger({ type: "error_info", title: "Error Message Context", content: context })
        }
        msgType(i18n.t("translation.backendErrors.ERR_NO_SERVER_RESPONSE"));
    } else {
        if (context) {
            logger({ type: "error_info", title: "Error Message Context", content: context })
        }
        msgType(i18n.t("translation.validation.genericError"));
    }
};

export default toastError;
