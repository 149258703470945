import React, { useEffect, useRef, useState } from "react";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Colorize } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import appVars from "../../../package.json";
import BotSelect from "../../components/BotSelect";
import ColorPicker from "../../components/ColorPicker";
import ConnectionItems from "../../components/ConnectionItems";
import Divider from "../../components/Divider";
import MultiFieldLine from "../../components/FormComponents/MultiFieldLine";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSettings from "../../hooks/useSettings";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            // maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    container: {
        padding: theme.spacing(2, 3),
    },
    mainPaper: {
        margin: "0 auto",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minWidth: "600px",
        },
    },
    title: {
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    titleH3: {
        fontSize: "1rem",
        fontWeight: "500",
        width: "100%",
    },
    textCampaignsContainer: {
        width: "100%",
    },
    content: {
        padding: "0 24px 16px",
    },
    formActions: {
        flex: "0 0 auto",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    option: {
        display: "inline-flex",
        width: "100%",
        margin: "8px 0",
    },
    widgetLabel: {
        padding: "5px 0",
    },
    widgetOption: {
        marginLeft: "auto",
        minWidth: "128px",
    },
    widgetTextField: {
        margin: "0 0 0 auto",
        maxWidth: "109px",
    },
    textField: {
        // marginRight: theme.spacing(1),
        flex: 1,
    },

    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
        marginBottom: theme.spacing(2),
    },

    btnWrapper: {
        position: "relative",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    colorAdorment: {
        width: 20,
        height: 20,
        borderRadius: 4
    },
    alert: {
        color: "red"
    },
    marginTop: {
        width: "100%",
        marginBottom: theme.spacing(2)
    }
}));

const WidgetSchema = Yup.object().shape({
    color: Yup.string()
        .min(4, `${i18n.t("translation.validation.too_short")}`)
        .max(7, `${i18n.t("translation.validation.too_long")}`),
    // .required(`${i18n.t("translation.validation.required")}`)
    text: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(150, `${i18n.t("translation.validation.too_long")}`),
    number: Yup.number()
        .integer(`${i18n.t("translation.validation.invalid_number")}`)
        .default(0),
    whatsapp: Yup.number()
        .integer(`${i18n.t("translation.validation.invalid_number")}`)
    // .required(`${i18n.t("translation.validation.required")}`),
});

const Widget = ({ initialValues }) => {
    const classes = useStyles();

    const { getSettingValue } = useSettings();
    const { tenant, tenantId } = useTenant();
    const { whatsApps, defaultWhatsApp } = useWhatsApps();

    const initialState = {
        actionOpenTicket: "0",
        actionBot: "",
        actionQueue: "",
        actionWhatsappNumber: "",
        clientCtaDisplay: i18n.t("translation.widgetModal.default.clientCtaDisplay", { number: defaultWhatsApp?.number || "..." }),
        clientCtaLink: `tel:${defaultWhatsApp?.number || "..."}`,
        clientMessage: i18n.t("translation.widgetModal.default.clientMessage"),
        clientTitle: i18n.t("translation.widgetModal.default.clientTitle"),
        clientWhatsappMessage: i18n.t("translation.widgetModal.default.clientWhatsappMessage"),
        formEmail: "0",
        formMessage: "0",
        formName: "1",
        formOpen: "5000",
        formPhone: "1",
        themeButton: "#44ca6c",
        themeColor: "#0d122e",
        themeIcon: "bits",
        themeNotification: "#ed0000",
        title: "0"
    };

    const [widgets, setWidgets] = useState(initialState);
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
    const [scriptGen, setScriptGen] = useState('');
    const ref = useRef();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchWidget = async () => {
            if (initialValues) {
                isMounted && setWidgets(prevState => ({ ...prevState, ...initialState, ...initialValues }));
            }

            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/widgets`);
                let storedValues;
                if (data && data?.length > 0) {
                    storedValues = data?.reduce((obj, item) => {
                        if (item.value) {
                            obj[item.key] = item.value;
                        }
                        return obj;
                    }, {});
                    isMounted && setWidgets(prevState => ({ ...prevState, ...initialState, ...storedValues }));
                }
            } catch (err) {
                toastError(err);
            }
        }
        if (tenantId) fetchWidget();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, tenantId]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setWidgets(values => ({ ...values, [name]: value }))
    }

    const handleSaveWidget = async (values) => {
        if (!tenantId) return;
        generateScript(values)
        try {
            for (let [widgetKey, widgetValue] of Object.entries(values)) {
                // let keyValue = widgetValue;
                // if(widgetKey === 'clientWhatsappNumber' && widgetValue === '') {
                //     keyValue = findDefaultWhatsApp()
                // }
                await axiosPrivate.put(`/${tenantId}/widgets/${widgetKey}`, {
                    value: widgetValue
                });
            }
            toast.success(i18n.t("translation.settings.toasts.success"));
        } catch (err) {
            toastError(err);
        }
    };

    const goToSupport = () => {
        window.open(`${appVars.support.link}?referral=${tenant.slug}`, "_blank");
    }

    const goToWidgetSetup = () => {
        window.open(appVars.support.widgetSetup, '_blank');
    }

    const getWhatsAppNumber = (id) => {
        const value = whatsApps?.find(s => s.id === Number(id))?.number?.replace(/\D/g, "");
        return value;
    };

    const generateScript = (values) => {
        let cdnURL = appVars.widgetURL;

        let clientCtaDisplay = values.clientCtaDisplay ? `ctaDisplay:"${values.clientCtaDisplay}"` : '';
        let clientCtaLink = values.clientCtaLink ? `ctaLink:"${values.clientCtaLink}"` : '';
        let clientMessage = values.clientMessage ? `message:"${values.clientMessage.replace(/^\s+|\s+$/g, '').replace(/\r?\n|\r/gm, '\\n')}"` : '';
        let clientTitle = values.clientTitle ? `title:"${values.clientTitle}"` : '';
        let clientWhatsappMessage = values.clientWhatsappMessage ? `whatsappMessage:"${values.clientWhatsappMessage.replace(/^\s+|\s+$/g, '').replace(/\r?\n|\r/gm, '\\n')}"` : '';

        let formEmail = values.formEmail && values.formEmail !== 'false' ? `email:${Boolean(values.formEmail)}` : '';
        let formMessage = values.formMessage && values.formMessage !== 'false' ? `message:${Boolean(values.formMessage)}` : '';
        let formName = values.formName && values.formName !== 'false' ? `name:${Boolean(values.formName)}` : '';
        let formOpen = values.formOpen ? `open:${Number(values.formOpen)}` : '';
        let formPhone = values.formPhone && values.formPhone !== 'false' ? `phone:${Boolean(values.formPhone)}` : '';

        let actionWhatsappNumber = values.actionWhatsappNumber ? `whatsappNumber:"${getWhatsAppNumber(values.actionWhatsappNumber)}"` : '';
        let actionOpenTicket = values.actionOpenTicket ? `openTicket:${Boolean(values.actionOpenTicket)}` : '';
        let actionQueue = values.actionQueue ? `queue:${Number(values.actionQueue)}` : '';
        let actionBot = values.actionBot ? `bot:${Number(values.actionBot)}` : '';

        let themeButton = values.themeButton && values.themeButton !== '#44ca6c' ? `button:"${values.themeButton.substring(1)}"` : '';
        let themeColor = values.themeColor && values.themeColor !== '#0d122e' ? `color:"${values.themeColor.substring(1)}"` : '';
        let themeIcon = values.themeIcon && values.themeIcon !== 'whatsapp' ? `icon:"${values.themeIcon}"` : '';
        let themeNotification = values.themeNotification && values.themeNotification !== '#ed0000' ? `notification:"${values.themeNotification.substring(1)}"` : '';
        let theme = themeButton === '' &&
            themeColor === '' &&
            themeIcon === '' &&
            themeNotification === '' ? '' : `\n            theme:{${[themeColor, themeButton, themeNotification, themeIcon].filter(Boolean).join(",")}},`

        let title = values.title && values.title !== 'false' ? `title:${Boolean(values.title)},` : '';

        let endpoint = `endpoint:"api"`;
        let token = `token:"${getSettingValue("userApiToken")}"`;

        let baseScript =
            `<!-- Start Bits Chat -->
<script>
    (function (d, s, id) {
    var js,
    el = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return}js = d.createElement(s);js.id = id;
    js.src = "${cdnURL}";
    el.parentNode.insertBefore(js, el);
    js.onload = function () {
        var w=window.Bits({${title}
            client:{${[clientTitle, clientMessage, clientWhatsappMessage, clientCtaLink, clientCtaDisplay].filter(Boolean).join(",")}},${theme}
            form:{${[formName, formMessage, formEmail, formPhone, formOpen].filter(Boolean).join(",")}},
            action:{${[actionWhatsappNumber, actionOpenTicket, actionQueue, actionBot].filter(Boolean).join(",")}},
            auth:{${[token, endpoint].join(",")}}})
        };
    })(document, 'script', 'bits-js');
</script>
<!-- End Bits Chat -->`;
        setScriptGen(() => {
            return (baseScript)
        })
    }

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.widget.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={goToSupport}
                    >
                        {i18n.t("translation.widget.buttons.support")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.title}>
                            <h2 className={classes.titleH2}>{i18n.t("translation.widgetModal.title")}</h2>
                        </div>
                        <Typography variant="body1">
                            {i18n.t("translation.widgetModal.description")}
                        </Typography>

                        <Divider silent={true} />

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={goToWidgetSetup}
                        >
                            {i18n.t("translation.widgetModal.button")}
                        </Button>
                        <Divider />

                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Formik
                                    initialValues={widgets}
                                    enableReinitialize={true}
                                    validationSchema={WidgetSchema}
                                    onSubmit={(values, actions, e) => {
                                        setTimeout(() => {
                                            handleSaveWidget(values)
                                            actions.setSubmitting(false)
                                        }, 400);
                                    }}
                                >
                                    {({ touched, errors, isSubmitting, values }) => (
                                        <Form>

                                            <Grid item xs={12}>
                                                <Typography variant="body1" className={classes.marginTop}>
                                                    <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.client.title")}</span><br />
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControl}
                                                >
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.widgetModal.ui.client.input.title")}
                                                        name="clientTitle"
                                                        // id="clientTitle"
                                                        error={touched.clientTitle && Boolean(errors.text)}
                                                        helperText={touched.clientTitle && errors.text}
                                                        variant="outlined"
                                                        size="small"
                                                        onBlur={e => { handleChange(e) }}
                                                    />
                                                </FormControl>

                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("translation.widgetModal.ui.client.input.message")}
                                                    name="clientMessage"
                                                    multiline
                                                    minRows={3}
                                                    fullWidth
                                                    error={touched.clientMessage && Boolean(errors.text)}
                                                    helperText={touched.clientMessage && errors.text}
                                                    variant="outlined"
                                                    size="small"
                                                />

                                                <MultiFieldLine>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.widgetModal.ui.client.input.ctaDisplay")}
                                                            name="clientCtaDisplay"
                                                            id="clientCtaDisplay"
                                                            error={touched.clientCtaDisplay && Boolean(errors.text)}
                                                            helperText={touched.clientCtaDisplay && errors.text}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </FormControl>

                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.widgetModal.ui.client.input.ctaLink")}
                                                            name="clientCtaLink"
                                                            id="clientCtaLink"
                                                            error={touched.clientCtaLink && Boolean(errors.text)}
                                                            helperText={touched.clientCtaLink && errors.text}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                </MultiFieldLine>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider silent={true} />

                                                <Typography variant="body1" className={classes.marginTop}>
                                                    <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.theme.title")}</span>
                                                </Typography>

                                                <MultiFieldLine>
                                                    <ColorPicker
                                                        open={colorPickerModalOpen}
                                                        handleClose={() => setColorPickerModalOpen(false)}
                                                        onChange={color => {
                                                            if (ref.current) {
                                                                ref.current.value = color;
                                                                const key = ref.current.name
                                                                values[key] = color;
                                                                setWidgets(() => {
                                                                    return { ...values, [key]: color };
                                                                });
                                                            }
                                                        }
                                                        }
                                                    />

                                                    <Grid item xs={3}>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.widgetModal.ui.theme.input.color")}
                                                            name="themeColor"
                                                            id="themeColor"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                ref.current = event.target;
                                                                setColorPickerModalOpen(true);
                                                            }}
                                                            error={touched.themeColor && Boolean(errors.color)}
                                                            helperText={touched.themeColor && errors.color}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div
                                                                            style={{ backgroundColor: values.themeColor ? values.themeColor : '#0d122e' }}
                                                                            className={classes.colorAdorment}
                                                                        ></div>
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <IconButton
                                                                        size="small"
                                                                        color="default"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            ref.current = document.querySelector("#themeColor");
                                                                            setColorPickerModalOpen(true);
                                                                        }}
                                                                    >
                                                                        <Colorize />
                                                                    </IconButton>
                                                                ),
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.widgetModal.ui.theme.input.button")}
                                                            name="themeButton"
                                                            id="themeButton"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                ref.current = event.target;
                                                                setColorPickerModalOpen(true);
                                                            }}
                                                            error={touched.themeButton && Boolean(errors.color)}
                                                            helperText={touched.themeButton && errors.color}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div
                                                                            style={{ backgroundColor: values.themeButton ? values.themeButton : '#44ca6c' }}
                                                                            className={classes.colorAdorment}
                                                                        ></div>
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <IconButton
                                                                        size="small"
                                                                        color="default"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            ref.current = document.querySelector("#themeButton");
                                                                            setColorPickerModalOpen(true);
                                                                        }}
                                                                    >
                                                                        <Colorize />
                                                                    </IconButton>
                                                                ),
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.widgetModal.ui.theme.input.notification")}
                                                            name="themeNotification"
                                                            id="themeNotification"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                ref.current = event.target;
                                                                setColorPickerModalOpen(true);
                                                            }}
                                                            error={touched.themeNotification && Boolean(errors.color)}
                                                            helperText={touched.themeNotification && errors.color}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div
                                                                            style={{ backgroundColor: values.themeNotification ? values.themeNotification : '#ed0000' }}
                                                                            className={classes.colorAdorment}
                                                                        ></div>
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <IconButton
                                                                        size="small"
                                                                        color="default"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            ref.current = document.querySelector("#themeNotification");
                                                                            setColorPickerModalOpen(true);
                                                                        }}
                                                                    >
                                                                        <Colorize />
                                                                    </IconButton>
                                                                ),
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <FormControl
                                                            variant="outlined"
                                                            className={classes.formControl}
                                                            size="small"
                                                        >
                                                            <InputLabel id="themeIcon-selection-input-label">
                                                                {i18n.t("translation.widgetModal.ui.theme.input.icon.title")}
                                                            </InputLabel>

                                                            <Field
                                                                as={Select}
                                                                label={i18n.t("translation.widgetModal.ui.theme.input.icon.title")}
                                                                labelId="themeIcon-selection-label"
                                                                name="themeIcon"
                                                                id="themeIcon"
                                                            >
                                                                <MenuItem value="bits">{i18n.t("translation.widgetModal.ui.theme.input.icon.options.bits")}</MenuItem>
                                                                <MenuItem value="chat">{i18n.t("translation.widgetModal.ui.theme.input.icon.options.chat")}</MenuItem>
                                                                <MenuItem value="whatsapp">{i18n.t("translation.widgetModal.ui.theme.input.icon.options.whatsapp")}</MenuItem>
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                </MultiFieldLine>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider silent={true} />

                                                <Typography variant="body1" className={classes.marginTop}>
                                                    <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.documentTitle.title")}</span><br />
                                                    <span className={classes.p}>{i18n.t("translation.widgetModal.ui.documentTitle.description")}</span>
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControl}
                                                    size="small"
                                                >
                                                    <InputLabel id="title-selection-input-label">
                                                        {i18n.t("translation.widgetModal.ui.documentTitle.input.title")}
                                                    </InputLabel>

                                                    <Field
                                                        as={Select}
                                                        label={i18n.t("translation.widgetModal.ui.documentTitle.input.title")}
                                                        labelId="title-selection-label"
                                                        name="title"
                                                        id="title"
                                                    >
                                                        <MenuItem value="1">{i18n.t("translation.widgetModal.ui.documentTitle.input.options.true")}</MenuItem>
                                                        <MenuItem value="0">{i18n.t("translation.widgetModal.ui.documentTitle.input.options.false")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider silent={true} />

                                                <Typography variant="body1" className={classes.marginTop}>
                                                    <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.form.title")}</span><br />
                                                    {/* <span
                                                        className={classes.alert}
                                                    // style={{display:
                                                    //     values.formName === 1 ||
                                                    //     values.formEmail === 1 ||
                                                    //     values.formMessage === 1 ||
                                                    //     values.formPhone === 1
                                                    //     ? 'block' : 'none'
                                                    // }}
                                                    >{i18n.t("translation.widgetModal.ui.form.alert")}</span> */}
                                                </Typography>

                                            </Grid>

                                            <MultiFieldLine>
                                                <Grid item xs={3}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                    >
                                                        <InputLabel id="formName-selection-input-label">
                                                            {i18n.t("translation.widgetModal.ui.form.input.name")}
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.widgetModal.ui.form.input.name")}
                                                            labelId="formName-selection-label"
                                                            name="formName"
                                                            id="formName"
                                                        >
                                                            <MenuItem value="1">{i18n.t("translation.validation.show.true")}</MenuItem>
                                                            <MenuItem value="0">{i18n.t("translation.validation.show.false")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                    >
                                                        <InputLabel id="formEmail-selection-input-label">
                                                            {i18n.t("translation.widgetModal.ui.form.input.email")}
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.widgetModal.ui.form.input.email")}
                                                            labelId="formEmail-selection-label"
                                                            name="formEmail"
                                                            id="formEmail"
                                                        >
                                                            <MenuItem value="1">{i18n.t("translation.validation.show.true")}</MenuItem>
                                                            <MenuItem value="0">{i18n.t("translation.validation.show.false")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                {/* </div>
                                        <MultiFieldLine> */}
                                                <Grid item xs={3}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                    >
                                                        <InputLabel id="formMessage-selection-input-label">
                                                            {i18n.t("translation.widgetModal.ui.form.input.message")}
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.widgetModal.ui.form.input.message")}
                                                            labelId="formMessage-selection-label"
                                                            name="formMessage"
                                                            id="formMessage"
                                                        >
                                                            <MenuItem value="1">{i18n.t("translation.validation.show.true")}</MenuItem>
                                                            <MenuItem value="0">{i18n.t("translation.validation.show.false")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                    >
                                                        <InputLabel id="formPhone-selection-input-label">
                                                            {i18n.t("translation.widgetModal.ui.form.input.phone")}
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.widgetModal.ui.form.input.phone")}
                                                            labelId="formPhone-selection-label"
                                                            name="formPhone"
                                                            id="formPhone"
                                                        >
                                                            <MenuItem value="1">{i18n.t("translation.validation.show.true")}</MenuItem>
                                                            <MenuItem value="0">{i18n.t("translation.validation.show.false")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                            </MultiFieldLine>
                                            <Grid item xs={12}>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("translation.widgetModal.ui.client.input.whatsappMessage")}
                                                    name="clientWhatsappMessage"
                                                    multiline
                                                    minRows={3}
                                                    fullWidth
                                                    error={
                                                        touched.clientWhatsappMessage && Boolean(errors.text)
                                                    }
                                                    helperText={
                                                        touched.clientWhatsappMessage && errors.text
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </Grid>

                                            <MultiFieldLine>
                                                <Grid item xs={9}>
                                                    <Typography variant="body1">
                                                        <span className={classes.p}>{i18n.t("translation.widgetModal.ui.form.input.open.description")}</span>
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                    >
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t("translation.widgetModal.ui.form.input.open.title")}
                                                            name="formOpen"
                                                            id="formOpen"
                                                            type="number"
                                                            error={touched.formOpen && Boolean(errors.number)}
                                                            helperText={touched.formOpen && errors.number}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </MultiFieldLine>

                                            <Grid item xs={12}>
                                                <Divider silent={true} />

                                                <Typography variant="body1" className={classes.marginTop}>
                                                    <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.actions.title")}</span><br />
                                                    <span className={classes.p}>{i18n.t("translation.widgetModal.ui.actions.description")}</span>
                                                </Typography>
                                            </Grid>

                                            <MultiFieldLine>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel>{i18n.t("translation.widgetModal.ui.client.input.whatsappNumber")}</InputLabel>
                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.widgetModal.ui.client.input.whatsappNumber")}
                                                            name="actionWhatsappNumber"
                                                            id="actionWhatsappNumber"
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            error={touched.actionWhatsappNumber && Boolean(errors.whatsapp)}
                                                            placeholder={i18n.t("translation.campaigns.form.id")}
                                                        >
                                                            {ConnectionItems(whatsApps) || ""}
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                    >
                                                        <InputLabel id="actionOpenTicket-selection-input-label">
                                                            {i18n.t("translation.widgetModal.ui.form.input.ticket")}
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.widgetModal.ui.form.input.ticket")}
                                                            labelId="actionOpenTicket"
                                                            name="actionOpenTicket"
                                                            id="actionOpenTicket"
                                                        >
                                                            <MenuItem value="1">{i18n.t("translation.validation.state.active")}</MenuItem>
                                                            <MenuItem value="0">{i18n.t("translation.validation.state.inactive")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                            </MultiFieldLine>

                                            <MultiFieldLine>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControl}
                                                >
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.widgetModal.ui.client.input.queues")}
                                                        // value={i18n.t("translation.widgetModal.ui.client.input.queues", { number: getWhatsAppNumber(values?.clientWhatsappNumber) || "..." })}
                                                        name="actionQueue"
                                                        id="actionQueue"
                                                        error={touched.actionQueue && Boolean(errors.text)}
                                                        helperText={touched.actionQueue && errors.text}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </FormControl>

                                                <BotSelect fieldId="actionBot" />
                                            </MultiFieldLine>

                                            <Divider silent={true} />

                                            <Button
                                                type="submit"
                                                color="primary"
                                                disabled={isSubmitting}
                                                variant="contained"
                                                className={classes.btnWrapper}
                                            >
                                                {i18n.t("translation.validation.buttons.okEdit")}
                                                {isSubmitting && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                )}
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>

                            <Grid item xs={1}></Grid>

                            <Grid item xs={5}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.code.title")}</span><br />
                                        <span className={classes.p}>{i18n.t("translation.widgetModal.ui.code.description")}</span>
                                    </Typography>
                                    <div className={classes.option}>
                                        <TextField
                                            readOnly
                                            style={{ whiteSpace: "pre-wrap" }}
                                            label={i18n.t("translation.widgetModal.ui.code.input.code")}
                                            size="small"
                                            variant="outlined"
                                            spellCheck="false"
                                            fullWidth
                                            multiline
                                            minRows={5}
                                            value={scriptGen}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <span className={classes.titleH3}>{i18n.t("translation.widgetModal.ui.code.class")}</span><br />
                                        <span className={classes.p}>{i18n.t("translation.widgetModal.ui.code.function")}</span>
                                    </Typography>
                                    <div className={classes.option}>
                                        <TextField
                                            readOnly
                                            label={i18n.t("translation.widgetModal.ui.code.input.class")}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            value='<div class="toggle-bits"> ... </div>'
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default Widget;
