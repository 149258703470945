import React, { useEffect, useRef, useState } from "react";

import {
    DeleteOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../ConfirmationModal";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1)
    },
}));

const ChatOptionsMenu = ({
    chat,
    menuOpen,
    handleClose,
    anchorEl
}) => {
    const classes = useStyles();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const isMounted = useRef(true);
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleDeleteChat = async () => {
        if (tenantId) {
            try {
                await axiosPrivate.delete(`/${tenantId}/chats/${chat.id}`);
            } catch (err) {
                toastError(err, "", "Error ocurred while trying to delete chat from the options menu.");
            }
        }
    };

    const handleOpenConfirmationModal = e => {
        setConfirmationOpen(true);
        handleClose();
    };

    return (
        <>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={handleOpenConfirmationModal}>
                    <DeleteOutlined color="error" size="small" className={classes.icon} />
                    <Typography color="error">{i18n.t("translation.ticketOptionsMenu.delete")}</Typography>
                </MenuItem>
            </Menu>
            <ConfirmationModal
                title={i18n.t("translation.chatOptionsMenu.confirmationModal.title", { chat: chat.title })}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteChat}
            >
                {i18n.t("translation.chatOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
        </>
    );
};

export default ChatOptionsMenu;
