import React from 'react';

import {
    CropFree,
    SignalCellular4BarRounded,
    SignalCellularConnectedNoInternet0BarRounded,
    SignalCellularConnectedNoInternet2BarRounded,
} from "@mui/icons-material";
import { CircularProgress } from '@mui/material';
import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import CustomTooltip from '../CustomTooltip';

const useStyles = makeStyles(theme => ({
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    colorGreen: {
        color: green[500]
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
    }
}))

const StatusTooltip = ({ whatsApp }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.customTableCell}>
                {whatsApp.status === "DISCONNECTED" && (
                    <CustomTooltip
                        title={i18n.t("translation.connections.toolTips.disconnected.title")}
                        content={i18n.t("translation.connections.toolTips.disconnected.content")}
                    >
                        <SignalCellularConnectedNoInternet0BarRounded color="secondary" />
                    </CustomTooltip>
                )}
                {whatsApp.status === "OPENING" && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
                {whatsApp.status === "qrcode" && (
                    <CustomTooltip
                        title={i18n.t("translation.connections.toolTips.qrcode.title")}
                        content={i18n.t("translation.connections.toolTips.qrcode.content")}
                    >
                        <CropFree />
                    </CustomTooltip>
                )}
                {whatsApp.status === "CONNECTED" && (
                    <CustomTooltip title={i18n.t("translation.connections.toolTips.connected.title")}>
                        <SignalCellular4BarRounded color="success" />
                    </CustomTooltip>
                )}
                {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
                    <CustomTooltip
                        title={i18n.t("translation.connections.toolTips.timeout.title")}
                        content={i18n.t("translation.connections.toolTips.timeout.content")}
                    >
                        <SignalCellularConnectedNoInternet2BarRounded color="secondary" />
                    </CustomTooltip>
                )}
            </div>
        </>
    );
};

export default StatusTooltip;
