import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { green } from "@mui/material/colors";

import makeStyles from "@mui/styles/makeStyles";

import Title from "../../components/LayoutComponents/Title/index.js";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import { toast } from "react-toastify";
import appVars from "../../../package.json";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n.js";

import Divider from "../../components/Divider/index.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSocketListener from "../../hooks/useSocket/Events/useSocketListener.js";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    accordion: {
        boxShadow: "none",
        padding: 0
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    container: {
        padding: "16px 24px",
    },
    content: {
        padding: "0 24px 16px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    formActions: {
        flex: "0 0 auto",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    mainPaper: {
        margin: "0 auto",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minWidth: "600px",
        },
    },
    noMargin: {
        margin: 0
    },
    noPadding: {
        padding: 0
    },
    option: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: theme.spacing(2, 0),
    },
    root: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    settingLabel: {
        padding: "5px 0",
    },
    settingOption: {
        marginLeft: "auto",
        minWidth: "128px",
        "& .MuiSelect-select": {
            width: "95px",
        },
    },
    settingTextField: {
        margin: "0 0 0 auto",
        maxWidth: "109px",
        "& .MuiInputBase-input": {
            width: "95px",
        },
    },
    title: {},
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    titleH3: {
        fontSize: "1rem",
        fontWeight: "500",
        width: "100%",
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    textCampaignsContainer: {
        width: "100%",
    },
}));

const Settings = () => {
    const { tenant, tenantId } = useTenant();

    const classes = useStyles();
    const [settings, setSettings] = useState([]);
    const [VCardMessage, setVCardMessage] = useState("");
    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchSession = async () => {
            try {
                const { data } = await axiosPrivate.get(
                    `/${tenantId}/settings`
                );
                isMounted && setSettings(data);
            } catch (err) {
                toastError(err);
                navigate("/login", {
                    state: { from: location },
                    replace: true,
                });
            }
        };

        if (tenantId) fetchSession();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    // Handle Settings Changes
    const handleSettingsChange = (data) => {
        if (data.action === "update") {
            setSettings((prevState) => {
                const aux = [...prevState];
                const settingIndex = aux.findIndex(
                    (s) => s.key === data.setting.key
                );
                aux[settingIndex].value = data.setting.value;
                return aux;
            });
        }
    };
    useSocketListener(`setting`, (data) => handleSettingsChange(data));

    const handleChangeSetting = async (e) => {
        if (!tenantId) return;
        const selectedValue = e.target.value;
        const settingKey = e.target.name;

        try {
            await axiosPrivate.put(`/${tenantId}/settings/${settingKey}`, {
                value: selectedValue,
            });
            toast.success(i18n.t("translation.settings.toasts.success"));
        } catch (err) {
            toastError(err);
        }
    };

    const getSettingValue = (key) => {
        const { value } = settings.find((s) => s.key === key);
        return value;
    };

    const goToSupport = () => {
        window.open(
            `${appVars.support.link}?referral=${tenant.slug}`,
            "_blank"
        );
    };

    function ChangeVCardMessage(e) {
        if (e.target.value !== getSettingValue("VCardMessage")) {
            setVCardMessage(e.target.value);
        }
    }

    async function SaveVCardMessage() {
        if (!tenantId) return;
        try {
            await axiosPrivate.put(`/${tenantId}/settings/VCardMessage`, {
                value: VCardMessage,
            });
            toast.success(i18n.t("translation.settings.toasts.success"));
        } catch (err) {
            toastError(err);
        }
    }

    return settings && settings.length > 0 && (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.settings.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={goToSupport}
                    // disabled
                    >
                        {i18n.t("translation.settings.support")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper className={classes.mainPaper} variant="outlined">
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.title}>
                            <h2 className={classes.titleH2}>
                                {i18n.t("translation.settings.settings.title")}
                            </h2>
                        </div>
                        <Divider />

                        <div className={classes.content}>
                            {/* Sidebar */}
                            <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t("translation.settings.settings.sideMenu.note")}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.sideMenu.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="sideMenu-setting"
                                    name="sideMenu"
                                    value={getSettingValue("sideMenu")}
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="enabled">
                                        {i18n.t("translation.validation.options.enabled")}
                                    </option>
                                    <option value="disabled">
                                        {i18n.t("translation.validation.options.disabled")}
                                    </option>
                                </Select>
                            </div>

                            {/* Message user on transfering tickets */}
                            <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t("translation.settings.settings.transferTicket.note")}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.transferTicket.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="transferTicket-setting"
                                    name="transferTicket"
                                    value={getSettingValue("transferTicket")}
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="enabled">
                                        {i18n.t("translation.validation.options.enabled")}
                                    </option>
                                    <option value="disabled">
                                        {i18n.t("translation.validation.options.disabled")}
                                    </option>
                                </Select>
                            </div>

                            {/* Campaigns */}
                            <div>
                                <Accordion className={classes.accordion}
                                    expanded={getSettingValue("campaignCreation") === "enabled"}
                                >
                                    <AccordionSummary className={[classes.noPadding, classes.noMargin].join(" ")}>
                                        {/* Campaign creation */}
                                        <Tooltip
                                            title={i18n.t("translation.settings.settings.campaignCreation.note")}
                                        >
                                            <Typography variant="body1">
                                                {i18n.t("translation.settings.settings.campaignCreation.name")}
                                            </Typography>
                                        </Tooltip>
                                        <Select
                                            size="small"
                                            variant="outlined"
                                            native
                                            id="campaignCreation-setting"
                                            name="campaignCreation"
                                            value={getSettingValue("campaignCreation")}
                                            className={classes.settingOption}
                                            onChange={handleChangeSetting}
                                        >
                                            <option value="enabled">
                                                {i18n.t("translation.validation.options.enabled")}
                                            </option>
                                            <option value="disabled">
                                                {i18n.t("translation.validation.options.disabled")}
                                            </option>
                                        </Select>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.noPadding}>
                                        {/* Send VCards */}
                                        <div className={classes.option}>
                                            <Tooltip
                                                title={i18n.t("translation.settings.settings.campaignCreation.sendVCard.note")}
                                            >
                                                <Typography variant="body1">
                                                    {i18n.t("translation.settings.settings.campaignCreation.sendVCard.title")}
                                                </Typography>
                                            </Tooltip>
                                            <Select
                                                size="small"
                                                variant="outlined"
                                                native
                                                id="sendVCard-setting"
                                                name="sendVCard"
                                                value={getSettingValue("sendVCard")}
                                                className={classes.settingOption}
                                                onChange={handleChangeSetting}
                                            >
                                                <option value="enabled">
                                                    {i18n.t("translation.validation.options.enabled")}
                                                </option>
                                                <option value="disabled">
                                                    {i18n.t("translation.validation.options.disabled")}
                                                </option>
                                            </Select>
                                        </div>

                                        {/* VCard message */}
                                        <div className={classes.form}>
                                            <TextField
                                                label={i18n.t("translation.settings.settings.campaignCreation.VCardMessage")}
                                                id="VCardMessage-setting"
                                                name="VCardMessage"
                                                multiline
                                                minRows={3}
                                                fullWidth
                                                defaultValue={getSettingValue("VCardMessage")}
                                                onChange={ChangeVCardMessage}
                                                variant="outlined"
                                                size="small"
                                                disabled={getSettingValue("sendVCard") === "disabled"}
                                            />

                                            <Button
                                                variant="contained"
                                                onClick={SaveVCardMessage}
                                                disabled={getSettingValue("sendVCard") === "disabled"}
                                            >
                                                Salvar
                                            </Button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            {/* Restrict users to only see chats on their own queues */}
                            <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t(
                                        "translation.settings.settings.restrictToQueues.note"
                                    )}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.restrictToQueues.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="restrictToQueues-setting"
                                    name="restrictToQueues"
                                    value={getSettingValue("restrictToQueues")}
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="enabled">
                                        {i18n.t("translation.validation.options.enabled")}
                                    </option>
                                    <option value="disabled">
                                        {i18n.t("translation.validation.options.disabled")}
                                    </option>
                                </Select>
                            </div>

                            {/* Enable common users to control sign message settings */}
                            <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t("translation.settings.settings.signMessage.note")}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.signMessage.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="signMessage-setting"
                                    name="signMessage"
                                    value={getSettingValue("signMessage")}
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="enabled">
                                        {i18n.t("translation.validation.options.enabled")}
                                    </option>
                                    <option value="disabled">
                                        {i18n.t("translation.validation.options.disabled")}
                                    </option>
                                </Select>
                            </div>

                            {/* Notification sounds */}
                            <div className={classes.option}>
                                <Typography variant="body1">
                                    {i18n.t("translation.settings.settings.alertSound.name")}
                                </Typography>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="alertSound-setting"
                                    name="alertSound"
                                    value={getSettingValue("alertSound")}
                                    // disabled
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="dot">
                                        {i18n.t("translation.settings.settings.alertSound.options.dot")}
                                    </option>
                                    <option value="original">
                                        {i18n.t("translation.settings.settings.alertSound.options.original")}
                                    </option>
                                    <option value="pipe">
                                        {i18n.t("translation.settings.settings.alertSound.options.pipe")}
                                    </option>
                                    <option value="pop">
                                        {i18n.t("translation.settings.settings.alertSound.options.pop")}
                                    </option>
                                    <option value="stairs">
                                        {i18n.t("translation.settings.settings.alertSound.options.stairs")}
                                    </option>
                                </Select>
                            </div>

                            {/* Time to create a new ticket */}
                            <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t("translation.settings.settings.timeCreateNewTicket.note")}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="timeCreateNewTicket-setting"
                                    name="timeCreateNewTicket"
                                    value={getSettingValue("timeCreateNewTicket")}
                                    // disabled
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="10">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.10")}
                                    </option>
                                    <option value="30">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.30")}
                                    </option>
                                    <option value="60">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.60")}
                                    </option>
                                    <option value="300">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.300")}
                                    </option>
                                    <option value="1800">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.1800")}
                                    </option>
                                    <option value="3600">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.3600")}
                                    </option>
                                    <option value="7200">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.7200")}
                                    </option>
                                    <option value="21600">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.21600")}
                                    </option>
                                    <option value="43200">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.43200")}
                                    </option>
                                    <option value="86400">
                                        {i18n.t("translation.settings.settings.timeCreateNewTicket.options.86400")}
                                    </option>
                                </Select>
                            </div>

                            <Divider />

                            {/* Send to analysis */}
                            <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t("translation.settings.settings.submitTicketForReview.note")}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.submitTicketForReview.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="submitTicketForReview-setting"
                                    name="submitTicketForReview"
                                    value={getSettingValue("submitTicketForReview")}
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="enabled">
                                        {i18n.t("translation.validation.options.enabled")}
                                    </option>
                                    <option value="disabled">
                                        {i18n.t("translation.validation.options.disabled")}
                                    </option>
                                </Select>
                            </div>

                            {/* Analysis alerts */}
                            {/* <div className={classes.option}>
                                <Tooltip
                                    title={i18n.t("translation.settings.settings.alertWaiting.note")}
                                >
                                    <Typography variant="body1">
                                        {i18n.t("translation.settings.settings.alertWaiting.name")}
                                    </Typography>
                                </Tooltip>
                                <Select
                                    size="small"
                                    variant="outlined"
                                    native
                                    id="alertWaiting-setting"
                                    name="alertWaiting"
                                    value={getSettingValue("alertWaiting")}
                                    className={classes.settingOption}
                                    onChange={handleChangeSetting}
                                >
                                    <option value="0">
                                        {i18n.t("translation.validation.options.disabled")}
                                    </option>
                                    <option value="1">
                                        {i18n.t("translation.variables.calendar.day")}
                                    </option>
                                    <option value="?">
                                        {i18n.t("translation.variables.calendar.week")}
                                    </option>
                                    <option value="2">
                                        {i18n.t("translation.variables.calendar.month")}
                                    </option>
                                    <option value="3">
                                        {i18n.t("translation.variables.calendar.year")}
                                    </option>
                                </Select>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default Settings;
