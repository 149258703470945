import React, { useEffect, useState } from "react";
import {
    Link as RouterLink
} from "react-router-dom";
import * as Yup from "yup";

import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {
    Field,
    Form,
    Formik
} from "formik";

import { toast } from "react-toastify";
import Copyright from "../../components/Copyright";
import toastError from "../../errors/toastError";
import { axiosOpen } from "../../services/axios";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    description: {
        marginBottom: theme.spacing(3),
        textAlign: "center"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    connectInfoItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
}));

const TenantSchema = Yup.object().shape({
    name: Yup.string()
        .min(4, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    email: Yup.string()
        .email(`${i18n.t("translation.validation.invalid_email")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    // slug: Yup.string()
    //     .min(3, `${i18n.t("translation.validation.too_short")}`)
    //     .matches(/^[a-zA-Z0-9-]+$/, `${i18n.t("translation.validation.specialChars")}`)
    //     .required(`${i18n.t("translation.validation.required")}`),
});

const Checkout = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [, setSelectedPlanInfo] = useState(null);

    const search = Object.fromEntries(new URLSearchParams(window.location.search));
    const extraCheckout = {
        slug: search?.slug || "",
        userName: search?.name || "",
        url: search?.url || "",
        planUUID: search?.planUUID || "",
        referral: search?.referral || "",
        consent: search?.consent === "checked" ? true : false,
    }

    const initialState = {
        name: search?.company || "",
        email: search?.email || "",
        planUUID: search?.planUUID || "",
    };

    const [tenant] = useState(initialState);

    const handleCheckout = async values => {
        try {
            const body = { ...tenant, ...values, extraCheckout }
            const { data } = await axiosOpen.post("/checkout", body);

            toast.success(i18n.t("translation.signup.toasts.success"));
            window.open(data.url, '_self');
        } catch (err) {
            toastError(err, "", "Error while trying to post checkout page.");
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchPlans = async () => {
            try {
                const { data } = await axiosOpen.get(`/plans/checkout/`, {
                    params: { showAll: false },
                });

                if (isMounted) {
                    // Revert content from string to JSON
                    for (let plan of data.plans) {
                        if (typeof plan.extraInfo === "string") plan.extraInfo = JSON.parse(plan.extraInfo)
                    }

                    // Set available plans
                    setPlans(data.plans)
                    setLoading(false);
                };
            } catch (err) {
                isMounted && toastError(err, "", "Error while trying to pull tenants on tenant manager page.");
                setLoading(false);
            }
        };
        fetchPlans();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (loading) return
        if (tenant?.name && tenant?.email && extraCheckout?.planUUID) handleCheckout(tenant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, tenant, extraCheckout])

    useEffect(() => {
        if (loading || !selectedPlan) return;

        // Fetch extra information for the selected plan
        const fetchSelectedPlanInfo = async () => {
            try {
                // Find the selected plan in the plans array
                const data = plans.find((plan) => plan.uuid === selectedPlan);

                // Check if the selected plan was found
                if (data) {
                    // Update the state with the extra information
                    setSelectedPlanInfo(data);
                }
            } catch (err) {
                toastError(err, "", "Error while trying to fetch extra information for the selected plan.");
            }
        };

        fetchSelectedPlanInfo();
    }, [loading, selectedPlan, plans]);

    return (
        <>
            <div className={classes.description}>
                <Typography component="h1" variant="h5">
                    {i18n.t("translation.checkout.title")}
                </Typography>
                <Typography component="p">
                    {i18n.t("translation.checkout.description")}
                </Typography>
            </div>

            <Formik
                initialValues={tenant}
                enableReinitialize={true}
                validationSchema={TenantSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleCheckout(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ touched, errors, isSubmitting, setFieldValue }) => (
                    <Form className={classes.form}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {!search.name &&
                                    <Field
                                        as={TextField}
                                        autoComplete="name"
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        fullWidth
                                        id="name"
                                        label={i18n.t("translation.tenantModal.form.name")}
                                        disabled={isSubmitting}
                                        size="small"
                                        autoFocus
                                    />
                                }
                                {!search.email &&
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        label={i18n.t("translation.signup.form.email")}
                                        name="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        autoComplete="email"
                                        disabled={isSubmitting}
                                        size="small"
                                    />
                                }
                                {!search.planUUID &&
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel id="planUUID-label">{i18n.t("translation.planModal.form.option.plan")}</InputLabel>
                                        <Field
                                            as={Select}
                                            label={i18n.t("translation.planModal.form.option.plan")}
                                            labelId="planUUID-label"
                                            id="planUUID"
                                            name="planUUID"
                                            fullWidth
                                            onChange={(e) => {
                                                setFieldValue("planUUID", e.target.value)
                                                setSelectedPlan(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={""}>{i18n.t("translation.botModal.form.option.select")}</MenuItem>
                                            {!loading && plans.map((plan) => (
                                                <MenuItem key={plan.uuid} value={plan.uuid}>
                                                    <div className={classes.connectInfoItem}>
                                                        <Tooltip arrow title={plan.description}>
                                                            <>
                                                                <span>{plan.name}</span>
                                                                <span>{plan.currency} {plan.price}/{i18n.t(`translation.planModal.form.option.recurrence.${plan.recurrence}`)}</span>
                                                            </>
                                                        </Tooltip>
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                }
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                            {i18n.t("translation.validation.buttons.submit")}
                        </Button>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link
                                    href="#"
                                    variant="body2"
                                    component={RouterLink}
                                    to="/login"
                                >
                                    {i18n.t("translation.validation.buttons.login")}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={1}>
                            <Copyright />
                        </Box>
                    </Form>
                )}
            </Formik>

            {/* <div>
                {selectedPlanInfo && (
                    <>
                        <Typography component="h2" variant="h6">
                            {selectedPlanInfo.name}
                        </Typography>
                        <Typography component="p">
                            {selectedPlanInfo.description}
                        </Typography>
                        <ul>
                            {selectedPlanInfo.planPieces.map(piece => {
                                return <li>{piece.name}: {piece.PlanComposition.quantity}</li>
                            })}
                        </ul>
                    </>
                )}
            </div> */}
        </>

    );
};

export default Checkout;
