import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

export default function PaperContainer({ children, props }) {
    const classes = useStyles();

    return (
        <Paper
            className={classes.mainPaper}
            variant="outlined"
            {...props}
        >
            {children}
        </Paper>
    );
}
