import React, { useEffect, useRef, useState } from "react";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { DeleteOutlined, GetApp } from "@mui/icons-material";
//import MicRecorder from "mic-recorder-to-mp3";
import InputVariables from "../../components/InputVariables";
import useTenant from "../../hooks/useTenant";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import { InputFile } from "../InputFile";

import Audio from "../Audio";
import ModalImageCors from "../ModalImageCors";
const useStyles = makeStyles((theme) => ({
    actionButtons: {
        marginLeft: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap"
    },
    actionButtonError: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    btnWrapper: {
        position: "relative",
    },
    media: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        gap: 1,
        marginTop: theme.spacing(2)
    },
    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
    },
    root: {
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    textQuickAnswerContainer: {
        width: "100%",
    },
}));

// const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const QuickAnswerSchema = Yup.object().shape({
    shortcut: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(15, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    message: Yup.string()
        .min(8, `${i18n.t("translation.validation.too_short")}`)
        .max(30000, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

const QuickAnswersModal = ({
    open,
    onClose,
    quickAnswerId,
    initialValues,
    onSave,
}) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    // const inputRef = useRef(null);
    const initialState = {
        shortcut: "",
        hasMedia: false,
        message: "",
        visibility: false,
    };

    const [quickAnswer, setQuickAnswer] = useState(initialState);
    const [quickAnswerMedias, setQuickAnswerMedias] = useState([]);

    const [field, setField] = useState();

    // Todo: Added record logic to quick reports
    // const [showAudio, setShowAudio] = useState(false);
    // const [records, setRecords] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [recording, setRecording] = useState(false);

    const { tenantId } = useTenant();
    const [medias, setMedias] = useState([]);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleFocus = (e) => {
        setField(e.target);
    };

    useEffect(() => {
        const fetchQuickAnswer = async () => {
            if (initialValues) {
                setQuickAnswer((prevState) => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!quickAnswerId || !tenantId) return;

            try {
                const { data } = await axiosPrivate.get(
                    `/${tenantId}/quickAnswers/${quickAnswerId}`
                );
                if (isMounted.current) {
                    setQuickAnswer(data);
                    setQuickAnswerMedias(data.QuickAnswerMedias);
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchQuickAnswer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickAnswerId, open, initialValues, tenantId]);

    const handleClose = () => {
        onClose();
        setQuickAnswer(initialState);
        setQuickAnswerMedias([]);
    };

    function changeMedias(values) {
        setMedias(values);
    }

    const handleSaveQuickAnswer = async (values) => {
        if (!tenantId) return;

        let input;
        try {
            if (medias.length > 0) {
                input = new FormData();
                input.append("shortcut", values.shortcut);
                input.append("message", values.message);
                medias.forEach((media) => {
                    input.append("file", media);
                });
                if (quickAnswerId) {
                    await axiosPrivate.put(
                        `/${tenantId}/quickAnswers/${quickAnswerId}`,
                        input
                    );
                    handleClose();
                } else {
                    const { data } = await axiosPrivate.post(
                        `/${tenantId}/quickAnswers`,
                        input
                    );
                    if (onSave) {
                        onSave(data);
                    }
                    handleClose();
                }
            } else {
                if (quickAnswerId) {
                    await axiosPrivate.put(
                        `/${tenantId}/quickAnswers/${quickAnswerId}`,
                        values
                    );
                    handleClose();
                } else {
                    const { data } = await axiosPrivate.post(
                        `/${tenantId}/quickAnswers`,
                        values
                    );
                    if (onSave) {
                        onSave(data);
                    }
                    handleClose();
                }
            }

            toast.success(i18n.t("translation.quickAnswersModal.success"));
        } catch (err) {
            toastError(err);
        }
    };

    // Todo: Added record logic to quick reports

    // const handleUploadAudio = async () => {
    //     setLoading(true);
    //     if (tenantId) {
    //         try {
    //             const [, blob] = await Mp3Recorder.stop().getMp3();
    //             if (blob.size < 10000) {
    //                 setLoading(false);
    //                 setRecording(false);
    //                 return;
    //             }

    //             const formData = new FormData();
    //             const filename = `audio-record-site-${new Date().getTime()}.mp3`;
    //             formData.append("medias", blob, filename);
    //             formData.append("body", filename);
    //             formData.append("fromMe", true);

    //             setRecords(formData);

    //             // await axiosPrivate.post(`/${tenantId}/messages`, formData);
    //         } catch (err) {
    //             toastError(err);
    //         }
    //     }

    //     setRecording(false);
    //     setLoading(false);
    // };

    // const handleCancelAudio = async () => {
    //     try {
    //         await Mp3Recorder.stop().getMp3();
    //         setRecording(false);
    //     } catch (err) {
    //         toastError(err);
    //     }
    // };

    // const handleStartRecording = async () => {
    //     setLoading(true);
    //     try {
    //         await navigator.mediaDevices.getUserMedia({ audio: true });
    //         await Mp3Recorder.start();
    //         setRecording(true);
    //         setLoading(false);
    //     } catch (err) {
    //         toastError(err);
    //         setLoading(false);
    //     }
    // };

    const checkMedias = (quickAnswer) => {
        if (quickAnswer.mediaType === "image") {
            return <ModalImageCors imageUrl={quickAnswer.mediaUrl} />;
        } else if (quickAnswer.mediaType === "audio") {
            // I'd like to style the AUDIO HTML component with red background
            return <Audio url={quickAnswer.mediaUrl} />;
        } else if (quickAnswer.mediaType === "video") {
            return (
                <video
                    src={quickAnswer.mediaUrl}
                    controls
                />
            );
        } else {
            return (
                <Button
                    startIcon={<GetApp />}
                    color="primary"
                    size="small"
                    variant="outlined"
                    target="_blank"
                    href={quickAnswer.mediaUrl}
                >
                    {i18n.t("translation.validation.buttons.download")}
                </Button>
            );
        }
    };

    async function DeleteMedia(media) {
        try {
            await axiosPrivate.delete(
                `/${tenantId}/quickAnswers/${quickAnswerId}/medias/${media.id}`
            );
            const { data } = await axiosPrivate.get(
                `/${tenantId}/quickAnswers/${quickAnswerId}`
            );
            if (isMounted.current) {
                setQuickAnswer(data);
                setQuickAnswerMedias(data.QuickAnswerMedias);
            }

        } catch (err) {
            toastError(err);
        }
    }

    return open && (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {quickAnswerId
                        ? `${i18n.t("translation.quickAnswersModal.title.edit")}`
                        : `${i18n.t("translation.quickAnswersModal.title.add")} `}
                </DialogTitle>
                <Formik
                    initialValues={quickAnswer}
                    enableReinitialize={true}
                    validationSchema={QuickAnswerSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveQuickAnswer(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                        errors,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.textQuickAnswerContainer}>
                                    <MultiFieldLine>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.quickAnswersModal.form.shortcut")}
                                            name="shortcut"
                                            autoFocus
                                            error={
                                                touched.shortcut &&
                                                Boolean(errors.shortcut)
                                            }
                                            helperText={
                                                touched.shortcut &&
                                                errors.shortcut
                                            }
                                            variant="outlined"
                                            size="small"
                                            className={classes.textField}
                                            fullWidth
                                        />
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="visibility"
                                                    checked={values.visibility}
                                                />
                                            }
                                            label={i18n.t("translation.quickAnswersModal.form.visibility")}
                                        />
                                    </MultiFieldLine>
                                </div>

                                <div className={classes.textQuickAnswerContainer} >
                                    <Box sx={{ display: "block" }}>
                                        <Field
                                            // ref={inputRef}
                                            as={TextField}
                                            label={i18n.t("translation.quickAnswersModal.form.message")}
                                            name="message"
                                            error={
                                                touched.message &&
                                                Boolean(errors.message)
                                            }
                                            helperText={
                                                touched.message &&
                                                errors.message
                                            }
                                            variant="outlined"
                                            size="small"
                                            className={
                                                classes.textField
                                            }
                                            onFocus={handleFocus}
                                            multiline
                                            minRows={5}
                                            fullWidth
                                        />
                                    </Box>

                                    <InputVariables
                                        group="campaigns"
                                        field={field}
                                        setFieldValue={setFieldValue}
                                    />

                                    <InputFile
                                        medias={medias}
                                        changeMedias={changeMedias}
                                    />
                                </div>

                                {quickAnswerMedias?.map((q) => (
                                    <div
                                        className={classes.media}
                                        key={q.id}
                                    >
                                        <Typography
                                            variant="body2"
                                            noWrap

                                        >
                                            {q?.body}
                                        </Typography>
                                        <div className={classes.actionButtons}>
                                            {checkMedias(q)}

                                            <IconButton
                                                color="error"
                                                size="small"
                                                onClick={() => DeleteMedia(q)}
                                                className={[classes.actionButtonError, classes.buttonColorError].join(' ')}
                                            >
                                                <DeleteOutlined />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("translation.validation.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {quickAnswerId
                                        ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                        : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default QuickAnswersModal;
