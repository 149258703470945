import useAxiosPrivate from "../useAxiosPrivate";
import toastError from "../../errors/toastError";
import useTenant from "../useTenant";

const useQueues = () => {
    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    const findAll = async () => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/queue`);
            return data;
        } catch (err) {
            toastError(err);
        }
    };

    return { findAll };
};

export default useQueues;
