import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import toastError from "../../errors/toastError";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Button, Divider, } from "@mui/material";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useTenant from '../../hooks/useTenant';

const VcardPreview = ({ contact, numbers }) => {
    const navigate = useNavigate();
    // const { user } = useAuth();
    const { tenant, tenantId, userTenant } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                if (tenantId) {
                    try {
                        let contactObj = {
                            name: contact,
                            number: numbers !== undefined && numbers.replace(/\D/g, ""),
                            email: ""
                        }
                        const { data } = await axiosPrivate.post(`/${tenantId}/contacts/findorcreate`, contactObj);
                        isMounted && setContact(data)

                    } catch (err) {
                        toastError(err, "", "Couldn't fetch contacts at the VCard Preview component.");
                    }
                }
            };
            fetchContacts();

        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact, numbers, tenantId]);

    const handleNewChat = async () => {
        if (!tenantId) return;
        try {
            const { data: ticket } = await axiosPrivate.post(`/${tenantId}/tickets`, {
                contactId: selectedContact.id,
                pauseAutoCloseTimer: true,
                userTenantId: userTenant.id,
                status: "open",
            });
            navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
        } catch (err) {
            toastError(err);
        }
    }

    return (
        <>
            <div style={{
                minWidth: "250px",
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Avatar src={selectedContact.profilePicUrl} alt={selectedContact.name} variant="rounded" />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="primary" gutterBottom>
                            {selectedContact.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Button
                            fullWidth
                            color="primary"
                            onClick={handleNewChat}
                            disabled={!selectedContact.number}
                        >Conversar</Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );

};

export default VcardPreview;
