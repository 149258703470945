import { useState, useEffect } from "react";

import useAxiosPrivate from "../useAxiosPrivate";
import toastError from "../../errors/toastError";
import useTenant from "../useTenant";

const useSettings = () => {
    const [settings, setSettings] = useState([]);
    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchSession = async () => {
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/settings`);
                isMounted && setSettings(data);
            } catch (err) {
                toastError(err, "", "Error ocurred while trying to fetch settings at useSettings.");
            }
        }
        if (tenantId) fetchSession();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const getSettingValue = (key) => {
        const value = settings?.find(s => s.key === key)?.value;
        return value;
    };

    return { settings, getSettingValue };
};

export default useSettings;
