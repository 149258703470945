import { useNavigate } from "react-router-dom";

import { Button, Paper, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Divider from "../../components/Divider/index.js";
import MainContainer from "../../components/MainContainer";
import { i18n } from "../../translate/i18n.js";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    container: {
        padding: "16px 24px",
    },
    mainPaper: {
        margin: "0 auto",
        [theme.breakpoints.down('md')]: {
            marginTop: 50,
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: 50,
            minWidth: "600px",
        },
    },
    title: {
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    }
}));

const Unauthorized = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <MainContainer>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.title}>
                            <h2 className={classes.titleH2}>{i18n.t("translation.unauthorized.info.title")}</h2>
                        </div>
                        <Typography variant="body1">
                            {i18n.t("translation.unauthorized.info.description")}
                        </Typography>

                        <Divider silent={true} />

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={goBack}
                        >
                            {i18n.t("translation.unauthorized.buttons.goBack")}
                        </Button>
                    </div>
                </div>
            </Paper>
        </MainContainer>

    );
};

export default Unauthorized;
