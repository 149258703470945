import makeStyles from '@mui/styles/makeStyles';
import { Form } from "formik";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
}));

export default function FormContainer({ children, props }) {
    const classes = useStyles();

    return (
        <Form
            className={classes.form}
            {...props}
        >
            {children}
        </Form>
    );
}
