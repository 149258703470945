import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Title from '../../../components/LayoutComponents/Title';
import MainContainer from '../../../components/MainContainer';
import MainHeader from '../../../components/MainHeader';

// TODO: Check if we can use default renderer already in place.
import MarkdownRenderer from '../../../components/MarkdownRenderer';

import toastError from "../../../errors/toastError";

import MainHeaderButtonsWrapper from '../../../components/MainHeaderButtonsWrapper';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSocketListener } from '../../../hooks/useSocket/Events';
import useTenant from "../../../hooks/useTenant";
import { i18n } from '../../../translate/i18n';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 3),
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            // maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    title: {
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
}));

function Item() {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();

    const initialValues = {
        title: "",
        content: ""
    };
    const { protocol } = useParams();
    const [content, setContent] = useState(initialValues);

    const navigate = useNavigate();
    const { tenant, tenantId } = useTenant();

    useEffect(() => {
        if (!protocol) return;

        const controller = new AbortController();
        let isMounted = true;

        const fetchData = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`${tenantId}/wiki/content/${protocol}`, { signal: controller.signal });
                    if (isMounted) {
                        const updateContent = {
                            title: data.wiki.title,
                            content: data.content
                        }
                        setContent(updateContent);
                    }
                } catch (err) {
                    navigate(`/${tenant.slug}/wiki`);
                    toastError(err);
                }
            }
        };

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [protocol, tenantId]);

    // Handle Wiki Changes
    const handleWikiChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            if (data.wiki.protocol === +protocol) {
                const updateContent = {
                    title: data.wiki.title,
                    content: data.wikiData.content
                }
                setContent(updateContent);
            }
        }
        if (data.action === "delete" && +data.wikiId === +protocol) {
            navigate(`/${tenant.slug}/wiki`);
        }

    };
    useSocketListener(`wiki`, (data) => handleWikiChange(data))

    return (
        <MainContainer>
            {content && (
                <>
                    <MainHeader>
                        <Title>
                            {i18n.t("translation.wiki.title")}
                        </Title>
                        <MainHeaderButtonsWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate(`/${tenant.slug}/wiki`)}
                            >
                                {i18n.t("translation.validation.buttons.back")}
                            </Button>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>
                    <Paper className={classes.mainPaper} variant="outlined">
                        <div className={classes.root}>
                            <div className={classes.container}>
                                <div className={classes.title}>
                                    <h2 className={classes.titleH2}>
                                        {content.title}
                                    </h2>
                                </div>
                                <MarkdownRenderer html={content.content} />
                            </div>
                        </div>
                    </Paper>
                </>
            )}
        </MainContainer>
    )
}
export default Item;
