import React, { useEffect, useRef, useState } from "react";

import { Field, Form, Formik, useField } from "formik";
import * as Yup from "yup";

import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

// TODO: check if can be done without dependency
import ReactQuill from "react-quill";
import showdown from "showdown";
// import Editor from '../RichEditor/Quill';

import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles((theme) => ({
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonWrapper: {
        position: "relative",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        '&:hover': {
            filter: 'brightness(120%)',
        },
    },
    chip: {
        margin: 2,
    },
    richEditor: {
        '& *[dir="rtl"] .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg': {
            left: 0,
            right: "unset"
        },
        '& .ql-editor': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.spacing(2),
        },
        '& .ql-toolbar.ql-snow': {
            borderRadius: theme.spacing(2, 2, 0, 0),
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(1),
            textAlign: "center",
        },
        '& .ql-container.ql-snow': {
            borderRadius: theme.spacing(0, 0, 2, 2)
        },
        '& .ql-toolbar.ql-snow .ql-formats': {
            margin: "unset"
        },
        '& .ql-tooltip': {
            borderRadius: theme.spacing(2),
            left: '0 !important',
            zIndex: 10
        },
        '& .c-dark-theme': {
            '& .ql-picker-label': {
                color: `var(--color)`,
            },
            '& .ql-picker-options': {
                color: `var(--color)`,
                backgroundColor: `var(--dark-dark-theme) !important`,
                borderColor: '#181924'
            },
            '& .ql-toolbar.ql-snow svg': {
                '& .ql-stroke': {
                    stroke: `var(--color)`
                },
                '& .ql-fill': {
                    fill: `var(--color)`
                }
            }
        }
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textContentContainer: {
        width: "100%",
    },

}));

const WikiSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(550, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

const converter = new showdown.Converter();

const WikiModal = ({
    open,
    onClose,
    wikiId,
    wikiProtocol,
    initialValues,
    onSave,
}) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const initialState = {
        title: "",
        content: "",
        tagId: "",
    };
    const [wiki, setWiki] = useState(initialState);
    const [tags, setTags] = useState([]);
    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        return () => isMounted.current = false;
    }, []);

    const convertToMarkdown = (content) => {
        return converter.makeMarkdown(content);
    };

    const convertToHtml = (markdown) => {
        return converter.makeHtml(markdown);
    };

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            if (initialValues) {
                setWiki((prevState) => {
                    return { ...prevState, ...initialValues };
                });
            }

            if (!tenantId) return;

            try {
                // Load tags to render in plain view.
                await loadTags();

                // If a Wiki ID is present, check for data
                if (wikiId) {
                    const { data } = await axiosPrivate.get(`/${tenantId}/wiki/${wikiId}`, { signal: controller.signal });
                    const wikiContent = await axiosPrivate.get(`/${tenantId}/wiki/content/${wikiProtocol}`, { signal: controller.signal });

                    // Set data according to selected wiki article
                    const fullData = {
                        title: data.title,
                        tagId: data.tagId,
                        content: convertToHtml(wikiContent.data.content),
                    }

                    // Set values based on results
                    setWiki((prevState) => {
                        return {
                            ...prevState,
                            ...fullData,
                        }
                    })
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchData();

        return () => {
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wikiId, wikiProtocol, open, initialValues, tenantId]);

    const loadTags = async () => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setWiki(initialState);
    };

    const handleSaveWiki = async (values) => {
        try {
            values.content = convertToMarkdown(values.content);
            if (wikiId) {
                await axiosPrivate.put(`/${tenantId}/wiki/${wikiId}`, values);
                handleClose();
            } else {
                const { data } = await axiosPrivate.post(`/${tenantId}/wiki`, values);
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t("translation.wikiModal.success"));
        } catch (err) {
            toastError(err);
        }
    };

    // Render special Rich Editor
    const Editor = ({ ...props }) => {
        const [field, meta, helpers] = useField(props);

        const handleEditorChange = (value) => {
            helpers.setValue(value); // Update value using setFieldValue
        };

        // Modules for React Quill
        const modules = {
            toolbar: [
                ["bold", "italic", "underline"],
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                [{ link: true }],
            ],
        };

        return (
            <>
                <ReactQuill
                    value={field.value}
                    onChange={handleEditorChange}
                    modules={modules}
                    className={classes.richEditor}
                />
                {meta.touched && meta.error && (
                    <div className={classes.error}>{meta.error}</div>
                )}
            </>
        );
    };


    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {wikiId
                        ? `${i18n.t("translation.wikiModal.title.edit")}`
                        : `${i18n.t("translation.wikiModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={wiki}
                    enableReinitialize={true}
                    validationSchema={WikiSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveWiki(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.textContentContainer}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.wikiModal.form.title")}
                                        autoFocus
                                        name="title"
                                        error={touched.title && Boolean(errors.title)}
                                        helperText={touched.title && errors.title}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <FormControl fullWidth size="small" variant="outlined">
                                        <InputLabel>{i18n.t("translation.wikiModal.form.tag")}</InputLabel>

                                        <Field
                                            as={Select}
                                            label={i18n.t("translation.wikiModal.form.tag")}
                                            name="tagId"
                                            id="tagId"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={touched.tagId && Boolean(errors.tagId)}
                                            placeholder={i18n.t("translation.wiki.form.id")}
                                        >
                                            {tags.map((tag) => (
                                                <MenuItem value={tag.id} key={tag.id}>
                                                    <Chip
                                                        key={tag.id}
                                                        style={{
                                                            backgroundColor: tag.color + "20",
                                                            borderColor: tag.color,
                                                            color: tag.color,
                                                        }}
                                                        variant="outlined"
                                                        label={tag.name}
                                                        className={classes.chip}
                                                        size="small"
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </div>
                                <div className={classes.textContentContainer}>
                                    <Editor name="content" />
                                    {/* // TODO: review Slate so that we can make inline changes on the go (tags/@ mentions)
                                    <Field name="content">
                                        {({ field }) => <Editor content={field.value} placeholder="" />}
                                    </Field> */}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("translation.validation.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.buttonWrapper}
                                >
                                    {wikiId
                                        ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                        : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
};

export default WikiModal;
