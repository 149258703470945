import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

export default function TextFieldInput(props) {
    const classes = useStyles();
    return (
        <Field
            as={TextField}
            id={props.name}
            className={classes.field}
            variant="outlined"
            fullWidth
            margin="normal"
            {...props}
        />
    );
}
