import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
    AccountCircle,
    AccountTreeTwoTone,
    Addchart,
    AssessmentTwoTone,
    AssistantRounded,
    AutoAwesomeTwoTone,
    BookTwoTone,
    CampaignTwoTone,
    ContactsTwoTone,
    CreditCardTwoTone,
    DevicesTwoTone,
    Error,
    ExpandLess,
    ExpandMore,
    LocalOfferRounded,
    PeopleAltRounded,
    PeopleAltTwoTone,
    PersonRounded,
    PieChartTwoTone,
    PowerRounded,
    QuestionAnswerTwoTone,
    QuickreplyTwoTone,
    Receipt,
    SettingsRounded,
    SettingsTwoTone,
    SmsTwoTone,
    TimerTwoTone
} from "@mui/icons-material";
import {
    Badge,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { Can } from "../components/Can";
import { i18n } from "../translate/i18n";

import NotificationFavicon from "../components/NotificationFavicon";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import useSettings from "../hooks/useSettings";
import useTenant from "../hooks/useTenant";

function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} style={{ color: "inherit" }} />
            )),
        [to]
    );

    return (
        <li>
            <ListItem component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const useStyles = makeStyles((theme) => ({
    alert: {
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.error.main}`,
        textAlign: "center",
        margin: theme.spacing(1, 1.5),
        padding: theme.spacing(0.5),
        whiteSpace: "normal",
    },
    error: {
        color: theme.palette.error.main,
    },
    link: {
        cursor: "pointer"
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit"
    },
}));

const MainListItems = (props) => {
    const classes = useStyles();
    const { drawerClose } = props;

    // Submenus
    const [alertSubmenu, toggleAlertSubmenu] = useState(false);
    const [reportSubmenu, toggleReportSubmenu] = useState(false);
    const [financeSubmenu, toggleFinanceSubmenu] = useState(false);
    const [contactsSubmenu, toggleContactsSubmenu] = useState(false);
    const [settingsSubmenu, toggleSettingsSubmenu] = useState(false);

    // Contexts
    const { whatsApps } = useContext(WhatsAppsContext);
    const { settings, getSettingValue } = useSettings();
    const { tenant, userTenant } = useTenant();

    // States
    const [connectionWarning, setConnectionWarning] = useState(false);
    const [numberOfConnections, setNumberOfConnections] = useState(0);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps?.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                    setNumberOfConnections(offlineWhats.length);
                } else {
                    setConnectionWarning(false);
                    setNumberOfConnections(0);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps, tenant]);

    useEffect(() => {
        if (!connectionWarning) return;
        NotificationFavicon({ content: 'warning', state: connectionWarning })
    }, [connectionWarning])

    return (
        <div onClick={drawerClose}>
            <Can
                role={userTenant?.role}
                perform="drawer-items:tickets"
                yes={() => (
                    <>
                        <ListItemLink
                            to={`/${tenant.slug}/tickets`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.tickets"
                            )}
                            icon={<SmsTwoTone color="primary" />}
                        />
                        <ListItemLink
                            to={`/${tenant.slug}/chats`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.chats"
                            )}
                            icon={<QuestionAnswerTwoTone color="primary" />}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:schedule"
                            yes={() => (
                                <>
                                    <ListItemLink
                                        to={`/${tenant.slug}/scheduledMessages`}
                                        primary={i18n.t(
                                            "translation.mainDrawer.listItems.scheduledMessages"
                                        )}
                                        icon={<TimerTwoTone color="primary" />}
                                    />
                                </>
                            )}
                        />

                        <ListItem
                            onClick={() => toggleContactsSubmenu((prev) => !prev)}
                            className={classes.link}
                        >
                            <ListItemIcon>
                                <ContactsTwoTone color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t("translation.mainDrawer.listItems.contactsToggle")}
                            />
                            {contactsSubmenu
                                ? <ExpandLess color="primary" />
                                : <ExpandMore color="primary" />
                            }
                        </ListItem>

                        <Collapse
                            in={contactsSubmenu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List
                                component="div"
                                disablePadding
                                sx={{ pl: 2 }}
                            >
                                <ListItemLink
                                    to={`/${tenant.slug}/contacts`}
                                    primary={i18n.t("translation.mainDrawer.listItems.contacts")}
                                    icon={
                                        <PersonRounded
                                            fontSize="small"
                                            color="primary"
                                        />
                                    }
                                />
                                <ListItemLink
                                    to={`/${tenant.slug}/contactGroups`}
                                    primary={i18n.t("translation.mainDrawer.listItems.contactGroups")}
                                    icon={
                                        <PeopleAltRounded
                                            fontSize="small"
                                            color="primary"
                                        />
                                    }
                                />
                            </List>
                        </Collapse>


                        <ListItemLink
                            to={`/${tenant.slug}/wiki`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.Wiki"
                            )}
                            icon={<BookTwoTone color="primary" />}
                        />
                        <ListItemLink
                            to={`/${tenant.slug}/quickAnswers`}
                            primary={i18n.t(
                                "translation.mainDrawer.listItems.quickAnswers"
                            )}
                            icon={<QuickreplyTwoTone color="primary" />}
                        />
                    </>
                )}
            />
            {connectionWarning && (
                <>
                    <ListItem
                        onClick={() => toggleAlertSubmenu((prev) => !prev)}
                        className={classes.link}
                    >
                        <ListItemIcon>
                            <Error color="error" />
                        </ListItemIcon>
                        <ListItemText
                            primary={i18n.t("translation.mainDrawer.alert.title")}
                            className={classes.error}
                        />
                        {alertSubmenu ? (
                            <ExpandLess color="error" />
                        ) : (
                            <ExpandMore color="error" />
                        )}
                    </ListItem>

                    <Collapse in={alertSubmenu} timeout="auto" unmountOnExit>
                        <Typography
                            variant="inherit"
                            className={[classes.error, classes.alert].join(" ")}
                        >
                            {i18n.t("translation.mainDrawer.alert.description", {
                                count: numberOfConnections,
                            })}
                        </Typography>
                    </Collapse>
                </>
            )}
            <Can
                role={userTenant?.role}
                perform="drawer-items:management"
                yes={() => (
                    <>
                        <Divider />
                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:dashboard"
                            yes={() => (
                                <>
                                    <ListItemLink
                                        to={`/${tenant.slug}/`}
                                        primary={i18n.t(
                                            "translation.mainDrawer.listItems.dashboard"
                                        )}
                                        icon={<PieChartTwoTone color="primary" />}
                                    />
                                </>
                            )}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:reports"
                            yes={() => (
                                <>
                                    <ListItem
                                        onClick={() =>
                                            toggleReportSubmenu((prev) => !prev)
                                        }
                                        className={classes.link}
                                    >
                                        <ListItemIcon>
                                            <AssessmentTwoTone color="primary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={i18n.t(
                                                "translation.mainDrawer.listItems.reports"
                                            )}
                                        />
                                        {reportSubmenu ? (
                                            <ExpandLess color="primary" />
                                        ) : (
                                            <ExpandMore color="primary" />
                                        )}
                                    </ListItem>

                                    <Collapse
                                        in={reportSubmenu}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List
                                            component="div"
                                            disablePadding
                                            sx={{ pl: 2 }}
                                        >
                                            {/* <ListItemLink
                                                to={`/${tenant.slug}/reports/campaigns`}
                                                primary={i18n.t(
                                                    "translation.reports.report.campaigns.title"
                                                )}
                                                icon={
                                                    <CampaignRounded
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                }
                                            /> */}
                                            <Can
                                                role={userTenant?.role}
                                                perform="drawer-items:tags"
                                                yes={() => (
                                                    <>
                                                        <ListItemLink
                                                            to={`/${tenant.slug}/tags`}
                                                            primary={i18n.t(
                                                                "translation.mainDrawer.listItems.tags"
                                                            )}
                                                            icon={
                                                                <LocalOfferRounded
                                                                    fontSize="small"
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                            <ListItemLink
                                                to={`/${tenant.slug}/reports/teams`}
                                                primary={i18n.t(
                                                    "translation.reports.report.teams.title"
                                                )}
                                                icon={
                                                    <PeopleAltRounded
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        />

                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:finance"
                            yes={() => (
                                <>
                                    <ListItem
                                        onClick={() => toggleFinanceSubmenu((prev) => !prev)}
                                        className={classes.link}
                                    >
                                        <ListItemIcon>
                                            <CreditCardTwoTone color="primary" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={i18n.t("translation.mainDrawer.listItems.finance")}
                                        />
                                        {financeSubmenu ? (
                                            <ExpandLess color="primary" />
                                        ) : (
                                            <ExpandMore color="primary" />
                                        )}
                                    </ListItem>

                                    <Collapse
                                        in={financeSubmenu}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List
                                            component="div"
                                            disablePadding
                                            sx={{ pl: 2 }}
                                        >
                                            <ListItemLink
                                                to={`/${tenant.slug}/account`}
                                                primary={i18n.t("translation.mainDrawer.listItems.account")}
                                                icon={
                                                    <AccountCircle
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                            <ListItemLink
                                                to={`/${tenant.slug}/subscriptions`}
                                                primary={i18n.t("translation.mainDrawer.listItems.subscriptions")}
                                                icon={
                                                    <Addchart
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                            <ListItemLink
                                                to={`/${tenant.slug}/invoices`}
                                                primary={i18n.t("translation.mainDrawer.listItems.invoices")}
                                                icon={
                                                    <Receipt
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        />

                        <Can
                            role={userTenant?.role}
                            perform="drawer-items:management"
                            yes={() => (
                                <>
                                    {settings &&
                                        settings.length > 0 &&
                                        getSettingValue("campaignCreation") ===
                                        "enabled" ? (
                                        <Can
                                            role={userTenant?.role}
                                            perform="drawer-items:campaign"
                                            yes={() => (
                                                <>
                                                    <ListItemLink
                                                        to={`/${tenant.slug}/campaign`}
                                                        primary={i18n.t(
                                                            "translation.mainDrawer.listItems.campaign"
                                                        )}
                                                        icon={
                                                            <CampaignTwoTone color="primary" />
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    <Can
                                        role={userTenant?.role}
                                        perform="drawer-items:supervisor"
                                        yes={() => (
                                            <>
                                                <ListItemLink
                                                    to={`/${tenant.slug}/users`}
                                                    primary={i18n.t(
                                                        "translation.mainDrawer.listItems.users"
                                                    )}
                                                    icon={
                                                        <PeopleAltTwoTone color="primary" />
                                                    }
                                                />
                                                <Can
                                                    role={userTenant?.role}
                                                    perform="drawer-items:connections"
                                                    yes={() => (
                                                        <>
                                                            <ListItemLink
                                                                to={`/${tenant.slug}/connections`}
                                                                primary={i18n.t(
                                                                    "translation.mainDrawer.listItems.connections"
                                                                )}
                                                                icon={
                                                                    <Badge
                                                                        badgeContent={
                                                                            connectionWarning
                                                                                ? "!"
                                                                                : 0
                                                                        }
                                                                        color="error"
                                                                        overlap="rectangular"
                                                                    >
                                                                        <DevicesTwoTone color="primary" />
                                                                    </Badge>
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Can
                                                    role={userTenant?.role}
                                                    perform="drawer-items:queues"
                                                    yes={() => (
                                                        <>
                                                            <ListItemLink
                                                                to={`/${tenant.slug}/queues`}
                                                                primary={i18n.t("translation.mainDrawer.listItems.queues")}
                                                                icon={
                                                                    <AccountTreeTwoTone color="primary" />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Can
                                                    role={userTenant?.role}
                                                    perform="drawer-items:bots"
                                                    yes={() => (
                                                        <>
                                                            <ListItemLink
                                                                to={`/${tenant.slug}/bots`}
                                                                primary={i18n.t("translation.mainDrawer.listItems.bots")}
                                                                icon={
                                                                    <AutoAwesomeTwoTone color="primary" />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        />
                    </>
                )}
            />
            <Can
                role={userTenant?.role}
                perform="drawer-items:admin"
                yes={() => (
                    <>
                        <ListItem
                            onClick={() => toggleSettingsSubmenu((prev) => !prev)}
                            className={classes.link}
                        >
                            <ListItemIcon>
                                <SettingsTwoTone color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t(
                                    "translation.mainDrawer.listItems.application"
                                )}
                            />
                            {settingsSubmenu ? (
                                <ExpandLess color="primary" />
                            ) : (
                                <ExpandMore color="primary" />
                            )}
                        </ListItem>

                        <Collapse
                            in={settingsSubmenu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                                <ListItemLink
                                    to={`/${tenant.slug}/api`}
                                    primary={i18n.t(
                                        "translation.mainDrawer.listItems.api"
                                    )}
                                    icon={
                                        <PowerRounded
                                            fontSize="small"
                                            color="primary"
                                        />
                                    }
                                />
                                <ListItemLink
                                    to={`/${tenant.slug}/widget`}
                                    primary={i18n.t(
                                        "translation.mainDrawer.listItems.widget"
                                    )}
                                    icon={
                                        <AssistantRounded
                                            fontSize="small"
                                            color="primary"
                                        />
                                    }
                                />
                                <Can
                                    role={userTenant?.role}
                                    perform="drawer-items:settings"
                                    yes={() => (
                                        <>
                                            <ListItemLink
                                                to={`/${tenant.slug}/settings`}
                                                primary={i18n.t(
                                                    "translation.mainDrawer.listItems.settings"
                                                )}
                                                icon={
                                                    <SettingsRounded
                                                        fontSize="small"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </List>
                        </Collapse>
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
