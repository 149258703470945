import {
    // Block,
    // Business,
    // CheckCircleOutlined,
    DeleteOutline,
    EditOutlined,
    // Group,
    // Person,
    Search,
    // Storefront,
    TimerOutlined,
    WhatsApp
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewTicketModal from "../../components/NewTicketModal";

import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal/";
import ContactModal from "../../components/ContactModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MaskData from "../../components/MaskData";
import ScheduledMessagesModal from "../../components/ScheduledMessagesModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TutorialModalButton from "../../components/TutorialModalButton";
import handleContactLink from "../../components/handleContactLink";

import { toast } from "react-toastify";
import DownloadCSV from "../../components/DownloadCSV";
import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        width: "15%",
        minWidth: 210,
        maxWidth: 230
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    contactName: {
        paddingLeft: theme.spacing(2)
    },
    disabled: {
        display: "flex",
        justifyContent: "center"
    },
    enabled: {
        display: "flex",
        justifyContent: "center"
    },
    formControl: {
        marginRight: "16px",
        "& input": {
            height: "1.2195em"
        }
    },
    link: {
        cursor: "pointer"
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    minWidthColumns: {
        display: "flex",
        alignItems: "center",
        minWidth: '100%',
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    searchMobile: {
        width: "100%",
        padding: "0 8px 16px",
    },
    tableActions: {
        alignItems: "center",
        maxWidth: 100,
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Contacts = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const { user } = useAuth();
    const { tenant, tenantId, userTenant } = useTenant();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [deletingContact, setDeletingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [scheduleMessageModalOpen, setScheduleMessageModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        setLoading(true);
        const fetchContacts = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/contacts/`, {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
                    if (isMounted) {
                        setHasMore(data.hasMore);
                        setLoading(false);
                    };
                } catch (err) {
                    toastError(err);
                }
            }
        };
        fetchContacts();
        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, tenantId]);

    // Handle Contact Changes
    const handleContactChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
        }
    };
    useSocketListener(`contact`, (data) => handleContactChange(data))

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const handleOpenScheduleMessageModal = (contactId) => {
        setSelectedContactId(contactId);
        setScheduleMessageModalOpen(true);
    };

    const handleCloseScheduleMessageModal = () => {
        setScheduleMessageModalOpen(false);
        setSelectedContactId(false);
    };

    const hadleEditContact = (contactId) => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };

    const handleDeleteContact = async (contactId) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/contacts/${contactId}`);
            toast.success(i18n.t("translation.contacts.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleImportContacts = async () => {
        if (!tenantId) return;
        try {
            await axiosPrivate.post(`/${tenantId}/contacts/import`);
            navigate(0);
        } catch (err) {
            toastError(err);
        }
    };

    const handleExportContacts =  async () => {
        const content = [{
            name: i18n.t("translation.variables.table.name"),
            phoneNumber: i18n.t("translation.variables.table.phone"),
            email: i18n.t("translation.variables.table.email"),
            // type: i18n.t("translation.variables.table.whatsappType"),
            // block: i18n.t("translation.variables.table.block"),
            // mute: i18n.t("translation.variables.table.mute")
        }];

        const { data } = await axiosPrivate.get(`/${tenantId}/contacts/list`,
        );

        data.map((contact) => (
            contact.isGroup === false && content.push({
                name: contact.name,
                phoneNumber: contact.number,
                email: contact.email,
                // type: contact.isBusiness ? i18n.t("translation.validation.whatsappType.business") : contact.isEnterprise ? i18n.t("translation.validation.whatsappType.enterprise") : i18n.t("translation.validation.whatsappType.user"),
                // block: contact.isBlocked ? i18n.t("translation.validation.state.active") : i18n.t("translation.validation.state.inactive"),
                // mute: contact.statusMute ? i18n.t("translation.validation.state.active") : i18n.t("translation.validation.state.inactive")
            })
        ))

        DownloadCSV(
            `${i18n.t("translation.validation.buttons.contacts.fileName")}_-_${i18n.t("translation.validation.locale")}.csv`,
            content,
            'text/csv; charset=utf-8'
        );
    }

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
        }
    }

    return (
        <MainContainer className={classes.mainContainer}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                contactId={selectedContactId}
                onClose={(ticket) => handleCloseOrOpenTicket(ticket)}
            />
            <ScheduledMessagesModal
                user={user}
                open={scheduleMessageModalOpen}
                onClose={handleCloseScheduleMessageModal}
                contactId={selectedContactId}
            />
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            >
            </ContactModal>
            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t("translation.contacts.confirmationModal.delete.title")} ${deletingContact.name
                        }?`
                        : `${i18n.t("translation.contacts.confirmationModal.import.title")}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={(e) =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : handleImportContacts()
                }
            >
                {deletingContact
                    ? `${i18n.t("translation.contacts.confirmationModal.delete.message")}`
                    : `${i18n.t("translation.contacts.confirmationModal.import.message")}`}
            </ConfirmationModal>
            <MainHeader>
                <Title>
                    {i18n.t("translation.contacts.title")}
                    <TutorialModalButton content="contacts" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            onChange={handleSearch}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="contacts-page:import:contact"
                            yes={() => (
                                <Button
                                    className={classes.formControl}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => setConfirmOpen(true)}
                                >
                                    {i18n.t("translation.contacts.buttons.import")}
                                </Button>
                            )}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="contacts-page:export:contact"
                            yes={() => (
                                <Button
                                    className={classes.formControl}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleExportContacts()}
                                >
                                    {i18n.t("translation.contacts.buttons.export")}
                                </Button>
                            )}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenContactModal}
                        >
                            {i18n.t("translation.contacts.buttons.add")}
                        </Button>
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Can
                            role={userTenant?.role}
                            perform="contacts-page:import:contact"
                            yes={() => (
                                <Button
                                    className={classes.formControl}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => setConfirmOpen(true)}
                                >
                                    {i18n.t("translation.contacts.buttons.import")}
                                </Button>
                            )}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="contacts-page:export:contact"
                            yes={() => (
                                <Button
                                    className={classes.formControl}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleExportContacts()}
                                >
                                    {i18n.t("translation.contacts.buttons.export")}
                                </Button>
                            )}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenContactModal}
                        >
                            {i18n.t("translation.contacts.buttons.add")}
                        </Button>
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.noWrap} >{i18n.t("translation.variables.table.name")}</TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.phone")}
                            </TableCell>
                            <TableCell className={classes.noWrap} align="center">
                                {i18n.t("translation.variables.table.email")}
                            </TableCell>
                            {/* <TableCell align="center">
                                {i18n.t("translation.variables.table.whatsappType")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.block")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("translation.variables.table.mute")}
                            </TableCell> */}
                            <TableCell align="center" className={classes.actionsCell}>
                                {i18n.t("translation.variables.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell className={classes.minWidthColumns} >
                                        <Avatar src={contact.profilePicUrl} alt={contact.name} variant="rounded" />
                                        <Typography className={classes.contactName} variant="inherit">{contact.name}</Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.noWrap} onClick={() => handleContactLink("phone", contact.number)}>
                                        <Typography className={classes.link}>
                                            <Can
                                                role={userTenant?.role}
                                                perform="contacts-page:view:contactNumber"
                                                yes={() => (contact.isGroup ? '' : contact?.formattedNumber)}
                                                no={() => (contact.isGroup ? '' : MaskData('phone', contact?.formattedNumber))}
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" className={classes.noWrap} onClick={() => handleContactLink("mail", contact.email)}>
                                        <Typography className={classes.link}>
                                            <Can
                                                role={userTenant?.role}
                                                perform="contacts-page:view:contactNumber"
                                                yes={() => (contact.email)}
                                                no={() => (contact.email ? MaskData('email', contact.email) : '')}
                                            />
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell align="center">
                    <Typography className={[classes.noWrap].join(" ")}>
                      {
                        contact.isBusiness === true
                          ? (
                            <span>
                              <Storefront fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.whatsappType.business")}
                            </span>
                          )
                          : contact.isEnterprise === true
                            ? (
                              <span>
                                <Business fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.whatsappType.enterprise")}
                              </span>
                            )
                            : contact.isGroup === true
                              ? (
                                <span>
                                  <Group fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.whatsappType.group")}
                                </span>
                              ) : (
                                <span>
                                  <Person fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.whatsappType.user")}
                                </span>
                              )
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {contact.isBlocked === false ? (
                      <div className={classes.enabled}>
                        <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.inactive")}
                      </div>
                    ) : (
                      <div className={classes.disabled}>
                        <Block color="error" fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.active")}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {contact.statusMute === false ? (
                      <div className={classes.enabled}>
                        <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.inactive")}
                      </div>
                    ) : (
                      <div className={classes.disabled}>
                        <Block color="error" fontSize="small" className={classes.marginRight} /> {i18n.t("translation.validation.state.active")}
                      </div>
                    )}
                  </TableCell> */}
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            className={classes.actionButtons}
                                            color="primary"
                                            onClick={() => handleOpenScheduleMessageModal(contact.id)}
                                        >
                                            <TimerOutlined />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            className={classes.actionButtons}
                                            onClick={() => {
                                                setNewTicketModalOpen(true)
                                                setSelectedContactId(contact.id)
                                            }}
                                            color="primary"
                                        >
                                            <WhatsApp />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            className={classes.actionButtons}
                                            onClick={() => hadleEditContact(contact.id)}
                                            color="primary"
                                        >
                                            <EditOutlined />
                                        </IconButton>
                                        <Can
                                            role={userTenant?.role}
                                            perform="contacts-page:delete:contact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                                    onClick={() => {
                                                        setConfirmOpen(true);
                                                        setDeletingContact(contact);
                                                    }}
                                                >
                                                    <DeleteOutline />
                                                </IconButton>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Contacts;
