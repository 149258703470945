import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

import { i18n } from "../../translate/i18n";
import MultiFieldLine from "../FormComponents/MultiFieldLine";

const useStyles = makeStyles(theme => ({
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

function AdvancedFilter({ open, setDateRange, setVisible }) {
    const classes = useStyles();
    const now = new Date();

    return (
        <Dialog open={open} scroll="paper">
            <DialogTitle id="form-dialog-title">
                {i18n.t("translation.dashboard.dateFilterModal.title")}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {i18n.t("translation.dashboard.dateFilterModal.description")}
                </DialogContentText>
                <br />
                <MultiFieldLine>
                    <TextField
                        ampm="false"
                        autoFocus
                        className={classes.textField}
                        defaultValue={now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + (now.getDate() - 7)).slice(-2)}
                        id="dateStart"
                        InputLabelProps={{ shrink: true }}
                        label={`${i18n.t("translation.dashboard.dateFilterModal.dateRange.start")}`}
                        size="small"
                        type="date"
                        variant="outlined"
                    />
                    <TextField
                        ampm="false"
                        className={classes.textField}
                        defaultValue={now.getFullYear() + "-" + ("0" + (now.getMonth() + 1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2)}
                        id="dateEnd"
                        InputLabelProps={{ shrink: true }}
                        label={`${i18n.t("translation.dashboard.dateFilterModal.dateRange.end")}`}
                        size="small"
                        type="date"
                        variant="outlined"
                    />
                </MultiFieldLine>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.buttonColorError}
                    color="secondary"
                    onClick={() => setVisible(false)}
                    variant="outlined"
                >
                    {i18n.t("translation.validation.buttons.cancel")}
                </Button>
                <Button
                    className={classes.btnWrapper}
                    color="primary"
                    onClick={() => {
                        setDateRange({
                            dateStart: document.getElementById("dateStart").value,
                            dateEnd: document.getElementById("dateEnd").value
                        })
                        setVisible(false);
                    }}
                    type="submit"
                    variant="contained"
                >
                    {i18n.t("translation.validation.buttons.filter")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default AdvancedFilter;
