import { Field } from "formik";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
// import makeStyles from '@mui/styles/makeStyles';

import useTenant from "../../hooks/useTenant";

import { i18n } from "../../translate/i18n";

// const useStyles = makeStyles(theme => ({
//     new: {
//         borderTop: "1px solid #dadde9"
//     }
// }))

const QueueSelectSingle = ({ fieldId, props }) => {
    // const classes = useStyles();

    // Context
    const { userTenant } = useTenant();
    const { queues } = userTenant;

    return (
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel>
                {i18n.t("translation.ticketsList.acceptModal.queue")}
            </InputLabel>
            <Field
                as={Select}
                name={fieldId}
                fullWidth
                label={i18n.t("translation.ticketsList.acceptModal.queue")}
                {...props}
            >
                <MenuItem
                    key="no-key-queue"
                    value={""}
                >
                    {i18n.t("translation.botModal.form.option.select")}
                </MenuItem>
                {queues?.length > 0 && queues?.map((queue) => (
                    <MenuItem
                        key={queue.id}
                        value={queue.id}
                    >
                        {queue.name}
                    </MenuItem>
                ))}
            </Field>
        </FormControl>
    )
}

export default QueueSelectSingle;
