import React, { useEffect, useMemo, useReducer, useState } from "react";

import {
    DeleteOutline,
    EditOutlined
} from "@mui/icons-material";
import {
    Button,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import BotModal from "../../components/BotModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import { toast } from "react-toastify";
import { Can } from "../../components/Can";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import { format, parseISO } from "date-fns";
import PaperContainer from "../../components/LayoutComponents/Paper";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    spaceRight: {
        marginRight: theme.spacing(1),
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_BOTS") {
        const bots = action.payload;
        const newBots = [];

        bots.forEach((bot) => {
            const botIndex = state?.findIndex((b) => b.id === bot.id);
            if (botIndex !== -1 && bot.parentId === null) {
                state[botIndex] = bot;
            } else if (bot.parentId === null) {
                newBots.push(bot);
            }
        });

        return [...state, ...newBots];
    }

    if (action.type === "UPDATE_BOTS") {
        const bot = action.payload;
        const botIndex = state?.findIndex((b) => b.id === bot.id);

        // Check if the bot is not currently on the set
        if (botIndex === -1) {
            return [...state, bot]
        } else {
            // Check if Bot has an index and no parent
            state[botIndex] = bot;
            return [...state];
        }
    }

    if (action.type === "DELETE_BOT") {
        if (state) {
            const botId = action.payload;
            const botIndex = state.findIndex((b) => b.id === botId);
            if (botIndex !== -1) {
                state?.splice(botIndex, 1);
            }
            return [...state];
        }
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Bots = () => {
    const classes = useStyles();

    const { tenantId, userTenant } = useTenant();

    const [bots, dispatch] = useReducer(reducer, []);
    const [selectedBot, setSelectedBot] = useState(null);
    const [loading, setLoading] = useState(false);

    const [botModalOpen, setBotModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        if (!tenantId) return;

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchBots = async () => {
            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`${tenantId}/bots`);
                isMounted && dispatch({
                    type: "LOAD_BOTS",
                    payload: data,
                });
                isMounted && setLoading(false);
            } catch (err) {
                isMounted && setLoading(false);
                toastError(err, "", "Error while trying to get bots list info on bots page component.");
            }
        };
        fetchBots();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    // Handle Bot Changes
    const handleBotChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            // Only update bots on the top level
            data.bot.parentId === null &&
                dispatch({ type: "UPDATE_BOTS", payload: data.bot });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_BOT", payload: data.botId });
        }
    };
    useSocketListener(`bot`, (data) => handleBotChange(data))

    const [rows, setRows] = useState([{
        action: '',
        description: '',
        id: '',
        protocol: '',
        title: '',
        updatedAt: ''
    }])

    useEffect(() => {
        if (!bots) return;
        let dataGridRows = bots.map(b => ({
            action: b?.action,
            description: b?.description,
            id: b?.id,
            protocol: b?.protocol,
            title: b?.title,
            updatedAt: b?.updatedAt,
        }))
        setRows(dataGridRows)
    }, [bots, loading])

    const handleOpenBotModal = () => {
        setSelectedBot(null);
        setBotModalOpen(true);
    };

    const handleCloseBotModal = () => {
        setSelectedBot(null);
        setBotModalOpen(false);
    };

    const handleEditBot = (bot) => {
        setSelectedBot(bot);
        setBotModalOpen(true);
    };

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedBot(null);
    };

    const handleDeleteBot = async (botId) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/bots/${botId}`);
            toast.success(i18n.t("translation.botModal.toasts.deleted"));
        } catch (err) {
            toastError(err, "", "Error while trying to delete bot at Bots page.");
        }
        setSelectedBot(null);
    };

    const columns = useMemo(() => [
        {
            field: "protocol",
            headerName: i18n.t("translation.variables.table.protocol"),
            flex: 0.07,
            minWidth: 30,
            align: 'center',
            description: i18n.t("translation.bot.table.descriptions.protocol")
        },
        {
            field: "title",
            headerName: i18n.t("translation.variables.table.title"),
            flex: 0.4,
            minWidth: 120,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            renderCell: params => (
                <Tooltip arrow title={params.row.description}>
                    <div>
                        {params.row.title}
                    </div>
                </Tooltip>
            )
        },
        {
            field: "description",
            headerName: i18n.t("translation.variables.table.description"),
            flex: 0.7,
            minWidth: 160,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            // renderCell: params => {params.row.title}
        },
        {
            field: "action",
            headerName: i18n.t("translation.variables.table.action"),
            flex: 0.1,
            minWidth: 60,
            type: 'string',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <Tooltip arrow title={i18n.t(`translation.bots.tooltips.actions.${params.row.action}`)}>
                    <div>
                        {i18n.t(`translation.bots.actions.${params.row.action}`)}
                    </div>
                </Tooltip>
            )
        },
        {
            field: "updatedAt",
            headerName: i18n.t("translation.variables.table.lastUpdate"),
            flex: 0.2,
            minWidth: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => format(parseISO(params.row.updatedAt), "dd/MM/yy HH:mm")
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.1,
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    <Tooltip title={i18n.t("translation.bots.buttons.edit")}>
                        <IconButton
                            size="small"
                            color="primary"
                            className={classes.actionButtons}
                            onClick={() => handleEditBot(params.row.id)}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                    <Can
                        role={userTenant?.role}
                        perform="bot:delete"
                        yes={() => (
                            <IconButton
                                size="small"
                                className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                onClick={e => {
                                    setSelectedBot(params.row.id)
                                    setConfirmModalOpen(true);
                                }}
                            >
                                <DeleteOutline />
                            </IconButton>
                        )}
                    />
                </>
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [bots, loading]);

    return (
        <MainContainer>
            <ConfirmationModal
                title={i18n.t("translation.bots.confirmationModal.title")}
                open={confirmModalOpen}
                onClose={() => handleCloseConfirmationModal()}
                onConfirm={() => handleDeleteBot(selectedBot)}
            >
                {i18n.t("translation.bots.confirmationModal.message")}
            </ConfirmationModal>
            <BotModal
                open={botModalOpen}
                onClose={handleCloseBotModal}
                selectedBot={selectedBot}
            />
            <MainHeader>
                <Title>
                    {i18n.t("translation.bots.title")}
                    {/* <TutorialModalButton content="bots" /> */}
                </Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenBotModal()}
                    >
                        {i18n.t("translation.bots.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <PaperContainer>
                <Grid item sx={{
                    width: "100%"
                }}>
                    {rows && <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight={true}
                        getRowHeight={() => 'auto'}
                        pageSize={20}
                        getRowId={row => row.id}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                    />}

                </Grid>
            </PaperContainer>
        </MainContainer>
    );
};

export default Bots;
