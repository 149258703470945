import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import ButtonWithSpinner from "../ButtonWithSpinner";
import { UsersFilter } from "../UsersFilter";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";


const useStyles = makeStyles(theme => ({
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    input: {
        width: 300,
        marginBottom: theme.spacing(2)
    },
    maxWidth: {
        width: "100%",
    },
}));

const NewChatModal = ({
    chat,
    modalOpen,
    onClose,
    type
}) => {
    const classes = useStyles();

    const [title, setTitle] = useState("");
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const { tenant, tenantId } = useTenant();

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        setTitle("");
        setUsers([]);
        if (type === "edit") {
            const userList = chat?.users?.map((user) => (user?.user));
            setUsers(userList);
            setTitle(chat?.title);
        }
    }, [chat, modalOpen, type]);

    const handleClose = () => {
        onClose();
        setTitle("");
        setUsers([]);
    };

    const handleSaveChat = async () => {
        if (!users) return;
        setLoading(true);
        if (tenantId) {
            try {
                if (type === "edit") {
                    await axiosPrivate.put(`/${tenantId}/chats/${chat.id}`, {
                        users,
                        title,
                    });
                } else {
                    const { data } = await axiosPrivate.post(`/${tenantId}/chats`, {
                        users,
                        title,
                    });
                    navigate(`/${tenant.slug}/chats/${data.uuid}`);
                }
            } catch (err) {
                toastError(err, "", "Couldn't save chat at New Chat Modal");
            }
        }
        setLoading(false);
        handleClose();
    };

    return (
        <>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
                    {type === "new"
                        ? i18n.t("translation.newChatModal.title.new")
                        : i18n.t("translation.newChatModal.title.edit")
                    }
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label={i18n.t("translation.newChatModal.placeholders.title")}
                        value={title}
                        autoFocus={true}
                        onChange={(e) => setTitle(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                    <UsersFilter
                        onFiltered={(users) => setUsers(users)}
                        initialUsers={users}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.buttonColorError}
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("translation.validation.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={users?.length < 1 || !title}
                        onClick={() => handleSaveChat()}
                        color="primary"
                        loading={loading}
                    >
                        {type === "edit"
                            ? i18n.t("translation.validation.buttons.save")
                            : i18n.t("translation.validation.buttons.create")
                        }
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewChatModal;
