import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from "@mui/material";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	selectNoBottom: {
		marginBottom: "unset!important"
	}
}));

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
}) => {
	const classes = useStyles();

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div>
			<FormControl
				fullWidth
				size="small"
				className={classes.selectNoBottom}
			>
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						}
					}}
					renderValue={() => i18n.t("translation.ticketsQueueSelect.placeholder")}
				>
					{userQueues?.length > 0 &&
						userQueues.map(queue => (
							<MenuItem dense key={queue.id} value={queue.id}>
								<Checkbox
									style={{
										color: queue.color,
									}}
									size="small"
									color="primary"
									checked={selectedQueueIds.indexOf(queue.id) > -1}
								/>
								<ListItemText primary={queue.name} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsQueueSelect;
