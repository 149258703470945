import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
    DeleteOutline
} from "@mui/icons-material";

import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Tooltip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BotItems from "../BotItems";
import ConfirmationModal from "../ConfirmationModal";
import ConnectionItems from "../ConnectionItems";
import QueueItems from "../QueueItems";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import UserTenantItems from "../UserTenantItems";


const useStyles = makeStyles(theme => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    botOption: {
        cursor: "pointer",
        display: "flex",
        // alignItems: "center",
        // "& > *:not(:last-child)": {
        //     marginRight: theme.spacing(1),
        // },
    },
    btnWrapper: {
        position: "100%",
    },
    buttonMarginLeft: {
        marginLeft: theme.spacing(1)
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    extraAttr: {
        display: "flex",
        // justifyContent: "center",
        alignItems: "flex-start",
        "& div": {
            marginBottom: "unset"
        },
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        }
    },
    form: {
        width: "100%",
        // paddingTop: theme.spacing(2)
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    multFieldLineLeft: {
        display: "flex",
        justifyContent: "flex-start",
        // "& > *:not(:last-child)": {
        "& > *:last-child": {
            // marginRight: theme.spacing(1),
            marginRight: 0,
        },
        width: "100%",
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    stepper: {
        width: "100%",
        // maxWidth: theme.spacing(45)
    },
    title: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginLeft: theme.spacing(1),
        }
    }
}));

const BotSchema = Yup.object().shape({
    options: Yup.array()
        .of(
            Yup.object().shape({
                title: Yup.string().min(4, "too short").required("Required"),
            })
        )
        .required("Must have friends"),
});

function getChildBot(botId, botInfo, firstBot, updateCallback) {
    return <VerticalLinearStepper selectedBot={botId} bot={botInfo} firstBot={firstBot} updateCallback={updateCallback} />;
}

function VerticalLinearStepper(props) {
    const classes = useStyles();

    // const { selectedBot, bot, updateCallback, handleClose } = props;
    const { firstBot, selectedBot, updateCallback } = props;

    // Define the initial callback function as a no-op (no operation).
    const [childUpdateCallback, setChildUpdateCallback] = useState();
    const [originalBot, setOriginalBot] = useState(firstBot)

    // Bot actions
    // const botActions = ["bot", "channel", "media", "message", "queue", "user"];
    const botActions = ["bot", "channel", "message", "queue", "user"];

    // Default bot info
    const initialState = {
        action: "message",
        description: "",
        extra: {
            body: i18n.t("translation.botModal.form.newOption.body"),
            botId: "",
            channel: "whatsapp",
            channelId: "",
            media: "",
            queueId: "",
            userId: ""
        },
        id: "",
        mediaUrl: null,
        parentId: null,
        position: 0,
        options: [],
        order: 0,
        title: i18n.t("translation.botModal.form.newOption.title"),
        type: "list",
    };

    // const { user: loggedInUser } = useAuth();
    const { tenantId } = useTenant();
    const { whatsApps } = useWhatsApps();

    // Define current bot
    const [activeStep, setActiveStep] = useState(-1);
    const [steps, setSteps] = useState(initialState);
    const [loading, setLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchBot = async () => {
            let botData
            // Check if a bot is selected
            if (!selectedBot) {
                let { id, ...newBot } = initialState;
                botData = newBot;
            } else {
                // If a bot is selected, go on, otherwise, end
                try {
                    const { data } = await axiosPrivate.get(`${tenantId}/bots/${selectedBot}`);
                    botData = data;
                } catch (err) {
                    toastError(err, "", "Error while trying to get bot info on Bot Modal.");
                }
            }
            isMounted && setSteps(botData)
            setLoading(false);
        };
        fetchBot();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBot]);

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedOption(null);
    };

    useEffect(() => {
        if (!childUpdateCallback) return;

        // Update bot data
        setSteps(childUpdateCallback)
        if (steps.options.length > childUpdateCallback.options.length) setActiveStep(-1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childUpdateCallback])

    const handleSaveBot = async (values) => {
        try {
            const { data } = await axiosPrivate.put(`/${tenantId}/bots`, values);
            toast.success(i18n.t("translation.botModal.toasts.optionSaved"));

            // Check if new or current bot
            if (!values.id && data.parentBot) {
                setSteps(data.parentBot)
            } else if (values.parentId) {
                updateCallback
                    ? updateCallback(data.parentBot)
                    : setChildUpdateCallback(data.parentBot)
            } else {
                setOriginalBot(data.id)
                setSteps(data)
            }
        } catch (err) {
            toastError(err, "", "Error while trying to save bot information.");
        }
    };

    const handleDeleteBot = async (bot) => {
        try {
            await axiosPrivate.delete(`${tenantId}/bots/${bot.id}`);
            toast.success(i18n.t("translation.botModal.toasts.optionDeleted"));

            // Check in what level it should be deleted
            if (bot.parentId) {
                let updatedParentBot = steps.parentBot;

                // Create a new array without the option to remove and update the callback function
                updatedParentBot.options = updatedParentBot.options.filter(option => option.id !== bot.id);

                updateCallback
                    ? updateCallback(updatedParentBot)
                    : setChildUpdateCallback(updatedParentBot)
            }
        } catch (err) {
            toastError(err, "", "Error while trying to delete bot option on Bot Modal.");
        }
        setSelectedOption(null);
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={i18n.t("translation.bots.confirmationModal.option.title")}
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteBot(selectedOption)}
            >
                {i18n.t("translation.bots.confirmationModal.option.message")}
            </ConfirmationModal>

            {!loading && steps && (<>
                <Formik
                    initialValues={steps}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validationSchema={BotSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveBot(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ errors, isSubmitting, setFieldValue, touched, values }) => (
                        <div
                            className={classes.form}
                        >
                            <Form className={classes.form}>
                                {/* <FormObserver /> */}
                                <MultiFieldLine>
                                    <Field
                                        as={TextField}
                                        className={classes.textField}
                                        label={i18n.t("translation.variables.table.title")}
                                        name="title"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        size="small"
                                        fullWidth
                                        autoFocus
                                        error={touched?.title && Boolean(errors.title)}
                                    />
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel id="action-label">
                                            {i18n.t("translation.variables.table.action")}
                                        </InputLabel>

                                        <Field
                                            as={Select}
                                            label={i18n.t("translation.variables.table.action")}
                                            name="action"
                                            labelId="action-label"
                                            id="action"
                                            size="small"
                                            required
                                        >
                                            {botActions.map((action, index) => (
                                                <MenuItem value={action} key={index}>{i18n.t(`translation.bots.actions.${action}`)}</MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </MultiFieldLine>

                                {/* Add GPT button */}
                                {values.parentId === null && (
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.variables.table.description")}
                                        type="description"
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        name="description"
                                        // onFocus={handleFocus}
                                        error={touched.description && Boolean(errors.description)}
                                        helperText={touched.description && errors.description}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}

                                {/* Content if bot */}
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ display: values.action === "bot" ? "block" : "none" }}
                                >
                                    <InputLabel>{i18n.t("translation.botModal.form.option.bot")}</InputLabel>
                                    <Field
                                        as={Select}
                                        name={`extra.botId`}
                                        onChange={(e) => setFieldValue("extra.botId", e.target.value)}
                                        // required
                                        fullWidth
                                        label={i18n.t("translation.botModal.form.option.bot")}
                                    >
                                        <MenuItem value={''}>{i18n.t("translation.botModal.form.option.select")}</MenuItem>
                                        {BotItems(originalBot)}
                                    </Field>
                                </FormControl>

                                {/* Content if channel */}
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ display: values.action === "channel" ? "block" : "none" }}
                                >
                                    <InputLabel>{i18n.t("translation.botModal.form.option.connection")}</InputLabel>
                                    <Field
                                        as={Select}
                                        name={`extra.channelId`}
                                        onChange={(e) => {
                                            setFieldValue("extra.channel", "whatsapp")
                                            setFieldValue("extra.channelId", e.target.value)
                                        }}
                                        // required
                                        fullWidth
                                        label={i18n.t("translation.botModal.form.option.connection")}
                                    >
                                        <MenuItem value={''}>{i18n.t("translation.botModal.form.option.select")}</MenuItem>
                                        {ConnectionItems(whatsApps)}
                                    </Field>
                                </FormControl>

                                {/* Content if message */}
                                {/* Content if media should also be placed here. */}
                                <Field
                                    as={TextField}
                                    label={i18n.t("translation.bots.actions.message")}
                                    type={`extra.body`}
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    name={`extra.body`}
                                    // onFocus={handleFocus}
                                    variant="outlined"
                                    size="small"
                                    sx={{ display: values.action === "message" ? "block" : "none" }}
                                    onChange={(e) => setFieldValue("extra.body", e.target.value)}
                                />

                                {/* Content if queue */}
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ display: values.action === "queue" ? "block" : "none" }}
                                >
                                    <InputLabel>{i18n.t("translation.botModal.form.option.queue")}</InputLabel>
                                    <Field
                                        as={Select}
                                        name={`extra.queueId`}
                                        onChange={(e) => setFieldValue("extra.queueId", e.target.value)}
                                        // required
                                        fullWidth
                                        label={i18n.t("translation.botModal.form.option.queue")}
                                    >
                                        <MenuItem value={''}>{i18n.t("translation.botModal.form.option.select")}</MenuItem>
                                        {QueueItems()}
                                    </Field>
                                </FormControl>

                                {/* Content if user */}
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ display: values.action === "user" ? "block" : "none" }}
                                >
                                    <InputLabel>{i18n.t("translation.botModal.form.option.user")}</InputLabel>
                                    <Field
                                        as={Select}
                                        name={`extra.userId`}
                                        onChange={(e) => {
                                            setFieldValue("extra.userId", e.target.value)
                                        }}
                                        // required
                                        fullWidth
                                        label={i18n.t("translation.botModal.form.option.user")}
                                    >
                                        <MenuItem value={''}>{i18n.t("translation.botModal.form.option.select")}</MenuItem>
                                        {UserTenantItems()}
                                    </Field>
                                </FormControl>

                                {/* Action buttons */}
                                <div>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {/* {selectedBot */}
                                        {values.id
                                            ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                            : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                    </Button>
                                    {values.parentId && (
                                        <Tooltip title={i18n.t("translation.validation.buttons.delete")}>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setSelectedOption(values);
                                                    setConfirmModalOpen(true);
                                                }}
                                                disabled={isSubmitting}
                                                color="error"
                                                className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                            >
                                                <DeleteOutline />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            </Form>
                            {["bot", "channel", "queue", "user"].indexOf(values.action) < 0 && (<>
                                <div className={classes.dividerSilent} />
                                <FieldArray name="options">
                                    <Stepper
                                        nonLinear
                                        activeStep={activeStep}
                                        orientation="vertical"
                                        className={classes.stepper}
                                    >
                                        {
                                            values.options &&
                                            values.options.length > 0 &&
                                            values.options.map((step, index) => (
                                                <Step
                                                    key={`${step.id ? step.id : index}-options`}
                                                >
                                                    <StepLabel
                                                        key={`${step.id}-options`}
                                                        className={classes.botOption}
                                                        onClick={() => setActiveStep(index === activeStep ? -1 : index)}
                                                    >
                                                        {step.title}
                                                    </StepLabel>
                                                    <StepContent>
                                                        {getChildBot(step.id, step, originalBot, setChildUpdateCallback)}
                                                    </StepContent>

                                                </Step>
                                            ))}

                                        {/* Add extra option */}
                                        <Step>
                                            <StepLabel
                                                className={classes.botOption}
                                                onClick={() => {
                                                    let { id, ...newBot } = initialState;
                                                    newBot.parentId = values.id;
                                                    handleSaveBot(newBot);
                                                    setActiveStep(values.options.length)
                                                }}
                                            >
                                                {i18n.t("translation.botModal.form.option.addOption")}
                                            </StepLabel>
                                        </Step>
                                    </Stepper>
                                </FieldArray>
                            </>)}
                        </div>
                    )}
                </Formik>
            </>)
            }
        </div >
    );
};

export default VerticalLinearStepper;
