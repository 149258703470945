// This optional code is used to register a service worker.
// register() is not called by default.


// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

import firebaseConfig from "../firebase/config";
import logger from "../logger";

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
    // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    if ('serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        window.addEventListener('load', () => {
            // const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
            const swUrl = 'service-worker.js';

            // Setting up service worker registration as well
            const swFirebaseUrl = `firebase-messaging-sw.js?${new URLSearchParams(firebaseConfig)}`;

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config, 'Localhost: CRA Service Worker install WPA registered.', true);

                // Setting up verification for Firebase
                checkValidServiceWorker(swFirebaseUrl, config, 'Localhost: Firebase Messaging Service service worker registered.', false);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    logger({ type: "info", title: "App", content: "This web app is being served cache-first by a service worker." })
                });
            } else {
                // Is not localhost. Just register service worker
                registerAndInstallValidSW(swUrl, config, 'CRA Service Worker install WPA registered.', true);

                // Also register the Firebase
                registerAndInstallValidSW(swFirebaseUrl, config, 'Firebase Messaging Service service worker registered.', false);
            }
        });
    }
}

function registerAndInstallValidSW(swUrl, config, message, install) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            logger({ type: "info", title: `App (${message || "Service Worker"})`, content: registration.scope })
            if (install) {
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker == null) {
                        return;
                    }
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                // At this point, the updated precached content has been fetched,
                                // but the previous service worker will still serve the older
                                // content until all client tabs are closed.
                                logger({ type: "info", title: `App`, content: "New content is available and will be used when all tabs for this page are closed." })

                                // Execute callback
                                if (config && config.onUpdate) {
                                    config.onUpdate(registration);
                                }
                            } else {
                                // At this point, everything has been precached.
                                // It's the perfect time to display a
                                // "Content is cached for offline use." message.
                                logger({ type: "info", title: `App`, content: "Content is cached for offline or background use." })

                                // Execute callback
                                if (config && config.onSuccess) {
                                    config.onSuccess(registration);
                                }
                            }
                        }
                    };
                };
            }
        })
        .catch((err) => {
            console.error('Error during service worker registration:', err);
        });
}

function checkValidServiceWorker(swUrl, config, message, install) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerAndInstallValidSW(swUrl, config, message, install);
            }
        })
        .catch(() => {
            logger({ type: "error_info", title: "Connection", content: "No internet connection found. App is running in offline mode." })
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((err) => {
                console.error(err.message);
            });
    }
}
