import React from "react";

import { Avatar, CardHeader } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import avatar from "../../services/avatar";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "500",
    },
}));

const ChatInfo = ({ chat, onClick }) => {
    const classes = useStyles();

    const users = chat?.isGroup
        ? chat?.users?.map((u) => (u.user.name))
        : chat?.users?.find(u => u.user.id !== chat.ownerId)?.user?.name;

    const getContact = () => {
        let data = chat?.users?.find(u => u?.user?.id !== chat?.ownerId);
        return data?.user
    }

    return (
        <CardHeader
            onClick={onClick}
            style={{ cursor: "pointer" }}
            titleTypographyProps={{ noWrap: true }}
            subheaderTypographyProps={{ noWrap: true }}
            avatar={
                <Avatar
                    src={chat.isGroup
                        ? avatar(chat?.title, true)
                        : avatar(getContact()?.email)}
                    alt={chat.isGroup
                        ? chat.title
                        : getContact()?.name}
                    variant="rounded"
                />
            }
            title={
                <span>
                    <strong>{chat?.title}</strong>
                </span>
            }
            subheader={
                chat.isGroup
                    ? i18n.t("translation.chat.header.chatMembers.group") + ": " + users.join(", ")
                    : i18n.t("translation.chat.header.chatMembers.single") + " " + users
            }
            className={classes.title}
        />
    );
};

export default ChatInfo;
