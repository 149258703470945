import React, { useCallback, useEffect, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import findDefaultSystemTagName from '../../../components/DefaultSystemTagName';
import Divider from '../../../components/Divider';
import Title from '../../../components/LayoutComponents/CardTitle';
import toastError from "../../../errors/toastError";
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import useTenant from '../../../hooks/useTenant';
import { i18n } from "../../../translate/i18n";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    wrap: {
        whiteSpace: "normal !important",
        wordWrap: "break-word !important"
    },

    graph: {
        width: "100%",
        maxWidth: "60%",
        textAlign: "center",
        flex: 1,
        flexFlow: "column",
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
        }
    },

    funnel: {
        margin: "0 auto 20px",
        padding: "0 15px",
        width: "100%",
        maxWidth: "calc(100% - 60px)",
        textAlign: "center",
        // minHeight: "420px",
    },

    step: {
        position: "relative",
        marginTop: "10px",
        padding: "10px 0 10px 35px",
        minHeight: "95px",
        textAlign: "left",
        '&::before': {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: "-29px",
            width: 0,
            height: 0,
        },
        '&::after': {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            right: "-29px",
            width: 0,
            height: 0,
        }
    },

    bold: {
        fontWeight: "bold",
    },

    total: {
        width: "100%",
        color: "#ffffff",
        backgroundColor: "#161B44",
        borderTop: "#161B44",
        '&::before, &::after': {
            borderTop: "95px solid #161B44",
        },
        '&::before': {
            borderLeft: "30px solid transparent",
        },
        '&::after': {
            borderRight: "30px solid transparent",
        }
    },

    leads: {
        width: "calc(100% - 70px)",
        backgroundColor: "#00B38D",
        left: "35px",
        borderTop: "#00B38D",
        '&::before, &::after': {
            borderTop: "95px solid #00B38D",
        },
        '&::before': {
            borderLeft: "30px solid transparent",
        },
        '&::after': {
            borderRight: "30px solid transparent",
        }
    },

    prospects: {
        width: "calc(100% - 140px)",
        backgroundColor: "#56ECC3",
        left: "70px",
        borderTop: "#56ECC3",
        '&::before, &::after': {
            borderTop: "95px solid #56ECC3",
        },
        '&::before': {
            borderLeft: "30px solid transparent",
        },
        '&::after': {
            borderRight: "30px solid transparent",
        }
    },

    customers: {
        width: "calc(100% - 210px)",
        backgroundColor: "#75e900",
        left: "105px",
        borderTop: "#75e900",
        '&::before, &::after': {
            borderTop: "95px solid #75e900",
        },
        '&::before': {
            borderLeft: "30px solid transparent",
        },
        '&::after': {
            borderRight: "30px solid transparent",
        }
    },

    rate: {
        position: "absolute",
        top: "60px",
        right: "35px",
        borderRadius: "50%",
        zIndex: 2,
        width: "80px",
        height: "80px",
        paddingTop: "10px",
        border: "10px solid #fff",
        color: "#fff",
        textAlign: "center",
        fontSize: "28px",
        fontWeight: 700,
        '&::after': {
            content: '"%"',
            fontSize: "50px",
            position: "absolute",
            translate: "-81% -23%",
            opacity: "34%"
        }
    },

    benchmark: {
        width: "100%",
        maxWidth: "40%",
        textAlign: "center",
        flex: 1,
        flexFlow: "column",
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
        }
    },

    benchmarkDescription: {
        margin: "10px"
    },

    comparisonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    block: {
        border: "1px solid #cecece",
        borderRadius: 8,
        padding: "2px 8px"
    },
    vsBlock: {
        borderRadius: "50%",
        margin: "-5px",
        backgroundColor: "#fff",
        zIndex: 2,
        width: "30px",
        height: "30px",
        border: "1px solid #cecece",
        textAlign: "center",
    }
}))

const Tags = () => {
    const classes = useStyles();

    const [pageNumber] = useState(1);
    const [searchParam] = useState("");
    const [tags, setTags] = useState();
    const [industry, setIndustry] = useLocalStorage("industryPersist", "services", false);
    const [rate, setRate] = useState({ industry: "services", leads: 0.041, prospects: 0.227, customers: 0.358 });

    const [benchmark] = useState([
        { industry: "ecommerce", leads: 0.030, prospects: 0.211, customers: 0.614 },
        { industry: "marketing", leads: 0.040, prospects: 0.135, customers: 0.240 },
        { industry: "consulting", leads: 0.045, prospects: 0.123, customers: 0.379 },
        { industry: "school", leads: 0.063, prospects: 0.121, customers: 0.321 },
        { industry: "engineering", leads: 0.038, prospects: 0.176, customers: 0.373 },
        { industry: "events", leads: 0.043, prospects: 0.102, customers: 0.572 },
        { industry: "finance", leads: 0.046, prospects: 0.148, customers: 0.239 },
        { industry: "electronics", leads: 0.055, prospects: 0.116, customers: 0.607 },
        { industry: "realstate", leads: 0.029, prospects: 0.131, customers: 0.383 },
        { industry: "media", leads: 0.038, prospects: 0.108, customers: 0.268 },
        { industry: "ongs", leads: 0.047, prospects: 0.126, customers: 0.244 },
        { industry: "healthcare", leads: 0.051, prospects: 0.224, customers: 0.363 },
        { industry: "services", leads: 0.041, prospects: 0.227, customers: 0.358 },
        { industry: "coaching", leads: 0.050, prospects: 0.162, customers: 0.209 },
        { industry: "software", leads: 0.057, prospects: 0.122, customers: 0.148 },
        { industry: "telecom", leads: 0.057, prospects: 0.436, customers: 0.147 },
        { industry: "tourism", leads: 0.051, prospects: 0.239, customers: 0.252 },
        { industry: "retail", leads: 0.026, prospects: 0.217, customers: 0.169 },
    ]);

    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    const fetchTags = useCallback(async () => {
        const controller = new AbortController();
        let isMounted = true;
        if (isMounted === true) {
            const fetchData = async () => {
                if (tenantId) {
                    try {
                        const { data } = await axiosPrivate.get(`/${tenantId}/tags/`, {
                            params: { searchParam, pageNumber },
                        });

                        let systemTags = data.tags.filter((tag) => {
                            return tag.system === true;
                        })

                        let chats = 0;
                        for (let tag of systemTags) {
                            chats += tag.count;
                        }

                        let getTag = (createdAs) => {
                            return systemTags.find((tag) => {
                                return tag.createdAs === createdAs;
                            })
                        }

                        let systemTagsObj = {
                            total: chats,
                            lead: getTag("Lead"),
                            prospect: getTag("Prospect"),
                            customer: getTag("Customer"),
                            win: getTag("Win"),
                            loss: getTag("Loss"),
                            schedule: getTag("Schedule")
                        }

                        setTags(systemTagsObj);

                    } catch (err) {
                        toastError(err);
                    }
                }
            }
            fetchData();
        }
        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, tenantId]);

    useEffect(() => {
        if (tenantId) fetchTags();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    useEffect(() => {
        const getRate = () => {
            return benchmark.find((dataSet) => {
                return dataSet.industry === industry;
            })
        };
        setRate(getRate())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [industry])

    const compareRate = (total, use, attribute) => {
        const businessRate = use / total || 0;
        const industryRate = rate[attribute] || 0;

        const format = number => {
            const multiply = number * 100;
            const int = isNaN(parseInt(multiply)) ? 0 : parseInt(multiply);

            switch (int?.toString()?.length) {
                case 3:
                    return int;
                case 2:
                    return multiply.toFixed(1);
                case 1:
                    return isFinite(multiply.toFixed(2)) ? multiply.toFixed(2) : "100";
                default:
                    return "0.00";
            }
        }

        return {
            winner: businessRate > industryRate,
            businessRate: format(businessRate),
            industryRate: format(industryRate)
        };
    };

    return (
        <>
            <Title>{i18n.t("translation.dashboard.tagsFunnel.title")}</Title>
            <Grid container spacing={3}>
                <Grid item xs={6} className={classes.graph}>
                    {tags && (
                        <>
                            <Typography component="h3" variant="h6" color="primary" >
                                {i18n.t("translation.dashboard.tagsFunnel.graph.title")}
                            </Typography>

                            <div className={classes.funnel}>
                                <div className={[classes.total, classes.step].join(" ")}>
                                    <Typography component="p" variant="h6" >
                                        {i18n.t("translation.dashboard.tagsFunnel.graph.chats")}
                                    </Typography>
                                    <Typography component="p" variant="h4" className={classes.bold} >
                                        {tags.total}
                                    </Typography>
                                    <div className={classes.rate} style={{
                                        backgroundColor: compareRate(tags.total, tags.lead.count, "leads").winner ? '#88e900' : '#f01c53'
                                    }}>
                                        {compareRate(tags.total, tags.lead.count, "leads").businessRate}
                                    </div>
                                </div>

                                <div className={[classes.leads, classes.step].join(" ")}>
                                    <Typography component="p" variant="h6" >
                                        {findDefaultSystemTagName(tags.lead)}
                                    </Typography>
                                    <Typography component="p" variant="h4" className={classes.bold}>
                                        {tags.lead.count}
                                    </Typography>
                                    <div className={classes.rate} style={{
                                        backgroundColor: compareRate(tags.lead.count, tags.prospect.count, "prospects").winner ? '#88e900' : '#f01c53'
                                    }}>
                                        {compareRate(tags.lead.count, tags.prospect.count, "customers").businessRate}
                                    </div>
                                </div>

                                <div className={[classes.prospects, classes.step].join(" ")}>
                                    <Typography component="p" variant="h6" >
                                        {findDefaultSystemTagName(tags.prospect)}
                                    </Typography>
                                    <Typography component="p" variant="h4" className={classes.bold}>
                                        {tags.prospect.count}
                                    </Typography>
                                    <div className={classes.rate} style={{
                                        backgroundColor: compareRate(tags.prospect.count, tags.customer.count, "customers").winner ? '#88e900' : '#f01c53'
                                    }}>
                                        {compareRate(tags.prospect.count, tags.customer.count, "customers").businessRate}
                                    </div>
                                </div>

                                <div className={[classes.customers, classes.step].join(" ")}>
                                    <Typography component="p" variant="h6">
                                        {findDefaultSystemTagName(tags.customer)}
                                    </Typography>
                                    <Typography component="p" variant="h4" className={classes.bold}>
                                        {tags.customer.count}
                                    </Typography>
                                </div>

                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={6} className={classes.benchmark}>
                    <Typography component="h3" variant="h6" color="primary">
                        {i18n.t("translation.dashboard.tagsFunnel.benchmark.title")}
                    </Typography>

                    <Typography className={classes.benchmarkDescription}>
                        {i18n.t("translation.dashboard.tagsFunnel.benchmark.description")}
                    </Typography>

                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                        fullWidth
                    >
                        <InputLabel id="industry-selection-input-label">
                            {i18n.t("translation.dashboard.tagsFunnel.industryOptions.title")}
                        </InputLabel>
                        <Select
                            size="small"
                            variant="outlined"
                            label={i18n.t("translation.dashboard.tagsFunnel.industryOptions.title")}
                            id="industry-selection"
                            name="industry-selection"
                            value={industry}
                            className={classes.settingOption}
                            onChange={(e) => setIndustry(e.target.value)}
                        >
                            {benchmark.map((industry) => (
                                <MenuItem key={industry.industry} value={industry.industry}>
                                    {i18n.t("translation.dashboard.tagsFunnel.industryOptions." + industry.industry)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Divider silent={true} />

                    {tags && (
                        <>
                            <div>
                                <Typography variant="h6" className={classes.bold} >
                                    <div className={classes.comparisonContainer}>
                                        <div className={classes.block}>
                                            {compareRate(tags.total, tags.lead.count, "leads").businessRate + "%"}
                                        </div>
                                        <div className={classes.vsBlock}>
                                            <Typography color="secondary" className={classes.bold}>vs</Typography>
                                        </div>
                                        <div className={classes.block}>
                                            {compareRate(tags.total, tags.lead.count, "leads").industryRate + "%"}
                                        </div>
                                    </div>
                                </Typography>
                                <Typography variant="p" className={classes.bold} >
                                    {i18n.t("translation.dashboard.tagsFunnel.benchmark.visitorsToLeads.title")}
                                </Typography>
                                <Typography >
                                    {compareRate(tags.total, tags.lead.count, "leads").winner
                                        ? i18n.t("translation.dashboard.tagsFunnel.benchmark.visitorsToLeads.win")
                                        : i18n.t("translation.dashboard.tagsFunnel.benchmark.visitorsToLeads.loose")}
                                </Typography>
                            </div>

                            <Divider silent={true} />

                            <div>
                                <Typography variant="h6" className={classes.bold} >
                                    <div className={classes.comparisonContainer}>
                                        <div className={classes.block}>
                                            {compareRate(tags.lead.count, tags.prospect.count, "prospects").businessRate + "%"}
                                        </div>
                                        <div className={classes.vsBlock}>
                                            <Typography color="secondary" className={classes.bold}>vs</Typography>
                                        </div>
                                        <div className={classes.block}>
                                            {compareRate(tags.lead.count, tags.prospect.count, "prospects").industryRate + "%"}
                                        </div>
                                    </div>
                                </Typography>
                                <Typography variant="p" className={classes.bold} >
                                    {i18n.t("translation.dashboard.tagsFunnel.benchmark.leadsToProspects.title")}
                                </Typography>
                                <Typography >
                                    {compareRate(tags.lead.count, tags.prospect.count, "prospects").winner
                                        ? i18n.t("translation.dashboard.tagsFunnel.benchmark.leadsToProspects.win")
                                        : i18n.t("translation.dashboard.tagsFunnel.benchmark.leadsToProspects.loose")}
                                </Typography>
                            </div>

                            <Divider silent={true} />

                            <div>
                                <Typography variant="h6" className={classes.bold} >
                                    <div className={classes.comparisonContainer}>
                                        <div className={classes.block}>
                                            {compareRate(tags.prospect.count, tags.customer.count, "customers").businessRate + "%"}
                                        </div>
                                        <div className={classes.vsBlock}>
                                            <Typography color="secondary" className={classes.bold}>vs</Typography>
                                        </div>
                                        <div className={classes.block}>
                                            {compareRate(tags.prospect.count, tags.customer.count, "customers").industryRate + "%"}
                                        </div>
                                    </div>
                                </Typography>
                                <Typography variant="p" className={classes.bold} >
                                    {i18n.t("translation.dashboard.tagsFunnel.benchmark.prospectsToCustomers.title")}
                                </Typography>
                                <Typography >
                                    {compareRate(tags.prospect.count, tags.customer.count, "customers").winner
                                        ? i18n.t("translation.dashboard.tagsFunnel.benchmark.prospectsToCustomers.win")
                                        : i18n.t("translation.dashboard.tagsFunnel.benchmark.prospectsToCustomers.loose")}
                                </Typography>
                            </div>
                        </>
                    )
                    }
                </Grid>
            </Grid>

        </>
    )
}

export default Tags;
