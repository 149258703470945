import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useRef, /*useMemo,*/ useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

// Styling
import { AttachFile, Cancel } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    Tooltip,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Components
import ConnectionItems from "../ConnectionItems";
import Divider from "../Divider";
import CheckboxInput from "../FormComponents/CheckboxInput";
import DateTimeField from "../FormComponents/DateFieldInput/DateTimeField";
import FormContainer from "../FormComponents/FormContainer";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import TextFieldInput from "../FormComponents/TextFieldInput";
import GPTModalButton from "../GPTModalButton";
import InputVariables from "../InputVariables";

// Hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";
import BotSelect from "../BotSelect";
import ContactGroupSelect from "../ContactGroupSelect";
import QueueSelectSingle from "../QueueSelectSingle";

const useStyles = makeStyles((theme) => ({
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        marginTop: "10px",
        borderColor: "rgba(0, 0, 0, 0.23)",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    btnWrapper: {
        position: "relative",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    labelPaddingInput: {
        marginTop: "-3px",
        "& input": {
            textAlign: "center"
        }
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    modal: {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 auto",
    },
    newMessageBox: {
        marginTop: theme.spacing(1),
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "left",
        border: "1px solid",
        borderRadius: 15,
    },
    removeBottomMargin: {
        marginBottom: 0,
    },
    status: {
        display: "flex",
        justifyContent: "center",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    uploadInput: {
        display: "none",
    },
}));

const CampaignSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    message: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .max(1200, `${i18n.t("translation.validation.too_long")}`),
});

export function CampaignModal({ open, onClose, campaignId, usage, viewer }) {
    const classes = useStyles();
    // const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const fieldRef = useRef(null);

    // Context
    const { tenantId, userTenantId } = useTenant();
    const { whatsApps } = useWhatsApps();

    // State
    // const [campaignId, setCampaignId] = useState();
    const [medias, setMedias] = useState([]);

    const initialState = {
        wpId: "",
        name: i18n.t("translation.variables.campaign.name") + "_" + formatDate(),
        message: `${i18n.t("translation.variables.campaign.content")} {{firstName}}, `,
        hasMedia: false,
        sendAt: "",
        status: "preview",
        contactGroupId: "",

        customSettings: false,

        customDelayMin: 1,
        customDelayMax: 5,
        sleepBetween: false,
        sleepAfter: 20,
        sleepDelay: 190,

        openTicket: false,
        botId: "",
        queueId: "",
    };
    const [inputs, setInputs] = useState(initialState);

    // Create a new date format for initial campaign name.
    function formatDate(date) {
        if (!date) date = new Date()
        return date
            .toISOString()
            .substring(0, 19)
            .replaceAll("T", ".")
            .replaceAll("-", "")
            .replaceAll(":", "")
    }

    // Handle focus on different fields
    const handleFocus = (e) => {
        fieldRef.current = e.target;
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
        // setInputs((values) => ({ ...inputs,...values, [name]: value }))
    };

    // Get campaign data in case need updating
    const fetchCampaign = useCallback(async () => {
        if (!campaignId || !tenantId) return;

        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/campaign/${campaignId}`);

            // Fix common issues
            // Fix Bot ID
            if (data.botId === null) data.botId = ""

            // Fix Date "yyyy-MM-ddThh:mm:ss"
            let getLocalDate = new Date(new Date(data.sendAt).toLocaleString())
            let getDate = getLocalDate.toLocaleDateString('sv-SE')
            let getTime = getLocalDate.toLocaleTimeString('en-us', { hour12: false, hour: '2-digit', minute: '2-digit' })
            data.sendAt = getDate + "T" + getTime;

            setInputs((prev) => ({ ...prev, ...data }));
        } catch (err) {
            toastError(err);
        }
    }, [axiosPrivate, campaignId, tenantId]);

    useEffect(() => {
        if (!open) return;
        fetchCampaign();
    }, [fetchCampaign, open])

    const handleClose = () => {
        setInputs(initialState);
        setMedias([]);
        // setCampaignId();
        onClose();
    }

    // Handle save data
    const handleSave = (values) => {
        // Add userTenant Value
        let campaignData = {
            ...inputs,
            ...values,
            userTenantId,
        }

        setInputs(campaignData);
        checkContent(campaignData);
    };

    // Helper function to verify content
    const checkContent = (inputs) => {
        if (!inputs.wpId) {
            toast.error(i18n.t("translation.campaigns.toasts.error.noWpId"));
            return;
        }
        if (!inputs.contactGroupId) {
            toast.error(i18n.t("translation.campaigns.toasts.error.noContacts"));
            return;
        }

        // If no items are selected, remove these from que payload.
        if (inputs.botId === "") delete inputs.botId;
        if (inputs.queueId === "") delete inputs.queueId;

        // TODO: add verification on credit and limit ussage for upsell
        // if (contacts.length > usage.limit - usage.usage) {
        //     toast.error(i18n.t("translation.campaigns.toasts.error.noCredits"));
        //     return;
        // }
        handleSaveCampaign(inputs);
    };

    // Save campaign data
    const handleSaveCampaign = async (inputs) => {
        try {
            let values;

            if (medias.length > 0) {
                values = new FormData();
                values.append("userTenantId", inputs.userTenantId);

                values.append("wpId", inputs.wpId);
                values.append("name", inputs.name);
                values.append("message", inputs.message);
                values.append("hasMedia", inputs.hasMedia);
                values.append("sendAt", inputs.sendAt);
                values.append("status", "preview");
                values.append("contactGroupId", inputs.contactGroupId);

                values.append("customSettings", inputs.customSettings);

                values.append("customDelayMin", inputs.customDelayMin);
                values.append("customDelayMax", inputs.customDelayMax);
                values.append("sleepBetween", inputs.sleepBetween);
                values.append("sleepAfter", inputs.sleepAfter);
                values.append("sleepDelay", inputs.sleepDelay);

                values.append("openTicket", inputs.openTicket);
                inputs?.botId && values.append("botId", inputs.botId);
                inputs?.queueId && values.append("queueId", inputs.queueId);

                medias.forEach((media) => {
                    values.append("file", media);
                });
            } else {
                values = inputs;
            }

            if (campaignId) {
                await axiosPrivate.put(
                    `/${tenantId}/campaign/${campaignId}`,
                    values ? values : inputs
                );
            } else {
                const { data: responseData } = await axiosPrivate.post(
                    `/${tenantId}/campaign`,
                    values ? values : inputs
                );
                // setCampaignId(responseData.id);
                campaignId = responseData.id;
            }

            toast.success(i18n.t("translation.campaigns.toasts.saved"));
            // TODO: Go to campaign page
            // navigate(`/${tenant.slug}/reports/campaign/${campaignId}`);
        } catch (err) {
            toast.error(i18n.t("translation.campaigns.toasts.error.saveCampaign") + err.message);
        }
        handleClose();
    };

    // If has media, set them
    useEffect(() => {
        let setHasMedia;
        medias.length > 0 ? (setHasMedia = true) : (setHasMedia = false);
        if (inputs.hasMedia !== setHasMedia) {
            setInputs((inputs) => ({ ...inputs, hasMedia: setHasMedia }));
        }
    }, [inputs.hasMedia, medias]);

    // TODO: componentize
    const handleInputMedias = (e) => {
        if (!e.target.files) return;
        const selectedMedias = Array.from(e.target.files);
        let renamedMedias = [];
        for (let media of selectedMedias) {
            const file = new File([media], `cpg_${media.name}`, {
                type: media.type,
            });
            renamedMedias.push(file);
        }
        setMedias(renamedMedias);
    };

    //Componentize
    const InputFile = () => {
        if (medias?.length > 0) {
            return (
                <>
                    <div className={classes.newMessageBox}>
                        <Button
                            startIcon={<Cancel color="error" />}
                            component="span"
                            onClick={(e) => setMedias([])}
                            size="small"
                        >
                            <Typography>
                                {medias[0]?.name}
                                {medias.length > 1
                                    ? i18n.t("translation.validation.medias.hasOthers", { medias: medias.length })
                                    : ""}
                            </Typography>
                        </Button>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className={classes.newMessageBox}>
                        <input
                            multiple
                            type="file"
                            id="upload-button2"
                            className={classes.uploadInput}
                            onChange={handleInputMedias}
                        />
                        <label htmlFor="upload-button2">
                            <Button
                                startIcon={<AttachFile />}
                                component="span"
                                size="small"
                            >
                                <Typography>
                                    {i18n.t("translation.validation.medias.none")}
                                </Typography>
                            </Button>
                        </label>
                    </div>
                </>
            );
        }
    };

    return open && (<>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                {i18n.t("translation.campaigns.form.title")}
            </DialogTitle>
            <Formik
                initialValues={inputs}
                enableReinitialize={true}
                validationSchema={CampaignSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSave(values);
                        // actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ errors, getFieldProps, isSubmitting, setFieldValue, touched, values }) => (
                    <FormContainer>
                        <DialogContent dividers>
                            <MultiFieldLine>
                                <TextFieldInput
                                    name="name"
                                    label={i18n.t("translation.campaigns.form.name")}
                                    size="small"
                                    required
                                    disabled={isSubmitting}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    onFocus={handleFocus}
                                    onBlur={(e) => { handleChange(e) }}
                                />

                                <DateTimeField
                                    name="sendAt"
                                    label={i18n.t("translation.scheduledMessagesModal.form.date")}
                                    size="small"
                                    disabled={isSubmitting}
                                    error={touched.sendAt && Boolean(errors.sendAt)}
                                    helperText={touched.sendAt && errors.sendAt}
                                    onChange={(e) => { handleChange(e) }}
                                />
                            </MultiFieldLine>

                            <ContactGroupSelect
                                current=""
                                fieldId="contactGroupId"
                                onChange={(e) => { handleChange(e) }}
                            />

                            <FormControl
                                variant="outlined"
                                size="small"
                                className={classes.maxWidth}
                                fullWidth
                            >
                                <InputLabel>
                                    {i18n.t("translation.campaigns.form.id")}
                                </InputLabel>
                                <Field
                                    as={Select}
                                    label={i18n.t("translation.campaigns.form.id")}
                                    name="wpId"
                                    variant="outlined"
                                    size="small"
                                    className={classes.textField}
                                    fullWidth
                                    placeholder={i18n.t("translation.campaigns.form.id")}
                                    onChange={(e) => { handleChange(e) }}
                                >
                                    {ConnectionItems(whatsApps)}
                                </Field>
                            </FormControl>

                            <GPTModalButton
                                disabled={isSubmitting}
                                field={fieldRef.current || getFieldProps("message")}
                                setFieldValue={setFieldValue}
                                prompt={i18n.t("translation.gptModal.prompts.campaignMessage")}
                            />
                            <TextFieldInput
                                name="message"
                                label={i18n.t("translation.campaigns.form.message")}
                                autoFocus
                                onFocus={handleFocus}
                                multiline
                                minRows={3}
                                error={touched.message && Boolean(errors.message)}
                                helperText={touched.message && errors.message}
                                size="small"
                                onBlur={(e) => { handleChange(e) }}
                            />

                            {campaignId
                                ? null
                                : (<>
                                    <InputVariables
                                        group="campaigns"
                                        field={fieldRef.current || getFieldProps("message")}
                                        setFieldValue={setFieldValue}
                                    />
                                    <InputFile />
                                    <Divider silent={true} />
                                </>)
                            }

                            <div>
                                <Accordion
                                    className={classes.accordion}
                                    expanded={values.customSettings}
                                >
                                    <AccordionSummary>
                                        <Tooltip title={i18n.t("translation.campaignModal.form.customDelay.note")} placement="top">
                                            <div>
                                                <CheckboxInput
                                                    label={i18n.t("translation.campaignModal.form.customDelay.title")}
                                                    name="customSettings"
                                                    className={classes.removeBottomMargin}
                                                />
                                            </div>
                                        </Tooltip>
                                    </AccordionSummary>

                                    <AccordionDetails className={classes.flexWrap} >
                                        <MultiFieldLine left={true}>
                                            <Typography>
                                                {i18n.t("translation.campaignModal.form.customDelay.delayInfo")}
                                            </Typography>
                                            <TextFieldInput
                                                name="customDelayMin"
                                                type="number"
                                                variant="standard"
                                                inputProps={{
                                                    min: 0,
                                                    max: 1440,
                                                }}
                                                style={{ width: 50 }}
                                                className={classes.labelPaddingInput}
                                            />
                                            <Typography>
                                                {i18n.t("translation.campaignModal.form.customDelay.delayInfoBetween")}
                                            </Typography>
                                            <TextFieldInput
                                                name="customDelayMax"
                                                type="number"
                                                variant="standard"
                                                inputProps={{
                                                    min: values.customDelayMin,
                                                    max: 1440,
                                                }}
                                                style={{ width: 50 }}
                                                className={classes.labelPaddingInput}
                                            />
                                            <Typography>
                                                {i18n.t("translation.campaignModal.form.customDelay.delayInfoEnd")}
                                            </Typography>
                                        </MultiFieldLine>

                                        <Tooltip title={i18n.t("translation.campaignModal.form.sleepBetween.note")} placement="top">
                                            <div>
                                                <CheckboxInput
                                                    label={i18n.t("translation.campaignModal.form.sleepBetween.title")}
                                                    name="sleepBetween"
                                                    className={classes.removeBottomMargin}
                                                />
                                            </div>
                                        </Tooltip>
                                        <Divider silent={true} />
                                        <Accordion
                                            expanded={values.sleepBetween}
                                        >
                                            <div style={{ display: values.sleepBetween ? "block" : "none" }}>
                                                <MultiFieldLine left={true}>
                                                    <Typography>
                                                        {i18n.t("translation.campaignModal.form.sleepBetween.sleepInfo")}
                                                    </Typography>
                                                    <TextFieldInput
                                                        name="sleepAfter"
                                                        type="number"
                                                        variant="standard"
                                                        inputProps={{
                                                            min: 0,
                                                            max: 1440,
                                                        }}
                                                        style={{ width: 50 }}
                                                        className={classes.labelPaddingInput}
                                                    />
                                                    <Typography>
                                                        {i18n.t("translation.campaignModal.form.sleepBetween.sleepInfoBetween")}
                                                    </Typography>
                                                    <TextFieldInput
                                                        name="sleepDelay"
                                                        type="number"
                                                        variant="standard"
                                                        inputProps={{
                                                            min: values.sleepAfter,
                                                            max: 1440,
                                                        }}
                                                        style={{ width: 50 }}
                                                        className={classes.labelPaddingInput}
                                                    />
                                                    <Typography>
                                                        {i18n.t("translation.campaignModal.form.sleepBetween.sleepInfoEnd")}
                                                    </Typography>
                                                </MultiFieldLine>
                                            </div>
                                        </Accordion>

                                        <Divider silent={true} />

                                        <Tooltip title={i18n.t("translation.campaignModal.form.openTicket.note")} placement="top">
                                            <div>
                                                <CheckboxInput
                                                    label={i18n.t("translation.campaignModal.form.openTicket.title")}
                                                    name="openTicket"
                                                    className={classes.removeBottomMargin}
                                                />
                                            </div>
                                        </Tooltip>

                                        <Divider />

                                        <BotSelect
                                            current=""
                                            fieldId="botId"
                                        />

                                        <QueueSelectSingle
                                            current=""
                                            fieldId="queueId"
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {viewer
                                ? (
                                    <Button
                                        onClick={handleClose}
                                        className={classes.buttonColorError}
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t("translation.validation.buttons.close")}
                                    </Button>
                                )
                                : (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={classes.btnWrapper}
                                        disabled={isSubmitting}
                                    >
                                        {campaignId
                                            ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                            : `${i18n.t("translation.validation.buttons.send")}`}
                                    </Button>
                                )
                            }
                        </DialogActions>
                    </FormContainer>
                )}
            </Formik>
        </Dialog>
    </>);
}
