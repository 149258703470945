import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import PlanSelect from "../PlanSelect";
import SupportUsersFilter from "../SupportUsersFilter";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import Divider from "../Divider";
import MultiFieldLine from "../FormComponents/MultiFieldLine";

const useStyles = makeStyles(theme => ({
    accordion: {
        boxShadow: "none",
        border: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.23)"
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: theme.spacing(-1.5),
        marginLeft: theme.spacing(-1.5),
    },
    btnWrapper: {
        position: "100%",
    },
    colorGreen: {
        color: theme.palette.secondary.main
    },
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: theme.spacing(-0.5),
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
    flexWrap: {
        flexWrap: "wrap",
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    removeBottomMargin: {
        marginBottom: 0,
    }
}));

const TenantSchema = Yup.object().shape({
    //TODO: Add one for CPF or CNPJ when country is Brazil
    name: Yup.string()
        .min(4, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    email: Yup.string()
        .email(`${i18n.t("translation.validation.invalid_email")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    slug: Yup.string()
        .min(3, `${i18n.t("translation.validation.too_short")}`)
        .matches(/^[a-zA-Z0-9-]+$/, `${i18n.t("translation.validation.specialChars")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

const TenantModal = ({ open, onClose, selectedTenant }) => {
    const classes = useStyles();

    // TODO: Change user type to match new API
    const initialState = {
        id: '',
        name: '',
        slug: '',
        email: '',
        documentNumber: '',
        addressStreet: '',
        addressZip: '',
        addressCity: '',
        addressCountry: '',
        planId: 3,
        // plan: {},
        dueDate: new Date(new Date() / 1 + (1000 * 60 * 60 * 24 * 15)), //TODO: Fix with settings
        status: true,
    };

    const [tenant, setTenant] = useState(selectedTenant || initialState);
    const { user } = useAuth();
    const [users, setUsers] = useState([user.id]);
    const [loading, setLoading] = useState(false);
    const [checkExtraFields, setCheckExtraFields] = useState(false);
    const { userTenant } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        const fetchTenant = async () => {
            let tenantData
            // Check if a tenant is selected
            if (!selectedTenant) {
                let { id, ...newTenant } = initialState;
                tenantData = newTenant
                setTenant(newTenant)
            } else {
                // If a tenant is selected, go on, otherwise, end
                try {
                    tenantData = selectedTenant
                } catch (err) {
                    toastError(err);
                }
            };
            if (isMounted) {
                setTenant(tenantData);
                setCheckExtraFields(tenantData.documentNumber ? true : false);
                setLoading(false)
            }
        };

        fetchTenant();

        return () => {
            isMounted = false
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant, open]);

    const handleClose = () => {
        setTenant(initialState);
        onClose();
    };

    const handleSaveTenant = async values => {
        try {
            // Remove empty properties from an object
            const data = Object.fromEntries(
                Object.entries(values).filter(([_, value]) => value !== "" && value !== undefined)
            );

            if (tenant?.id) {
                await axiosPrivate.put(`/tenants/${tenant.id}`, data);
            } else {
                // const newTenant =
                await axiosPrivate.post("/tenants", { ...data, addSupportUser: users });
            }
            toast.success(i18n.t("translation.tenantModal.success"));
        } catch (err) {
            toastError(err, "", "Error while trying to save tenant information.");
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {tenant
                        ? `${i18n.t("translation.tenantModal.title.edit")}`
                        : `${i18n.t("translation.tenantModal.title.add")}`}
                </DialogTitle>
                {!loading && tenant && (<>
                    <Formik
                        initialValues={tenant}
                        enableReinitialize={true}
                        validationSchema={TenantSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                const { checkExtraFields, ...filteredValues } = values;
                                handleSaveTenant(filteredValues);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, touched, errors, isSubmitting }) => (
                            <Form>
                                <DialogContent dividers>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.tenantModal.form.name")}
                                        autoFocus
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <MultiFieldLine>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("translation.tenantModal.form.slug")}
                                            name="slug"
                                            error={touched.slug && Boolean(errors.slug)}
                                            helperText={touched.slug && errors.slug}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                        <Can
                                            role={userTenant?.role}
                                            perform="tenant-modal:edit:status"
                                            yes={() => (
                                                <>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        <InputLabel id="status-selection-input-label">
                                                            {i18n.t("translation.userModal.form.connection.status")}
                                                        </InputLabel>
                                                        <Field
                                                            as={Select}
                                                            label={i18n.t("translation.userModal.form.connection.status")}
                                                            name="status"
                                                            labelId="status-selection-label"
                                                            id="status-selection"
                                                            required
                                                        >
                                                            <MenuItem value={true}>
                                                                {i18n.t("translation.validation.state.active")}
                                                            </MenuItem>
                                                            <MenuItem value={false}>
                                                                {i18n.t("translation.validation.state.inactive")}
                                                            </MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </>
                                            )}
                                        />
                                    </MultiFieldLine>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.userModal.form.email")}
                                        name="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />

                                    <PlanSelect fieldId="planId" user={user} />

                                    {!tenant?.id && (
                                        <SupportUsersFilter
                                            onFiltered={(users) => {
                                                const arrayUserIds = users.map(user => user.id)
                                                setUsers(arrayUserIds)
                                            }}
                                            initialUsers={[user]}
                                            title={i18n.t("translation.transferTicketModal.fieldLabel")}
                                            display="email"
                                        />
                                    )}

                                    <div>
                                        {/* <Accordion defaultExpanded className={classes.accordion}> */}
                                        <Accordion className={classes.accordion} expanded={checkExtraFields}>
                                            <AccordionSummary>
                                                <Tooltip title={i18n.t("translation.tenantModal.form.extraFields.note")} placement="top">
                                                    <FormControl component="fieldset">
                                                        <FormGroup>
                                                            <Field
                                                                as={FormControlLabel}
                                                                name="checkExtraFields"
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={checkExtraFields}
                                                                        onClick={() => setCheckExtraFields(check => !check)}
                                                                    />
                                                                }
                                                                label={i18n.t("translation.tenantModal.form.extraFields.title")}
                                                                labelPlacement="end"
                                                                className={classes.removeBottomMargin}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Tooltip>
                                            </AccordionSummary>

                                            <AccordionDetails className={classes.flexWrap}>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("translation.tenantModal.form.documentNumber")}
                                                    fullWidth
                                                    name="documentNumber"
                                                    error={touched.documentNumber && Boolean(errors.documentNumber)}
                                                    helperText={touched.documentNumber && errors.documentNumber}
                                                    variant="outlined"
                                                    size="small"
                                                />

                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("translation.tenantModal.form.addressStreet")}
                                                    name="addressStreet"
                                                    multiline
                                                    minRows={3}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                />

                                                <MultiFieldLine>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.tenantModal.form.addressCity")}
                                                        name="addressCity"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.tenantModal.form.addressZip")}
                                                        name="addressZip"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </MultiFieldLine>

                                                <MultiFieldLine>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.tenantModal.form.addressState")}
                                                        name="addressState"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                    />

                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t("translation.tenantModal.form.addressCountry")}
                                                        name="addressCountry"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </MultiFieldLine>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                    <Divider silent={true} />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        className={classes.buttonColorError}
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t("translation.validation.buttons.cancel")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {tenant
                                            ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                            : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </>)}
            </Dialog>
        </div>
    );
};

export default TenantModal;
