import axios from "axios";
import { getBackendUrl } from "../services/config";

export const axiosPrivate = axios.create({
    baseURL: getBackendUrl(),
    withCredentials: true
});


export const axiosOpen = axios.create({
    baseURL: getBackendUrl(),
});

export default axiosPrivate
