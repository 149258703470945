import React, { useEffect, useState } from "react";

import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';

import appVars from "../../../package.json";
import toastError from "../../errors/toastError";

import { Chip, TextField } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(1),
    }
}));

const filterOptions = createFilterOptions({
    trim: true,
});

export function SupportUsersFilter({ onFiltered, initialUsers, searchParams, display, title }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(initialUsers);
    const [selected, setSelected] = useState(initialUsers);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        async function fetchData() {
            await fetchUsers();
        }
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchUsers = async () => {
        let isMounted = true;
        setLoading(true);

        try {
            const { data } = await axiosPrivate.get(`/users/list`, {
                params: { searchParams },
            });

            let availableUsers = data.filter(userFilter);

            if (isMounted) {
                setUsers(availableUsers);
                setLoading(false);
            };
        } catch (err) {
            if (isMounted) {
                setLoading(false);
            };
            toastError(err, "", "Couldn't fetch users from backend at Support User's Filter");
        }
    };

    const userFilter = (user) => {
        let blockRoles = []; // i.e.: ["tenant"]
        let otherDomains = true;

        // check if user is part of the role list, remove it if so
        if (blockRoles.indexOf(user.role) !== -1) return

        // Check if user from the same domain as the controller
        if (otherDomains === false) {
            // if (otherDomains === false && !isControllerUrl()) {
            // If it is a controller isntallatin, then move forward anyway, else block it
            if (user.email.split('@')[1] !== appVars.controllerDomain) return
        }

        return user
    }

    const onChange = async (value) => {
        setSelected(value);
        onFiltered(value);
    };

    return (
        <Autocomplete
            getOptionLabel={option => display === "email" ? option.email : option.name}
            onChange={(e, newValue) => onChange(newValue)}
            options={users}
            filterOptions={filterOptions}
            noOptionsText={i18n.t("translation.transferTicketModal.noOptions")}
            loading={loading}
            fullWidth
            multiple

            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={title}
                    variant="outlined"
                />
            )}

            label="user"
            size="small"
            value={selected}
            // isOptionEqualToValue={() => selected === initialUsers} // TODO: refine function to deal with Autocomplete invalid error onClose
            renderTags={(value, getUserProps) =>
                value.map((option, index) => (
                    <Chip
                        color="primary"
                        sx={{
                            '& .MuiChip-deleteIcon': {
                                // color: theme.palette.primary.main,
                                transition: '1.5s'
                            },
                            '& .MuiChip-deleteIcon:hover': {
                                color: 'red',
                            },
                        }}
                        variant="outlined"
                        label={display === "email" ? option.email : option.name}
                        className={classes.chip}
                        {...getUserProps({ index })}
                        size="small"
                    />
                ))
            }
        />
    );
}

export default SupportUsersFilter;
