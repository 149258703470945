import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Button, TextField, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
	Formik,
	Form,
	Field
} from "formik";

import appVars from "../../../package.json";
import { i18n } from "../../translate/i18n";
import Copyright from "../../components/Copyright";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	container: {
		height: "calc(100vh)",
		padding: theme.spacing(3),
		display: "flex",
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "center",
		zIndex: 1
	},
	paper: {
		padding: theme.spacing(5),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
		maxWidth: 440,
		width: "100%"
	},
	changelog: {
		maxWidth: 440,
		padding: theme.spacing(4),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	img: {
		margin: `20px ${theme.spacing(1)}`,
		padding: "0px 0px 40px",
		width: 195,
		borderBottom: "1px solid #cecece",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	formInput: {
		margin: theme.spacing(2, 0, 1)
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, `${i18n.t("translation.validation.too_short")}`)
		.max(50, `${i18n.t("translation.validation.too_long")}`)
		.required(`${i18n.t("translation.validation.required")}`),
});

const UrlSelect = () => {
	const classes = useStyles();
	const initialState = { name: "" };
	const [name] = useState(initialState);

	const handleSubmit = async values => {
		let endpoint = `${"https://" + values.name + "." + appVars.controllerDomain + "/login"}`;

		if (window.location.hostname === "localhost") {
			toast.success(i18n.t("translation.app.toasts.success"));
			window.location.replace("/login");
		} else {
			await fetch(endpoint)
				.then(response => {
					if (!response.ok) {
						throw new Error(`HTTP error! status: ${response.status}`);
					} else {
						return response;
					}
				})
				.then(res => {
					toast.success(i18n.t("translation.app.toasts.success"));
					window.location.replace(endpoint);
				})
				.catch(e => {
					toastError(i18n.t("translation.app.toasts.fail"));
				});
		}
	};

	return (
		<>
			<Typography component="h1" variant="h5">
				{i18n.t("translation.app.title")}
			</Typography>
			<Formik
				initialValues={name}
				enableReinitialize={true}
				validationSchema={UserSchema}
				onSubmit={(values, actions) => {
					setTimeout(() => {
						handleSubmit(values);
						actions.setSubmitting(false);
					}, 400);
				}}
			>
				{({ touched, errors, isSubmitting }) => (
					<Form className={classes.form}>
						<Field
							as={TextField}
							autoComplete="name"
							name="name"
							error={touched.name && Boolean(errors.name)}
							helperText={touched.name && errors.name}
							variant="outlined"
							fullWidth
							id="name"
							label={i18n.t("translation.app.form.name")}
							autoFocus
							className={classes.formInput}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							{i18n.t("translation.validation.buttons.send")}
						</Button>
						<Box mt={1}><Copyright /></Box>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default UrlSelect;
