import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useParams } from "react-router-dom";

import Ticket from "../../components/Ticket/";

import Hidden from "@mui/material/Hidden";
import TicketsManager from "../../components/TicketsManager";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    chatContainer: {
        flex: 1,
        margin: theme.spacing(-1, -2, -2),
        height: `calc(100vh - 48px)`,
        overflowY: "hidden",
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: '0'
        }
    },
    chatPaper: {
        display: "flex",
        height: "100%",
    },
    contactsWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflowY: "hidden",
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: '0'
        }
    },
    contactsWrapperSmall: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflowY: "hidden",
        borderRadius: 0,
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
    },
    messagessWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
    },
    welcomeMsg: {
        backgroundColor: "#f0f2f5",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
    },
}));

const Chat = () => {
    const classes = useStyles();
    const { uuid } = useParams();

    return (
        <div className={classes.chatContainer}>
            <div className={classes.chatPaper}>
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className={uuid ? classes.contactsWrapperSmall : classes.contactsWrapper}
                    >
                        <TicketsManager />
                        {/* <Chats /> */}
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.messagessWrapper}>
                        {uuid ? (
                            <Ticket />
                        ) : (
                            <Hidden only={["sm", "xs"]}>
                                <Paper className={classes.welcomeMsg}>
                                    <span>{i18n.t("translation.chat.noTicketMessage")}</span>
                                </Paper>
                            </Hidden>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Chat;
