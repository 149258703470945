import { createTheme } from "@mui/material/styles";
// import { StylesProvider } from '@mui/styles';

const handleTheme = (locale) => {
    // const customStyles = {
    //   '.__react_modal_image__modal_container, .__react_modal_image__header': {
    //     backgroundColor: '#0437ff17!important',
    //     backdropFilter: 'blur(3px)',
    //   },
    //   '.__react_modal_image__header': {
    //     backgroundColor: '#0437ff17!important',
    //     boxShadow: '0px 0px 13px 13px #0437ff50',
    //   },
    // };

    // const theme = createTheme(adaptV4Theme({
    const theme = createTheme({
        scrollbarStyles: {
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                backgroundColor: "rgba(0,0,0,0.3)",
                borderRadius: "8px"
            },
        },
        palette: {
            primary: {
                main: "#131B43",
                contrastText: "#fff",
            },
            badgesGroup: {
                main: "#f6b300",
            },
            badgesSingle: {
                main: "#8f31ac",
            },
            badgesWhatsApp: {
                main: "#44b700"
            },
            comment: {
                main: "#fffdbe"
            },
            danger: {
                main: "#fda4af"
            },
            error: {
                main: "#f50057",
                contrastText: "#fff",
            },
            secondary: {
                main: "#F2691C",
                contrastText: "#fff",
            },
            success: {
                main: "#47e338",
                hover: "#4ff16a",
                contrastText: "#fff",
            },
            tertiary: {
                main: "#f50057",
                contrastText: "#fff",
            },
            warning: {
                main: "#fef9c3"
            }
        },
        typography: {
            fontFamily: [
                "Ubuntu",
                "Roboto",
                "Helvetica Neue",
                "Arial",
                "sans-serif"
            ].join(",")
        },

        // overrides: {
        //   MuiCssBaseline: {
        //     '@global': customStyles,
        //   },
        // },

        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        // borderRadius: "15px!important"
                    },
                    rounded: {
                        "&:first-of-type": {
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15
                        },
                        "&:last-of-type": {
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15
                        },
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 50,
                        // boxShadow: "0px 10px 26px 0px rgb(4 47 255 / 35%)"
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#0437ff17", //blue
                        // backgroundColor: "#ffffff99", //white
                        backdropFilter: "blur(3px)"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    container: {
                        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
                    },
                    paper: {
                        borderRadius: 20,
                        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)"
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        maxHeight: "calc(100vh - 181px)",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                            backgroundColor: "rgba(0,0,0,0.3)",
                            borderRadius: "8px"
                        },
                    }
                }
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none'
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: 'rgba(0,0,0,0.05)'
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    fullWidth: {
                        marginBottom: 16
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginBottom: 16
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 46
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 16
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    outlined: {
                        border: '1px solid rgba(0, 0, 0, 0.05)'
                    },
                    rounded: {
                        borderRadius: 20,
                    },
                    elevation1: {
                        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.07)"
                    }
                }
            }
        }
        // }, locale));
    }, locale);

    return theme
}

export default handleTheme
