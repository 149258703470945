import React, { useEffect, useMemo, useReducer, useState } from "react";

import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";

import { DataGrid, gridClasses } from '@mui/x-data-grid';

import {
    CloudDownload,
    DeleteOutline,
    EditOutlined,
    Lock,
    LockOpen,
    Search,
} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';

import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MarkdownWrapper from "../../components/MarkdownWrapper";
import QuickAnswersModal from "../../components/QuickAnswersModal";
import TutorialModalButton from "../../components/TutorialModalButton";

import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_QUICK_ANSWERS") {
        const quickAnswers = action.payload;
        const newQuickAnswers = [];

        quickAnswers.forEach((quickAnswer) => {
            const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
            if (quickAnswerIndex !== -1) {
                state[quickAnswerIndex] = quickAnswer;
            } else {
                newQuickAnswers.push(quickAnswer);
            }
        });

        return [...state, ...newQuickAnswers];
    }

    if (action.type === "UPDATE_QUICK_ANSWERS") {
        const quickAnswer = action.payload;
        const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

        if (quickAnswerIndex !== -1) {
            state[quickAnswerIndex] = quickAnswer;
            return [...state];
        } else {
            return [quickAnswer, ...state];
        }
    }

    if (action.type === "DELETE_QUICK_ANSWERS") {
        const quickAnswerId = action.payload;

        const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
        if (quickAnswerIndex !== -1) {
            state.splice(quickAnswerIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    formControl: {
        marginRight: theme.spacing(2),
        "& input": {
            height: "1.2195em"
        }
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    quickAnswer: {
        // width: 300,
    },
    searchMobile: {
        width: "100%",
        padding: theme.spacing(0, 1, 2),
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        }
    },
    status: {
        display: "flex",
        justifyContent: "center"
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit"
    }
}));

const QuickAnswers = () => {
    const classes = useStyles();

    const [, setLoading] = useState(false);

    const [quickAnswers, dispatch] = useReducer(reducer, []);

    const [deletingQuickAnswers, setDeletingQuickAnswers] = useState(null);
    const [selectedQuickAnswers, setSelectedQuickAnswers] = useState(null);

    const [quickAnswersModalOpen, setQuickAnswersModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const { user } = useAuth();
    const { tenantId, userTenant } = useTenant();

    const [showAll, setShowAll] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [, setHasMore] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const [rows, setRows] = useState([{
        id: '',
        shortcut: '',
        visibility: false,
        owner: '',
        attachment: false,
        message: ''
    }]);

    useEffect(() => {
        if (!quickAnswers) return;

        let dataGridRows = quickAnswers.map(quickAnswer => ({
            id: quickAnswer?.id,
            shortcut: quickAnswer?.shortcut,
            visibility: quickAnswer?.visibility,
            owner: quickAnswer?.userTenant?.user,
            attachment: quickAnswer?.hasMedia,
            message: quickAnswer?.message
        }));

        setRows(dataGridRows)

    }, [quickAnswers]);

    const columns = useMemo(() => [
        {
            field: "shortcut",
            headerName: i18n.t("translation.variables.table.shortcut"),
            flex: 0.5,
            minWidth: 120,
            description: i18n.t("translation.quickAnswers.table.descriptions.shortcut")
        },
        {
            field: "visibility",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.quickAnswers.table.descriptions.visibility.description"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.visibility
                    ? (<div className={classes.status}>
                        <LockOpen fontSize="small" className={classes.marginRight} /> {i18n.t("translation.quickAnswers.table.descriptions.visibility.public")}
                    </div>)
                    : (<div className={classes.status}>
                        <Lock fontSize="small" className={classes.marginRight} color="error" /> {i18n.t("translation.quickAnswers.table.descriptions.visibility.private")}
                    </div>)
            )
        },
        {
            field: "owner",
            headerName: i18n.t("translation.variables.table.author"),
            flex: 0.4,
            minWidth: 120,
            description: i18n.t("translation.quickAnswers.table.descriptions.owner"),
            renderCell: params => (params.row.owner?.name)
        },
        {
            field: "attachment",
            headerName: i18n.t("translation.variables.table.attachment.title"),
            flex: 0.2,
            minWidth: 80,
            type: "string",
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.attachment ? (
                    <div className={classes.imagePreview}>
                        <IconButton
                            color="primary"
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <CloudDownload />
                        </IconButton>
                    </div>
                ) : (
                    i18n.t("translation.variables.table.attachment.none")
                )
            )
        },
        {
            field: "message",
            headerName: i18n.t("translation.variables.table.message"),
            flex: 1,
            minWidth: 300,
            sortable: false,
            description: i18n.t("translation.quickAnswers.table.descriptions.message"), renderCell: params => <div className={classes.wrap}><MarkdownWrapper>{params.row.message}</MarkdownWrapper></div>
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.4,
            minWidth: 120,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.owner?.id === user.id || showAll ? (
                    <>
                        <IconButton
                            size="small"
                            className={classes.actionButtons}
                            color="primary"
                            onClick={() => handleEditQuickAnswers(params.row)}
                        >
                            <EditOutlined />
                        </IconButton>
                        <IconButton
                            size="small"
                            className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                            onClick={(e) => {
                                setConfirmModalOpen(true);
                                setDeletingQuickAnswers(params.row);
                            }}
                        >
                            <DeleteOutline />
                        </IconButton>
                    </>
                ) : ''
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [quickAnswers]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        // const { role } = userTenant;
        const roles = ["super", "admin", "supervisor"];
        const adminUser = roles.indexOf(userTenant?.role) !== -1;
        setShowAll(adminUser)
    }, [user, userTenant])

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchQuickAnswers = async () => {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/quickAnswers/`, {
                        params: { searchParam, pageNumber, showAll },
                    });
                    dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data.quickAnswers });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            if (tenantId) fetchQuickAnswers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, showAll, tenantId]);

    // Handle Quick Answer Changes
    const handleQuickAnswerChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            const roles = ["supervisor", "admin", "super"]
            if (data.quickAnswer.visibility === false && (
                data.quickAnswer.ownerId !== user.id &&
                roles.indexOf(userTenant.role || "none") === -1
            )) {
                dispatch({ type: "DELETE_QUICK_ANSWERS", payload: +data.quickAnswer.id });
            } else {
                dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.quickAnswer });
            }
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_QUICK_ANSWERS", payload: +data.quickAnswerId });
        }
    };
    useSocketListener(`quick-answer`, (data) => handleQuickAnswerChange(data))

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenQuickAnswersModal = () => {
        setSelectedQuickAnswers(null);
        setQuickAnswersModalOpen(true);
    };

    const handleCloseQuickAnswersModal = () => {
        setSelectedQuickAnswers(null);
        setQuickAnswersModalOpen(false);
    };

    const handleEditQuickAnswers = (quickAnswer) => {
        setSelectedQuickAnswers(quickAnswer);
        setQuickAnswersModalOpen(true);
    };

    const handleDeleteQuickAnswers = async (quickAnswerId) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/quickAnswers/${quickAnswerId}`);
            toast.success(i18n.t("translation.quickAnswers.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingQuickAnswers(null);
        setSearchParam("");
        setPageNumber(1);
    };

    // const loadMore = () => {
    //   setPageNumber((prevState) => prevState + 1);
    // };

    // const handleScroll = (e) => {
    //   if (!hasMore || loading) return;
    //   const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    //   if (scrollHeight - (scrollTop + 100) < clientHeight) {
    //     loadMore();
    //   }
    // };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingQuickAnswers &&
                    `${i18n.t("translation.quickAnswers.confirmationModal.title")} ${deletingQuickAnswers.shortcut}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteQuickAnswers(deletingQuickAnswers.id)}
            >
                {i18n.t("translation.quickAnswers.confirmationModal.message")}
            </ConfirmationModal>
            <QuickAnswersModal
                open={quickAnswersModalOpen}
                onClose={handleCloseQuickAnswersModal}
                aria-labelledby="form-dialog-title"
                quickAnswerId={selectedQuickAnswers && selectedQuickAnswers.id}
            ></QuickAnswersModal>
            <MainHeader>
                <Title>
                    {i18n.t("translation.quickAnswers.title")}
                    <TutorialModalButton content="quickAnswers" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            size="small"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenQuickAnswersModal}
                        >
                            {i18n.t("translation.quickAnswers.buttons.add")}
                        </Button>
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenQuickAnswersModal}
                        >
                            {i18n.t("translation.quickAnswers.buttons.add")}
                        </Button>
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            // onScroll={handleScroll}
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{
                            width: "100%"
                        }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}

                        </Grid>
                    </div>
                </div>

                {/* <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("translation.variables.table.shortcut")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("translation.variables.table.status.title")}
              </TableCell>
              <TableCell align="left">
                {i18n.t("translation.variables.table.author")}
              </TableCell>
              <TableCell align="left">
                {i18n.t("translation.variables.table.quickAnswer")}
              </TableCell>
              <TableCell align="center" className={classes.actionsCell}>
                {i18n.t("translation.variables.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {quickAnswers.map((quickAnswer) => (
                <TableRow key={quickAnswer.id}>
                  <TableCell align="center">{quickAnswer.shortcut}</TableCell>
                  <TableCell align="center">{quickAnswer.visibility.toString()}</TableCell>
                  <TableCell align="center">{quickAnswer.owner.name}</TableCell>
                  <TableCell align="left">
                    <Typography
                      className={classes.quickAnswer}
                      variant="body2"
                    // noWrap
                    >
                      <MarkdownWrapper>
                        {quickAnswer.message}
                      </MarkdownWrapper>
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className={classes.actionsCell}>
                    <IconButton
                      size="small"
                      color="primary"
                      className={classes.actionButtons}
                      onClick={() => handleEditQuickAnswers(quickAnswer)}
                    >
                      <EditOutlined />
                    </IconButton>

                    <IconButton
                      size="small"
                      className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingQuickAnswers(quickAnswer);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table> */}
            </Paper>
        </MainContainer>
    );
};

export default QuickAnswers;
