import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";

const useStyles = makeStyles(theme => ({
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    content: {
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(1),
        borderLeft: "1px solid"
    },
    divider: {
        borderBottom: "1px solid #efefef",
        marginBottom: theme.spacing(3)
    },
    message: {
        padding: theme.spacing(0, 1, 1),
    }
}));



const MessageHistoryModal = ({ open, onClose, message }) => {
    const classes = useStyles();
    const handleClose = () => {
        onClose(false)
    }

    console.log(message)
    console.log(message?.messageHistory?.length)

    return open && (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                {i18n.t("translation.messageHistoryModal.title")}
            </DialogTitle>

            <DialogContent dividers>
                {message.messageHistory.map((previousMessage, index) => (
                    <div
                        key={index}
                        className={index === message.messageHistory.length - 1
                            ? classes.message
                            : [classes.divider, classes.message].join(" ")
                        }
                    >
                        <Typography variant="subtitle2">
                            {new Date(previousMessage.timestamp).toLocaleString()}
                            {index === message.messageHistory.length - 1
                                ? <strong>
                                    {" - " + i18n.t("translation.messageHistoryModal.labels.original")}
                                </strong>
                                : null
                            }
                            {index === 0
                                ? <strong>
                                    {" - " + i18n.t("translation.messageHistoryModal.labels.mostRecent")}
                                </strong>
                                : null
                            }
                        </Typography>

                        <Typography className={classes.content} gutterBottom>
                            <MarkdownWrapper>
                                {previousMessage.message}
                            </MarkdownWrapper>
                        </Typography>
                    </div>
                ))}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    className={classes.buttonColorError}
                    variant="outlined"
                >
                    {i18n.t("translation.validation.buttons.close")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default MessageHistoryModal;
