import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { toast } from "react-toastify";
import appVars from "../../../package.json";
import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import ConnectionItems from "../ConnectionItems";
import MultiFieldLine from "../FormComponents/MultiFieldLine";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    btnWrapper: {
        position: "100%",
    },

    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    colorGreen: {
        color: theme.palette.secondary.main
    },
    //Connection Selection
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
}));

const UserSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`)
        .required(`${i18n.t("translation.validation.required")}`),
    password: Yup.string()
        .min(5, `${i18n.t("translation.validation.too_short")}`)
        .max(50, `${i18n.t("translation.validation.too_long")}`),
    email: Yup.string()
        .email(`${i18n.t("translation.validation.invalid_email")}`)
        .required(`${i18n.t("translation.validation.required")}`),
});

const UserModal = ({ open, onClose, userId }) => {
    const classes = useStyles();

    // TODO: Change user type to match new API
    const initialState = {
        name: "",
        email: "",
        password: "",
        role: "user",
        status: true
    };

    const { user: loggedInUser } = useAuth();
    const { tenantId, userData, userTenant } = useTenant();

    const [user, setUser] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [whatsappId, setWhatsappId] = useState(false);
    const { loading, whatsApps } = useWhatsApps();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchUser = async () => {
            if (!userId || !tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/user/${userId}`, { signal });
                const selectedUserData = userData(data);
                setUser((prev) => {
                    return {
                        ...prev,
                        ...data,
                        role: selectedUserData?.role || "user",
                        status: selectedUserData?.status || false,
                    };
                });
                const userQueueIds = selectedUserData?.queues?.map(queue => queue?.id);
                setSelectedQueueIds(userQueueIds);
                setWhatsappId(selectedUserData?.whatsapp ? selectedUserData.whatsapp.id : '');
            } catch (err) {
                toastError(err);
            }
        };

        fetchUser();

        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, tenantId, open]);

    const handleClose = () => {
        setUser(initialState);
        onClose();
    };

    const handleSaveUser = async values => {
        const { role, status, ...newValues } = values;
        const userData = {
            ...newValues,
            userTenant: {
                tenantId,
                whatsappId: whatsappId ? whatsappId : null,
                role: role ? role : "user",
                status,
                queueIds: selectedQueueIds,
            }
        };
        try {
            if (userId) {
                await axiosPrivate.put(`/user/${userId}`, userData);
            } else {
                await axiosPrivate.post("/users", userData);
            }
            toast.success(i18n.t("translation.userModal.success"));
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    const superUser = (mail) => {
        if ((mail.split('@')[1] === appVars.controllerDomain || window.location.hostname === "localhost") &&
            mail.split('@')[0] !== 'admin' &&
            mail.split('@')[0] !== 'super' &&
            mail.split('@')[0] !== 'supervisor' &&
            mail.split('@')[0] !== 'user' &&
            mail.split('@')[0] !== 'usuario') {
            return (
                <MenuItem value="super">{i18n.t("translation.userModal.form.profile.role.super")}</MenuItem>
            );
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {userId
                        ? `${i18n.t("translation.userModal.title.edit")}`
                        : `${i18n.t("translation.userModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={user}
                    enableReinitialize={true}
                    validationSchema={UserSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveUser(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <MultiFieldLine>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.userModal.form.name")}
                                        autoFocus
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        name="password"
                                        variant="outlined"
                                        size="small"
                                        label={i18n.t("translation.userModal.form.password")}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword((e) => !e)}
                                                        size="large">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth
                                    />
                                </MultiFieldLine>

                                <Field
                                    as={TextField}
                                    label={i18n.t("translation.userModal.form.email")}
                                    name="email"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                                <MultiFieldLine>
                                    <Can
                                        role={userTenant?.role}
                                        perform="user-modal:edit:profile"
                                        yes={() => (
                                            <>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControl}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <InputLabel id="role-selection-input-label">
                                                        {i18n.t("translation.userModal.form.profile.name")}
                                                    </InputLabel>

                                                    <Field
                                                        as={Select}
                                                        label={i18n.t("translation.userModal.form.profile.name")}
                                                        name="role"
                                                        labelId="role-selection-label"
                                                        id="role-selection"
                                                        required
                                                    >
                                                        {superUser(loggedInUser.email)}
                                                        <MenuItem value="admin">{i18n.t("translation.userModal.form.profile.role.admin")}</MenuItem>
                                                        <MenuItem value="finance">{i18n.t("translation.userModal.form.profile.role.finance")}</MenuItem>
                                                        <MenuItem value="dashboard">{i18n.t("translation.userModal.form.profile.role.dashboard")}</MenuItem>
                                                        <MenuItem value="supervisor">{i18n.t("translation.userModal.form.profile.role.supervisor")}</MenuItem>
                                                        <MenuItem value="user">{i18n.t("translation.userModal.form.profile.role.user")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                    <Can
                                        role={userTenant?.role}
                                        perform="user-modal:edit:status"
                                        yes={() => (
                                            <>
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControl}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <InputLabel id="status-selection-input-label">
                                                        {i18n.t("translation.userModal.form.connection.status")}
                                                    </InputLabel>
                                                    <Field
                                                        as={Select}
                                                        label={i18n.t("translation.userModal.form.connection.status")}
                                                        name="status"
                                                        labelId="status-selection-label"
                                                        id="status-selection"
                                                        required
                                                    >
                                                        <MenuItem value={true}>
                                                            {i18n.t("translation.validation.state.active")}
                                                        </MenuItem>
                                                        <MenuItem value={false}>
                                                            {i18n.t("translation.validation.state.inactive")}
                                                        </MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </>
                                        )}
                                    />
                                </MultiFieldLine>
                                {values?.role !== "finance" && values?.role !== "dashboard" && (
                                    <>
                                        <Can
                                            role={userTenant?.role}
                                            perform="user-modal:edit:connection"
                                            yes={() => (!loading &&
                                                <FormControl variant="outlined" size="small" className={[classes.maxWidth,].join(' ')} fullWidth>
                                                    <InputLabel>{i18n.t("translation.userModal.form.connection.select")}</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        value={whatsappId || ''}
                                                        onChange={(e) => setWhatsappId(e.target.value)}
                                                        label={i18n.t("translation.userModal.form.connection.select")}
                                                    >
                                                        <MenuItem value={''}>{i18n.t("translation.userModal.form.connection.none")}</MenuItem>
                                                        {ConnectionItems(whatsApps)}
                                                    </Field>
                                                </FormControl>
                                            )}
                                        />
                                        <Can
                                            role={userTenant?.role}
                                            perform="user-modal:edit:queue"
                                            yes={() => (
                                                <QueueSelect
                                                    selectedQueueIds={selectedQueueIds}
                                                    onChange={values => setSelectedQueueIds(values)}
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("translation.validation.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {userId
                                        ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                        : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default UserModal;
