import React, { useEffect, useState } from "react";

import { Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
		'&:hover': {
			filter: 'brightness(120%)',
		},
	},
	chip: {
		margin: 2,
	},
	marginTop: {
		marginTop: 6,
	},
}));

const QueueSelect = ({ selectedQueueIds, onChange }) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);
	const axiosPrivate = useAxiosPrivate();
	const { tenantId } = useTenant();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		if (tenantId) {
			(async () => {
				try {
					const { data } = await axiosPrivate.get(`/${tenantId}/queue`);
					isMounted && setQueues(data);
				} catch (err) {
					toastError(err);
				}
			})();
		}
		return () => {
			isMounted = false;
			controller.abort();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId]);

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div className={classes.marginTop}>
			<FormControl fullWidth size="small" variant="outlined">
				<InputLabel>{i18n.t("translation.queueSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					value={selectedQueueIds}
					onChange={handleChange}
					label={i18n.t("translation.queueSelect.inputLabel")}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						}
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const queue = queues.find(q => q.id === id);
									return queue ? (
										<Chip
											key={id}
											style={{
												backgroundColor: queue.color + "20",
												borderColor: queue.color,
												color: queue.color,
											}}
											variant="outlined"
											label={queue.name}
											className={classes.chip}
											size="small"
										/>
									) : null;
								})}
						</div>
					)}
				>
					{queues.map(queue => (
						<MenuItem key={queue.id} value={queue.id}>
							{queue.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueSelect;
