import React from "react";
import { Outlet } from "react-router-dom";

import {
    Box,
    Container,
    CssBaseline,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import appVars from "../../package.json";
import logo from "../assets/logo_dark.png";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
            height: "calc(100vh)",
        }
    },
    paper: {
        padding: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 25,
        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
        maxWidth: 440,
        width: "100%"
    },
    changelog: {
        maxWidth: 440,
        padding: theme.spacing(4),
    },
    img: {
        margin: `20px ${theme.spacing(1)}`,
        padding: "0px 0px 40px",
        width: 195,
        borderBottom: "1px solid #cecece",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoggedOutLayout = () => {
    const classes = useStyles();

    return (
        <Container component="main" className={classes.container}>
            <CssBaseline />
            <Box className={classes.paper}>
                <img className={classes.img} src={logo} alt={appVars.appName + " Logo"} />
                <Outlet />
            </Box>
            {/* <Box className={classes.changelog}>Informações do plano (slider seletor)</Box> */}
        </Container>
    );
};

export default LoggedOutLayout;
