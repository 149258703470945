import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
// import useTenant from "../hooks/useTenant";

import { useCallback } from 'react';
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import useTenant from '../hooks/useTenant';
import LoggedInLayout from "../layout";
// import { useState } from 'react';

const Private = ({ allowedRoles }) => {
    const { token, user } = useAuth();
    const { tenantSlug } = useTenant();
    const location = useLocation();
    const navigate = useNavigate();

    const userTenant = useCallback(() => {
        if (!user) navigate("/login");
        const userTenant = user?.userTenant?.find(t => t.tenant.slug === tenantSlug);
        return userTenant;
    }, [tenantSlug, navigate, user]);

    return (
        token && user?.status === true && userTenant()?.status === true
            ? allowedRoles?.includes(userTenant()?.role)
                ? (
                    <WhatsAppsProvider>
                        <LoggedInLayout>
                            <Outlet />
                        </LoggedInLayout>
                    </WhatsAppsProvider>
                )
                : <Navigate to={`${tenantSlug}/unauthorized`} state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default Private;
