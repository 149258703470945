export default function DownloadCSV(fileName, content, fileType) {
    const makeCSV = (content) => {
        let csv = '';
        content.forEach(item => {
            Object.entries(item).forEach(([, value], index) => {
                let innerValue = value == null ? '' : value.toString();
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) {
                    result = '"' + result + '"'
                }
                if (index > 0) { csv += ';' }
                csv += result;

            });
            csv += '\n';
        })
        return csv
    }
    const universalBOM = "\uFEFF";
    const file = new Blob([universalBOM + makeCSV(content)], { type: fileType });

    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = fileName;

    document.body.appendChild(element); // Required for this to work in FireFox
    return element.click();
};
