import { i18n } from "../../translate/i18n";

const findDefaultSystemTagName = tag => {
  const tagName = tag.name.toLowerCase();
  const tagCreatedAs = tag.createdAs.toLowerCase();

  let options = ["customer", "lead", "loss", "prospect", "schedule", "win"];
  if (options.indexOf(tagCreatedAs) === -1 || tagName !== tagCreatedAs) {
    return tag.name;
  }

  return i18n.t("translation.tags.defaultTags." + tagCreatedAs) + " (" + tag.name + ")";
}

export default findDefaultSystemTagName;