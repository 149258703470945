import { VolumeDownTwoTone, VolumeUpTwoTone } from "@mui/icons-material";
import { Grid, IconButton, List, Popover, Slider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useRef, useState } from "react";
const useStyles = makeStyles((theme) => ({
    tabContainer: {
        padding: theme.spacing(2),
    },
    popoverPaper: {
        width: "100%",
        maxWidth: 350,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            maxWidth: 270,
        },
    },
    noShadow: {
        boxShadow: "none !important",
    },
    icons: {
        color: "#fff",
    },
    customBadge: {
        backgroundColor: "#f44336",
        color: "#fff",
    },
}));
export function NotificationsVolume({ volume, setVolume }) {
    const classes = useStyles();

    const anchorEl = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const handleVolumeChange = (value) => {
        setVolume(value);
        localStorage.setItem("volume", value);
    };
    return (<>
        <IconButton
            className={classes.icons}
            onClick={handleClick}
            ref={anchorEl}
            aria-label="Open Notifications"
        >
            <VolumeUpTwoTone color="inherit" />
        </IconButton>
        <Popover
            disableScrollLock
            open={isOpen}
            anchorEl={anchorEl.current}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            classes={{ paper: classes.popoverPaper }}
            onClose={handleClickAway}
        >
            <List dense className={classes.tabContainer}>
                <Grid container spacing={2}>
                    <Grid item>
                        <VolumeDownTwoTone />
                    </Grid>
                    <Grid item xs>
                        <Slider
                            value={Number(volume)}
                            aria-labelledby="continuous-slider"
                            step={0.1}
                            min={0}
                            max={1}
                            onChange={(e, value) =>
                                handleVolumeChange(value)
                            }
                        />
                    </Grid>
                    <Grid item>
                        <VolumeUpTwoTone />
                    </Grid>
                </Grid>
            </List>
        </Popover>
    </>)
}
