import { useEffect, useState } from "react";
import logger from "../../../services/logger";

const useSocketSetup = (socket) => {
    // const { token } = useAuth();
    const [counter, setCounter] = useState(0)
    const retries = 4

    const reload = (content) => {
        logger({ type: "info", title: "Socket.io", content })
        setTimeout(() => { window.location.reload() }, 100);
    }

    useEffect(() => {
        socket.connect();
        // socket.open((err) => {
        //     if (err) {} else {}
        // })

        // Start listening
        socket.on("connect", () => {
            // Reset reconnection counter.
            setCounter(0)
            logger({ type: "info", title: "Socket.IO", content: "Connected" })
        })

        // Stop listening
        socket.on("disconnect", (reason, details) => {
            logger({ type: "error_info", title: "Socket.IO", content: `Disconnected "${reason}"${details?.description ? ", " : "."}${details?.description}` })
        })

        // Listen to connection errors
        // Other usefull client events might be: ["error", "ping", "reconnect", "reconnect_attempt", "reconnect_failed"]
        socket.on("connect_error", (data) => {
            if (socket.active) {
                logger({ type: "error_info", title: "Socket.io", content: "Temporary Connection Error. We'll automatically try to reconnect." })
            } else {
                // Check if the connection error is related to token, and update it.
                // https://socket.io/docs/v3/client-initialization/#socket-options
                if (data.message === "Socket.io: Unauthorized user.") {
                    // Update counter to track reconnection attempts
                    setCounter((prev) => prev + 1)
                    logger({ type: "error_info", title: "Socket.io", content: "Invalid token." })

                    // It'll retry N times before moving on
                    if (retries >= counter) {
                        const token = localStorage.getItem("token");
                        if (socket.auth.token !== token) {
                            logger({ type: "info", title: "Socket.io", content: "Attempting reconnection with a new token." })
                            socket.auth.token = token;
                            socket.connect();
                        }
                    } else reload("Retries exausted, reloading the page.")
                } else {
                    logger({ type: "error_info", title: "Connection Error", content: data.message })
                    reload("Unhandled socket error, reloading the page.")
                    // socket.disconnect();
                }
            }
        });

        // Clean up
        return () => {
            const keys = ["connect", "disconnect", "connect_error"]

            //Disconnect from all IO
            for (const key of keys) {
                socket.off(key)
            }
            socket.disconnect()
        }
    }, [socket, counter])
}

export default useSocketSetup
