import React, { useEffect, useState } from "react";

import { Chip, Paper, TextField } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Autocomplete from '@mui/material/Autocomplete';
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    chips: {
        display: "flex",
        flexWrap: "wrap",
        '&:hover': {
            filter: 'brightness(120%)',
        },
    },
    chip: {
        margin: 2,
    },
    paper: {
        width: 400,
        marginLeft: 12
    },
    removeMargin: {
        marginBottom: 0
    },
    tagArea: {
        margin: 0,
        padding: theme.spacing(0, 1),
        borderBottom: "1px solid #cecece75",
    },
    tagInput: {
        border: "none!important",
        '& .MuiOutlinedInput-root, & fieldset': {
            borderRadius: "0",
            border: "none"
        },
    },
}));

export function TagsContainer({ ticket }) {
    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const [isMounted, setIsMounted] = useState(false);

    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        setIsMounted(true);
        const controller = new AbortController();

        if (!ticket) return;

        async function fetchData() {
            isMounted && await loadTags();
        }

        if (Array.isArray(ticket.tags)) {
            isMounted && setSelecteds(ticket.tags);
        }
        fetchData();
        return () => {
            setIsMounted(false);
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket]);

    const createTag = async (data) => {
        if (!tenantId) return;
        try {
            const { data: responseData } = await axiosPrivate.post(`/${tenantId}/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const loadTags = async () => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/tags/list`);
            isMounted && setTags(data);
        } catch (err) {
            toastError(err);
        }
    }

    const syncTags = async (data) => {
        if (!tenantId) return;
        try {
            const { data: responseData } = await axiosPrivate.post(`/${tenantId}/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []
        if (!ticket) return;
        if (reason === 'createOption') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item })
                        newTag && optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: optionsChanged });
    }

    return (
        <div className={classes.tagArea}>
            <Autocomplete
                className={classes.tagInput}
                multiple
                size="small"
                options={tags}
                value={selecteds}
                freeSolo
                onChange={(e, v, r) => onChange(v, r)}
                getOptionLabel={(option) => option?.name}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            color="secondary"
                            sx={{
                                '& .MuiChip-deleteIcon': {
                                    color: option?.color,
                                    transition: '0.5s'
                                },
                                '& .MuiChip-deleteIcon:hover': {
                                    color: 'red',
                                },
                            }}
                            style={{
                                backgroundColor: option?.color + "20",
                                borderColor: option?.color,
                                color: option?.color,
                            }}
                            variant="outlined"
                            label={option?.name}
                            className={classes.chip}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" className={classes.removeMargin} placeholder={i18n.t("translation.tags.placeholder.filterByName")} />
                )}
                PaperComponent={({ children }) => (
                    <Paper className={classes.paper}>
                        {children}
                    </Paper>
                )}
            />
        </div>
    )
}
