import { createContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useSocketListener } from "../../hooks/useSocket/Events";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { setUser, user } = useAuth();
    const navigate = useNavigate();

    // Handle user updates
    const updateUser = (data) => {
        if (data.user.id === user.id) {
            if (data.action === "update") {
                setUser(data.user)
            }

            if (data.action === "disable" || data.user.status === false) {
                setUser(data.user)
                navigate("/")
            }

            // if it is current user, log it out
            if (data.action === "delete") {
                navigate("/")
            }
        }
    }
    useSocketListener(`user:${user.id}`, (data) => updateUser(data))

    return (
        <UserContext.Provider value={{}}>
            {/* {children} */}
            <Outlet />
        </UserContext.Provider>
    )
}

export default UserContext;
