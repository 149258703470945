// import { io } from "socket.io-client";
// import { getBackendUrl } from "./config";
// import { useLocalStorage } from "../hooks/useLocalStorage";

function socketOptions() {
    let options = {}
    options = {
        autoConnect: false,
        withCredentials: true,
        // transports: ["websocket"],
        pingTimeout: 18000,
        pingInterval: 18000
    }

    // Check token
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [token] = useLocalStorage("token", null, false)
    const token = localStorage.getItem("token");
    if (token) options = { ...options, auth: { token } }

    // Check slug
    const slug = window.location.pathname.split("/")[1];
    if (slug && slug !== "tenants") options = {
        ...options, query: { slug }
    }

    // Connect to socket
    // const manager = io(getBackendUrl(), options);

    return options
}

export default socketOptions;
