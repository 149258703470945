import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import BackdropLoading from "../BackdropLoading";

import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";

const PersistLogin = () => {
    const [loading, setLoading] = useState(true);
    const { authPersist, token } = useAuth();
    const refresh = useRefreshToken();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                isMounted && console.error(err);
            } finally {
                isMounted && setLoading(false);
            }
        }

        !token ? verifyRefreshToken() : setLoading(false);

        return () => isMounted = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return !authPersist
        ? <Outlet />
        : loading
            ? <BackdropLoading />
            : <Outlet />
}

export default PersistLogin;
