import React, { useEffect, useReducer, useState } from "react";

import { List, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import ChatListItem from "../ChatListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";


import useAuth from "../../hooks/useAuth";
import useChats from "../../hooks/useChats";
import { useSocketListener } from "../../hooks/useSocket/Events";


const useStyles = makeStyles(theme => ({
    ticketsList: {
        flex: 1,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    },

    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
    },

    ticketsListHeader: {
        color: "rgb(67, 83, 105)",
        zIndex: 2,
        backgroundColor: "white",
        // borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    ticketsCount: {
        fontWeight: "normal",
        color: "rgb(104, 121, 146)",
        marginLeft: "8px",
        fontSize: "14px",
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const tickets = action.payload.data;
        const newTickets = [];
        const user = action.payload.user

        tickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t?.id === ticket?.id);

            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;

                if (ticket.users.find(u => u?.userId === user?.id)?.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                newTickets.push(ticket);
            }
        });

        return [...state, ...newTickets];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        const ticketIndex = state.findIndex(t => t?.id === ticket?.id);

        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            return [...state]
        } else {
            return [ticket, ...state]
        }
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t?.id === +ticketId);

        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const ChatsList = (props) => {
    const { searchParam } = props;

    const classes = useStyles();

    const [pageNumber, setPageNumber] = useState(1);
    const [chatsList, dispatch] = useReducer(reducer, []);

    const { user } = useAuth();

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [
        dispatch,
        searchParam,
    ]);

    const { chats, hasMore, loading } = useChats({
        // date,
        pageNumber,
        searchParam,
        // withUnreadMessages,
    });

    useEffect(() => {
        if (!searchParam && !chats) return;
        dispatch({
            type: "LOAD_TICKETS",
            payload: {
                data: chats,
                user: user
            }
        });
    }, [
        chats,
        searchParam,
        user
    ]);


    // Handlers
    // Handle Chat Changes
    const handleChatChange = (data) => {
        if (data.action === "delete") {
            dispatch({ type: "DELETE_TICKET", payload: data?.id });
        }

        if ((data.action === "newMessage" || data.action === "update")) {
            dispatch({ type: "UPDATE_TICKET", payload: data?.chat });
        }
    };
    useSocketListener(`channel:internal`, (data) => handleChatChange(data))

    // Handle Chat Changes
    const handleChatUserChange = (data) => {
        if (data.action === "updateUnread") {
            dispatch({ type: "RESET_UNREAD", payload: data.chatId });
        }
        if ((data.action === "create" || data.action === "update")) {
            dispatch({ type: "UPDATE_TICKET", payload: data?.chat });
        }
        if (data.action === "delete") {
            dispatch({ type: "DELETE_TICKET", payload: data?.chatId });
        }
    };
    useSocketListener(`channel:internal:user:${user.id}`, (data) => handleChatUserChange(data))


    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            e.currentTarget.scrollTop = scrollTop - 100;
            loadMore();
        }
    };

    return (
        <Paper className={classes.ticketsListWrapper}>
            <Paper
                square
                name="closed"
                elevation={0}
                className={classes.ticketsList}
                onScroll={handleScroll}
            >
                <List style={{ paddingTop: 0 }}>
                    {chatsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>
                                {i18n.t("translation.chatsList.noChats.title")}
                            </span>
                            <p className={classes.noTicketsText}>
                                {i18n.t("translation.chatsList.noChats.message")}
                            </p>
                        </div>
                    ) : (
                        <>
                            {chatsList.map(chat => (
                                chat && <ChatListItem chat={chat} key={chat.id} />
                            ))}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
        </Paper>
    );
};

export default ChatsList;
