import React, { useState } from "react";

import { IconButton, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import ChatsList from "../ChatsList";
import NewChatModal from "../NewChatModal";

import { i18n } from "../../translate/i18n";

import { AddOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import TutorialModalButton from "../../components/TutorialModalButton";
import useTenant from "../../hooks/useTenant";
import Title from "../LayoutComponents/Title";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },

    badge: {
        right: "-10px",
    },

    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },

    hide: {
        display: "none !important",
    },

    multiItemLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1, 1, 0),
        gap: theme.spacing(1)
    },

    serachInputWrapper: {
        flex: 1,
        background: "#fff",
        display: "flex",
        borderRadius: theme.spacing(2),
        padding: 4,
        marginRight: theme.spacing(1),
    },

    searchIcon: {
        color: "grey",
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        borderRadius: theme.spacing(2),
    },

    show: {
        display: "block",
    },

    tabsHeader: {
        flex: "none",
        backgroundColor: "#f0f2f5",
    },

    tab: {
        minWidth: 120,
        width: 120,
    },

    ticketsList: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 0,
    },

    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        // alignItems: "center",
        background: "#fafafa",
        padding: theme.spacing(1, 1, 2),
        gap: theme.spacing(1),
    },

    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
    }
}));

const TicketsManager = () => {
    const classes = useStyles();
    const { tenant } = useTenant();

    const [newChatModalOpen, setNewChatModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleCloseOrOpenChat = (chat) => {
        setNewChatModalOpen(false);
        if (chat !== undefined && chat?.uuid !== undefined) {
            navigate(`/${tenant?.slug}/chats/${chat?.uuid}`);
        }
    }

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <NewChatModal
                modalOpen={newChatModalOpen}
                onClose={(chat) => handleCloseOrOpenChat(chat)}
                type="new"
            />
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
                <div className={classes.multiItemLine}>
                    <Title>
                        {i18n.t("translation.chats.title")}
                        <TutorialModalButton content="tutorial" />
                    </Title>
                    <IconButton
                        // variant="contained" // Won't work on iconbuttons.
                        className={classes.actionButtons}
                        color="primary"
                        onClick={() => setNewChatModalOpen(true)}
                        size="small"
                    >
                        <AddOutlined />
                    </IconButton>
                    {/* <Button
                        // variant="outlined"
                        color="primary"
                        onClick={() => setNewChatModalOpen(true)}
                    >
                        {i18n.t("translation.validation.buttons.newTicket")}
                    </Button> */}
                </div>
            </Paper>
            <Paper className={classes.ticketsList}>
                <ChatsList />
            </Paper>
        </Paper>
    );
};

export default TicketsManager;
