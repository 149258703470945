import React, { useState } from "react";

import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Copyright from "../../components/Copyright";
import useAuth from "../../hooks/useAuth";
import { i18n } from "../../translate/i18n";

import { Formik } from "formik";
import CheckboxInput from "../../components/FormComponents/CheckboxInput";
import FormContainer from "../../components/FormComponents/FormContainer";
import TextFieldInput from "../../components/FormComponents/TextFieldInput";
import { loginFormSchema } from "../../services/validation/formValidation";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "calc(100vh)",
        padding: theme.spacing(3),
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        zIndex: 1
    },
    paper: {
        padding: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 25,
        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
        maxWidth: 440,
        width: "100%"
    },
    changelog: {
        maxWidth: 440,
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    img: {
        margin: `20px ${theme.spacing(1)}`,
        padding: "0px 0px 40px",
        width: 195,
        borderBottom: "1px solid #cecece",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = () => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const { authPersist, handleLogin, setAuthPersist } = useAuth();
    const initialValues = { email: "", password: "", authPersist }

    return (
        <>
            <Typography component="h1" variant="h5">
                {i18n.t("translation.login.title")}
            </Typography>

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={loginFormSchema}
                onSubmit={(values, actions) => {
                    setAuthPersist(values.authPersist)
                    handleLogin(values);
                    actions.resetForm();
                }}
            >
                {({ errors, isSubmitting, touched }) => (

                    <FormContainer>
                        <TextFieldInput
                            name="email"
                            label={i18n.t("translation.login.form.email")}
                            size="large"
                            autoFocus
                            required
                            autoComplete="email"
                            disabled={isSubmitting}
                            error={touched?.title && Boolean(errors.title)}
                        />
                        <TextFieldInput
                            name="password"
                            label={i18n.t("translation.login.form.password")}
                            size="large"
                            required
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((e) => !e)}
                                            size="large">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            disabled={isSubmitting}
                            error={touched?.title && Boolean(errors.title)}
                        />
                        <CheckboxInput
                            label={i18n.t("translation.login.buttons.authPersist")}
                            name="authPersist"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {i18n.t("translation.login.buttons.submit")}
                        </Button>

                        <Box mt={1}>
                            <Copyright />
                        </Box>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default Login;
