import React, { useEffect, useState } from "react";

import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TutorialModal from "../TutorialModal";

const useStyles = makeStyles(theme => ({
    color: {
        position: "relative",
        margin: `0 ${theme.spacing(2)}`,
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.hover,
        },

    },
    button: {
        padding: `0 ${theme.spacing(1)}`,
    },
    buttonIcon: {
        width: "1.2rem",
        height: "1.2rem"
    },
    buttonActive: {
        fontSize: "0.85rem",
        fontWeight: "bold",
        marginLeft: -theme.spacing(0.5)
    },
    tooltip: {
        textAlign: "center"
    }
}));

const TutorialModalButton = ({ children, content, ...rest }) => {
    const classes = useStyles();

    const [seen, setSeen] = useState(false);
    const [tutorialModalOpen, setTutorialModalOpen] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const loadButton = () => {
        if (!content) return false;
        let translation = i18n.t(`translation.tutorialModal.tutorials.${content}.content`);
        const regex = /^Translation for ".+" not found\.$/gm;
        return !regex.test(translation)
    }

    useEffect(() => {
        if (!loadButton()) return;

        let isMounted = true;
        const controller = new AbortController();

        const fetchTutorial = async () => {
            try {
                await axiosPrivate.get(`/tutorial/`, { params: { tutorialRef: content } });
                isMounted && setSeen(true);
            } catch (err) {
                isMounted && setSeen(false);
            }
        };
        fetchTutorial();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        loadButton() && (
            <>
                <TutorialModal
                    content={content}
                    light={true}
                    controls={false}
                    playing={true}
                    open={tutorialModalOpen}
                    onClose={setTutorialModalOpen}
                >
                    {i18n.t("translation.tutorialModal.button")}
                </TutorialModal>
                <Tooltip
                    title={i18n.t("translation.tutorialModal.tooltip")}
                    placement="bottom"
                    className={classes.tooltip}
                    arrow
                >
                    {seen ? (
                        <IconButton
                            size="small"
                            className={[classes.color, classes.buttonIcon].join(' ')}
                            onClick={(e) => { setTutorialModalOpen(true) }}
                            variant="contained"
                            color="primary"
                            type="button"
                            {...rest}
                        >
                            <PlayArrow fontSize="small" />
                        </IconButton>
                    ) : (
                        <Button
                            className={[classes.color, classes.button].join(' ')}
                            startIcon={<PlayArrow />}
                            disableElevation={true}
                            onClick={(e) => { setTutorialModalOpen(true) }}
                            variant="contained"
                            color="primary"
                            size="small"
                            {...rest}
                        >
                            <Typography className={classes.buttonActive}>
                                {i18n.t("translation.tutorialModal.button")}
                            </Typography>
                        </Button>
                    )}
                </Tooltip>
            </>
        )
    );
};

export default TutorialModalButton;
