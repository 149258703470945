import { useEffect, useReducer, useState } from "react";
import toastError from "../../errors/toastError";

import useAxiosPrivate from "../useAxiosPrivate";
import { useSocketListener } from "../useSocket/Events";
import useTenant from "../useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_WHATSAPPS") {
        const whatsApps = action.payload;

        return [...whatsApps];
    }

    if (action.type === "UPDATE_WHATSAPPS") {
        const whatsApp = action.payload;
        const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

        if (whatsAppIndex !== -1) {
            state[whatsAppIndex] = whatsApp;
            return [...state];
        } else {
            return [whatsApp, ...state];
        }
    }

    if (action.type === "UPDATE_SESSION") {
        const whatsApp = action.payload;
        const whatsAppIndex = state?.findIndex(s => s.id === whatsApp.id);

        if (whatsAppIndex !== -1) {
            state[whatsAppIndex].id = whatsApp.id;
            state[whatsAppIndex].status = whatsApp.status;
            state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
            state[whatsAppIndex].qrcode = whatsApp.qrcode;
            state[whatsAppIndex].number = whatsApp.number;
            state[whatsAppIndex].retries = whatsApp.retries;
            return [...state];
        } else {
            return [...state];
        }
    }

    if (action.type === "DELETE_WHATSAPPS") {
        const whatsAppId = action.payload;

        const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
        if (whatsAppIndex !== -1) {
            state.splice(whatsAppIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useWhatsApps = () => {
    const [whatsApps, dispatch] = useReducer(reducer, []);
    const [defaultWhatsApp, setDefaultWhatsApp] = useState();
    const [loading, setLoading] = useState(true);
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    const getDefaultWhatsApp = (data) => {
        const value = data?.find(s => s.isDefault === true);
        return value;
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        setLoading(true);
        const fetchSession = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/whatsapp/`, { signal: controller.signal });
                    if (isMounted) {
                        setDefaultWhatsApp(getDefaultWhatsApp(data));
                        dispatch({ type: "LOAD_WHATSAPPS", payload: data });
                        setLoading(false);
                    }
                } catch (err) {
                    toastError(err);
                }
            }
        };
        fetchSession();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    // Handle Channel Session Change
    const handleSessionChange = (data) => {
        if (data.action === "update") {
            dispatch({ type: "UPDATE_SESSION", payload: data.session });
        }
    }
    useSocketListener(`channel:whatsapp:session`, (data) => handleSessionChange(data))

    // Handle channel Change
    const handleWhatsAppChange = (data) => {
        if (data.action === "update") {
            dispatch({ type: "UPDATE_WHATSAPPS", payload: data.channel });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_WHATSAPPS", payload: data.channelId });
        }
    }
    useSocketListener(`channel:whatsapp`, (data) => handleWhatsAppChange(data))

    return { whatsApps, defaultWhatsApp, loading };
};

export default useWhatsApps;
