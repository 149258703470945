import { useState } from "react";
import toastError from "../../errors/toastError";

export function useLocalStorage(key, initialValue, isString) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = localStorage.getItem(key);
            return item
                ? isString
                    ? item
                    : JSON.parse(item)
                : initialValue;
        } catch (err) {
            toastError(err, "", "Error on UseLocalStorage's 'getItem' function.");
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function
                ? value(storedValue)
                : value;
            setStoredValue(valueToStore);

            localStorage.setItem(key,
                isString
                    ? valueToStore
                    : JSON.stringify(valueToStore)
            );
        } catch (err) {
            toastError(err, "", "Error on UseLocalStorage's 'setValue' function.");
        }
    };

    return [storedValue, setValue];
}
