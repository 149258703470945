import React, { useEffect, useMemo, useReducer, useState } from "react";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

import {
    Addchart,
    CloudDownload,
    CoPresent,
    DeleteOutline,
    Description,
    Edit,
    FileDownloadOutlined,
    MenuBook,
    MusicNote,
    OndemandVideo,
    Pause,
    PictureAsPdf,
    PlayArrowOutlined,
    VisibilityOutlined,
    Web,
} from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

// Hooks
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import usePlan from '../../hooks/usePlan';
import useTenant from '../../hooks/useTenant';

// Components
import { toast } from 'react-toastify';
import { CampaignModal } from '../../components/CampaignModal';
import { Can } from '../../components/Can';
import ConfirmationModal from '../../components/ConfirmationModal';
import DownloadCSV from '../../components/DownloadCSV';
import PaperContainer from '../../components/LayoutComponents/Paper';
import Title from '../../components/LayoutComponents/Title';
import { LinearProgressWithLabel } from '../../components/LinearProgressWithLabel';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import ModalImageCors from '../../components/ModalImageCors';
import StatusComponent from '../../components/Status';
import TutorialModalButton from '../../components/TutorialModalButton';
import { useSocketListener } from '../../hooks/useSocket/Events';
import logger from '../../services/logger';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    imagePreview: {
        margin: "0 auto",
        maxHeight: 40,
        maxWidth: 40
    },
    mainPaper: {
        margin: "0 auto",
        overflowY: "scroll",
        width: "100%",
        ...theme.scrollbarStyles,
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        }
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit"
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_CAMPAIGNS") {
        const campaigns = action.payload;
        let newCampaign = [];

        campaigns?.forEach((campaign) => {
            const campaignIndex = state.findIndex((c) => c.id === campaign.id);
            if (campaignIndex !== -1) {
                state[campaignIndex] = campaign;
            } else {
                newCampaign.push(campaign);
            }
        });

        return [...state, ...newCampaign]
    }

    if (action.type === "UPDATE_CAMPAIGN") {
        const campaign = action.payload;
        const campaignIndex = state.findIndex((c) => c.id === campaign.id);
        if (campaignIndex !== -1) {
            state[campaignIndex] = campaign;
            return [...state]
        } else {
            return [campaign, ...state]
        }
    }

    if (action.type === "DELETE_CAMPAIGN") {
        const campaignId = action.payload;
        const campaignIndex = state.findIndex((c) => c.id === campaignId);
        if (campaignIndex !== -1) {
            state.splice(campaignIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
}

const Campaigns = () => {
    const classes = useStyles()
    const axiosPrivate = useAxiosPrivate();

    // Context
    const { tenantId, userTenant } = useTenant()
    const [campaignsData, dispatch] = useReducer(reducer, []);
    const { getPlanValue } = usePlan();
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [viewCampaign, setViewCampaign] = useState(false);

    // State
    const [openModal, setOpenModal] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deletingCampaign, setDeletingCampaign] = useState(null);
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([{
        attachment: '',
        author: '',
        contacts: '',
        dateTime: '',
        hasMedia: false,
        id: '',
        message: '',
        name: '',
        percentage: '',
        status: '',
        success: '',

    }]);

    // Update rows based on updated data
    useEffect(() => {
        if (!campaignsData || loading) return;
        if (campaignsData.length === 0) return;

        // Filter deleted campaigns
        let activeCampaigns = campaignsData.filter(campaign => campaign.status !== 'deleted')

        let dataGridRows = activeCampaigns.map((c) => ({
            attachment: c?.mediaUrl,
            author: c?.userTenant?.user?.name || i18n.t("translation.dashboard.campaigns.table.authorNotFound"),
            contacts: calcData({
                pending: c?.logPendingCount,
                failed: c?.logFailedCount,
                sent: c?.logSentCount,
                insuficientCredits: c?.logInsuficientCreditsCount,
            })?.total,
            dateTime: new Date(c?.sendAt).toLocaleString(),
            hasMedia: c?.hasMedia,
            id: c?.id,
            message: c?.message,
            name: c?.name,
            percentage: calcData({
                pending: c?.logPendingCount,
                failed: c?.logFailedCount,
                sent: c?.logSentCount,
                insuficientCredits: c?.logInsuficientCreditsCount,
            })?.percentage,
            status: c?.status,
            success: calcData({
                pending: c?.logPendingCount,
                failed: c?.logFailedCount,
                sent: c?.logSentCount,
                insuficientCredits: c?.logInsuficientCreditsCount,
            })?.sent,
        }));

        setRows(dataGridRows)
    }, [campaignsData, loading])

    // Get campaigns data
    useEffect(() => {
        if (!tenantId) return;

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const fetchCampaigns = async () => {
            try {
                // first get data from campaigns
                const { data: campaignData } = await axiosPrivate.get(`/${tenantId}/campaign`, { signal: controller.signal })

                // then get limits
                const { data: limitData } = await axiosPrivate.get(`/${tenantId}/campaign/limit`, { signal: controller.signal });

                if (isMounted) {
                    // Set campaigns
                    if (campaignData?.length > 0) {
                        dispatch({
                            type: "LOAD_CAMPAIGNS",
                            payload: campaignData
                        })
                    }

                    // Set limits
                    setUsage((info) => ({
                        ...info,
                        usage: Number(limitData?.count),
                    }));

                    // Set loading false
                    setLoading(false);
                }

            } catch (err) {
                isMounted && setLoading(false);
                logger({ type: "error_info", title: "Campaigns", content: err.message })
                // toastError(err, "", "Error while trying to get campaign data info on campaign listing page component.");
            }
        }
        fetchCampaigns();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId])

    // Handle Campaign Changes
    // TODO: include listener to campaignLog event with update to increase counters in realtime when sending the campaign.
    function handleCampaignChange(data) {
        if (data.action === "create" || data.action === "update") {
            dispatch({ type: "UPDATE_CAMPAIGN", payload: data.campaign })
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_CAMPAIGN", payload: data.campaignId })
        }
    }
    useSocketListener(`campaign`, (data) => handleCampaignChange(data))


    // Campaign limits
    const [usage, setUsage] = useState({
        limit: 1,
        usage: 0,
        message: i18n.t("translation.campaigns.buttons.credits.loading"),
    });
    const [usageInfo, setUsageInfo] = useState({
        color: "primary",
        message: usage.message,
        credits: 0,
        tooltip: i18n.t("translation.campaigns.tooltips.trial"),
    });

    useEffect(() => {
        let limit = getPlanValue("campaignLimit");
        let use = Number(usage.usage);
        let credits = limit - use;
        let percent = credits / limit;

        setUsageInfo((prev) => ({ ...prev }));

        let message = i18n.t("translation.campaigns.buttons.credits.left", {
            credits: credits.toLocaleString("pt-BR"),
            limit: limit.toLocaleString("pt-BR"),
        });
        let buy = i18n.t("translation.campaigns.buttons.credits.buy", {
            credits: credits.toLocaleString("pt-BR"),
            limit: limit.toLocaleString("pt-BR"),
        });
        let negative = i18n.t("translation.campaigns.buttons.credits.negative");

        if (credits <= 0) {
            setUsageInfo((info) => ({
                ...info,
                color: "secondary",
                message: negative,
                credits: credits,
                tooltip: i18n.t(
                    "translation.campaigns.tooltips.buyCreditsNow",
                    {
                        credits: credits.toLocaleString("pt-BR"),
                        limit: limit.toLocaleString("pt-BR"),
                    }
                ),
            }));
        } else if (percent <= 0.15 || credits < 1) {
            setUsageInfo((info) => ({
                ...info,
                color: "secondary",
                message: buy,
                credits: credits,
                tooltip: i18n.t("translation.campaigns.tooltips.buyCredits"),
            }));
        } else {
            setUsageInfo((info) => ({
                ...info,
                color: "primary",
                message: message,
                credits: credits,
                tooltip: i18n.t("translation.campaigns.tooltips.creditsInfo"),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage]);

    const columns = useMemo(() => [
        {
            field: "name",
            headerName: i18n.t("translation.variables.table.campaignName"),
            flex: 0.3,
            minWidth: 260,
            description: i18n.t("translation.dashboard.campaigns.table.descriptions.name")
        },
        {
            field: "author",
            headerName: i18n.t("translation.variables.table.user"),
            flex: 0.2,
            minWidth: 80,
            description: i18n.t("translation.dashboard.campaigns.table.descriptions.author")
        },
        {
            field: "attachment",
            headerName: i18n.t("translation.variables.table.attachment.title"),
            flex: 0.2,
            minWidth: 80,
            type: "string",
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.attachment ? (
                    <div className={classes.imagePreview}>
                        {renderPreview(params.row.attachment)}
                    </div>
                ) : (
                    i18n.t("translation.variables.table.attachment.none")
                )
            )
        },
        {
            field: "message",
            headerName: i18n.t("translation.variables.table.message"),
            flex: 1,
            minWidth: 300,
            sortable: false,
            description: i18n.t("translation.dashboard.campaigns.table.descriptions.message"),
            renderCell: (params) => (
                <div className={classes.wrap}>
                    {params.row.message}
                </div>
            )
        },
        {
            field: "contacts",
            headerName: i18n.t("translation.variables.table.contacts"),
            flex: 0.15,
            minWidth: 60,
            type: 'number',
            description: i18n.t("translation.dashboard.campaigns.table.descriptions.contacts"),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "percentage",
            headerName: i18n.t("translation.variables.table.percentage"),
            flex: 0.1,
            minWidth: 60,
            type: "string",
            description: i18n.t("translation.dashboard.campaigns.table.descriptions.percentage"),
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    <LinearProgressWithLabel
                        value={parseInt(
                            params.row.percentage.replace("%", "")
                        )}
                    />
                </>
            ),
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.dashboard.teams.table.descriptions.nps"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <StatusComponent status={params.row.status} />
            )
        },
        {
            field: "dateTime",
            headerName: i18n.t("translation.variables.table.dateTime"),
            flex: 0.3,
            minWidth: 180,
            description: i18n.t("translation.dashboard.campaigns.table.descriptions.dateTime"),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.3,
            minWidth: 120,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    {params.row.status === "preview" ? (
                        <IconButton
                            size="small"
                            color="primary"
                            className={classes.actionButtons}
                            onClick={() => {
                                setSelectedCampaign(params.row);
                                setOpenModal(true);
                            }}
                        >
                            <Edit />
                        </IconButton>
                    ) : params.row.status === "paused" ? (
                        <IconButton
                            size="small"
                            color="primary"
                            disabled
                            className={classes.actionButtons}
                            onClick={() => setOpenModal(true)}
                        >
                            <PlayArrowOutlined />
                        </IconButton>
                    ) : params.row.status === "pending" ? (
                        <IconButton
                            size="small"
                            color="primary"
                            disabled
                            className={classes.actionButtons}
                            onClick={() => setOpenModal(true)}
                        >
                            <Pause />
                        </IconButton>
                    ) : (
                        <IconButton
                            size="small"
                            color="primary"
                            className={classes.actionButtons}
                            onClick={() => {
                                setSelectedCampaign(params.row);
                                setViewCampaign(true);
                                setOpenModal(true);
                            }}
                        >
                            <VisibilityOutlined />
                        </IconButton>
                    )}

                    <Can
                        role={userTenant?.role}
                        perform="reports-page:download:campaignData"
                        yes={() => (
                            <>
                                <Tooltip
                                    arrow
                                    title={i18n.t(
                                        "translation.dashboard.campaigns.table.download"
                                    )}
                                >
                                    <IconButton
                                        size="small"
                                        className={classes.actionButtons}
                                        color="primary"
                                        onClick={(e) => {
                                            handleDownloadData(
                                                params.row.id
                                            );
                                        }}
                                    >
                                        <FileDownloadOutlined />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    />

                    {params.row.status === "sent" || params.row.status === "error" || params.row.status === "pending" || params.row.status === "preview" ? (
                        <Can
                            role={userTenant?.role}
                            perform="schedule-page:delete:message:self"
                            yes={() => (
                                <IconButton
                                    title={i18n.t("translation.validation.buttons.delete")}
                                    size="small"
                                    className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                    onClick={e => {
                                        setConfirmOpen(true);
                                        setDeletingCampaign(params.row.id)
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            )}
                        />
                    ) : ("")}
                </>
            ),
        },


        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [userTenant?.role]);

    //TODO: componetize for the entire app
    const renderPreview = (fileUrl) => {
        const images = ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "ico", "apng", "png", "webp", "svg"];
        const videos = ["mp4", "mkv", "webm", "avi", "flv", "mov"];
        const audios = ["mpeg", "m4a", "wav", "wave", "mp3", "ogg"];
        const ebook = ["epub", "mobi", "azw", "pdb", "prc"];
        const doc = ["pdf", "xps"];
        const text = ["doc", "docm", "docx", "dot", "dotm", "dotx", "odt", "rtf", "txt", "pages"];
        const spreadsheet = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "csv", "ods", "number"];
        const presentation = ["pptx", "pptm", "ppt", "potx", "potm", "pot", "ppsx", "pps", "odp", "key"];
        const web = ["html", "htm"];

        const fileExt = fileUrl.split('.').pop();

        const getFileName = () => {
            let breakPath = fileUrl.split("/");
            let pathCount = breakPath.length - 1;
            let fileName = breakPath[pathCount]
            return fileName
        }

        const downloadFile = () => {
            const element = document.createElement("a");
            element.href = fileUrl;
            element.target = "_blank";
            element.rel = "noopener noreferrer";
            element.download = getFileName();

            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }

        switch (true) {
            case (images.indexOf(fileExt) !== -1):
                return (
                    <ModalImageCors
                        imageUrl={fileUrl}
                    />
                );

            case (videos.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.video")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <OndemandVideo />
                        </IconButton>
                    </Tooltip>
                );

            case (audios.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.audio")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <MusicNote />
                        </IconButton>
                    </Tooltip>
                );

            case (ebook.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.ebook")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <MenuBook />
                        </IconButton>
                    </Tooltip>
                );

            case (doc.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.print")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <PictureAsPdf />
                        </IconButton>
                    </Tooltip>
                );

            case (text.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.document")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <Description />
                        </IconButton>
                    </Tooltip>
                );

            case (spreadsheet.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.spreadsheet")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <Addchart />
                        </IconButton>
                    </Tooltip>
                );

            case (presentation.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.presentation")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <CoPresent />
                        </IconButton>
                    </Tooltip>
                );

            case (web.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.web")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <Web />
                        </IconButton>
                    </Tooltip>
                );

            default:
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.cloud")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                )
        }
    }

    // Calculate success data for campaigns
    function calcData(obj) {
        // Start count
        let pending = Number(obj?.pending) || 0;
        let failed = Number(obj?.failed) || 0;
        let sent = Number(obj?.sent) || 0;
        let insuficientCredits = Number(obj?.insuficientCredits) || 0;

        // Sum all to get total amount of contacts in campaign
        let total = pending + failed + sent + insuficientCredits;

        let percentage = Math.round((sent / total) * 100, -1) || 0;

        let values = {
            total,
            failed,
            sent,
            percentage: percentage + "%"
        }

        return values
    }

    const handleDownloadData = async (id) => {
        try {
            const { data } = await axiosPrivate.get(`/${tenantId}/campaign/${id}/download`);

            // Status reference table
            const statusTranslation = {
                1: "pending",
                8: "error",
                9: "sent",
                13: "insuficient_credits",
            };

            // Create file
            const content = [{
                timestamp: i18n.t("translation.variables.table.dateTime"),
                name: i18n.t("translation.variables.table.name"),
                phoneNumber: i18n.t("translation.variables.table.phone"),
                email: i18n.t("translation.variables.table.email"),
                status: i18n.t("translation.variables.table.status.title")
            }];

            data?.contacts?.map((contactInfo) => (
                content.push({
                    timestamp: contactInfo?.createdAt,
                    name: contactInfo?.contact?.name,
                    phoneNumber: contactInfo?.phoneNumber,
                    email: contactInfo?.contact?.email,
                    status: i18n.t(`translation.variables.table.status.${statusTranslation[contactInfo?.status]}`)
                })
            ))

            // Set download
            DownloadCSV(
                `${i18n.t("translation.validation.buttons.campaigns.fileName")}_-_${data.createdAt.slice(0, 10)}_#${id}.csv`,
                content,
                'text/csv; charset=utf-8'
            );
        } catch (err) {
            toastError(err);
        }
    }

    const handleCloseCampaignModal = () => {
        setOpenModal(false)
        setSelectedCampaign(null)
        setViewCampaign(false)
    }

    const handleDeleteCampaign = async (campaignId, status) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/campaign/${campaignId}`);
            toast.success(i18n.t("translation.scheduledMessages.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingCampaign(null);
    }

    return (
        <MainContainer>
            <CampaignModal
                open={openModal}
                onClose={handleCloseCampaignModal}
                usage={usage}
                campaignId={selectedCampaign?.id}
                viewer={viewCampaign}
            />

            <ConfirmationModal
                title={
                    deletingCampaign
                        ? i18n.t("translation.scheduledMessages.confirmationModal.delete.title")
                        : null
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={(e) =>
                    deletingCampaign
                        ? handleDeleteCampaign(deletingCampaign)
                        : null
                }
            >
                {deletingCampaign
                    ? i18n.t("translation.scheduledMessages.confirmationModal.delete.message")
                    : null
                }
            </ConfirmationModal>

            <MainHeader>
                <Title>
                    {i18n.t("translation.reports.report.campaigns.title")}
                    <TutorialModalButton content="campaigns" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Tooltip arrow title={usageInfo.tooltip}>
                        <Button variant="outlined" color={usageInfo.color}>
                            {usageInfo.message}
                        </Button>
                    </Tooltip>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenModal(true)}
                    >
                        {i18n.t("translation.reports.buttons.goToCampaigns")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <PaperContainer>
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{ width: "100%" }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{ [`& .${gridClasses.cell}`]: { py: 1 } }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}
                        </Grid>
                    </div>
                </div>
            </PaperContainer>
        </MainContainer>
    )
}

export default Campaigns;
