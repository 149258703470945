import React from 'react';
import ReactMarkdown from 'react-markdown';
const MarkdownRenderer = ({ html }) => {
  return (
    <ReactMarkdown>{html}
</ReactMarkdown>
   
  );
};

export default MarkdownRenderer;