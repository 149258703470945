import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { QuestionAnswerTwoTone } from '@mui/icons-material';
import {
    Badge,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import notifySound from "../../assets/chimes/notify/sound.mp3";

import { format } from "date-fns";
import useSound from "use-sound";
import { i18n } from "../../translate/i18n";
import ChatListItem from "../ChatListItem";

import useAuth from "../../hooks/useAuth";
import useChats from "../../hooks/useChats";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";


const useStyles = makeStyles((theme) => ({
    tabContainer: {
        overflowY: "auto",
        maxHeight: 350,
        ...theme.scrollbarStyles,
    },
    popoverPaper: {
        width: "100%",
        maxWidth: 350,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            maxWidth: 270,
        },
    },
    noShadow: {
        boxShadow: "none !important",
    },
}));

export default function ChatPopover() {
    const classes = useStyles();

    const navigate = useNavigate();

    const { user } = useAuth();
    const { tenant } = useTenant();

    const { uuid } = useParams();
    const { chats } = useChats({ withUnreadMessages: "true" })

    const anchorEl = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    // const [, setDesktopNotifications] = useState([]);

    const [play] = useSound(notifySound);
    const soundAlertRef = useRef();

    useEffect(() => {
        soundAlertRef.current = play;
        if (!("Notification" in window)) {
            console.info(i18n.t("translation.validation.noNotifications"));
        } else {
            Notification.requestPermission();
        }
    }, [play]);

    useEffect(() => {
        setNotifications(chats);
    }, [chats]);

    // Handle Chat Changes
    const handleChatChange = (data) => {
        const userInChat = data?.chat?.users?.find(u => u.userId === user?.id)
        if (
            (data?.action === "updateUnread" || data?.action === "delete")
            && userInChat
            // && userInChat.unreadMessages > 0
        ) {
            setNotifications(prevState => {
                const chatIndex = prevState?.findIndex(chat => chat.id === data.chat.id);
                if (chatIndex !== -1) {
                    prevState.splice(chatIndex, 1);
                    return [...prevState];
                }
                return prevState;
            });
        }
        if (
            data?.action === "newMessage"
            && userInChat?.unreadMessages > 0
            && data?.message?.senderId !== user?.id
        ) {
            setNotifications(prevState => {
                const chatIndex = prevState?.findIndex(chat => chat.id === data.chat.id);
                if (chatIndex !== -1) {
                    prevState[chatIndex] = data.chat;
                    return [...prevState];
                }
                return [data.chat, ...prevState];
            });
            const shouldNotNotificate = (data.chat.uuid === uuid && document.visibilityState === "visible")
            if (shouldNotNotificate) return

            handleNotifications(data);
        }
    };
    useSocketListener(`channel:internal`, (data) => handleChatChange(data))

    const handleNotifications = data => {
        const { newMessage, chat } = data;

        const options = {
            body: `${newMessage?.body} - ${format(new Date(), "HH:mm")}`,
            // icon: contact.profilePicUrl,
            tag: chat.id,
            renotify: true,
        };

        const notification = new Notification(
            `${i18n.t("translation.tickets.notification.message")} ${newMessage?.sender?.name}`,
            options
        );

        notification.onclick = e => {
            e.preventDefault();
            window.focus();
            navigate(`/${tenant.slug}/chats/${chat.uuid}`);
        };

        soundAlertRef.current();
    };

    const handleClick = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const NotificationTicket = ({ children }) => {
        return <div onClick={handleClickAway}>{children}</div>;
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                ref={anchorEl}
                color="inherit"
                size="large"
            >
                <Badge
                    badgeContent={notifications?.length}
                    color="error"
                    overlap="rectangular"
                >
                    <QuestionAnswerTwoTone />
                </Badge>
            </IconButton>

            <Popover
                disableScrollLock
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{ paper: classes.popoverPaper }}
                onClose={handleClickAway}
            >
                <List
                    dense
                    className={classes.tabContainer}
                >
                    {!notifications || notifications?.length === 0 ? (
                        <ListItem>
                            <ListItemText>{i18n.t("translation.notifications.noTickets")}</ListItemText>
                        </ListItem>
                    ) : (
                        notifications?.map(chat => (
                            <NotificationTicket
                                key={chat.id}
                            >
                                <ChatListItem
                                    key={chat.id}
                                    chat={chat}
                                />
                            </NotificationTicket>
                        ))
                    )}
                </List>
            </Popover>
        </>
    );
}
