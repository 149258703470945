import React, { useState } from "react";

import { Box, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
    CheckCircleOutline,
    MoreVert,
} from "@mui/icons-material";

import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import ChatOptionsMenu from "../ChatOptionsMenu";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        flex: "none",
        alignSelf: "center",
        marginLeft: "auto",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    buttonMargin: {
        margin: theme.spacing(1),
    },
}));

const ChatActionButtons = ({ chat, openChatModal }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading,] = useState(false);
    const chatOptionsMenuOpen = Boolean(anchorEl);

    const handleOpenChatOptionsMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseChatOptionsMenu = e => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.actionButtons}>
            <Box display={{ xs: "inline-flex", sm: "none" }}>
                <IconButton
                    color="primary"
                    onClick={() => openChatModal(true)}
                    size="large"
                >
                    <CheckCircleOutline />
                </IconButton>
                <IconButton
                    onClick={handleOpenChatOptionsMenu}
                    size="large">
                    <MoreVert />
                </IconButton>
            </Box>

            <Box display={{ xs: "none", sm: "inline-flex" }}>
                <ButtonWithSpinner
                    loading={loading}
                    size="small"
                    className={classes.buttonMargin}
                    variant="contained"
                    color="primary"
                    onClick={() => openChatModal(true)}
                >
                    {i18n.t("translation.validation.buttons.edit")}
                </ButtonWithSpinner>
                <IconButton onClick={handleOpenChatOptionsMenu} size="large">
                    <MoreVert />
                </IconButton>
            </Box>

            <ChatOptionsMenu
                chat={chat}
                anchorEl={anchorEl}
                menuOpen={chatOptionsMenuOpen}
                handleClose={handleCloseChatOptionsMenu}
            />
        </div>
    );
};

export default ChatActionButtons;
