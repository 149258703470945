import React, { useEffect, useMemo, useReducer, useState } from "react";

import {
    Box,
    Button,
    Grid,
    IconButton,
    //IconButton,
    InputAdornment,
    Paper,
    TextField
} from "@mui/material";

import { DataGrid, gridClasses } from "@mui/x-data-grid";

import {
    Block,
    CheckCircleOutlined,
    EditOutlined,
    Search,
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import { useNavigate } from "react-router-dom";
import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSocket from "../../hooks/useSocket";
import useTenant from "../../hooks/useTenant";
import logger from "../../services/logger";

const reducer = (state, action) => {
    if (action.type === "LOAD_SUBSCRIPTIONS") {
        const subscriptions = action.payload;
        const newSubscription = [];

        subscriptions.forEach((subscription) => {
            const subscriptionIndex = state.findIndex((s) => s.id === subscription.id);
            if (subscriptionIndex !== -1) {
                state[subscriptionIndex] = subscription;
            } else {
                newSubscription.push(subscription);
            }
        });

        return [...state, ...newSubscription];
    }

    if (action.type === "UPDATE_SUBSCRIPTIONS") {
        const subscription = action.payload;
        const subscriptionIndex = state.findIndex((s) => s.id === subscription.id);

        if (subscriptionIndex !== -1) {
            state[subscriptionIndex] = subscription;
            return [...state];
        } else {
            return [subscription, ...state];
        }
    }

    if (action.type === "DELETE_SUBSCRIPTIONS") {
        const subscriptionId = action.payload;

        const subscriptionIndex = state.findIndex((s) => s.id === subscriptionId);
        if (subscriptionIndex !== -1) {
            state.splice(subscriptionIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140,
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    formControl: {
        marginRight: theme.spacing(2),
        "& input": {
            height: "1.2195em",
        },
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    searchMobile: {
        width: "100%",
        padding: theme.spacing(0, 1, 2),
    },
    root: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexWrap: "wrap",
        },
    },
    status: {
        display: "flex",
        justifyContent: "center",
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit",
    },
}));

const Subscriptions = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [, setLoading] = useState(false);

    const [subscriptions, dispatch] = useReducer(reducer, []);

    const { user } = useAuth();
    const { tenant, tenantId, userTenant } = useTenant();
    const { socket } = useSocket();

    const [showAll, setShowAll] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [, setHasMore] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const [rows, setRows] = useState([
        {
            id: "",
            plan: {},
            currency: "",
            total: 0,
            recurrence: "",
            provider: "",
            status: "",
            expiresAt: "",
            createdAt: "",
            updatedAt: "",
            extraInfo: {},
        },
    ]);

    useEffect(() => {
        if (!subscriptions) return;
        let dataGridRows = subscriptions.map((subscription) => ({
            id: subscription?.id,
            plan: subscription?.plan,
            currency: subscription?.currency,
            total: subscription?.total,
            recurrence: subscription?.plan?.recurrence,
            provider: subscription?.provider,
            status: subscription?.status,
            paymentStatus: subscription?.extraInfo?.stripe?.checkout?.paymentStatus,
            expiresAt: subscription?.expiresAt,
            createdAt: subscription?.createdAt,
            updatedAt: subscription?.updatedAt,
            extraInfo: subscription?.extraInfo,

        }));

        setRows(dataGridRows);
    }, [subscriptions]);

    const columns = useMemo(() => [
        // {
        //     field: "id",
        //     headerName: i18n.t("translation.variables.table.id"),
        //     flex: 0.6,
        //     minWidth: 20,
        //     description: i18n.t("translation.tenants.table.descriptions.name")
        // },
        {
            field: "plan",
            headerName: i18n.t("translation.variables.table.plan"),
            flex: 0.4,
            minWidth: 120,
            description: i18n.t("translation.plans.table.descriptions.publicName"),
            renderCell: (params) => params.row.plan.name
        },
        {
            field: "description",
            headerName: i18n.t("translation.variables.table.description"),
            flex: 0.6,
            minWidth: 160,
            description: i18n.t("translation.tenants.table.descriptions.plan"),
            renderCell: (params) => params.row.plan.description
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.2,
            minWidth: 40,
            description: i18n.t(
                "translation.tenants.table.descriptions.visibility.description"
            ),
            headerAlign: "center",
            align: "center",
            renderCell: params => (
                params.row.status
                    ? (<div className={classes.status}>
                        <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.tenants.table.descriptions.status.active")}
                    </div>)
                    : (<div className={classes.status}>
                        <Block fontSize="small" className={classes.marginRight} color="error" /> {i18n.t("translation.tenants.table.descriptions.status.inactive")}
                    </div>)
            ),
        },
        {
            field: "total",
            headerName: i18n.t("translation.variables.table.value"),
            flex: 0.2,
            minWidth: 40,
            description: i18n.t("translation.invoices.table.descriptions.total"),
            renderCell: (params) => (new Intl.NumberFormat(
                i18n.t("translation.validation.locale"),
                { style: "currency", currency: params.row.currency }
            ).format(params.row.total) + "/" + i18n.t(`translation.planModal.form.option.recurrence.${params.row.recurrence}`))
        },
        {
            field: "expiresAt",
            headerName: i18n.t("translation.variables.table.expireDate"),
            flex: 0.2,
            minWidth: 40,
            type: "date",
            headerAlign: "center",
            align: "center",
            renderCell: (params) =>
                new Date(params.row.expiresAt).toLocaleString(
                    i18n.t("translation.validation.locale"),
                    { dateStyle: "short" }
                ),
        },
        {
            field: "updatedAt",
            headerName: i18n.t("translation.variables.table.updatedDate"),
            flex: 0.2,
            minWidth: 40,
            type: "date",
            headerAlign: "center",
            align: "center",
            renderCell: (params) =>
                new Date(params.row.updatedAt).toLocaleString(
                    i18n.t("translation.validation.locale"),
                    { dateStyle: "short" }
                ),
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.2,
            minWidth: 60,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                // params.row.owner?.id === user.id || showAll ? (
                <>
                    <IconButton
                        size="small"
                        className={classes.actionButtons}
                        color="primary"
                        onClick={() => logger({ type: "info", title: "Subscription", content: "Subscription update not available yet" })}
                        disabled={true}
                    >
                        <EditOutlined />
                    </IconButton>
                </>
            )
        }
    ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [subscriptions]
    );

    useEffect(() => {
        if (searchParam.length > 3) {
            dispatch({ type: "RESET" });
            setPageNumber(1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        // const { role } = userTenant;
        const roles = ["super", "admin", "supervisor"];
        const adminUser = roles.indexOf(userTenant?.role) !== -1;
        setShowAll(adminUser);
    }, [user, userTenant]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        if (searchParam.length < 4 && subscriptions > 0) {
            return () => {
                setLoading(false);
                isMounted = false;
                controller.abort();
            };
        }

        const fetchInvoices = async () => {
            try {
                const params = { searchParam, pageNumber, showAll }
                const { data } = await axiosPrivate.get(`/${tenantId}/subscriptions`, { params });
                if (isMounted) {
                    dispatch({ type: "LOAD_SUBSCRIPTIONS", payload: data.subscriptions });
                    setHasMore(data.hasMore);
                    setLoading(false);
                }
            } catch (err) {
                isMounted &&
                    toastError(
                        err,
                        "",
                        "Error while trying to pull tenants on tenant manager page."
                    );
                setLoading(false);
            }
        };
        fetchInvoices();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, showAll, tenantId]);

    useEffect(() => {
        socket.on(`subscription-update`, (data) => {
            if (
                data.action === "update" ||
                data.action === "create" ||
                data.action === "disable"
            ) {
                dispatch({ type: "UPDATE_SUBSCRIPTIONS", payload: data.Subscription });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_SUBSCRIPTIONS",
                    payload: data.subscriptionId,
                });
            }
        });

        return () => {
            const keys = [
                `subscription-update`
            ]

            //Disconnect from all IO
            for (const key of keys) {
                socket.off(key)
            }
        };
    }, [socket, tenantId]);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.subscriptions.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t(
                                "translation.variables.placeholders.search"
                            )}
                            type="search"
                            display={{ xs: "none" }}
                            value={searchParam}
                            size="small"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            // TODO: add open invoices notification/alert
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(`/${tenant.slug}/invoices`)}
                            className={classes.formControl}
                        >
                            {i18n.t("translation.account.buttons.invoices")}
                        </Button>
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.formControl}
                    placeholder={i18n.t(
                        "translation.variables.placeholders.search"
                    )}
                    type="search"
                    display={{ xs: "none" }}
                    value={searchParam}
                    size="small"
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid
                            item
                            sx={{ width: "100%" }}
                        >
                            {rows && (
                                <DataGrid
                                    className={classes.gridPaper}
                                    rows={rows}
                                    columns={columns}
                                    autoHeight={true}
                                    getRowHeight={() => "auto"}
                                    pageSize={20}
                                    getRowId={(row) => row.id}
                                    sx={{ [`& .${gridClasses.cell}`]: { py: 1 } }}
                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                />
                            )}
                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default Subscriptions;
