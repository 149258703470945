import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
    Colorize
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';


import { i18n } from "../../translate/i18n";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import ColorPicker from "../ColorPicker";
import MultiFieldLine from "../FormComponents/MultiFieldLine";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
        borderRadius: 4
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}));

const TagSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, i18n.t("translation.validation.too_short"))
        .required(i18n.t("translation.validation.required"))
});

const TagModal = ({ open, onClose, tagId, reload }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const { tenantId } = useTenant();
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

    const initialState = {
        name: "",
        color: ""
    };

    const [tag, setTag] = useState(initialState);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchTag = async () => {
            try {
                if (!tagId || !tenantId) return;

                const { data } = await axiosPrivate.get(`/${tenantId}/tags/${tagId}`);
                isMounted && setTag(prevState => {
                    return { ...prevState, ...data };
                });
            } catch (err) {
                toastError(err);
            }
        };

        fetchTag();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagId, open, tenantId]);

    const handleClose = () => {
        setTag(initialState);
        setColorPickerModalOpen(false);
        onClose();
    };

    const handleSaveTag = async values => {
        const tagData = { ...values, userId: user.id };
        if (tenantId) {
            try {
                if (tagId) {
                    await axiosPrivate.put(`/${tenantId}/tags/${tagId}`, tagData);
                } else {
                    await axiosPrivate.post(`/${tenantId}/tags`, tagData);
                }
                toast.success(i18n.t("translation.tagModal.success"));
                if (typeof reload == 'function') {
                    reload();
                }
            } catch (err) {
                toastError(err);
            }
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {(tagId ? `${i18n.t("translation.tagModal.title.edit")}` : `${i18n.t("translation.tagModal.title.add")}`)}
                </DialogTitle>
                <Formik
                    initialValues={tag}
                    enableReinitialize={true}
                    validationSchema={TagSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveTag(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <MultiFieldLine>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("translation.tagModal.form.name")}
                                        name="name"
                                        autoFocus
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setTag(prev => ({ ...prev, name: e.target.value }))}
                                        fullWidth
                                    />

                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label={i18n.t("translation.tagModal.form.color")}
                                        name="color"
                                        id="color"
                                        error={touched.color && Boolean(errors.color)}
                                        helperText={touched.color && errors.color}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <div
                                                        style={{ backgroundColor: values.color }}
                                                        className={classes.colorAdorment}
                                                    ></div>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <IconButton
                                                    size="small"
                                                    color="default"
                                                    onClick={() => setColorPickerModalOpen(!colorPickerModalOpen)}
                                                >
                                                    <Colorize />
                                                </IconButton>
                                            ),
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <ColorPicker
                                        open={colorPickerModalOpen}
                                        handleClose={() => setColorPickerModalOpen(false)}
                                        onChange={color => {
                                            values.color = color;
                                            setTag(prev => ({ ...prev, color: color }));
                                        }}
                                    />
                                </MultiFieldLine>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    className={classes.buttonColorError}
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("translation.validation.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {tagId
                                        ? `${i18n.t("translation.validation.buttons.okEdit")}`
                                        : `${i18n.t("translation.validation.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default TagModal;
