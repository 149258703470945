import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../useAxiosPrivate";

import useTenant from "../useTenant";

const useChats = ({
    date,
    pageNumber,
    searchParam,
    withUnreadMessages
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [chats, setChats] = useState([]);
    const [count, setCount] = useState(0);
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        setLoading(true);
        const fetchChats = async () => {
            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/chats`, {
                    params: {
                        date,
                        pageNumber,
                        searchParam,
                        withUnreadMessages
                    },
                }, { signal: controller.signal }
                )

                if (isMounted) {
                    setChats(data.chats)
                    setHasMore(data.hasMore)
                    setCount(data.count)
                    setLoading(false)
                }
            } catch (err) {
                setLoading(false)
                toastError(err, "", "Couldn't fetch chats from backend at Use Chats")
            }
        }

        fetchChats()


        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        date,
        pageNumber,
        searchParam,
        withUnreadMessages,
        tenantId
    ])

    return { chats, loading, hasMore, count };
};

export default useChats;