const colors = [
    "#006600",
    "#000066",
    "#410f70",
    "#660066",
    "#800015",
    "#660000",
    "#70380f",
    "#664200",
    "#937301",
    "#00b300",
    "#0000b3",
    "#6918b4",
    "#b300b3",
    "#cc0022",
    "#b30000",
    "#9d4e15",
    "#b37400",
    "#eeb902",
    "#2dc58e",
    "#0000ff",
    "#8a2be2",
    "#ff00ff",
    "#ff1a40",
  ];

  export default colors;