import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    color: {
        width: 60,
        height: 20,
        alignSelf: "center",
        borderRadius: 4
    },
    customCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function ColorItem(props) {
    const classes = useStyles();

    return (
        <div className={classes.customCell}>
            <span className={classes.color}
                style={{ backgroundColor: props.color }}
            />
        </div>
    );
}
