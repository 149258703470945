import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ButtonWithSpinner from "../ButtonWithSpinner";
import { Can } from "../Can";

import appVars from "../../../package.json";
import toastError from "../../errors/toastError";
import { isControllerUrl } from "../../services/config";
import { i18n } from "../../translate/i18n";
import ConnectionItems from "../ConnectionItems";

// import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useQueues from "../../hooks/useQueues";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
    formWidth: {
        width: 350,
    },
    maxWidth: {
        width: "100%",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    marginTop: {
        width: "100%",
        marginBottom: theme.spacing(2)
    }
}));

const filterOptions = createFilterOptions({
    trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketId, ticketWhatsappId }) => {
    const [options, setOptions] = useState([]);
    const [queues, setQueues] = useState([]);
    const [allQueues, setAllQueues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState('');
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
    const classes = useStyles();

    // const { user: loggedInUser } = useAuth();
    const { findAll: findAllQueues } = useQueues();
    const { loadingWhatsapps, whatsApps } = useWhatsApps();
    const { tenant, tenantId, userTenant } = useTenant();


    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const loadQueues = async () => {
            try {
                const list = await findAllQueues();
                if (isMounted) {
                    setAllQueues(list);
                    setQueues(list);
                };
            } catch (err) {
                toastError(err);
            }
        };

        loadQueues();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }

        setLoading(true);
        const fetchUsers = async () => {
            if (!tenantId) return;
            try {
                const { data } = await axiosPrivate.get(`/users/${tenantId}`, {
                    params: { searchParam },
                });
                let blockRoles = ["finance", "dashboard"];
                let availableUsers = data.users.filter(user =>
                    (user.userTenant[0].status === true &&
                        blockRoles.indexOf(user.userTenant[0].role) === -1) ||
                    (
                        user.userTenant[0].role === 'super' &&
                        user.email.split('@')[1] === appVars.controllerDomain &&
                        isControllerUrl
                    )
                );
                if (isMounted) {
                    setOptions(availableUsers);
                    setLoading(false);
                }
            } catch (err) {
                if (isMounted) {
                    setLoading(false);
                }
                toastError(err);
            }
        };

        fetchUsers();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, modalOpen, tenantId]);

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedUser(null);
    };

    const handleSaveTicket = async e => {
        e.preventDefault();
        if (!ticketId) return;
        setLoading(true);
        try {
            let data = {};

            if (selectedUser) {
                data.userTenantId = selectedUser.userTenant[0].id
            }

            if (selectedQueue && selectedQueue !== null) {
                data.queueId = selectedQueue

                if (!selectedUser) {
                    data.status = 'pending';
                    data.userTenantId = null;
                }
            }

            if (selectedWhatsapp) {
                data.whatsappId = selectedWhatsapp;
            }

            if (tenantId) {
                await axiosPrivate.put(`/${tenantId}/tickets/${ticketId}`, data);
            };

            setLoading(false);
            navigate(`/${tenant.slug}/tickets`);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };


    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
            <form onSubmit={handleSaveTicket} className={classes.formWidth}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("translation.transferTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <Autocomplete
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, newValue) => {
                            setSelectedUser(newValue);
                            if (newValue != null && Array.isArray(newValue.queues)) {
                                setQueues(newValue.queues);
                            } else {
                                setQueues(allQueues);
                                setSelectedQueue('');
                            }
                        }}
                        options={options}
                        filterOptions={filterOptions}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t("translation.transferTicketModal.noOptions")}
                        loading={loading}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={i18n.t("translation.transferTicketModal.fieldLabel")}
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParam(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <FormControl variant="outlined" className={classes.marginTop}>
                        <InputLabel>{i18n.t("translation.transferTicketModal.fieldQueueLabel")}</InputLabel>
                        <Select
                            value={selectedQueue}
                            onChange={(e) => setSelectedQueue(e.target.value)}
                            label={i18n.t("translation.transferTicketModal.fieldQueuePlaceholder")}
                        >
                            <MenuItem value={''}>&nbsp;</MenuItem>
                            {queues.map((queue) => (
                                <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Can
                        role={userTenant?.role}
                        perform="ticket-options:transfer:connection"
                        yes={() => (!loadingWhatsapps &&
                            <FormControl variant="outlined" className={classes.marginTop}>
                                <InputLabel>{i18n.t("translation.transferTicketModal.fieldConnectionLabel")}</InputLabel>
                                <Select
                                    value={selectedWhatsapp}
                                    onChange={(e) => setSelectedWhatsapp(e.target.value)}
                                    label={i18n.t("translation.transferTicketModal.fieldConnectionPlaceholder")}
                                >
                                    {ConnectionItems(whatsApps)}
                                </Select>
                            </FormControl>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.buttonColorError}
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("translation.validation.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="submit"
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("translation.validation.buttons.transfer")}
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default TransferTicketModal;
