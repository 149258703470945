import React, { useEffect, useState } from "react";

import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";

import { Dialog, DialogContent, Paper, Typography } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
    const [qrCode, setQrCode] = useState("");
    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchSession = async () => {
            if (!whatsAppId || !tenantId) return;

            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/whatsapp/${whatsAppId}`);
                isMounted && setQrCode(data.qrcode);
            } catch (err) {
                toastError(err);
            }
        };

        fetchSession();

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, whatsAppId]);

    // Handle Channel Session Change
    const handleWhatsAppSessionChange = (data) => {
        if (data.action === "update" && data.session.id === whatsAppId) {
            setQrCode(data.session.qrcode);
        }

        if (data.action === "update" && data.session.qrcode === "") {
            onClose();
        }
    }
    useSocketListener(`channel:whatsapp:session`, (data) => handleWhatsAppSessionChange(data))

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
            <DialogContent>
                <Paper elevation={0}>
                    <Typography color="primary" gutterBottom>
                        {i18n.t("translation.qrCode.message")}
                    </Typography>
                    {qrCode ? (
                        <QRCode value={qrCode} size={256} />
                    ) : (
                        <span>{i18n.t("translation.qrCode.waiting")}</span>
                    )}
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(QrcodeModal);
