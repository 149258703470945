import React from "react";

import { Avatar, CardHeader, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    avatar: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        }
    },
    cardHeader: {
        fontWeight: "500",
        cursor: "pointer",
        paddingLeft: 0,
        // [theme.breakpoints.up("sm")]: {
        // },
        [theme.breakpoints.down("sm")]: {
            // marginLeft: theme.spacing(-1.5),
            "& .MuiCardHeader-content": {
                maxWidth: `calc(${window.innerWidth}px - ${theme.spacing(19)})`
            }
        },
    },
    ticketId: {
        opacity: "70%",
    },
}));

const TicketInfo = ({ contact, ticket, onClick }) => {
    const classes = useStyles();
    return (
        <CardHeader
            onClick={onClick}
            className={classes.cardHeader}
            titleTypographyProps={{ noWrap: true }}
            subheaderTypographyProps={{ noWrap: true }}
            avatar={<Avatar className={classes.avatar} src={contact.profilePicUrl} alt={contact.name} variant="rounded" />}
            title={
                <Typography noWrap>
                    <strong>{contact.name}</strong>
                    <span className={classes.ticketId}> #{ticket.protocol}</span>
                </Typography>
            }
            subheader={
                ticket?.userTenant?.user &&
                `${i18n.t("translation.messagesList.header.assignedTo")} ${ticket.userTenant.user.name}`
            }
        />
    );
};

export default TicketInfo;
