import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Paper, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

import makeStyles from '@mui/styles/makeStyles';

import { Can } from "../../../components/Can";
import Title from "../../../components/LayoutComponents/Title";
import MainContainer from "../../../components/MainContainer";
import MainHeader from "../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../components/MainHeaderButtonsWrapper";

import appVars from "../../../../package.json";
import Divider from "../../../components/Divider/index.js";
import useTenant from "../../../hooks/useTenant";
import { i18n } from "../../../translate/i18n.js";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "600px",
            flexWrap: "wrap",
        },
    },
    container: {
        padding: "16px 24px",
    },
    mainPaper: {
        margin: "0 auto",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minWidth: "600px",
        },
    },
    titleH2: {
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    titleH3: {
        fontSize: "1rem",
        fontWeight: "500",
        width: "100%",
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textCampaignsContainer: {
        width: "100%",
    },
    content: {
        padding: "0 24px 16px",
    }
}));

const Reports = () => {
    const classes = useStyles();
    // const { user } = useAuth();
    const { tenant, userTenant } = useTenant();

    const navigate = useNavigate();

    const goToSupport = () => {
        window.open(`${appVars.support.link}?referral=${tenant.slug}`, "_blank");
    }

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("translation.reports.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={goToSupport}
                    // disabled
                    >
                        {i18n.t("translation.settings.support")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.title}>
                            <h2 className={classes.titleH2}>{i18n.t("translation.reports.report.title")}</h2>
                        </div>
                        <Divider />

                        <div className={classes.content}>
                            <Can
                                role={userTenant?.role}
                                perform="reports-page:view:teams"
                                yes={() => (
                                    <>
                                        <Typography variant="body1">
                                            <span className={classes.titleH3}>{i18n.t("translation.reports.report.teams.title")}</span>
                                        </Typography>
                                        <Typography variant="body1">
                                            {i18n.t("translation.reports.report.teams.description")}
                                        </Typography>

                                        <Divider silent={true} />

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate(`/${tenant.slug}/reports/teams`)}
                                        // disabled
                                        >
                                            {i18n.t("translation.reports.buttons.access")}
                                        </Button>
                                    </>
                                )}
                            />
                            <Can
                                role={userTenant?.role}
                                perform="reports-page:view:campaigns"
                                yes={() => (
                                    <>
                                        <Divider />
                                        <Typography variant="body1">
                                            <span className={classes.titleH3}>{i18n.t("translation.reports.report.campaigns.title")}</span>
                                        </Typography>
                                        <Typography variant="body1">
                                            {i18n.t("translation.reports.report.campaigns.description")}
                                        </Typography>

                                        <Divider silent={true} />

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate(`/${tenant.slug}/reports/campaigns`)}
                                        // disabled
                                        >
                                            {i18n.t("translation.reports.buttons.access")}
                                        </Button>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Paper>

        </MainContainer>

    );
};

export default Reports;
