import { Button } from "@mui/material";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const LS_NAME = 'audioMessageRate';

// eslint-disable-next-line import/no-anonymous-default-export
export default function({url}) {
    const audioRef = useRef(null);
    const [showButtonRate, setShowButtonRate] = useState(false);
    const [audioRate, setAudioRate] = useLocalStorage(LS_NAME, 1, true)

    useEffect(() => {
        audioRef.current.playbackRate = audioRate;
        setAudioRate(audioRate, true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRate]);

    useEffect(() => {
        audioRef.current.onplaying = () => {
            setShowButtonRate(true);
        };
        audioRef.current.onpause = () => {
            setShowButtonRate(false);
        };
        audioRef.current.onended = () => {
            setShowButtonRate(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toogleRate = () => {
        let newRate = null;

        switch(audioRate) {
            case 0.5:
                newRate = 1;
                break;
            case 1:
                newRate = 1.5;
                break;
            case 1.5:
                newRate = 2;
                break;
            case 2:
                newRate = 0.5;
                break;
            default:
                newRate = 1;
                break;
        }

        setAudioRate(newRate, true);
    };
    
    return (
        <>
            <audio ref={audioRef} controls sx={{"& audio::-webkit-media-controls-panel": {backgroundColor: "red"}}}>
                <source src={url} type="audio/ogg"></source>
            </audio>
            {showButtonRate && <Button style={{marginLeft: "5px", marginTop: "-45px"}} onClick={toogleRate}>{audioRate}x</Button>}
        </>
    );
} 