import { useEffect, useState } from "react";

import {
    Button,
    ButtonGroup,
    Container,
    Grid,
    Paper,
    Typography
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import TagsFunnel from "./Components/TagsFunnel";

import { i18n } from "../../translate/i18n";
import ChartTicketsNPS from "./Charts/ChartTicketsNPS";
import ChartTicketsQueues from "./Charts/ChartTicketsQueues";
import ChartTicketsTodayByHour from "./Charts/ChartTicketsTodayByHour";
import ChartTicketsWhatsapps from "./Charts/ChartTicketsWhatsapps";
import ChartTicketsYear from "./Charts/ChartTicketsYear";

import AdvancedFilter from "../../components/AdvancedFiltersModal";
import DashCard from "../../components/DashCard";
import DateFilterModal from "../../components/DateFilterModal";

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 244,
        border: '1px solid rgba(0, 0, 0, 0.05)',
    },
    mainPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
        overflowY: "scroll",
        border: '1px solid rgba(0, 0, 0, 0.05)',
        ...theme.scrollbarStyles,
    }
}))

const Dashboard = () => {
    const classes = useStyles();
    const now = new Date();
    const { tenantId, userTenant } = useTenant();
    const axiosPrivate = useAxiosPrivate();

    const [visibleDateRange, setVisibleDateRange] = useState(false)
    const [visibleQueue, setVisibleQueue] = useState(false)

    const [data, setData] = useState();
    const [todayData, setTodayData] = useState();
    const [filteredData, setFilteredData] = useState();
    const [dateRange, setDateRange] = useState({
        dateStart: new Date(now.getFullYear(), now.getMonth(), 1), // .toLocaleDateString('pt-BR')
        dateEnd: new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
    });
    const userQueueIds = userTenant?.queues?.map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

    useEffect(() => {
        const controller = new AbortController();
        let isMounted = true;
        if (isMounted === true) {
            const { dateStart, dateEnd } = dateRange;
            const fetchData = async () => {
                if (tenantId && selectedQueueIds) {
                    try {
                        const { data } = await axiosPrivate.post(`/${tenantId}/dashboard/data`, {
                            dateStart,
                            dateEnd,
                            queueId: selectedQueueIds
                        });
                        const today = await axiosPrivate.get(`/${tenantId}/dashboard/today`, {
                        });
                        setTodayData(today.data);
                        setData(data);
                        setFilteredData(data);
                    } catch (err) {
                        toastError(err, "", "Error ocurred while trying to fetch dashboard data at the Dashboard page.");
                    }
                }
            };
            fetchData();
        }
        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, tenantId, selectedQueueIds]);

    // Filtering data when selecting queues
    // TODO: proper filter from dashboard data
    useEffect(() => {
        if (selectedQueueIds?.length > 0 && data?.length > 0) {
            let totalCount = data?.ticketCountQueues?.rows.reduce((total, item) => {
                if (selectedQueueIds.includes(item?.queue?.id)) {
                    return total + item?.ticketCount;
                }
                return total;
            }, 0)

            const someData = {
                ...data,
                ticketTotalWhatsapp: {
                    count: totalCount,
                    rows: {
                        ticketCount: totalCount
                    }
                },
                ticketCountQueues: {
                    ...data?.ticketCountQueues,
                    count: data?.ticketCountQueues?.count.filter((item) => selectedQueueIds.includes(item?.id)),
                    rows: data?.ticketCountQueues?.rows.filter((item) => selectedQueueIds.includes(item?.queue?.id))
                },
                ticketCountContacts: {
                    count: totalCount,
                    rows: {
                        ticketCount: totalCount
                    }
                }
            };
            setFilteredData(someData)
        } else {
            setFilteredData(data)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQueueIds])

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <AdvancedFilter
                    open={visibleQueue}
                    setVisible={setVisibleQueue}
                    selectedQueueIds={selectedQueueIds}
                    setSelectedQueueIds={setSelectedQueueIds}
                    userTenant={userTenant}
                />

                <DateFilterModal
                    open={visibleDateRange}
                    setDateRange={setDateRange}
                    setVisible={setVisibleDateRange}
                />

                <Grid container spacing={3}>
                    <DashCard title={i18n.t("translation.dashboard.messages.inAttendance.title")}>
                        {todayData?.openTickets.count}
                        {/* {GetTickets("open", "true", "false", date.current)} */}

                    </DashCard>
                    <DashCard title={i18n.t("translation.dashboard.messages.waiting.title")}>
                        {todayData?.pendingTickets.count}
                        {/* {GetTickets("pending", "true", "false", date.current)} */}
                    </DashCard>
                    <DashCard title={i18n.t("translation.dashboard.messages.closed.title")}
                    >
                        {todayData?.closedTickets.count}
                        {/* {GetTickets("closed", "true", "false", date.current)} */}
                    </DashCard>

                    <Grid item xs={12}>
                        <Paper className={classes.fixedHeightPaper}>
                            <ChartTicketsTodayByHour />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.mainPaper}>
                            <TagsFunnel />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.mainPaper}>
                            <ChartTicketsYear />
                        </Paper>
                    </Grid>

                    {filteredData && (
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.mainPaper}>
                                    <Typography
                                        component="h3"
                                        variant="h6"
                                        color="primary"
                                        paragraph
                                        className={classes.dashCardInnerLeft}
                                    >
                                        {i18n.t("translation.dashboard.filter.title")}
                                    </Typography>
                                    <ButtonGroup size="small">
                                        <Button
                                            onClick={() => setDateRange({
                                                dateStart: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
                                                dateEnd: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)
                                            })}
                                            color="primary">
                                            {i18n.t("translation.variables.calendar.day")}
                                        </Button>
                                        <Button
                                            onClick={() => setDateRange({
                                                dateStart: new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay()),
                                                dateEnd: new Date(now.getFullYear(), now.getMonth(), now.getDate() + (6 - now.getDay()), 23, 59, 59)
                                            })}
                                            color="primary">
                                            {i18n.t("translation.variables.calendar.week")}
                                        </Button>
                                        <Button
                                            onClick={() => setDateRange({
                                                dateStart: new Date(now.getFullYear(), now.getMonth(), 1),
                                                dateEnd: new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
                                            })}
                                            color="primary">
                                            {i18n.t("translation.variables.calendar.month")}
                                        </Button>
                                        <Button
                                            onClick={() => setDateRange({
                                                dateStart: new Date(now.getFullYear(), 0, 1),
                                                dateEnd: new Date(now.getFullYear(), 11, 31, 23, 59, 59)
                                            })}
                                            color="primary">
                                            {i18n.t("translation.variables.calendar.year")}
                                        </Button>
                                        <Button onClick={() => setVisibleDateRange(!visibleDateRange)} color="primary">
                                            {i18n.t("translation.dashboard.filter.period")}
                                        </Button>
                                        <Button onClick={() => setVisibleQueue(!visibleQueue)} color="primary">
                                            {i18n.t("translation.dashboard.filter.advanced")}
                                        </Button>
                                    </ButtonGroup>
                                </Paper>
                            </Grid>

                            <DashCard title={i18n.t("translation.dashboard.messages.totalTickets.title")}>
                                {parseInt(
                                    filteredData?.ticketTotalWhatsapp?.count
                                        ? filteredData?.ticketTotalWhatsapp?.count
                                        : 0
                                )}
                            </DashCard>
                            <DashCard title={i18n.t("translation.dashboard.messages.waitingTime.title")}>
                                {parseInt(
                                    filteredData?.ticketWaitingTime[0]?.minutes
                                        ? filteredData?.ticketWaitingTime[0]?.minutes
                                        : 0
                                )
                                    .toString()
                                    .padStart(2, "0")
                                }:{parseInt(
                                    filteredData?.ticketWaitingTime[0]?.seconds
                                        ? filteredData?.ticketWaitingTime[0]?.seconds
                                        : 0
                                )
                                    .toString()
                                    .padStart(2, "0")
                                }
                            </DashCard>
                            <DashCard title={i18n.t("translation.dashboard.messages.ticketDuration.title")}>
                                {
                                    parseInt(
                                        filteredData?.ticketClosed[0]?.minutes
                                            ? filteredData?.ticketClosed[0]?.minutes
                                            : 0
                                    )
                                        .toString()
                                        .padStart(2, "0")
                                }:{parseInt(
                                    filteredData?.ticketClosed[0]?.seconds
                                        ? filteredData?.ticketClosed[0]?.seconds
                                        : 0
                                )
                                    .toString()
                                    .padStart(2, "0")
                                }
                            </DashCard>
                            <Grid item xs={6}>
                                <Paper className={classes.mainPaper}>
                                    <ChartTicketsQueues data={filteredData} />
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.mainPaper}>
                                    <ChartTicketsWhatsapps data={filteredData} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.mainPaper}>
                                    <ChartTicketsNPS data={data} dateStart={dateRange.dateStart} dateEnd={dateRange.dateEnd} queueId={selectedQueueIds} />
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
        </div>
    )
}

export default Dashboard
