import React, { useEffect, useMemo, useReducer, useState } from "react";

import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Tooltip
} from "@mui/material";

import { DataGrid, gridClasses } from '@mui/x-data-grid';

import {
    Block,
    CheckCircleOutlined,
    EditOutlined,
    Lock,
    LockOpen,
    Search
} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';

import ConfirmationModal from "../../components/ConfirmationModal";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import PlansModal from "../../components/PlansModal";

import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";

// import { Can } from "../../components/Can";
import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";

const reducer = (state, action) => {
    if (action.type === "LOAD_PLANS") {
        const plans = action.payload;
        const newPlans = [];

        plans.forEach((plan) => {
            const planIndex = state.findIndex((p) => p.id === plan.id);
            if (planIndex !== -1) {
                state[planIndex] = plan;
            } else {
                newPlans.push(plan);
            }
        });

        return [...state, ...newPlans];
    }

    if (action.type === "UPDATE_PLANS") {
        const plan = action.payload;
        const planIndex = state.findIndex((p) => p.id === plan.id);

        if (planIndex !== -1) {
            state[planIndex] = plan;
            return [...state];
        } else {
            return [plan, ...state];
        }
    }

    if (action.type === "DELETE_PLANS") {
        const planId = action.payload;

        const planIndex = state.findIndex((p) => p.id === planId);
        if (planIndex !== -1) {
            state.splice(planIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    actionsCell: {
        minWidth: 140
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    formControl: {
        marginRight: theme.spacing(2),
        "& input": {
            height: "1.2195em"
        }
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    quickAnswer: {
        // width: 300,
    },
    searchMobile: {
        width: "100%",
        padding: theme.spacing(0, 1, 2),
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        }
    },
    status: {
        display: "flex",
        justifyContent: "center"
    },
    wrap: {
        wordWrap: "break-word !important",
        maxWidth: "inherit"
    }
}));

const Plans = () => {
    const classes = useStyles();

    const [, setLoading] = useState(false);

    const [plans, dispatch] = useReducer(reducer, []);

    const [disablingPlans, setDisablingPlans] = useState(null);
    const [confirmAction, setConfirmAction] = useState("")
    const [selectedPlans, setSelectedPlans] = useState(null);

    const [plansModalOpen, setPlansModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const { user } = useAuth();
    const { tenantId, userTenant } = useTenant();

    const [showAll, setShowAll] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [, setHasMore] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const [rows, setRows] = useState([{
        id: '',
        uuid: '',
        name: '',
        description: '',
        price: '',
        currency: '',
        recurrence: '',
        status: false,
        public: false,
        planPieces: {},
        createdAt: '',
        updatedAt: ''
    }]);

    useEffect(() => {
        if (!plans) return;

        let dataGridRows = plans.map(plan => ({
            id: plan?.id,
            uuid: plan?.uuid,
            name: plan?.name,
            description: plan?.description,
            price: plan?.price,
            currency: plan?.currency,
            recurrence: plan?.recurrence,
            status: plan?.status,
            public: plan?.public,
            planPieces: plan?.planPieces,
            createdAt: plan?.createdAt,
            updatedAt: plan?.updatedAt
        }));

        setRows(dataGridRows)

    }, [plans]);

    const columns = useMemo(() => [
        {
            field: "name",
            headerName: i18n.t("translation.variables.table.name"),
            flex: 0.4,
            minWidth: 120,
            description: i18n.t("translation.plans.table.descriptions.name")
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.tenants.table.descriptions.visibility.description"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.status
                    ? (<div className={classes.status}>
                        <CheckCircleOutlined fontSize="small" className={classes.marginRight} /> {i18n.t("translation.tenants.table.descriptions.status.active")}
                    </div>)
                    : (<div className={classes.status}>
                        <Block fontSize="small" className={classes.marginRight} color="error" /> {i18n.t("translation.tenants.table.descriptions.status.inactive")}
                    </div>)
            )
        },
        {
            field: "public",
            headerName: i18n.t("translation.variables.table.visibility.title"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.quickAnswers.table.descriptions.visibility.description"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.public
                    ? (<div className={classes.status}>
                        <LockOpen fontSize="small" className={classes.marginRight} /> {i18n.t("translation.quickAnswers.table.descriptions.visibility.public")}
                    </div>)
                    : (<div className={classes.status}>
                        <Lock fontSize="small" className={classes.marginRight} color="error" /> {i18n.t("translation.quickAnswers.table.descriptions.visibility.private")}
                    </div>)
            )
        },
        {
            field: "description",
            headerName: i18n.t("translation.variables.table.description"),
            flex: 0.6,
            minWidth: 160,
            description: i18n.t("translation.tenants.table.descriptions.plan"),
            renderCell: params => (
                params.row.description
                    ? (<Tooltip arrow title={<>
                        <span><b>{params.row.name}</b><br /></span>
                        {params.row?.planPieces?.map((piece) => (
                            <span key={params.row.uuid + piece?.name}>
                                {`${i18n.t(`translation.planModal.form.option.planPiece.name.${piece?.fieldName}`)}: ${piece?.PlanComposition?.quantity}`}<br />
                            </span>
                        ))}
                    </>}>
                        <span>
                            {params.row.description}
                        </span>
                    </Tooltip>)
                    : ""
            )
        },
        {
            field: "createdAt",
            headerName: i18n.t("translation.variables.table.createdAt"),
            flex: 0.2,
            minWidth: 60,
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                new Date(params.row.createdAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short' })
            )
        },
        {
            field: "updatedAt",
            headerName: i18n.t("translation.variables.table.updatedDate"),
            flex: 0.2,
            minWidth: 60,
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.updatedAt
                    ? new Date(params.row.updatedAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short' })
                    : "-"
            )
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.2,
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                // params.row.owner?.id === user.id || showAll ? (
                <>
                    <IconButton
                        size="small"
                        className={classes.actionButtons}
                        color="primary"
                        onClick={() => handleEditPlan(params.row)}
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        // TODO: add disable permission
                        size="small"
                        className={params.row.status
                            ? [classes.actionButtons, classes.buttonColorError].join(' ')
                            : classes.actionButtons
                        }
                        color="primary"
                        onClick={() => {
                            setConfirmAction(params.row.status ? "disable" : "enable");
                            setConfirmModalOpen(true);
                            setDisablingPlans(params.row);
                        }}
                    >
                        {params.row.status ? <Block /> : <CheckCircleOutlined />}
                    </IconButton>

                    {/* <Can
                        role={userTenant?.role}
                        perform="tenant-modal:delete:tenant"
                        yes={() => (
                            <>
                                <IconButton
                                    size="small"
                                    className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                    onClick={(e) => {
                                        // setConfirmModalOpen(true);
                                        handleDeleteTenants(params.row.id)
                                        setDeletingTenants(params.row);
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </>
                        )}
                    /> */}
                </>
                // ) : ''
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [plans]);

    useEffect(() => {
        if (searchParam.length > 3) {
            dispatch({ type: "RESET" });
            setPageNumber(1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        // const { role } = userTenant;
        const roles = ["super", "admin"];
        const adminUser = roles.indexOf(userTenant?.role) !== -1;
        setShowAll(adminUser)
    }, [user, userTenant])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        if (searchParam.length < 4 && plans > 0) {
            return () => {
                setLoading(false);
                isMounted = false;
                controller.abort();
            };
        }

        const fetchPlans = async () => {
            try {
                const { data } = await axiosPrivate.get(`/plans/list/`, {
                    params: { searchParam, pageNumber, showAll },
                });
                if (isMounted) {
                    dispatch({ type: "LOAD_PLANS", payload: data });
                    setHasMore(data.hasMore);
                    setLoading(false);
                };
            } catch (err) {
                isMounted && toastError(err, "", "Error while trying to pull tenants on tenant manager page.");
                setLoading(false);
            }
        };
        fetchPlans();

        return () => {
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, showAll]);

    // Handle Plan Changes
    const handlePlanChange = (data) => {
        if (data.action === "update" || data.action === "create" || data.action === "disable") {
            dispatch({ type: "UPDATE_PLANS", payload: data.plan });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_PLANS", payload: data.plan });
        }
    };
    useSocketListener(`plan`, (data) => handlePlanChange(data))

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    // TODO: change to toggle?
    const handleOpenPlansModal = () => {
        setSelectedPlans(null);
        setPlansModalOpen(true);
    };

    const handleClosePlansModal = () => {
        setSelectedPlans(null);
        setPlansModalOpen(false);
    };

    const handleEditPlan = (plan) => {
        setSelectedPlans(plan);
        setPlansModalOpen(true);
    };

    const handleTogglePlans = async (planUUID) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.put(`/plans/${planUUID}`, { status: confirmAction === "disable" ? false : true })
            toast.success(confirmAction === "disable"
                ? i18n.t("translation.plans.toasts.disable")
                : i18n.t("translation.plans.toasts.enable")
            );
        } catch (err) {
            toastError(err);
        }
        setDisablingPlans(null);
        setSearchParam("");
        setPageNumber(1);
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    disablingPlans
                        ? confirmAction === "disable"
                            ? `${i18n.t("translation.tenants.confirmationModal.title", { name: disablingPlans.name })}`
                            : `${i18n.t("translation.tenants.confirmationModal.titleAlternate", { name: disablingPlans.name })}`
                        : ""
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleTogglePlans(disablingPlans.uuid)}
            >
                {disablingPlans && (
                    confirmAction === "disable" ? (
                        <>{i18n.t("translation.plans.confirmationModal.message.disable")}</>
                    ) : (
                        <>{i18n.t("translation.plans.confirmationModal.message.enable")}</>
                    )
                )}
            </ConfirmationModal>
            <PlansModal
                open={plansModalOpen}
                onClose={handleClosePlansModal}
                initialValues={selectedPlans}
            ></PlansModal>
            <MainHeader>
                <Title>
                    {i18n.t("translation.plans.title")}
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            size="small"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenPlansModal}
                        >
                            {i18n.t("translation.plans.buttons.add")}
                        </Button>
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenPlansModal}
                        >
                            {i18n.t("translation.plans.buttons.add")}
                        </Button>
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            // onScroll={handleScroll}
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{
                            width: "100%"
                        }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}

                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer >
    );
};

export default Plans;
