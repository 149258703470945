import appVars from "../../package.json";

function getConfig(name, defaultValue = null) {
    // If inside a docker container, use window.ENV
    if (window.ENV !== undefined && Object.keys(window.ENV).length > 0) {
        return window.ENV[name] || defaultValue;
    }
    return process.env[name] || defaultValue;
}

export function getBackendUrl() {
    const getDomain = url => {
        const urlParts = new URL(url).hostname.split('.')
        return urlParts
            .slice(0)
            .slice(-(urlParts.length === 4 ? 3 : 2))
            .join('.')
    }

    if (getConfig('BACKEND_URL') || getConfig('REACT_APP_BACKEND_URL')) {
        return getConfig('BACKEND_URL') || getConfig('REACT_APP_BACKEND_URL');
    } else {
        if (window.location.hostname === 'localhost') {
            return `http://${window.location.hostname}:8080`;
        } else {
            // return `https://api.${getDomain(window.location.origin)}`;
            return `https://api.${getDomain(window.location.origin)}`;
        }
    }
}

export function isControllerUrl() {
    if (window.location.pathname.split("/").filter(Boolean)[0] === appVars.controllerInstall) {
        return true
    } else if (window.location.hostname === 'localhost') {
        return true
    }
}
