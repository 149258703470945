import { Field } from "formik";
import { useEffect, useState } from "react";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import BotModal from "../BotModal";

const useStyles = makeStyles(theme => ({
    connectInfoItem: {
        justifyContent: "space-between",
        width: "100%",
    },
    connectionInfo: {
        alignItems: "center",
        display: "flex",
        float: "right",
        marginTop: "-5px",
        marginBottom: "-4px",
    },
    connectionInfoSpan: {
        opacity: "70%",
    },
    new: {
        borderTop: "1px solid #dadde9"
    }
}))

const BotSelect = ({ current, fieldId, props }) => {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();

    // Context
    const { tenantId } = useTenant();

    // State
    const [loading, setLoading] = useState(false);
    const [bots, setBots] = useState([]);
    const [botModalOpen, setBotModalOpen] = useState(false);

    const fetchBots = async (isMounted) => {
        if (!tenantId) return;
        try {
            const { data } = await axiosPrivate.get(`${tenantId}/bots`);
            const filtered = data.filter((bot) => bot.id !== current);
            if (isMounted && filtered) {
                setBots(filtered);
                setLoading(false);
            }
        } catch (err) {
            toastError(err, "", "Error while trying to get bot list info on botItems component.");
        }
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchBots(isMounted);

        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const handleOpenBotModal = () => {
        setBotModalOpen(true)
    }

    const handleCloseBotModal = () => {
        setBotModalOpen(false)

        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);
        fetchBots(isMounted)

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    return (<>
        <BotModal
            open={botModalOpen}
            onClose={handleCloseBotModal}
        />
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel>
                {i18n.t("translation.botModal.form.option.bot")}
            </InputLabel>
            <Field
                as={Select}
                name={fieldId}
                fullWidth
                label={i18n.t("translation.botModal.form.option.bot")}
                {...props}
            >
                <MenuItem
                    key="no-key-bot"
                    value={""}
                >
                    {i18n.t("translation.botModal.form.option.select")}
                </MenuItem>
                {!loading && bots?.length > 0 && bots?.map((bot) => (
                    <MenuItem
                        key={bot.id}
                        value={bot.id}
                        className={classes.connectInfoItem}
                    >
                        <Tooltip arrow title={bot.description}>
                            <div>
                                <span>
                                    {bot.title}
                                </span>
                                <span>
                                    ({i18n.t(`translation.bots.actions.${bot.action}`)})
                                </span>
                            </div>
                        </Tooltip>
                    </MenuItem>
                )
                )}
                <MenuItem
                    key="new-key-bot"
                    onClick={handleOpenBotModal}
                    className={classes.new}>
                    {i18n.t("translation.botModal.form.option.add")}
                </MenuItem>
            </Field>
        </FormControl>
    </>)
}

export default BotSelect;
