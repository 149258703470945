import React from "react";

import {
    Box,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import GoBackButton from "../../components/GoBackButton";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "calc(100vh)",
        padding: theme.spacing(3),
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        zIndex: 1
    },
    paper: {
        padding: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 25,
        boxShadow: "0 25px 65px rgba(34, 85, 131, 0.2)",
        maxWidth: 440,
        width: "100%"
    },
    changelog: {
        maxWidth: 440,
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    img: {
        margin: `20px ${theme.spacing(1)}`,
        padding: "0px 0px 40px",
        width: 195,
        borderBottom: "1px solid #cecece",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    placeholder: {
        margin: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = () => {
    const classes = useStyles();
    return (
        <>
            <Typography component="h1" variant="h5">
                {i18n.t("translation.notFound.title")}
            </Typography>
            <Box mt={1}>
                {/* <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => }
                >
                    {i18n.t("translation.notFound.buttons.back")}
                </Button> */}

                <GoBackButton className={classes.placehoder} label={i18n.t("translation.validation.buttons.otherTenant")} url={window.location.origin + "/"} />
            </Box>
        </>
    );
};

export default Login;
