import React, {
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
// import { useNavigate, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";

import { SmsTwoTone } from "@mui/icons-material";
import {
    Badge,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { default as alertSoundDot, default as alertSoundOriginal, default as alertSoundPipe, default as alertSoundPop, default as alertSoundStairs } from "../../assets/chimes/stairs/sound.mp3";

import useSound from "use-sound";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSettings from "../../hooks/useSettings";
import useSocket from "../../hooks/useSocket";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import useTickets from "../../hooks/useTickets";
import logger from "../../services/logger";
import NotificationFavicon from "../NotificationFavicon";

const useStyles = makeStyles(theme => ({
    tabContainer: {
        overflowY: "auto",
        maxHeight: 350,
        ...theme.scrollbarStyles,
    },
    popoverPaper: {
        width: "100%",
        maxWidth: 350,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            maxWidth: 270,
        },
    },
    noShadow: {
        boxShadow: "none !important",
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const tickets = action.payload;
        // const newTickets = [];

        tickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
                // newTickets.push(ticket);
            }
        });

        return [...state];
        // return [...state, ...newTickets];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticket.id);

        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            // return [...state]
        } else {
            state.unshift(ticket)
        }
        return [...state]
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticketId);

        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const NotificationsPopOver = (volume) => {
    const classes = useStyles();

    // State
    const { uuid } = useParams();
    const anchorEl = useRef();
    const [isOpen, setIsOpen] = useState(false);

    // Reducer
    // const [notifications, setNotifications] = useState([]);
    const [ticketList, dispatch] = useReducer(reducer, []);

    // Context
    const { settings, getSettingValue } = useSettings();
    const { tenantId, userTenant, userTenantAdminRole } = useTenant();
    const { tickets } = useTickets({ withUnreadMessages: "true" });
    const { socket } = useSocket();

    // API
    // const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const limitNoQueue = useMemo(() => {
        if (!settings || !userTenant?.role) return;

        // Get settings information
        const restrictToQueues = settings?.length > 0 && getSettingValue("restrictToQueues") === "enabled";

        // If userTenant not an admin role and restriction is set
        if (!userTenantAdminRole && restrictToQueues) {
            // setLimitNoQueue("enabled");
            return true
        }

        // If userTenant admin role or no restriction is set
        if (userTenantAdminRole || !restrictToQueues) {
            // setLimitNoQueue("disabled");
            return false
        }
    }, [getSettingValue, settings, userTenant, userTenantAdminRole])

    useEffect(() => {
        // Won't need group notifications
        let chats = tickets.filter((t) => t?.isGroup === false);

        const queueIds = userTenant?.queues?.map((q) => q.id);
        const filterQueues = chats.filter((t) => queueIds.indexOf(t.queueId) > -1);

        if (userTenantAdminRole) {
            dispatch({ type: "LOAD_TICKETS", payload: chats });
        } else {
            dispatch({ type: "LOAD_TICKETS", payload: filterQueues });
        }
    }, [tickets, userTenant?.queues, userTenantAdminRole]);

    // Sound alerts
    let configAlertSound = "";
    useRef(() => {
        const fetchAlertSound = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/settings`);
                    const settingIndex = data.filter(s => s.key === 'alertSound');
                    configAlertSound = settingIndex[0].value;

                } catch (err) {
                    configAlertSound = "original";
                }
            }
        };
        fetchAlertSound();
    }, []);

    let alertSound = "";
    switch (configAlertSound) {
        case "dot":
            alertSound = alertSoundDot;
            break;
        case "pipe":
            alertSound = alertSoundPipe;
            break;
        case "pop":
            alertSound = alertSoundPop;
            break;
        case "stair":
            alertSound = alertSoundStairs;
            break;
        default:
            alertSound = alertSoundOriginal;
    }

    const [play] = useSound(alertSound,volume);
    const soundAlertRef = useRef();

    useEffect(() => {
        soundAlertRef.current = play;
        if (!("Notification" in window)) {
            logger({ type: "error_info", title: "Notificações", content: (i18n.t("translation.validation.noNotifications")) })
        } else {
            Notification.requestPermission();
        }
    }, [play]);

    useEffect(() => {
        if (!ticketList) return;
        NotificationFavicon({ content: 'messages', state: ticketList.length > 0 })
    }, [ticketList])

    // useEffect(() => {
    //     setNotifications(tickets);
    // }, [tickets, uuid]);

    // userTenantQueues
    const userTenantQueues = userTenant?.queues.map((queue) => queue.id)

    const shouldUpdateTicket = (ticket) => (!ticket?.userTenantId || ticket?.userTenantId === userTenant?.id) &&
        (!ticket?.queueId || userTenantQueues?.indexOf(ticket?.queueId) > -1);

    // Check if the message belongs to user queues
    const notBelongsToUserQueues = (ticket) =>
        ticket?.queueId && userTenantQueues?.indexOf(ticket?.queueId) === -1;

    // Check if should notify
    const shouldNotify = (ticket) => (((
        ticket.uuid !== uuid // Check if the ticket is the same from the UUID
    ) && (
            document.visibilityState !== "visible" // Check if the ticket from the ticket and message notification are visible
        )))

    // Handlers
    // Handle Contact Changes
    const handleContactChange = (data) => {
        if (data.action === "update") {
            dispatch({ type: "UPDATE_TICKET_CONTACT", payload: data.contact });
        }
    };
    useSocketListener(`contact`, (data) => handleContactChange(data))

    // Handle Chat Changes
    const handleChatChange = (data) => {
        if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
            dispatch({
                type: "UPDATE_TICKET_UNREAD_MESSAGES",
                payload: data.ticket,
            });
        }
    };
    useSocketListener(`message`, (data) => handleChatChange(data))

    // Special Socket listener function
    useEffect(() => {
        if (!socket || !userTenant || !userTenantQueues) return;

        // Handle Chats Changes
        const handleChatsChange = (data) => {
            if (data.action === "updateUnread") {
                dispatch({ type: "RESET_UNREAD", payload: data.ticketId });
            }

            if (
                data.action === "update" &&
                shouldUpdateTicket(data.ticket) &&
                shouldNotify(data.ticket) &&
                data?.ticket?.isGroup === false
            ) {
                dispatch({ type: "UPDATE_TICKET", payload: data.ticket });
                handleNotifications(data)
            }

            if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
                dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
            }
        };

        // Listen to socket event
        const events = []
        userTenantQueues.forEach((queueId) => {
            const statuses = ["open", "pending"]
            statuses.forEach((status) => {
                events.push(`queue:${queueId}:status:${status}`)
                if (!limitNoQueue) events.push(`queue:no-queue:status:${status}`)
            })
        })

        // Start listeners
        events.forEach((event) => socket.on(event, handleChatsChange))

        // Clean up
        return () => {
            events.forEach((event) => socket.off(event, handleChatsChange))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitNoQueue, socket, userTenant, userTenantQueues])






















    // Handlers
    // Handle Message Changes
    // const handleMessageChange = (data) => {
    //     let checkSettings = true;

    //     if (limitNoQueue === true) {
    //         const hasPermission = userTenant.queues.some(q => q.id === data.ticket.queueId);
    //         if (!userTenantAdminRole && !hasPermission) checkSettings = false;
    //     }

    //     if (
    //         data.action === "create" &&
    //         !data.message.read &&
    //         checkSettings &&
    //         (data.ticket.userTenantId === userTenant?.id || !data.ticket.userTenantId)
    //     ) {
    //         const shouldNotNotify = ((
    //             (
    //                 data.message.ticketId === data.ticket.id && // Check if the message is for the same ticket
    //                 data.ticket.uuid === uuid // Check if the ticket is the same from the UUID
    //             ) && (
    //                 document.visibilityState === "visible" // Check if the ticket from the ticket and message notification are visible
    //             )
    //         ) || (
    //                 data.ticket.userTenantId && // Check if ticket has user
    //                 data.ticket.userTenantId !== userTenant?.id // CHeck if the ticket user is the same as current user
    //             ) ||
    //             data.ticket.isGroup || // Should not notify messages from groups
    //             data.message.importedAt ||
    //             !checkSettings // Should check groups
    //         )

    //         if (shouldNotNotify) return;

    //         setNotifications(prevState => {
    //             const ticketIndex = prevState.findIndex(ticket => ticket.id === data.ticket.id);
    //             if (ticketIndex !== -1) {
    //                 prevState[ticketIndex] = data.ticket;
    //                 return [...prevState];
    //             }
    //             return [data.ticket, ...prevState];
    //         });

    //         handleNotifications(data);
    //     }
    // };
    // useSocketListener(`message`, (data) => handleMessageChange(data))

    // Handle Chats Changes
    // const handleChatsChange = (data) => {
    //     if (data.action === "updateUnread" || data.action === "delete") {
    //         setNotifications(prevState => {
    //             const ticketIndex = prevState.findIndex(ticket => ticket.id === data.ticketId);
    //             if (ticketIndex !== -1) {
    //                 prevState.splice(ticketIndex, 1);
    //                 return [...prevState];
    //             }
    //             return prevState;
    //         });

    //         // setDesktopNotifications(prevState => {
    //         // 	const notfiticationIndex = prevState.findIndex(
    //         // 		notification => notification.tag === String(data.ticketId)
    //         // 	);
    //         // 	if (notfiticationIndex !== -1) {
    //         // 		prevState[notfiticationIndex].close();
    //         // 		prevState.splice(notfiticationIndex, 1);
    //         // 		return [...prevState];
    //         // 	}
    //         // 	return prevState;
    //         // });
    //     }
    // };
    // useSocketListener(`chat:update`, (data) => handleChatsChange(data))


    const handleNotifications = data => {
        // const { message, contact, ticket } = data;

        // const options = {
        //     // body: `${message.body} - ${format(new Date(), "HH:mm")}`,
        //     badge: '/bits-white-alpha.png',
        //     body: message.body,
        //     image: message?.mediaUrl,
        //     icon: contact.profilePicUrl,
        //     tag: ticket.uuid,
        //     renotify: true,
        // };

        // const notification = new Notification(
        //     // `${i18n.t("translation.tickets.notification.message")} ${contact.name}`,
        //     contact.name,
        //     options
        // );

        // notification.onclick = e => {
        //     e.preventDefault();
        //     window.focus();
        //     navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
        // };

        // setDesktopNotifications(prevState => {
        // 	const notfiticationIndex = prevState.findIndex(
        // 		n => n.tag === notification.tag
        // 	);
        // 	if (notfiticationIndex !== -1) {
        // 		prevState[notfiticationIndex] = notification;
        // 		return [...prevState];
        // 	}
        // 	return [notification, ...prevState];
        // });

        soundAlertRef.current();
    };

    const handleClick = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const NotificationTicket = ({ children }) => {
        return <div onClick={handleClickAway}>{children}</div>;
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                ref={anchorEl}
                color="inherit"
                size="large"
            >
                <Badge
                    badgeContent={ticketList.length}
                    color="error"
                    overlap="rectangular"
                >
                    <SmsTwoTone />
                </Badge>
            </IconButton>

            <Popover
                disableScrollLock
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{ paper: classes.popoverPaper }}
                onClose={() => handleClickAway()}
            >
                <List
                    dense
                    className={classes.tabContainer}
                >
                    {ticketList.length === 0 ? (
                        <ListItem>
                            <ListItemText>
                                {i18n.t("translation.notifications.noTickets")}
                            </ListItemText>
                        </ListItem>
                    ) : (
                        ticketList.map(ticket => (
                            <NotificationTicket
                                key={ticket.id}
                            >
                                <TicketListItem
                                    key={ticket.id}
                                    ticket={ticket}
                                />
                            </NotificationTicket>
                        ))
                    )}
                </List>
            </Popover>
        </>
    );
};

export default NotificationsPopOver;
