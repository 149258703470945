import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(2, 0),
        border: "none",
        height: "1px",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
    dividerSilent: {
        margin: theme.spacing(1, 0),
        height: "1px",
        width: "100%",
    },
}));

export default function Divider(props) {
    const classes = useStyles();
    return (
        <div
            className={props.silent
                ? classes.dividerSilent
                : classes.divider
            }
        ></div>
    );
}
