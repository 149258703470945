import { useEffect } from "react";
import useSocket from "..";
import toastError from "../../../errors/toastError";

const useSocketListener = (event, callback) => {
    const { socket } = useSocket();

    useEffect(() => {
        if (!socket) {
            return toastError("SOCKET_ERROR_SOCKET_NOT_AVAILABLE", "", "While trying to listen to event on Socket.IO server.");
        }

        // Check event data
        if (!event || !callback) {
            return toastError("SOCKET_ERROR_EVENT_DATA_NOT_LISTENABLE", "", "While trying to listen to event with custom callback on Socket.IO server.");
        }

        // Function to handle event
        const handleEvent = (data) => callback(data);

        // Listen to socket event
        socket.on(event, handleEvent);

        // Clean up
        return () => {
            socket.off(event, handleEvent);
        }
    }, [socket, event, callback])
}

export default useSocketListener
