import ReactPlayer from "react-player";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const useStyles = makeStyles((theme) => ({
	auto: {
		width: "100%",
		minWidth: "40vw",
		minHeight: "27vw",
		margin: "0 auto"
	}
}))

const TutorialModal = (props) => {
	const classes = useStyles();
	const axiosPrivate = useAxiosPrivate();

	const handleRegisterTutorialView = async () => {
		try {
			await axiosPrivate.post("/tutorial/", {
				tutorialRef: props.content,
				type: "video",
				status: 1
			});
			toast.success(i18n.t("translation.tutorialModal.success", { tutorial: i18n.t(`translation.tutorialModal.tutorials.${props.content}.title`) }));
		} catch (err) {
			toastError(err, true);
		}
	}

	return (
		<Dialog
			open={props.open}
			onClose={() => props.onClose(false)}
			aria-labelledby="tutorial-dialog"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="tutorial-dialog" >
				{i18n.t(`translation.tutorialModal.tutorials.${props.content}.title`)}
			</DialogTitle>
			<DialogContent dividers >
				<ReactPlayer
					className={classes.auto}
					url={i18n.t(`translation.tutorialModal.tutorials.${props.content}.content`)}
					light={props.light}
					onClickPreview={handleRegisterTutorialView}
					playing={props.playing}
					controls={props.controls}
					width="100%"
				/>
			</DialogContent>
		</Dialog>
	);
};

export default TutorialModal;
