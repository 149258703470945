import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import {} from "react-i18next";

import resources from "./locales";

i18n.use(LanguageDetector).init({
    debug: false,
    defaultNS: ['translation'],
    nsSeparator: ".",
    fallbackLng: "en",
    interpolation: { espaceValue: false },
    resources: resources,
    parseMissingKeyHandler: (key) => {
        return `Translation for "${key}" not found.`;
    }
});

if (process.env.NODE_ENV === "development") {
    i18n.changeLanguage('pt', (err, t) => {
        if (err) return console.error('Something went wrong loading', err);
        // t('key'); // -> same as i18next.t
    });
}

export { i18n };
