import { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import useAuth from "../../hooks/useAuth";
import useSocket from "../../hooks/useSocket";
import { SocketEmiter, useSocketListener } from "../../hooks/useSocket/Events";
import logger from "../../services/logger";

const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
    const { user, setUser } = useAuth();
    const { socket } = useSocket();

    const { tenantSlug } = useParams();
    const [tenant, setTenant] = useState();
    const [tenantId, setTenantId] = useState();
    const [userTenant, setUserTenant] = useState();
    const [userTenantAdminRole, setUserTenantAdminRole] = useState(false);

    const navigate = useNavigate();

    const getBackToTenantSelect = () => {
        if (tenantSlug) {
            toastError(i18n.t("translation.tenant.toasts.noAccess"), "", `Unauthorized user trying to access tenant "${tenantSlug}".`);
            navigate("/");
        }
    }

    // Emit socket event to join tenant
    const joinTenant = (tenantId) => {
        SocketEmiter(socket, "tenant:join", tenantId);
    }

    useEffect(() => {
        let isMounted = true;
        if (!tenantSlug || tenantSlug === "tenants") return;

        if (tenantSlug && tenantSlug !== "tenants") {
            const fetchTenant = (slug) => {
                const userTenant = user?.userTenant?.find(t => t?.tenant?.slug === slug);
                return userTenant;
            };

            const tenantData = fetchTenant(tenantSlug);
            if (!tenantData && isMounted) {
                return getBackToTenantSelect();
            }
            const { id, role, status, whatsappId, queues } = tenantData;

            // if (status === true) {
            let parsedTenantData = { ...tenantData.tenant, id: tenantData.tenantId }
            let parsedUserTenantData = { id, queues, role, status, whatsappId }

            if (status === false) {
                return getBackToTenantSelect()
            }

            setTenant(parsedTenantData);
            setTenantId(tenantData.tenantId);
            setUserTenant(parsedUserTenantData);

            // Check if user from admin role
            const roles = ["super", "admin", "supervisor"];
            setUserTenantAdminRole(roles.indexOf(role) !== -1);

            // Emit socket event to join tenant
            joinTenant(tenantData.tenantId)
        }

        return () => isMounted = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, tenantSlug]);

    // Handle Tenant Changes
    const handleTenantChange = (data) => {
        if (data.action === "update" && data.tenant.id === tenantId) {
            setTenant(data.tenant)
        }

        if (
            (data.action === "delete" && +data.tenantId === tenantId) ||
            (data.action === "disable" && data.tenant.id === tenantId)
        ) {
            logger({ type: "info", title: "Tenant", content: "The current tenant is disabled or is from now on unavailable." })

            // Remove the tenant from user
            const userTenants = user?.userTenant?.filter((userTenant) => userTenant.tenantId !== tenantId);
            user.userTenant = userTenants
            setUser(user);

            // Send user back to the tenant selection page
            navigate("/")
        }
    }

    // Listen to Tenant Updates
    useSocketListener("tenant", (data) => handleTenantChange(data))

    const userData = (data) => {
        const getUserTenant = data?.userTenant?.find(t => t.tenantId === tenantId);
        return getUserTenant;
    };

    return (
        <TenantContext.Provider
            value={{
                tenant,
                tenantId,
                tenantSlug,
                userData,
                userTenant,
                userTenantAdminRole
            }}
        >
            {/* {children} */}
            <Outlet />
        </TenantContext.Provider>
    );
};

export default TenantContext;
