import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
    Addchart,
    CloudDownload,
    CoPresent,
    DeleteOutline,
    Description,
    EditOutlined,
    MenuBook,
    MusicNote,
    OndemandVideo,
    PictureAsPdf,
    Search,
    Web,
    WhatsApp
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { DataGrid, gridClasses } from '@mui/x-data-grid';

import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal/";
import Title from "../../components/LayoutComponents/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MarkdownWrapper from "../../components/MarkdownWrapper";
import ModalImageCors from "../../components/ModalImageCors";
import ScheduledMessagesModal from "../../components/ScheduledMessagesModal";
import TutorialModalButton from "../../components/TutorialModalButton";

import StatusComponent from "../../components/Status";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        border: "1px solid",
        marginLeft: theme.spacing(1),
    },
    downloadButtons: {
        border: "1px solid",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    contactName: {
        paddingLeft: theme.spacing(2)
    },
    container: {
        paddingLeft: theme.spacing(2),
    },
    formControl: {
        marginRight: "16px",
        "& input": {
            height: "1.2195em"
        }
    },
    gridPaper: {
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    imagePreview: {
        margin: "0 auto",
        maxHeight: 40,
        maxWidth: 40
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    root: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            flexWrap: "wrap",
        }
    },
    searchMobile: {
        width: "100%",
        padding: "0 8px 16px",
    },
    wrap: {
        whiteSpace: "normal !important",
        wordWrap: "break-word !important"
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_SCHEDULEDMESSAGES") {
        const scheduledMessages = action.payload;
        const newScheduledMessages = [];

        scheduledMessages.forEach((scheduledMessage) => {
            const scheduledMessageIndex = state.findIndex((m) => m.id === scheduledMessage.id);
            if (scheduledMessageIndex !== -1) {
                state[scheduledMessageIndex] = scheduledMessage;
            } else {
                newScheduledMessages.push(scheduledMessage);
            }
        });

        return [...state, ...newScheduledMessages];
    }

    if (action.type === "UPDATE_SCHEDULEDMESSAGES") {
        const scheduledMessage = action.payload;
        const scheduledMessageIndex = state.findIndex((m) => m.id === scheduledMessage.id);

        if (scheduledMessageIndex !== -1) {
            state[scheduledMessageIndex] = scheduledMessage;
            return [...state];
        } else {
            return [scheduledMessage, ...state];
        }
    }

    if (action.type === "DELETE_SCHEDULEDMESSAGES") {
        const scheduledMessageId = action.payload;

        const scheduledMessageIndex = state.findIndex((m) => m.id === scheduledMessageId);
        if (scheduledMessageIndex !== -1) {
            state.splice(scheduledMessageIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const ScheduledMessages = () => {
    const classes = useStyles();
    const { tenant, tenantId, userTenant } = useTenant();

    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedMessageId, setSelectedMessageId] = useState(null);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deletingScheduledMessage, setDeletingScheduledMessage] = useState(null);
    const [scheduledMessagesModalOpen, setScheduledMessagesModalOpen] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [scheduledMessages, dispatch] = useReducer(reducer, []);
    const [scheduledMessagesCount, setScheduledMessagesCount] = useState();

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [rows, setRows] = useState([{
        attachment: '',
        avatar: '',
        contact: '',
        contactId: '',
        createdAt: '',
        id: '',
        message: '',
        sendAt: '',
        sentBy: '',
        status: ''
    }]);

    useEffect(() => {
        if (!scheduledMessages) return;

        let dataGridRows = scheduledMessages.map(m => ({
            attachment: m?.mediaUrl,
            avatar: m?.contact?.profilePicUrl,
            contact: m?.contact?.name,
            contactId: m?.contactId,
            createdAt: m?.createdAt,
            id: m?.id,
            message: m?.message,
            sendAt: m?.sendAt,
            sentBy: m?.userTenant?.user?.name,
            status: m?.status
        }));
        setRows(dataGridRows)

    }, [scheduledMessages]);

    //TODO: componetize for the entire app
    const renderPreview = fileUrl => {
        const images = ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "ico", "apng", "png", "webp", "svg"];
        const videos = ["mp4", "mkv", "webm", "avi", "flv", "mov"];
        const audios = ["mpeg", "m4a", "wav", "wave", "mp3", "ogg"];
        const ebook = ["epub", "mobi", "azw", "pdb", "prc"];
        const doc = ["pdf", "xps"];
        const text = ["doc", "docm", "docx", "dot", "dotm", "dotx", "odt", "rtf", "txt", "pages"];
        const spreadsheet = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "csv", "ods", "number"];
        const presentation = ["pptx", "pptm", "ppt", "potx", "potm", "pot", "ppsx", "pps", "odp", "key"];
        const web = ["html", "htm"];

        const fileExt = fileUrl.split('.').pop();

        const getFileName = () => {
            let breakPath = fileUrl.split("/");
            let pathCount = breakPath.length - 1;
            let fileName = breakPath[pathCount]
            return fileName
        }

        const downloadFile = () => {
            const element = document.createElement("a");
            element.href = fileUrl;
            element.target = "_blank";
            element.rel = "noopener noreferrer";
            element.download = getFileName();

            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }

        switch (true) {
            case (images.indexOf(fileExt) !== -1):
                return (
                    <ModalImageCors
                        imageUrl={fileUrl}
                    />
                );

            case (videos.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.video")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <OndemandVideo />
                        </IconButton>
                    </Tooltip>
                );

            case (audios.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.audio")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <MusicNote />
                        </IconButton>
                    </Tooltip>
                );

            case (ebook.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.ebook")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <MenuBook />
                        </IconButton>
                    </Tooltip>
                );

            case (doc.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.print")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <PictureAsPdf />
                        </IconButton>
                    </Tooltip>
                );

            case (text.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.document")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <Description />
                        </IconButton>
                    </Tooltip>
                );

            case (spreadsheet.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.spreadsheet")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <Addchart />
                        </IconButton>
                    </Tooltip>
                );

            case (presentation.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.presentation")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <CoPresent />
                        </IconButton>
                    </Tooltip>
                );

            case (web.indexOf(fileExt) !== -1):
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.web")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <Web />
                        </IconButton>
                    </Tooltip>
                );

            default:
                return (
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={i18n.t("translation.variables.fileTypes.cloud")}
                    >
                        <IconButton
                            color="primary"
                            onClick={() => downloadFile(fileUrl)}
                            size="small"
                            className={classes.downloadButtons}
                        >
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                )
        }
    }

    const columns = useMemo(() => [
        {
            field: "contact",
            headerName: i18n.t("translation.variables.table.contact"),
            flex: 0.6,
            minWidth: 260,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            renderCell: params => (
                <>
                    <Avatar src={params.row.avatar} alt={params.row.contact} variant="rounded" />
                    <Typography className={classes.contactName} variant="inherit">{params.row.contact}</Typography>
                </>
            )
        },
        {
            field: "message",
            headerName: i18n.t("translation.variables.table.message"),
            flex: 1,
            minWidth: 200,
            headerAlign: 'left',
            align: 'left',
            renderCell: params => (
                <MarkdownWrapper>{params.row.message}</MarkdownWrapper>
            )
        },
        {
            field: "attachment",
            headerName: i18n.t("translation.variables.table.attachment.title"),
            flex: 0.3,
            minWidth: 80,
            type: "string",
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                params.row.attachment ? (
                    <div className={classes.imagePreview}>
                        {renderPreview(params.row.attachment)}
                    </div>
                ) : (
                    i18n.t("translation.variables.table.attachment.none")
                )
            )
        },
        {
            field: "sentBy",
            headerName: i18n.t("translation.variables.table.sentBy"),
            flex: 0.3,
            minWidth: 80,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "createdAt",
            headerName: i18n.t("translation.variables.table.createdAt"),
            flex: 0.3,
            minWidth: 80,
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (

                new Date(params.row.createdAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short', timeStyle: 'short' })
            )
        },
        {
            field: "status",
            headerName: i18n.t("translation.variables.table.status.title"),
            flex: 0.3,
            minWidth: 80,
            description: i18n.t("translation.dashboard.teams.table.descriptions.nps"),
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <StatusComponent status={params.row.status} />
            )
        },
        {
            field: "senddAt",
            headerName: i18n.t("translation.variables.table.dateTime"),
            flex: 0.3,
            minWidth: 80,
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (

                new Date(params.row.sendAt).toLocaleString(i18n.t("translation.validation.locale"), { dateStyle: 'short', timeStyle: 'short' })
            )
        },
        {
            field: "actions",
            headerName: i18n.t("translation.variables.table.actions"),
            flex: 0.4,
            minWidth: 120,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => (
                <>
                    <IconButton
                        size="small"
                        className={classes.actionButtons}
                        onClick={() => handleSaveTicket(params.row.contactId)}
                        color="primary"
                    >
                        <WhatsApp />
                    </IconButton>
                    {params.row.status === "pending" || params.row.status === "error" ? (
                        <Can
                            role={userTenant?.role}
                            perform="schedule-page:edit:message:self"
                            yes={() => (
                                <IconButton
                                    size="small"
                                    className={classes.actionButtons}
                                    color="primary"
                                    onClick={() => {
                                        handleEditScheduledMessagesModal(params.row.id);
                                        setSelectedContactId(params.row.contactId)
                                    }}
                                >
                                    <EditOutlined />
                                </IconButton>
                            )}
                        />
                    ) : ("")}
                    {params.row.status === "sent" || params.row.status === "error" || params.row.status === "pending" ? (
                        <Can
                            role={userTenant?.role}
                            perform="schedule-page:delete:message:self"
                            yes={() => (
                                <IconButton
                                    title={i18n.t("translation.validation.buttons.delete")}
                                    size="small"
                                    className={[classes.actionButtons, classes.buttonColorError].join(' ')}
                                    onClick={e => {
                                        setConfirmOpen(true);
                                        setDeletingScheduledMessage(params.row.id)
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            )}
                        />
                    ) : (
                        ""
                    )}
                </>
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [scheduledMessages, scheduledMessagesCount]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchScheduledMessage = async () => {
            try {
                const { data } = await axiosPrivate.get(`/${tenantId}/scheduledMessages/`,
                    { params: { searchParam, pageNumber } },
                    { signal }
                );
                dispatch({ type: "LOAD_SCHEDULEDMESSAGES", payload: data.scheduledMessage });
                setScheduledMessagesCount(data.count);
            } catch (err) {
                toastError(err);
            }
        };
        if (tenantId) fetchScheduledMessage();

        return () => controller.abort();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber, tenantId]);

    // Handle Scheduled Message Changes
    const handleScheduledMessageChange = (data) => {
        if (data.action === "update" || data.action === "create") {
            dispatch({ type: "UPDATE_SCHEDULEDMESSAGES", payload: data.scheduledMessage });
        }

        if (data.action === "delete") {
            dispatch({ type: "DELETE_SCHEDULEDMESSAGES", payload: +data.scheduledMessageId });
        }
    };
    useSocketListener(`scheduled-message`, (data) => handleScheduledMessageChange(data))

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenScheduledMessagesModal = () => {
        setSelectedMessageId(null);
        setScheduledMessagesModalOpen(true);
    };

    const handleCloseScheduledMessagesModal = () => {
        setSelectedMessageId(null);
        setSelectedContactId(null);
        setScheduledMessagesModalOpen(false);
    };

    const handleSaveTicket = async (contactId) => {
        if (!contactId || !tenantId) return;
        try {
            const { data: ticket } = await axiosPrivate.post(`/${tenantId}/tickets`, {
                contactId: contactId,
                pauseAutoCloseTimer: true,
                userTenantId: userTenant?.id,
                status: "open",
            });
            navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleEditScheduledMessagesModal = MessageId => {
        setSelectedMessageId(MessageId);
        setScheduledMessagesModalOpen(true);
    };

    const handleDeleteScheduledMessage = async (scheduledMessageId, status) => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/scheduledMessages/${scheduledMessageId}`);
            toast.success(i18n.t("translation.scheduledMessages.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingScheduledMessage(null);
        setSearchParam("");
        setPageNumber(1);
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <ScheduledMessagesModal
                userTenant={userTenant}
                open={scheduledMessagesModalOpen}
                onClose={handleCloseScheduledMessagesModal}
                messageId={selectedMessageId}
                contactId={selectedContactId}
            />
            <ConfirmationModal
                title={
                    deletingScheduledMessage
                        ? i18n.t("translation.scheduledMessages.confirmationModal.delete.title")
                        : null
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={(e) =>
                    deletingScheduledMessage
                        ? handleDeleteScheduledMessage(deletingScheduledMessage)
                        : null
                }
            >
                {deletingScheduledMessage
                    ? i18n.t("translation.scheduledMessages.confirmationModal.delete.message")
                    : null
                }
            </ConfirmationModal>

            <MainHeader>
                <Title>
                    {i18n.t("translation.scheduledMessages.title")}
                    <TutorialModalButton content="scheduledMessages" />
                </Title>
                <MainHeaderButtonsWrapper>
                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <TextField
                            className={classes.formControl}
                            placeholder={i18n.t("translation.variables.placeholders.search")}
                            type="search"
                            value={searchParam}
                            onChange={handleSearch}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Can
                            role={userTenant?.role}
                            perform="schedule-page:add:message"
                            yes={() => (
                                <Button
                                    className={classes.formControl}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenScheduledMessagesModal}
                                >
                                    {i18n.t("translation.scheduledMessages.buttons.add")}
                                </Button>
                            )} />
                    </Box>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <Can
                            role={userTenant?.role}
                            perform="schedule-page:add:message"
                            yes={() => (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenScheduledMessagesModal}
                                >
                                    {i18n.t("translation.scheduledMessages.buttons.add")}
                                </Button>
                            )} />
                    </Box>
                </MainHeaderButtonsWrapper>
            </MainHeader>

            <Box display={{ xs: "block", sm: "none" }}>
                <TextField
                    className={classes.searchMobile}
                    placeholder={i18n.t("translation.variables.placeholders.search")}
                    type="search"
                    value={searchParam}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Paper
                className={classes.mainPaper}
                variant="outlined"
            // onScroll={handleScroll}
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid item sx={{
                            width: "100%"
                        }}>
                            {rows && <DataGrid
                                className={classes.gridPaper}
                                rows={rows}
                                columns={columns}
                                autoHeight={true}
                                getRowHeight={() => 'auto'}
                                pageSize={20}
                                getRowId={row => row.id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                            />}

                        </Grid>
                    </div>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default ScheduledMessages;
