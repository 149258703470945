import { AttachFile, Cancel } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    newMessageBox: {
        marginTop: theme.spacing(1),
        width: "100%",
        display: "flex",
        padding: "7px",
        alignItems: "left",
        border: "1px solid",
        borderRadius: 15,
    },
    uploadInput: {
        display: "none",
    },
}))

export function InputFile({ medias, changeMedias }) {
    const classes = useStyles();

    const handleInputMedias = (e) => {
        if (!e.target.files) return;
        const selectedMedias = Array.from(e.target.files);
        let renamedMedias = [];
        for (let media of selectedMedias) {
            const file = new File([media], `qka_${media.name}`, {
                type: media.type,
            });
            renamedMedias.push(file);
        }
        changeMedias(renamedMedias);
    };

    if (medias?.length > 0) {
        return (
            <>
                <div className={classes.newMessageBox}>
                    <Button
                        startIcon={<Cancel color="error" />}
                        component="span"
                        onClick={(e) => changeMedias([])}
                        size="small"
                    >
                        <Typography>
                            {medias[0]?.name}
                            {medias.length > 1
                                ? i18n.t("translation.validation.medias.hasOthers", { medias: medias.length })
                                : ""}
                        </Typography>
                    </Button>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={classes.newMessageBox}>
                    <input
                        multiple
                        type="file"
                        id="upload-button2"
                        className={classes.uploadInput}
                        onChange={handleInputMedias}
                    />
                    <label htmlFor="upload-button2">
                        <Button
                            startIcon={<AttachFile />}
                            component="span"
                            size="small"
                        >
                            <Typography>
                                {i18n.t("translation.validation.medias.none")}
                            </Typography>
                        </Button>
                    </label>
                </div>
            </>
        );
    }
}
