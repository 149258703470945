import React, { useEffect, useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";

import toastError from '../../errors/toastError';
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";

const useStyles = makeStyles((theme) => ({
    dialogModal: {
        ...theme.scrollbarStyles,
    }
}));

const NPSModal = ({ open, handleClose, status, dateStart, dateEnd, queueId }) => {
    const classes = useStyles();
    const { tenantId } = useTenant();
    const axiosPrivate = useAxiosPrivate();
    const [NPS, setNPS] = useState([]);

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                if (open === true && tenantId) {
                    const response = await axiosPrivate.post(`/${tenantId}/dashboard/nps`, {
                        status,
                        dateStart,
                        dateEnd,
                        queueId
                    });
                    setNPS(response.data);
                }
            } catch (err) {
                toastError(err, "", "Error ocurred while trying to fetch NPS Modal dashboard data at the Dashboard page.");
            }
        };

        fetchData();

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, dateStart, dateEnd, status, tenantId, queueId]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll='paper'
            fullWidth={true}
            className={classes.dialogModal}
        >
            <DialogTitle color={status === "low" ? "error" : ""}>
                {status === "low"
                    ? i18n.t("translation.dashboard.nps.low.modal")
                    : status === "ok"
                        ? i18n.t("translation.dashboard.nps.ok.modal")
                        : i18n.t("translation.dashboard.nps.great.modal")
                }
            </DialogTitle>
            <DialogContent dividers>
                <List>
                    {NPS.map(nps => {
                        return (
                            <TicketListItem
                                key={nps.ticket.id}
                                ticket={nps.ticket}
                                nps={nps}
                            />
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                >
                    {i18n.t("translation.variables.buttons.ok")}
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default NPSModal;
