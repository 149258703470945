import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';

import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import useWhatsApps from "../../hooks/useWhatsApps";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Can } from "../Can";
import ConnectionItems from "../ConnectionItems";
import ContactModal from "../ContactModal";


const useStyles = makeStyles(theme => ({
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    connection: {
        marginTop: theme.spacing(2.5),
        borderRadius: "100px"
    },
    input: {
        width: 300,
        // marginBottom: theme.spacing(2)
    },
    maxWidth: {
        width: "100%",
    },
}));

const filter = createFilterOptions({
    trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, ticketWhatsappId, contactId }) => {
    const classes = useStyles();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState("");
    const [newContact, setNewContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);

    const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);

    const { tenant, tenantId, userTenant } = useTenant();

    const { loadingWhatsapps, whatsApps } = useWhatsApps();
    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const fetchContacts = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/contacts`, {
                        params: { searchParam },
                    });
                    isMounted && setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            }
        };

        fetchContacts();
        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, modalOpen, tenantId]);


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        if (!modalOpen || searchParam.length < 3) return;

        setLoading(true);
        const fetchContacts = async () => {
            if (tenantId) {
                try {
                    const { data } = await axiosPrivate.get(`/${tenantId}/contact/${contactId}`);
                    isMounted && setOptions(data.contact);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err, "", "Error while trying to load contact ID on new ticket modal.");
                }
            }
        };

        fetchContacts();
        return () => {
            isMounted = false;
            controller.abort();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId, modalOpen, tenantId]);

    const handleClose = () => {
        onClose();
        setSearchParam("");
        setSelectedContact(null);
    };

    const handleSaveTicket = async contactId => {
        if (!contactId) return;
        if (selectedQueue === "") {
            toast.error(i18n.t("translation.newTicketModal.toasts.selectQueue"));
            return;
        }
        setLoading(true);
        if (tenantId) {
            try {
                const queueId = selectedQueue !== "" ? selectedQueue : null;
                const { data: ticket } = await axiosPrivate.post(`/${tenantId}/tickets`, {
                    contactId: contactId,
                    pauseAutoCloseTimer: true,
                    queueId,
                    userTenantId: userTenant.id,
                    status: "open",
                    type: 2
                });
                navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
            } catch (err) {
                toastError(err);
            }
        }
        setLoading(false);
        handleClose();
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue);
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name });
            setContactModalOpen(true);
        }
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };

    const handleAddNewContactTicket = contact => {
        handleSaveTicket(contact.id);
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const renderOption = (props, option) => {
        if (option.number) {
            return <li {...props}>{option.name} - {option.number}</li>;
        } else {
            return <li {...props}>{i18n.t("translation.newTicketModal.add")} {option.name}</li>;
        }
    };

    const renderOptionLabel = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContactTicket}
            ></ContactModal>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("translation.newTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    {contactId
                        ? <div className={classes.input}></div>
                        : (
                            <Autocomplete
                                options={options}
                                loading={loading}
                                className={classes.input}
                                clearOnBlur
                                autoHighlight
                                freeSolo
                                clearOnEscape
                                getOptionLabel={renderOptionLabel}
                                renderOption={renderOption}
                                filterOptions={createAddContactOption}
                                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={i18n.t("translation.newTicketModal.fieldLabel")}
                                        variant="outlined"
                                        autoFocus
                                        // disabled={contactId? true :false}
                                        onChange={e => setSearchParam(e.target.value)}
                                        onKeyPress={e => {
                                            if (loading || !selectedContact) return;
                                            else if (e.key === "Enter") {
                                                handleSaveTicket(selectedContact.id);
                                            }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}

                    <FormControl variant="outlined" className={classes.maxWidth}>
                        <InputLabel>{i18n.t("translation.ticketsList.acceptModal.queue")}</InputLabel>
                        <Select
                            value={selectedQueue}
                            className={classes.autoComplete}
                            onChange={(e) => setSelectedQueue(e.target.value)}
                            label={i18n.t("translation.ticketsList.acceptModal.queue")}
                        >
                            <MenuItem value={''}>&nbsp;</MenuItem>
                            {userTenant?.queues?.length > 0 &&
                                userTenant?.queues?.map((queue, key) => (
                                    <MenuItem key={key} value={queue.id}>{queue.name}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Can
                        role={userTenant?.role}
                        perform="ticket-options:transfer:connection"
                        yes={() => (!loadingWhatsapps &&
                            <FormControl
                                className={[classes.connection, classes.maxWidth].join(" ")}
                                variant="outlined"
                            >
                                <InputLabel>{i18n.t("translation.transferTicketModal.fieldConnectionLabel")}</InputLabel>
                                <Select
                                    value={selectedWhatsapp || ''}
                                    onChange={(e) => setSelectedWhatsapp(e.target.value)}
                                    label={i18n.t("translation.transferTicketModal.fieldConnectionPlaceholder")}
                                >
                                    {ConnectionItems(whatsApps)}
                                </Select>
                            </FormControl>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.buttonColorError}
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("translation.validation.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContact & !contactId}
                        onClick={() => handleSaveTicket(contactId ? contactId : selectedContact.id)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("translation.validation.buttons.create")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewTicketModal;
