import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
    autoComplete: {
        width: 300,
        // marginBottom: 20
    },
    maxWidth: {
        width: "100%",
    },
    buttonColorError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}));

const AcceptTicketWithouSelectQueue = ({ modalOpen, onClose, ticket }) => {
    const classes = useStyles();
    const [selectedQueue, setSelectedQueue] = useState('');
    const [loading, setLoading] = useState(false);
    const { tenant, tenantId, userTenant } = useTenant();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const handleClose = () => {
        onClose();
        setSelectedQueue("");
    };

    const handleUpdateTicketStatus = async (queueId) => {
        const controller = new AbortController();

        const updateTicket = async () => {
            if (tenantId) {
                setLoading(true);
                try {
                    await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, {
                        status: "open",
                        userTenantId: userTenant?.id || null,
                        queueId: queueId
                    }, { signal: controller.signal });
                    navigate(`/${tenant.slug}/tickets/${ticket.uuid}`);
                    onClose();
                } catch (err) {
                    toastError(err);
                }
            }
        };

        updateTicket();

        return () => {
            setLoading(false)
            controller.abort();
        };
    };

    return (
        <>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("translation.ticketsList.acceptModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="outlined" className={classes.maxWidth}>
                        <InputLabel>{i18n.t("translation.ticketsList.acceptModal.queue")}</InputLabel>
                        <Select
                            value={selectedQueue}
                            className={classes.autoComplete}
                            onChange={(e) => setSelectedQueue(e.target.value)}
                            label={i18n.t("translation.ticketsList.acceptModal.queue")}
                        >
                            <MenuItem value={''}>&nbsp;</MenuItem>
                            {userTenant?.queues?.map((queue) => (
                                <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        className={classes.buttonColorError}
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("translation.validation.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={(selectedQueue === "")}
                        onClick={() => handleUpdateTicketStatus(selectedQueue)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("translation.validation.buttons.start")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AcceptTicketWithouSelectQueue;
