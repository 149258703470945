import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import React from "react";
import usePlan from "../../hooks/usePlan";
import useTenant from "../../hooks/useTenant";
import logger from "../../services/logger";

// Styled component for the action container
const Actions = styled(DialogActions)({
    // display: 'flex',
    justifyContent: 'space-between', // Aligns items to the edges
});

const Test = () => {
    const { tenant, tenantId, userTenant } = useTenant();
    const { plan, getPlanValue } = usePlan();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    logger({
        type: "info",
        title: "Test (Plan)",
        content: plan
    })

    logger({
        type: "info",
        title: "Test (Plan Max Users)",
        content: getPlanValue("users")
    })

    logger({
        type: "info",
        title: "Test (Tenant Info)",
        content: { tenantId, tenant, userTenant }
    })

    return <>

        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Test Dialog
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>Custom Dialog</DialogTitle>
                <DialogContent dividers>
                    <h1>
                        Tenant: /{tenant?.slug}
                    </h1>
                    <p>
                        Name: {tenant?.name}<br />
                        E-mail: {tenant?.email}<br />
                        Role: {userTenant?.role}
                    </p>
                </DialogContent>
                <Actions>
                    <Button color="primary">Left Button</Button>
                    <Box sx={{ display: 'flex', gap: '8px' }}> {/* Grouping the right buttons */}
                        <Button color="secondary">Right Button 1</Button>
                        <Button color="secondary">Right Button 2</Button>
                    </Box>
                </Actions>
            </Dialog>
        </div>
    </>
};

export default Test;
