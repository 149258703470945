import React, { useEffect } from 'react';
import toastError from "../../errors/toastError";

import { Button, Divider, Link, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
	address: {
		fontSize: "small"
	},
	center: {
		display: "flex",
		flex: 1,
		justifyContent: "space-evenly",
	},
	clear: {
		clear: "both",
		content: "",
		display: "block",
	},
	coordinates: {
		fontSize: "small"
	},
	desc: {
		display: "flex",
		flexWrap: "wrap"
	},
	descText: {
		marginTop: "12px",
		marginLeft: "15px",
		marginRight: "15px",
		float: "left"
	},
	img: {
		width: "100px",
	},
	imgContainer: {
		float: "left",
	},
	mainContainer: {
		minWidth: "250px",
	},
	name: {
		// fontWeight: "regular"
	},
	url: {
		fontSize: "small"
	},
}));

const LocationPreview = ({ image, link, description }) => {
	const classes = useStyles();
	useEffect(() => { }, [image, link, description]);

	const handleLink = (url) => {
		try {
			window.open(url, "_blank");
		} catch (err) {
			toastError(err);
		}
	}

	return (
		<>
			<div className={classes.mainContainer}>
				<div>
					<div className={classes.imgContainer}>
						<img src={image} onClick={() => handleLink(link)} style={{ cursor: "pointer" }} alt="" className={classes.img} />
					</div>
					{description && (
						<div className={classes.desc}>
							<Typography className={classes.descText} variant="subtitle1" color="primary" gutterBottom>
								{description.type === "liveLocationMessage" &&
									<div className={classes.name}>{i18n.t("translation.location.live")}</div>
								}
								{description.name && description.name !== "undefined" &&
									<div className={classes.name} dangerouslySetInnerHTML={{ __html: description.name.replace('\\n', '<br />') }}></div>
								}
								{description.address && description.address !== "undefined" &&
									<div className={classes.address} dangerouslySetInnerHTML={{ __html: description.address.replace('\\n', '<br />') }}></div>
								}
								{(!description.address || description.address === "undefined") && (description.coordinates && description.coordinates !== "undefined") &&
									<div className={classes.coordinates} dangerouslySetInnerHTML={{ __html: description.coordinates.replace('\\n', '<br />') }}></div>
								}
								{description.url && description.url !== "undefined" &&
									<div className={classes.url}>
										<Link underline="hover" onClick={() => handleLink(description.url)} style={{ cursor: "pointer" }}>{description.url}</Link>
									</div>
								}
							</Typography>
						</div>
					)}
					<div className={classes.clear}></div>
					<Divider />
					<div className={classes.center}>
						<Button
							color="primary"
							onClick={() => handleLink(link)}
							disabled={!link}
						>{i18n.t("translation.location.view")}
						</Button>
						{description.url && description.url !== "undefined" &&
							<Button
								color="primary"
								onClick={() => handleLink(description.url)}
								disabled={!link}
							>{i18n.t("translation.location.site")}
							</Button>
						}
					</div>
				</div>
			</div>
		</>
	);

};

export default LocationPreview;