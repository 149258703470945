import {
    BuildCircleOutlined,
    CheckCircleOutlined,
    CreditCardOffOutlined,
    Error,
    ErrorOutline,
    HelpOutlineOutlined,
    PauseCircleOutline,
    PhonelinkOffOutlined,
    PlayCircleOutlined,
    ScheduleOutlined
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    status: {
        display: "flex",
        justifyContent: "space-between",
        columnGap: theme.spacing(1)
    }
}));

export default function StatusComponent(props) {
    const classes = useStyles();

    // Create variables
    const { status } = props;
    let icon
    let message

    switch (status) {
        case "fail":
            icon = (
                <Error
                    fontSize="small"
                    color="error"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.fail");
            break;
        case "sent":
            icon = (
                <CheckCircleOutlined
                    fontSize="small"
                    color="success"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.sent");
            break;
        case "paused":
            icon = (
                <PauseCircleOutline
                    color="error"
                    fontSize="small"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.paused");
            break;
        case "pending":
            icon = (
                <ScheduleOutlined
                    fontSize="small"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.pending");
            break;
        case "preview":
            icon = (
                <BuildCircleOutlined
                    fontSize="small"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.preview");
            break;
        case "processing":
            icon = (
                <PlayCircleOutlined
                    fontSize="small"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.processing");
            break;

        // Errors
        case "error":
            icon = (
                <ErrorOutline
                    fontSize="small"
                    className={classes.marginRight}
                    color="error"
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.error");
            break;
        case "insuficientCredits":
            icon = (
                <CreditCardOffOutlined
                    fontSize="small"
                    className={classes.marginRight}
                    color="error"
                    {...props}
                />
            );
            message = i18n.t(
                "translation.variables.table.status.insuficient_credits"
            );
            break;
        case "whatsappUnavailable":
            icon = (
                <PhonelinkOffOutlined
                    fontSize="small"
                    className={classes.marginRight}
                    color="error"
                    {...props}
                />
            );
            message = i18n.t(
                "translation.variables.table.status.whatsappUnavailable"
            );
            break;

        // Default
        default:
            icon = (
                <HelpOutlineOutlined
                    fontSize="small"
                    className={classes.marginRight}
                    {...props}
                />
            );
            message = i18n.t("translation.variables.table.status.unknown");
            break;
    }
    return (
        <div className={classes.status}>
            {icon} {message}
        </div>
    );
}
